import { Request } from "@/services/request";

export default {
  superAdminDashboard() {
    return new Request().append(`api/v1/superadmin/dashboard/`).method("get");
  },

  numberOfProvidersWaitingForApproval() {
    return new Request()
      .append(`api/v1/superadmin/providerforapproval/`)
      .method("get");
  },

  fetchNumberOfCustomerNeededToCompleteProfile() {
    return new Request()
      .append(`api/v1/superadmin/newcustomer/`)
      .method("get");
  },

  fetchNumberOfJob() {
    return new Request()
      .append(`api/v1/superadmin/jobs/`)
      .method("get");
  },
  fetchNumberOfInvoice() {
    return new Request()
      .append(`api/v1/superadmin/invoices/`)
      .method("get");
  },
  fetchNumberOfEstimate() {
    return new Request()
      .append(`api/v1/superadmin/estimates/`)
      .method("get");
  },
  signInAs(id, token) {
    return new Request()
      .append(`api/v1/customer/${id}/sign_in_as/`)
      .method("post")
      .setData({ g_token: token });
  },
};
