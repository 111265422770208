<template>
  <div class="job-detail">
    <loader-spinner v-if="loading" />

    <div
      v-else-if="errors.notFoundErrorMessage"
      class="row mt-4"
    >
      <div class="col-lg-8 offset-md-2">
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>
    </div>

    <section
      v-else
      class="content"
    >
      <div class="page-header">
        <div class="d-md-flex title-part mb-0">
          <router-link
            :to="{ name: 'ProviderInvoices' }"
            class="title me-1"
          >
            Invoices
          </router-link>

          <p
            v-if="invoice"
            class="title-content"
          >
            / {{ invoice.invoice_no }}
          </p>
        </div>

        <div class="d-flex align-items-center">
          <div class="position-proposal-icon me-3">
            <app-invoice-status
              v-if="invoice"
              :status="invoice.status || ''"
            />
          </div>

          <submission-spinner
            v-if="downloading || sendingInvoice || remindingInvoice || deletingInvoice"
            :color="'#209516'"
          />
          <div
            v-else
            class="btn-group btn-group-dropdown"
          >
            <button
              type="button"
              class="btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="icons.actionsIcon"
                alt="Actions Icon"
                class="icon-white"
              >
              Actions
            </button>
            <ul class="dropdown-menu">
              <li
                v-for="option in actionOptions"
                :key="option.value"
                @click="handleActionSelection(option.value)"
              >
                <a class="dropdown-item cursor-pointer">{{ option.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        id="element-to-convert"
        class="page-content"
      >
        <div
          v-if="provider"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ provider.full_name }}
                </h5>
                <p class="my-0">
                  {{ provider?.location_description }}
                </p>
                <p
                  class="my-0"
                >
                  {{ getCountryLabel(provider.country) }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end">
              <div
                v-if="showBalancePaid"
                class="col-md-12 align-self-end mb-3"
              >
                <h6 class="mb-2 text-secondary">
                  Balance Paid
                </h6>
                <h5 class="text-bold text-primary mb-0">
                  {{ formatPrice(balancePaid) }}
                </h5>
              </div>

              <div
                v-if="showBalanceDue"
                class="col-md-12 align-self-end mb-3"
              >
                <h6 class="mb-2 text-secondary">
                  Balance Due
                </h6>
                <h5 class="text-bold mb-0">
                  {{ formatPrice(balanceDue) }}
                </h5>
              </div>

              <div
                v-if="invoice.payed_date"
                class="col-md-12 align-self-end mb-3"
              >
                <h6 class="mb-2 text-secondary">
                  Payment Date
                </h6>
                <h6 class="fw-normal mb-0">
                  {{ formatDate(invoice.payed_date, "MMMM Do, YYYY hh:mm") }}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="job?.owner"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ job.owner.user.full_name }}
                </h5>
                <p class="my-0">
                  {{ job.owner.user.location_description }}
                </p>
                <p
                  class="my-0"
                >
                  {{ getCountryLabel(job.owner.user.country) }}
                </p>
                <p class="my-0">
                  {{ job.owner.user.phone }}
                </p>
                <p class="my-0">
                  {{ job.owner.user.email }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Creation Date :
                  </p>
                  <p class="col-md-4">
                    {{ formatDate(invoice.created_at, "MMMM Do, YYYY h:mm a") }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Due Date :
                  </p>
                  <p class="col-md-4">
                    {{ formatDate(invoice.due_date) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Job :
                  </p>
                  <p class="col-md-4">
                    {{ job.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="invoice?.client"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ invoice.client.full_name }}
                </h5>
                <p class="my-0">
                  {{ invoice.client.company_name }}
                </p>
                <p class="my-0">
                  {{ invoice.client.main_propriety?.location_description }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Invoice Date :
                  </p>
                  <p class="col-md-4">
                    {{ formatDate(invoice.created_at) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Due Date :
                  </p>
                  <p class="col-md-4">
                    {{ formatDate(invoice.due_date) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Primary contact :
                  </p>
                  <p class="col-md-4">
                    {{ invoice.client.primary_contact?.full_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 md-3">
          <div class="table-responsive">
            <table class="table">
              <head-table :list="thead" />
              <tbody>
                <tr
                  v-for="(item, index) in invoice.items"
                  :key="index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    <h6>
                      {{ item.item }}
                    </h6>
                    <p class="mb-2 text-secondary">
                      {{ item.description }}
                    </p>
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ formatPrice(item.value) }}
                  </td>
                  <td>
                    {{ formatPrice(item.quantity * item.value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-if="invoice.items?.length"
          class="col-md-12 my-5 text-end d-flex"
        >
          <div class="col-md-12 align-self-end mb-3">
            <div class="text-end">
              <div class="d-inline-block text-bold">
                <p>Sub Total : &nbsp;</p>
                <p>Tax : &nbsp;</p>
                <p>Service Fee : &nbsp;</p>
                <p>You will receive : &nbsp;</p>
                <p>Total : &nbsp;</p>
              </div>
              <div class="d-inline-block">
                <p>{{ formatPrice((invoice.subtotal || 0).toFixed(2)) }}</p>
                <p>{{ invoice.tax || 0 }}</p>
                <p>{{ formatPrice((invoice.provider_fee || 0).toFixed(2)) }}</p>
                <p>
                  {{ formatPrice((invoice.provider_total || 0).toFixed(2)) }}
                </p>
                <p>{{ formatPrice((invoice.total || 0).toFixed(2)) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-start">
          <div class="col-md-3 mb-3">
            <h5 class="mb-3 text-primary">
              Notes :
            </h5>
            <p class="my-0">
              {{ invoice.message }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";
import html2pdf from "html2pdf.js";

import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import actionsIcon from "@/assets/img/icons/actions.svg";

import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import PriceHelpers from "@/utils/price-format";
import CountryUtils from "@/utils/country";

import { GlobalConstants, InvoiceStatus } from "@/configs/constants";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderInvoiceDetails",

  components: {
    HeadTable,
    ConfirmModal,
    AppInvoiceStatus,
    LoaderSpinner,
    SubmissionSpinner,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      actionsIcon,
    },
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    invoice: {},
    job: {},
    provider: {},
    thead: ["#", "Item", "QTY", "Price", "Amount"],
    datasend: {
      items: [
        {
          item: null,
          value: null,
          quantity: null,
          description: null,
        },
      ],
      job: null,
      message: "",
      due_date: null,
      send: null,
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    actions: {
      EDIT: "edit",
      SEND: "send",
      REMIND: "remind",
      DELETE: "delete",
      DOWNLOAD: "download",
      EXTERNAL_PAYMENT: "external-payment",
    },
    downloading: false,
    sendingInvoice: false,
    remindingInvoice: false,
    deletingInvoice: false,
    submittingExternalPayment: false,
    errors: {
      items: null,
      job: null,
      mutableName: null,
      message: null,
      due_date: null,
      error: null,
      send: null,
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    actionOptions() {
      const items = [
        {
          label: "Download",
          value: this.actions.DOWNLOAD,
        },
      ];
      if (this.canEditOrSendInvoice) {
        items.push({
          label: "Edit",
          value: this.actions.EDIT,
        });
        items.push({
          label: "Send",
          value: this.actions.SEND,
        });
      }
      if (this.canRemindInvoice) {
        items.push({
          label: "Remind",
          value: this.actions.REMIND,
        });
      }
      if (this.canDeleteInvoice) {
        items.push({
          label: "Delete",
          value: this.actions.DELETE,
        });
      }
      if (this.canMakeExternalPayment) {
        items.push({
          label: "External payment",
          value: this.actions.EXTERNAL_PAYMENT,
        });
      }

      return items;
    },

    canEditOrSendInvoice() {
      if (!this.invoice) return false;
      return this.invoice.status === InvoiceStatus.UNSENT;
    },

    canRemindInvoice() {
      if (!this.invoice || !this.invoice?.send) return false;
      return (
        this.invoice.status === InvoiceStatus.PENDING &&
        new Date().getTime() > new Date(this.invoice?.send).getTime()
      );
    },

    canDeleteInvoice() {
      if (!this.invoice) return false;
      return [InvoiceStatus.UNSENT, InvoiceStatus.PENDING].includes(
        this.invoice.status
      );
    },

    canMakeExternalPayment() {
      if (!this.invoice) return false;
      return this.invoice.status === InvoiceStatus.PENDING
    },

    balanceDue() {
      let amount = 0;

      switch (this.invoice.status) {
        case InvoiceStatus.PENDING:
          amount = (this.invoice.total || 0).toFixed(2)
          break;

        case InvoiceStatus.PARTIAL:
          amount = ((this.invoice.total || 0) - (this.invoice.payed_value || 0)).toFixed(2)
          break;
      }
      
      return amount
    },

    balancePaid() {
      let amount = this.invoice.payed_value || 0;
      const total = this.invoice.total || 0

      if (amount > total) {
        amount = total
      }
      
      return amount.toFixed(2)
    },

    showBalancePaid() {
      if (!this.invoice) return false;
      return this.invoice.status !== InvoiceStatus.PENDING;
    },

    showBalanceDue() {
      if (!this.invoice) return false;
      return this.invoice.status !== InvoiceStatus.PAID;
    },

    showPaymentDate() {
      if (!this.invoice) return false;
      return this.invoice.status === InvoiceStatus.PAID;
    }
  },

  mounted() {
    this.fetchInvoiceById();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    ...CountryUtils,

    back() {
      this.$router.back();
    },

    getCountryLabel(code) {
      const country = this.getCountry(code)
      return country?.label || code;
    },
    
    handleActionSelection(action) {
      switch (action) {
        case this.actions.EDIT:
          this.$router.push({
            name: "ProviderInvoiceEdit",
            params: { id: this.invoice.id },
          });
          break;

        case this.actions.DOWNLOAD:
          this.donwloadInvoice();
          break;

        case this.actions.SEND:
          this.confirmClaim(this.actions.SEND);
          break;

        case this.actions.REMIND:
          this.confirmClaim(this.actions.REMIND);
          break;

        case this.actions.DELETE:
          this.confirmClaim(this.actions.DELETE);
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.confirmClaim(this.actions.EXTERNAL_PAYMENT);
          break;
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.SEND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to send this invoice ?`;
          break;

        case this.actions.REMIND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to remind the customer for this invoice payment ?`;
          break;

        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this invoice ?`;
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.modalConfigs.confirmTitle = "Confirm";
          this.modalConfigs.confirmMessage = `Confirm that the invoice has been paid outside of the ServiceLoop system`;
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.SEND:
          this.sendInvoice()
          break;

        case this.actions.REMIND:
          this.remindInvoice();
          break;

        case this.actions.DELETE:
          this.deleteInvoice();
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.setInvoiceExternalPayment();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },
    
    donwloadInvoice() {
      if (this.downloading) return;

      this.downloading = true;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: `${this.invoice.invoice_no}.pdf`,
      });
      setTimeout(() => {
        this.downloading = false;
      }, 2000);
    },

    async fetchInvoiceById() {
      this.loading = true;

      try {
        const response = await api.fetchInvoiceById(this.$route.params.id);
        this.invoice = response.data;
        this.job = response.data.job;
        this.provider = response.data.bid.owner.user;
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    sendInvoice() {
      this.sendingInvoice = true;

      const path = this.$router.resolve({
        name: "SharedInvoiceDetails",
        params: {
          id: this.invoice.id,
        },
      })?.path;
      const redirectLink = `${GlobalConstants.BASE_URL + path}`

      api
        .sendInvoice(this.invoice.id, redirectLink)
        .then((_) => {
          toast.success(`Invoice successfully sent.`);
          this.invoice.status = InvoiceStatus.PENDING;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.sendingInvoice = false;
        });
    },

    remindInvoice() {
      this.remindingInvoice = true;

      api
        .remindInvoice(this.invoice.id)
        .then((_) => {
          toast.success(`Invoice successfully reminded.`);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.remindingInvoice = false;
        });
    },

    deleteInvoice() {
      this.deletingInvoice = true;

      api
        .deleteInvoice(this.invoice.id)
        .then((_) => {
          toast.success(`Invoice successfully deleted.`);
          this.$router.push({
            name: "ProviderInvoices",
            params: { successToastMessage: `Invoice successfully deleted.` },
          });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingInvoice = false;
        });
    },

    setInvoiceExternalPayment() {
      this.submittingExternalPayment = true;

      api
        .setExternalPayment(this.invoice.id)
        .then((response) => {
          this.invoice.payed_value = response.data.payed_value;
          this.invoice.payed_date = response.data.payed_date;
          toast.success(`Invoice has been externally paid.`);
          this.invoice.status = InvoiceStatus.PAID;
        })
        .catch((err) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.submittingExternalPayment = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        items: null,
        job: null,
        mutableName: null,
        title: null,
        message: null,
        due_date: null,
        error: null,
        send: null,
        notFoundErrorMessage: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 404) {
          this.errors.notFoundErrorMessage = errorMessages.INVOICE_NOT_FOUND;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
