<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Bank Account</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors?.error"
            @updateContent="errors.error = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <!-- <p class="title mb-3">
                Bank Account
              </p> -->
              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Bank Name <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.bank_name"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write bank name..."
                  :class="{
                    'is-invalid':
                      v$.datasend.bank_name.$invalid &&
                      v$.datasend.bank_name.$dirty,
                  }"
                  @blur="v$.datasend.bank_name.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.bank_name.$invalid &&
                      v$.datasend.bank_name.$dirty) ||
                      errors.bank_name
                  "
                >
                  <small
                    v-if="v$.datasend.bank_name.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>
                  <small
                    v-else-if="v$.datasend.bank_name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.bank_name"
                    class="invalid-feedback"
                  >
                    {{ errors.bank_name }}
                  </small>
                </template>
              </div>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Country <span class="text-danger">*</span>
                  </label>
                  <select
                    v-model="datasend.country"
                    class="form-select p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.country.$invalid && v$.datasend.country.$dirty,
                    }"
                    @blur="v$.datasend.country.$touch"
                  >
                    <option
                      value="null"
                      disabled
                    >
                      Select a country
                    </option>
                    <option
                      v-for="(item, index) in countries"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.country.$invalid &&
                        v$.datasend.country.$dirty) ||
                        errors.country
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.country.required
                          .$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.country"
                      class="invalid-feedback"
                    >
                      {{ errors.country }}
                    </small>
                  </template>
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Currency <span class="text-danger">*</span>
                  </label>
                  <select
                    v-model="datasend.currency"
                    class="form-select p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.currency.$invalid && v$.datasend.currency.$dirty,
                    }"
                    @blur="v$.datasend.currency.$touch"
                  >
                    <option
                      value="null"
                      disabled
                    >
                      Select a currency
                    </option>
                    <option
                      v-for="(item, index) in currencys"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.currency.$invalid &&
                        v$.datasend.currency.$dirty) ||
                        errors.currency
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.currency.required.$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>
                    <small
                      v-else-if="errors.currency"
                      class="invalid-feedback"
                    >
                      {{ errors.currency }}
                    </small>
                  </template>
                </div>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Routing Number
                </label>
                <input
                  v-model="datasend.routing_number"
                  type="number"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write routing..."
                  :class="{
                    'is-invalid':
                      v$.datasend.routing_number.$invalid &&
                      v$.datasend.routing_number.$dirty,
                  }"
                  @blur="v$.datasend.routing_number.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.routing_number.$invalid &&
                      v$.datasend.routing_number.$dirty) || errors.routing_number"
                >
                  <small
                    v-if="v$.datasend.routing_number.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 9 characters
                  </small>
                  <small
                    v-else-if="v$.datasend.routing_number.maxLength.$invalid"
                    class="invalid-feedback"
                  >
                    The maximum size for this field is 9 characters
                  </small>
                  <small
                    v-else-if="errors.routing_number"
                    class="invalid-feedback"
                  >
                    {{ errors.routing_number }}
                  </small>
                </template>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Account Number 
                </label>
                <input
                  v-model="datasend.account"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write account..."
                  :class="{
                    'is-invalid':
                      v$.datasend.account.$invalid &&
                      v$.datasend.account.$dirty,
                  }"
                  @blur="v$.datasend.account.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.account.$invalid &&
                      v$.datasend.account.$dirty) || errors.account"
                >
                  <small
                    v-if="v$.datasend.account.numeric.$invalid"
                    class="invalid-feedback"
                  >
                    This field is numeric
                  </small>
                  <small
                    v-else-if="errors.account"
                    class="invalid-feedback"
                  >
                    {{ errors.account }}
                  </small>
                </template>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Holder Name <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.account_holder_name"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write holder..."
                  :class="{
                    'is-invalid':
                      v$.datasend.account_holder_name.$invalid &&
                      v$.datasend.account_holder_name.$dirty,
                  }"
                  @blur="v$.datasend.account_holder_name.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.account_holder_name.$invalid &&
                      v$.datasend.account_holder_name.$dirty) ||
                      errors.account_holder_name
                  "
                >
                  <small
                    v-if="
                      v$.datasend.account_holder_name.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.account_holder_name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>
                  <small
                    v-else-if="errors.account_holder_name"
                    class="invalid-feedback"
                  >
                    {{ errors.account_holder_name }}
                  </small>
                </template>
              </div>

              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Account Type 
                </label>
                <select
                  v-model="datasend.account_holder_type"
                  class="form-select p-3 shadow-none"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a type
                  </option>
                  <option
                    v-for="(item, index) in types"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
                <template
                  v-if="errors.account_holder_type"
                >
                  <small
                    v-if="errors.account_holder_type"
                    class="invalid-feedback"
                  >
                    {{ errors.account_holder_type }}
                  </small>
                </template>
              </div>
            </div>
            <div class="row mt-5 mx-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  type="button"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="submitting"
                >
                  <submission-spinner
                    v-if="submitting"
                    :color="'#209516'"
                  />
                  Approve
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, numeric } from "@vuelidate/validators";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import { GeneralHelpers } from '@/utils/general';

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: { 
    SubmissionSpinner,
    AlerteDanger
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    submitting: false,
    listData: [],
    types: ['individual', 'company'],
    countries:[],
    currencys: ['usd'],
    datasend: {
      country: null,
      currency: null,
      account: null,
      stripe_id: null,
      account_holder_name: null,
      account_holder_type: null,
      routing_number: null,
      last4: null,
      bank_name: null,
      enabled: null,
      owner: null,
    },
    errors: {
      country: null,
      currency: null,
      account: null,
      account_holder_name: null,
      account_holder_type: null,
      routing_number: null,
      error: null,
      message: null
    },
    existe: false,
  }),

  validations() {
    return {
      datasend: {
        bank_name: {
          required,
          minLength: minLength(3),
        },
        currency: {
          required,
        },
        country: {
          required,
        },
        routing_number: {
          maxLength: maxLength(9),
          minLength: minLength(9),
        },
        account: {
          numeric
        },
        account_holder_name: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },

  mounted() {
    this.countries.push(this.$store.getters["user/user"]?.country)
    this.fetchCurrentBankAccount();
  },

  methods: {
    ...GeneralHelpers,
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    providerWithdraw() {
      this.$router.push({
        name: "ProviderWithdraw",
      });
    },

    fetchCurrentBankAccount() {
      this.loading = true;

      api
        .fetchCurrentBankAccount()
        .then((response) => {
          this.datasend = response.data;
        })
        .catch((error) => {
          this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    save() {
      if (this.submitting) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.submitting = true;
      this.clearErrorsMessages();

      api
        .createBankAccount(this.datasend)
        .then((response) => {
          this.resetValidation();
          this.providerWithdraw();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.submitting = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        country: null,
        currency: null,
        account: null,
        account_holder_name: null,
        account_holder_type: null,
        routing_number: null,
        error: null,
        message: null
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

  },
};
</script>
