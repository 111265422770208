import { Request } from "@/services/request";

export default {
  fetchEstimates({ page = 1, status, clientId = null }) {
    return new Request()
      .append("api/v1/estimation/own_estimation/")
      .method("get")
      .params({ page, status, client_id: clientId });
  },

  fetchEstimatesSummary() {
    return new Request().append("api/v1/estimation/summary/").method("get");
  },

  updateEstimatesStatus(id, status) {
    return new Request()
      .append(`api/v1/estimation/${id}/`)
      .method("patch")
      .setData({ status });
  },

  fetchEstimatesDetail(id) {
    return new Request().append(`api/v1/estimation/${id}/`).method("get");
  },

  previewEstimatePDF(id) {
    return new Request()
      .append(`api/v1/estimates/${id}/preview/`)
      .method("get");
  },

  deleteEstimate(id) {
    return new Request().append(`api/v1/estimation/${id}/`).method("delete");
  },

  sendEstimate(id, redirectLink) {
    return new Request()
      .append(`api/v1/estimation/${id}/send/`)
      .method("post")
      .setData({ redirectLink });
  },

  createEstimate(data) {
    return new Request()
      .append("api/v1/estimation/")
      .method("post")
      .setData(data);
  },

  updateEstimate(id, data) {
    return new Request()
      .append(`api/v1/estimation/${id}/`)
      .method("put")
      .setData(data);
  },
};
