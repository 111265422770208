<template>
  <div class="row">
    <div>
      <loader-spinner v-if="loading" />
      <section
        v-else
        class="content profile"
      >
        <div class="page-header row px-0 m-0">
          <div class="col-md-4 col-sm-12 title-part p-0">
            <router-link
              :to="{ name: 'CustomerJobDetails' }"
              class="d-inline-block title me-1 fw-bold"
            >
              {{ job.title }}
            </router-link>

            <p class="title-content d-inline-block">
              / To Do List
            </p>
          </div>
          <div class="col-md-8 col-sm-12 row float-end">
            <p class="col-md-5 col-sm-6 py-2 mb-0 text-lg-end">
              <button
                v-if="filterAssign"
                class="rounded-circle btn-primary p-0 ms-2"
                alt="Provider avatar"
                style="width: 40px; height: 40px"
                @click="filterAssign = null"
              >
                All
              </button>
              <span
                v-for="(assign, l) in config.assigned.filter(
                  (el, index) => index < 3
                )"
                :key="l"
                class="avatar-wrapper ms-2 d-inline-block"
                @click="filterAssign = assign.id"
              >
                <img
                  v-if="assign?.url"
                  :src="assign?.url"
                  style="width: 40px; height: 40px"
                  class="user-avatar"
                  alt="Customer Profile Photo"
                  :class="
                    filterAssign == assign.id
                      ? 'border border-primary'
                      : 'border border-white'
                  "
                >
                <avatar
                  v-else
                  :name="assign?.name"
                  :size="40"
                  :color="assign?.avatarColorConfigs?.color"
                  :background="assign?.avatarColorConfigs?.background"
                  class="proposal-avatar"
                  :class="
                    filterAssign == assign.id
                      ? 'border border-primary'
                      : 'border border-white'
                  "
                  alt="Provider avatar"
                />
                <div class="circle position-absolute" />
              </span>
              <span
                v-if="config.assigned.length > 3"
                class="avatar-wrapper ms-2 dropdown"
              >
                <button
                  class="rounded-circle btn-secondary p-0"
                  style="width: 40px; height: 40px"
                  alt="Provider avatar"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  +
                </button>
                <ul class="dropdown-menu">
                  <li
                    v-for="(assign, l) in config.assigned.filter(
                      (el, index2) => index2 >= 3
                    )"
                    :key="l"
                    class="dropdown-item d-flex"
                    @click="filterAssign = assign.id"
                  >
                    <div class="avatar-wrapper">
                      <img
                        v-if="assign?.url"
                        :src="assign?.url"
                        class="user-avatar"
                        style="min-width: 48px"
                        alt="Customer Profile Photo"
                        :class="
                          filterAssign == assign.id
                            ? 'border border-primary'
                            : 'border border-white'
                        "
                      >
                      <avatar
                        v-else
                        :name="assign?.name"
                        :size="48"
                        :color="assign?.avatarColorConfigs?.color"
                        :background="assign?.avatarColorConfigs?.background"
                        class="proposal-avatar"
                        alt="Provider avatar"
                        :class="
                          filterAssign == assign.id
                            ? 'border border-primary'
                            : 'border border-white'
                        "
                      />
                      <div class="circle position-absolute" />
                    </div>
                    <div>
                      <div>
                        <strong class="provider-name">{{
                          assign?.name
                        }}</strong>
                      </div>

                      <div>
                        <p class="proposal-date">Applied on</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </span>
            </p>
            <div
              class="col-md-2 col-sm-6 form-label mt-3 mb-0 position-relative"
            >
              <input
                type="color"
                class="position-absolute w-100 h-100 opacity-0"
                title="color"
                :value="job?.color_board || color"
                @input="updateJobColorBoard($event)"
              >
              <button
                class="col-12 btn-primary"
                :style="{
                  'background-color': job?.color_board || color,
                  'border-color': job?.color_board || color,
                }"
              >
                Theme
              </button>
            </div>
            <div class="col-md-5 col-sm-12 mt-3 p-0">
              <button
                class="col-6 btn-secondary ms-2"
                @click="createGroupToDo"
              >
                Add Status
              </button>
              <button
                class="col-5 btn-primary float-end"
                @click="createPriorityToDo"
              >
                Priority
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-12 p-3 text-start text-nowrap overflow-scroll"
          style="height: 75vh"
          :style="{ 'background-color': job?.color_board || color }"
        >
          <Container
            group-name="cols"
            tag="div"
            orientation="horizontal"
            style="height: 90%"
            @drop="onColumnDrop($event)"
          >
            <Draggable
              v-for="column in scene.children"
              :key="column.id"
              class="col-4 mb-3 mt-0"
              style="width: 350px"
            >
              <div class="col-11 bg-light text-dark bg-opacity-75 p-3">
                <!-- header-->
                <p class="row">
                  <span
                    class="col-10 fw-bold"
                    @click="updateGroupToDo(column.data)"
                  >
                    {{ column.data.name }}
                  </span>
                  <span class="col-2 text-end">
                    <group-button-table
                      :status="null"
                      :options="options"
                      @update:options="choose($event, column.data)"
                    />
                  </span>
                </p>
                <Container
                  class="flex-grow overflow-y-auto overflow-x-hidden"
                  group-name="col-items"
                  :should-accept-drop="
                    (e, payload) =>
                      e.groupName === 'col-items' && !payload.loading
                  "
                  :get-child-payload="getCardPayload(column.id)"
                  :drop-placeholder="{
                    className: `bg-secondary bg-opacity-20 border-dotted border-2 border-secondary rounded-lg mx-4 my-2`,
                    animationDuration: '200',
                    showOnTop: true,
                  }"
                  drag-class="bg-secondary dark:bg-secondary 
                    border-2 border-secondary-hover text-white 
                    transition duration-100 ease-in z-50
                    transform rotate-6 scale-110"
                  drop-class="transition duration-100 
                    ease-in z-50 transform 
                    -rotate-2 scale-90"
                  @drop="(e) => onCardDrop(column.id, e)"
                >
                  <!-- Items -->
                  <task-to-do
                    v-for="item in filterAssign
                      ? column.children.filter((task) =>
                        task.data.assigned.includes(filterAssign)
                      )
                      : column.children"
                    :key="item.id"
                    :tache="item.data"
                    :config="config"
                    @edit="updateTacheToDo(item.data)"
                    @delete="deleteTacheToDo(item.data)"
                    @updateList="fetchToDoStatus"
                  />
                </Container>
                <button
                  class="col-12 btn-outline fw-normal rounded-0"
                  @click="createTacheToDo(column.data)"
                >
                  Add Task
                </button>
              </div>
            </Draggable>
            <button
              class="col-4 mb-3 btn-outline fw-normal bg-opacity-75 py-2 rounded-0"
              style="width: 300px; height: 40px"
              @click="createGroupToDo"
            >
              Add Status
            </button>
          </Container>
        </div>

        <create-to-do-task
          ref="CreateToDoTask"
          title="Create new To Do Task"
          @updateList="fetchToDoStatus"
        />
        <update-to-do-task
          ref="UpdateToDoTask"
          title="Update To Do Task"
          :config="config"
          @fetchCheckList="fetchCheckList"
          @updateList="fetchToDoStatus"
        />
        <delete-to-do-task
          ref="DeleteToDoTask"
          title="Delete To Do Task"
          @updateList="fetchToDoStatus"
        />
        <create-to-do
          ref="CreateToDo"
          title="Create new To Do Status"
          @updateList="fetchToDoStatus"
        />
        <update-to-do
          ref="UpdateToDo"
          title="Update To Do Status"
          @updateList="fetchToDoStatus"
        />
        <delete-to-do
          ref="DeleteToDo"
          title="Delete To Do Status"
          @updateList="fetchToDoStatus"
        />
        <priority-to-do
          ref="PriorityToDo"
          title="Priority List"
          :priority="config?.priority"
          @updateList="fetchPriority"
        />
      </section>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import errorMessages from "@/utils/error-messages";
import { Container, Draggable } from "vue3-smooth-dnd";
import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import Avatar from "vue3-avatar";
import UserAvatarHelper from "@/utils/user-avatar";

import CreateToDo from "@/components/group/CreateToDo.vue";
import UpdateToDo from "@/components/group/UpdateToDo.vue";
import DeleteToDo from "@/components/group/DeleteToDo.vue";
import PriorityToDo from "@/components/group/priority/PriorityToDo.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import GroupButtonTable from "@/components/GroupButtonTable.vue";
import CreateToDoTask from "@/components/group/task/CreateToDoTask.vue";
import TaskToDo from "@/components/group/task/TaskToDo.vue";
import UpdateToDoTask from "@/components/group/task/UpdateToDoTask.vue";
import DeleteToDoTask from "@/components/group/task/DeleteToDoTask.vue";
import { GlobalEvents } from '@/configs/constants';

export default {
  name: "CustomerToDoList",
  components: {
    CreateToDo,
    UpdateToDo,
    DeleteToDo,
    PriorityToDo,
    LoaderSpinner,
    Container,
    Draggable,
    CreateToDoTask,
    TaskToDo,
    GroupButtonTable,
    UpdateToDoTask,
    DeleteToDoTask,
    Avatar,
  },

  data: () => ({
    loading: false,
    loadingJob: false,
    updatingJob: false,
    job: {},
    toDoStatus: [],
    config: {
      status: [],
      priority: [],
      assigned: [],
    },
    options: [
      {
        title: "Add Task",
        value: "addtask",
      },
      {
        title: "Edit",
        value: "edit",
      },
      {
        title: "Delete",
        value: "delete",
      },
    ],
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
    color: "#6c757d",
    scene: {
      type: "container",
      props: { orientation: "horizontal" },
      children: [],
    },
    filterAssign: null,
  }),

  mounted() {
    this.fetchJob();
    this.fetchToDoStatus();
    this.fetchPriority();
    this.fetchJobBidsAwarded();
  },

  created() {
    /**
     * @description
     * New task created
     */
    this.$emitter.on(GlobalEvents.todo.TASK_CREATED, (data) => {
      this.fetchToDoStatus();
    });

    /**
     * @description
     * Task updated
     */
    this.$emitter.on(GlobalEvents.todo.TASK_UPDATED, (data) => {
      this.$refs.UpdateToDoTask.closeModal();
      this.fetchToDoStatus();
    });

    /**
     * @description
     * Task deleted
     */
    this.$emitter.on(GlobalEvents.todo.TASK_DELETE, (data) => {
      this.$refs.UpdateToDoTask.closeModal();
      this.fetchToDoStatus();
    });

    this.$emitter.on(GlobalEvents.todo.TASK_CHECK_LIST_CREATED, (data) => {
      this.fetchCheckList();
    });

    /**
     * @description
     * Task updated
     */
    this.$emitter.on(GlobalEvents.todo.TASK_CHECK_LIST_UPDATED, (data) => {
      this.$refs.UpdateToDoTask.closeModal();
      this.fetchCheckList();
    });

    /**
     * @description
     * Task deleted
     */
    this.$emitter.on(GlobalEvents.todo.TASK_CHECK_LIST_DELETE, (data) => {
      this.$refs.UpdateToDoTask.closeModal();
      this.fetchCheckList();
    });

    /**
     * @description
     * Status created
     */
    this.$emitter.on(GlobalEvents.todo.STATUS_CREATE, (data) => {
      this.fetchToDoStatus();
    });

    /**
     * @description
     * Status updated
     */
    this.$emitter.on(GlobalEvents.todo.STATUS_UPDATED, (data) => {
      this.$refs.UpdateToDo.closeModal();
      this.fetchToDoStatus();
    });

    /**
     * @description
     * Status deleted
     */
    this.$emitter.on(GlobalEvents.todo.STATUS_DELETED, (data) => {

      this.$refs.UpdateToDo.closeModal();
      this.fetchToDoStatus();
    });

    /**
     * @description
     * Priority created
     */
    this.$emitter.on(GlobalEvents.todo.PRIORITY_CREATE, (data) => {

      this.$refs.PriorityToDo.closeModal();
      this.fetchPriority();
    });

    /**
     * @description
     * Priority updated
     */
    this.$emitter.on(GlobalEvents.todo.PRIORITY_UPDATED, (data) => {

      this.$refs.PriorityToDo.closeModal();
      this.fetchPriority();
    });

    /**
     * @description
     * Priority deleted
     */
    this.$emitter.on(GlobalEvents.todo.PRIORITY_DELETE, (data) => {

      this.$refs.PriorityToDo.closeModal();
      this.fetchPriority();
    });
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,
    ...UserAvatarHelper,

    createPriorityToDo() {
      this.$refs.PriorityToDo.openModal({ job: this.$route.params.id });
    },

    createGroupToDo() {
      this.$refs.CreateToDo.openModal({ job: this.$route.params.id });
    },

    updateGroupToDo(item) {
      this.$refs.UpdateToDo.openModal(item);
    },

    deleteGroupToDo(item) {
      this.$refs.DeleteToDo.openModal(item);
    },

    createTacheToDo(item) {
      this.$refs.CreateToDoTask.openModal({
        job: this.$route.params.id,
        ...item,
      });
    },

    updateTacheToDo(tache) {
      this.$refs.UpdateToDoTask.openModal(tache);
    },

    deleteTacheToDo(tache) {
      this.$refs.DeleteToDoTask.openModal(tache);
    },

    choose(action, column) {
      switch (action) {
        case "edit":
          this.updateGroupToDo(column);
          break;
        case "delete":
          this.deleteGroupToDo(column);
          break;
        case "addtask":
          this.createTacheToDo(column);
          break;

        default:
          break;
      }
    },

    async fetchJob() {
      try {
        this.loadingJob = true;
        const response = await api.fetchJob(this.$route.params.id);
        this.job = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    async updateJobColorBoard(event) {

      try {
        this.loadingJob = true;
        await api.updateJob(
          this.$route.params.id,
          { color_board: event.target.value },
          () => {
            this.fetchJob();
          }
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    async fetchToDoStatus() {
      try {
        this.loadingJob = true;
        const response = await api.fetchToDoStatus(this.$route.params.id);
        this.config.status = response.data.map((el) => ({
          id: el.id,
          name: el.name,
        }));
        let children = response.data
          .map((el) => ({
            id: el.order,
            type: "container",
            data: el,
            props: { orientation: "vertical" },
            children: [],
          }))
          .sort((a, b) => (a.data.order > b.data.order ? 1 : -1));

        this.fetchToDo(children);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    async fetchToDo(children) {
      try {
        this.loadingJob = true;
        const response = await api.fetchToDo(this.$route.params.id);
        children.forEach((column) => {
          column.children = response.data
            .map((item) =>
              item.status == column.data.id
                ? {
                    type: "draggable",
                    id: item.order,
                    column: column.data.order,
                    loading: false,
                    data: item,
                  }
                : false
            )
            .filter((el) => el !== false)
            .sort((a, b) => (a.data.order > b.data.order ? 1 : -1));
        });
        this.scene.children = children;
        await this.fetchCheckList();

      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    async fetchCheckList() {
      this.scene.children.forEach((status) => {
        status.children.forEach(async (task) => {
          try {
            const response = await api.fetchCheckList(task.data.id);
            task.data.checklist =
              response.data.length !== 0
                ? response.data.sort((a, b) => (a.order > b.order ? 1 : -1))
                : false;
          } catch (error) {
            console.error(error);
          }
        });
      });
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    async fetchPriority() {
      try {
        // this.loading = true;
        const response = await api.fetchPriority(this.$route.params.id);
        this.config.priority = response.data;

      } catch (error) {
        this.errorsHandler(error);
      }
    },

    async fetchJobBidsAwarded() {
      try {
        this.loading = true;
        const response = await api.fetchJobBidsAwarded(this.$route.params.id);
        this.config.assigned = response.data.data.map((el) => ({
          id: el.owner.id,
          name: el.owner.user.full_name,
          url: el.owner.user.photo?.url,
          avatarColorConfigs: this.getAvatarColorsConfigs(),
        }));
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    applyDrag(arr, dragResult) {

      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },

    onColumnDrop(dropResult) {

      const scene = Object.assign({}, this.scene);

      scene.children = this.applyDrag(scene.children, dropResult);
      this.scene = scene;

      this.updateColumnOrder();
    },

    async updateColumnOrder() {
      try {
        this.scene.children.forEach(async (el, i) => {
          await api.updateToDoStatus(el.data.id, { order: i });
        });
      } catch (error) {
        console.error(error);
      }

      await this.fetchToDoStatus();
    },

    updateTaskOrder(id, order) {
      api
        .updateToDoTask(id, { order: order })
        .then((response) => {
          this.fetchToDoStatus();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateTaskStatus(id, status, order) {
      api
        .updateToDoTask(id, { status: status })
        .then((response) => {
          this.updateTaskOrder(id, order);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onCardDrop(columnId, dropResult) {
      // check if element where ADDED or REMOVED in current collumn
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, this.scene);
        const column = scene.children.filter((p) => p.id === columnId)[0];
        const itemIndex = scene.children.indexOf(column);
        const newColumn = Object.assign({}, column);

        // check if element was ADDED in current column
        if (dropResult.removedIndex == null && dropResult.addedIndex >= 0) {
          // your action / api call
          dropResult.payload.loading = true;
          this.updateTaskStatus(
            dropResult.payload.data.id,
            newColumn.data.id,
            dropResult.addedIndex
          );
          // simulate api call
          setTimeout(function () {
            dropResult.payload.loading = false;
          }, 1000);
        }

        newColumn.children = this.applyDrag(newColumn.children, dropResult);
        scene.children.splice(itemIndex, 1, newColumn);
        this.scene = scene;
      }
    },

    getCardPayload(columnId) {
      return (index) => {
        return this.scene.children.filter((p) => p.id === columnId)[0].children[
          index
        ];
      };
    },
  },
};
</script>

<style>
/** NB: dont remove, 
* When using orientation="horizontal" it auto sets "display: table"
* In this case we need flex and not display table  
*/
.smooth-dnd-container.horizontal {
  display: flex !important;
}
</style>
