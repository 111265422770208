<template>
  <div class="sidebar">
    <ul class="nav">
      <li
        v-for="item in dashboardMenuItems"
        :key="item.routeName"
      >
        <router-link
          :to="{ name: item.routeName }"
          class="nav-link position-relative"
        >
          <img :src="icons[item.icon]">
          
          <template v-if="!toggleMenu">
            {{ item.title }}
          </template>
            
          <span
            v-if="item.showProvidersIndicator"
            class="indicator position-absolute"
          >
            {{ getProvidersCount }}
          </span>

          <span
            v-if="item.showCustomersIndicator"
            class="indicator position-absolute"
          >
            {{ getCustomersCount }}
          </span>
          <span
            v-if="item.showJobsIndicator"
            class="indicator position-absolute"
          >
            {{ getJobsCount }}
          </span>
          <span
            v-if="item.showInvoicesIndicator"
            class="indicator position-absolute"
          >
            {{ getInvoicesCount }}
          </span>
          <span
            v-if="item.showEstimatesIndicator"
            class="indicator position-absolute"
          >
            {{ getEstimatesCount }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import superAdminDashboardIcon from "@/assets/img/icons/dashboard.svg";
import superAdminListIcon from "@/assets/img/icons/list.svg";
import superAdminBagIcon from "@/assets/img/icons/bag.svg";
import superAdminDocumentIcon from "@/assets/img/icons/document.svg";
import superAdminSettingsIcon from "@/assets/img/icons/settings.svg";
import superAdminStatisticsIcon from "@/assets/img/icons/statistics.svg";
import superAdminTicketIcon from "@/assets/img/icons/ticket.svg";
import superAdminRecycleIcon from "@/assets/img/icons/recycle.svg";
import superAdminIconEstimates from "@/assets/img/icons/iconestimates.svg";

import api from "@/services/api";
import { SuperAdminDashboardMenuItems } from "@/configs/super-admin-menu-items";

export default {
    name: "SuperAdminMenu",
    props: {
        toggleMenu: {
        type: Boolean,
        required: false,
        default: false,
        },
    },

    data: () => ({
        icons: {
            superAdminDashboardIcon,
            superAdminListIcon,
            superAdminBagIcon,
            superAdminDocumentIcon,
            superAdminSettingsIcon,
            superAdminStatisticsIcon,
            superAdminTicketIcon,
            superAdminRecycleIcon,
            superAdminIconEstimates
        },
    }),

    computed: {
        dashboardMenuItems() {
            return SuperAdminDashboardMenuItems;
        },

        approvalNumber() {
            return this.$store.getters["sDashState/approvalNumber"];
        },
        jobNumber() {
            return this.$store.getters["sDashState/jobNumber"];
        },
        invoiceNumber() {
            return this.$store.getters["sDashState/invoiceNumber"];
        },
        estimateNumber() {
            return this.$store.getters["sDashState/estimateNumber"];
        },

        customerNeededToCompleteProfile() {
            return this.$store.getters[
                "sDashState/customerNeededToCompleteProfile"
            ];
        },

        getProvidersCount() {
            if (!this.approvalNumber) return "";

            if (this.approvalNumber > 0 && this.approvalNumber <= 9) {
                return this.zeroPad(this.approvalNumber);
            }

            return "9+";
        },

        getCustomersCount() {
            if (!this.customerNeededToCompleteProfile) return "";

            if (
                this.customerNeededToCompleteProfile > 0 &&
                this.customerNeededToCompleteProfile <= 9
            ) {
                return this.zeroPad(this.customerNeededToCompleteProfile);
            }

            return "9+";
        },
        getJobsCount() {

            if (!this.jobNumber) return "";

            if (
                this.jobNumber > 0 &&
                this.jobNumber <= 9
            ) {
                return this.zeroPad(this.jobNumber);
            }

            return "9+";
        },
        getInvoicesCount() {

            if (!this.invoiceNumber) return "";

            if (
                this.invoiceNumber > 0 &&
                this.invoiceNumber <= 9
            ) {
                return this.zeroPad(this.invoiceNumber);
            }

            return "9+";
        },
        getEstimatesCount() {

            if (!this.estimateNumber) return "";

            if (
                this.estimateNumber > 0 &&
                this.estimateNumber <= 9
            ) {
                return this.zeroPad(this.estimateNumber);
            }

            return "9+";
        },
    },

    watch: {
        $route() {
            this.$emit("routeUpdated");
        },
    },

    async mounted() {
        await this.fetchNumberOfProvidersWaitingForApproval();
        await this.fetchNumberOfCustomerNeededToCompleteProfile();
        await this.fetchNumberOfJob();
        await this.fetchNumberOfInvoice();
        await this.fetchNumberOfEstimate();
    },

    methods: {
        async fetchNumberOfProvidersWaitingForApproval() {
            try {
                const response =
                    await api.numberOfProvidersWaitingForApproval();

                this.$store.dispatch(
                    "sDashState/setApproval",
                    response.data.provider_for_approval || 0
                );
            } catch (e) {
            }
        },

        async fetchNumberOfCustomerNeededToCompleteProfile() {
            try {
                const response =
                    await api.fetchNumberOfCustomerNeededToCompleteProfile();

                this.$store.dispatch(
                    "sDashState/setCustomerNeededToCompleteProfile",
                    response.data.newcustomer || 0
                );
            } catch (e) {
            }
        },

        async fetchNumberOfJob() {
            try {
                const response =
                    await api.fetchNumberOfJob();

                this.$store.dispatch(
                    "sDashState/setJobProfile",
                    response.data.newjobs || 0
                );
            } catch (e) {
            }
        },
        async fetchNumberOfInvoice() {
            try {
                const response =
                    await api.fetchNumberOfInvoice();

                this.$store.dispatch(
                    "sDashState/setInvoiceProfile",
                    response.data.newinvoices || 0
                );
            } catch (e) {
            }
        },
        async fetchNumberOfEstimate() {
            try {
                const response =
                    await api.fetchNumberOfEstimate();

                this.$store.dispatch(
                    "sDashState/setEstimateProfile",
                    response.data.newestimates || 0
                );
            } catch (e) {
            }
        },
        zeroPad(num) {
            return (num < 10 ? "0" : "") + num.toString();
        },
    },
};
</script>