import { Request } from "@/services/request";

const baseUrl = "api/v1/provider_saved";

export default {
  fetchAllSavedProviders({ page = 1, search = null }) {
    return new Request()
      .append(`${baseUrl}/customer/`)
      .params({ page, search })
      .method("get");
  },

  fetchSavedProvider(id) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("get");
  },
};
