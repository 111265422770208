export default {
  extractLocationInfo(data) {
    const locationInfo = {
      country: {
        name: "",
        shortName: "",
      },
      state: "",
      city: "",
      pincode: "",
      address: "",
    };

    data.forEach((item) => {
      if (item.types) {
        switch (item.types[0]) {
          case "street_number":
            locationInfo.address = `${item.long_name} ${locationInfo.address}`;
            break;

          case "route":
            locationInfo.address += item.short_name;
            break;

          case "postal_code":
            locationInfo.pincode = `${item.long_name}${locationInfo.pincode}`;
            break;

          case "postal_code_suffix":
            locationInfo.pincode = `${locationInfo.pincode}-${item.long_name}`;
            break;

          case "locality":
          case "postal_town":
          case "sublocality_level_1":
            locationInfo.city = item.long_name;
            break;

          case "administrative_area_level_1":
            locationInfo.state = item.long_name;
            break;

          case "country":
            locationInfo.country = {
              name: item.long_name,
              shortName: item.short_name,
            };
            break;
        }
      }
    });

    return locationInfo;
  },
};
