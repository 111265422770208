import VuexPersist from "vuex-persist";
import { createStore, createLogger } from "vuex";

import user from "@/store/modules/user";
import token from "@/store/modules/token";
import sDashState from "@/store/modules/sDashState";

const debug = process.env.NODE_ENV !== "production";

const vuexLocalPersist = new VuexPersist({
  key: "service-loop",
  storage: localStorage,
  modules: ["token", "user"],
});

type ServiceLoopStore = {
  user: {
    user: {
      my_current_plan: {
        services: {
          code: string
        }[]
      }
    }
  }
};

export default createStore<ServiceLoopStore>({
  modules: {
    user,
    token,
    sDashState,
  },
  getters: {
    hasService: state => service => {
      return state.user.user?.my_current_plan.services.map(service => service.code).includes(service);
    }
  },
  strict: debug,
  plugins: debug
    ? [createLogger(), vuexLocalPersist.plugin]
    : [vuexLocalPersist.plugin],
});
