import { Request } from "@/services/request";

export default {
  fetchWithdraws(page = 1) {
    return new Request()
      .append("api/v1/withdraw/")
      .method("get")
      .params({ page });
  },

  requestWithdraw(token) {
    return new Request()
      .append("api/v1/withdraw/request_withdraw/")
      .method("post")
      .setData({ g_token: token });
  },
};
