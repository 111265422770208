<template>
  <section class="content">
    <div class="page-header px-0">
      <div class="title-part mb-0">
        <p class="mb-0 mind">
          Completed Jobs
        </p>
      </div>

      <datepicker @range:change="filterJos($event)" />
    </div>

    <jobs-list
      :jobs="jobs"
      :hide="!access.canAccessMarketplace()"
      @fetchNextPage="onFetchNextJobsPage"
    />

    <loader-spinner v-if="fetching" />
  </section>
</template>

<script>
import access from "@/services/access";
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import Datepicker from "@/components/AppDatePicker.vue";
import JobsList from "@/components/provider/jobs/JobsList";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

export default {
  name: "ProviderCompletedJobsPage",

  components: { JobsList, Datepicker, LoaderSpinner },

  mixins: [ListMixin(api.fetchProviderCompletedJobs, true, false)],

  data: () => ({
    access: access,
    jobs: [],
    fetching: false,
    maxPagesReached: false,
  }),

  computed: {
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    }
  },

  watch: {
    listData(val) {
      const newJobs = val.map((x) => x.job);
      this.jobs = this.jobs.concat(newJobs);
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchProviderCompletedJobs();
  },

  methods: {
    fetchProviderCompletedJobs() {
      this.fetching = true;
      this.updateListFilters({
        page: 1,
      });
    },

    onFetchNextJobsPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        ...this.filters,
        page: this.currentPage + 1,
      });
    },

    filterJos(filters) {
      this.filters = filters;
      this.updateListFilters({
        ...filters,
        page: this.currentPage || 1,
      });
    },
  },
};
</script>
