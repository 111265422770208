<template>
  <section class="content">
    <div class="page-header d-md-flex">
      <div class="d-md-flex title-part mb-0 job-detail">
        <router-link
          :to="{
            name: 'ProviderProposals',
            params: {
              status: jobProposalStatus.ACCEPTED,
            },
          }"
          class=" title me-1"
        >
          Proposals
        </router-link>

        <p class="title-content">
          / Subcontracting
        </p>
      </div>
    </div>
    <loader-spinner v-if="fetching" />

    <subcontract-list
      v-else
      :subcontracts="subcontract"
    />
  </section>
</template>

<script>
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import SubcontractList from "@/components/provider/jobs/SubcontractingList";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { JobProposalStatus } from "@/configs/constants/jobs";
export default {
  name: "ProviderSubcontracting",

  components: { SubcontractList, LoaderSpinner },

  mixins: [ListMixin(api.fetchSubcontracting, false)],

  data: () => ({
    subcontract: [],
    fetching: false,
  }),

  computed: {
    jobProposalStatus() {
      return JobProposalStatus;
    },
  },

  watch: {
    listData(val) {
      this.subcontract = val;
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchAllSubcontract();
  },

  methods: {
    fetchAllSubcontract() {
      this.fetching = true;
      this.updateListFilters({
        id: this.$route.params.proposalId,
      });
    },
  },
};
</script>