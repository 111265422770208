import { Request } from "@/services/request";

export default {
  fetchCoupons({
    page = 1,
    startDate = null,
    endDate = null,
    project = null,
    status = null,
    search = null,
  }) {
    return new Request()
      .append("api/v1/coupon/")
      .method("get")
      .params({ page, start_date: startDate, end_date: endDate, project, status, search });
  },

  createCoupon(data) {
    return new Request().append("api/v1/coupon/").method("post").setData(data);
  },
  updateCoupon(id, data) {
    return new Request()
      .append(`api/v1/coupon/${id}/`)
      .method("put")
      .setData(data);
  },
  updateCouponOneAttr(id, data) {
    return new Request()
      .append(`api/v1/coupon/${id}/`)
      .method("patch")
      .setData(data);
  },

  fetchCouponById(id) {
    return new Request().append(`api/v1/coupon/${id}/`).method("get");
  },

  deleteCoupon(id) {
    return new Request().append(`api/v1/coupon/${id}/`).method("delete");
  },
  couponByCode(code) {
    return new Request().append(`api/v1/coupon/couponByCode/`).method("get").params({code});
  },
};
