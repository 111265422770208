import { Request } from "@/services/request";

export default {
  makeRating(data) {
    return new Request().append("api/v1/rating/").method("post").setData(data);
  },

  fetchRatings(page) {
    return new Request()
      .append("api/v1/rating/")
      .params({ page })
      .method("get");
  },

  fetchRatingStats() {
    return new Request()
      .append("api/v1/rating/stats")
      .method('get');
  }
};
