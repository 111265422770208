<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Activate Call
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div class="mb-3 col-md-12">
            <label class="pb-1">
              Forward phone <span class="text-danger">*</span>
            </label>
            <vue-tel-input
              v-model="datasend.forward_phone"
              class="form-control pl-3 shadow-none"
              :class="{
                'is-invalid':
                  (v$.datasend.forward_phone.$invalid &&
                    v$.datasend.forward_phone.$dirty) ||
                  errors.forward_phone,
              }"
              @blur="v$.datasend.forward_phone.$touch"
            />
            <template
              v-if="
                (v$.datasend.forward_phone.$invalid &&
                  v$.datasend.forward_phone.$dirty) ||
                  errors.forward_phone
              "
            >
              <small
                v-if="v$.datasend.forward_phone.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="errors.forward_phone"
                class="invalid-feedback"
              >
                {{ errors.forward_phone }}
              </small>
            </template>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn-primary"
            :disabled="loading"
            @click="save"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '../AlerteDanger.vue';

export default {
  name: "ActivateCall",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    datasend: {
      forward_phone: null,
    },
    id: null,
    errors: {
      forward_phone: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        forward_phone: {
          required,
        },
      },
    };
  },

  methods: {
    ...validationHelpers,

    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();
      api
        .activateCall(this.datasend)
        .then((response) => {
          this.datasend = { forward_phone: "" };
          this.resetValidation();
          this.closeModal();
          this.$emit("updateList");
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors.forward_phone = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.forward_phone = err.response.data[error];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
