<template>
  <div class="post-job row">
    <section class="content ">
      <div class="page-header">
        <h3>Edit Coupon</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div class="row">
            <div
              class="col-md-6 border-bottom border-3 mb-3"
              :class="{'border-primary bg-white': choise == 'amount_off'}"
              @click="choise = 'amount_off'"
            >
              <h5 
                class="text-center py-2"
                :class="{'text-primary': choise == 'amount_off'}"
              >
                Amount off
              </h5>
            </div>
            <div
              class="col-md-6 border-bottom border-3 mb-3"
              :class="{'border-primary bg-white': choise == 'percent_off'}"
              @click="choise = 'percent_off'"
            >
              <h5 
                class="text-center py-2"
                :class="{'text-primary': choise == 'percent_off'}"
              >
                Percent off
              </h5>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label">
                Code <span class="text-danger">*</span>
              </label>
              <input
                v-model="datasend.code"
                type="text"
                class="form-control pl-3 shadow-none"
                placeholder="Enter your discount code here..."
                :class="{
                  'is-invalid':
                    (v$.datasend.code.$invalid &&
                      v$.datasend.code.$dirty) ||
                    errors.code
                }"
                @blur="v$.datasend.code.$touch"
              >
              <template
                v-if="
                  (v$.datasend.code.$invalid &&
                    v$.datasend.code.$dirty) ||
                    errors.code
                "
              >
                <small
                  v-if="v$.datasend.code.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="v$.datasend.code.minLength.$invalid"
                  class="invalid-feedback"
                >
                  The minimum size for this field is 3 characters
                </small>

                <small
                  v-else-if="v$.datasend.code.maxLength.$invalid"
                  class="invalid-feedback"
                >
                  The maximum size for this field is 10 characters
                </small>

                <small
                  v-else-if="errors.code"
                  class="invalid-feedback"
                >
                  {{ errors.code }}
                </small>
              </template>
            </div>

            <div class="col-md-6 mb-3">
              <label class="form-label">
                {{ choise == 'amount_off' ? 'Amount off' : 'Percent off' }} 
                <span class="text-danger">*</span>
              </label>
              <money3
                v-if="choise == 'amount_off'"
                v-model="datasend[choise]"
                prefix="$"
                class="form-control p-3"
                :class="{
                  'is-invalid':
                    (v$.datasend[choise].$invalid &&
                      v$.datasend[choise].$dirty) ||
                    errors[choise]
                }"
                @blur="v$.datasend[choise].$touch"
              />
              <input
                v-if="choise == 'percent_off'"
                v-model="datasend[choise]"
                type="number"
                class="form-control pl-3 shadow-none"
                placeholder="Enter percent off here..."
                :class="{
                  'is-invalid':
                    (v$.datasend[choise].$invalid &&
                      v$.datasend[choise].$dirty) ||
                    errors[choise]
                }"
                @blur="v$.datasend[choise].$touch"
              >
              <template
                v-if="
                  (v$.datasend[choise].$invalid &&
                    v$.datasend[choise].$dirty) ||
                    errors[choise]
                "
              >
                <small
                  v-if="v$.datasend[choise].required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors[choise]"
                  class="invalid-feedback"
                >
                  {{ errors[choise] }}
                </small>
              </template>
            </div>


            <div class="col-md-6 mb-3">
              <label class="form-label">
                Duration <span class="text-danger">*</span>
              </label>
              <select
                v-model="datasend.duration"
                class="form-select p-3 shadow-none"
                :class="{
                  'is-invalid':
                    v$.datasend.duration.$invalid && v$.datasend.duration.$dirty,
                }"
                @blur="v$.datasend.duration.$touch"
              >
                <option
                  value="null"
                  disabled
                >
                  Select a duration
                </option>
                <option value="forever">
                  Forever
                </option>
                <option value="once">
                  Once
                </option>
                <option value="repeating">
                  Repeating
                </option>
              </select>
              <template
                v-if="
                  (v$.datasend.duration.$invalid && v$.datasend.duration.$dirty) ||
                    errors.duration
                "
              >
                <small
                  v-if="v$.datasend.duration.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.duration"
                  class="invalid-feedback"
                >
                  {{ errors.duration }}
                </small>
              </template>
            </div>

            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>
              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="loading"
                >
                  <submission-spinner v-if="loading" />
                  Edit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { Money3Component } from "v-money3";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from "@/components/AlerteDanger.vue";

export default {
  name: "ProviderInvoiceEdit",

  components: {
    money3: Money3Component,
    SubmissionSpinner,
    AlerteDanger
},

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    datasend: {
      code: null,
      amount_off: 0,
      percent_off: null,
      duration: null,
    },
    errors: {
      code: null,
      amount_off: null,
      percent_off: null,
      duration: null,
    },
    choise: 'amount_off'
  }),

  validations() {
    return {
      datasend: {
        code: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(10)
        },
        [this.choise]: {
          required,
        },
        duration: {
          required,
        },
      },
    };
  },

  mounted() {
    this.fetchCouponById()
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    list() {
      this.$router.push({
        name: "SuperAdminCoupon",
      });
    },
    async fetchCouponById() {
      this.loading = true;

      try {
        let response = await api.fetchCouponById(this.$route.params.id);
        this.datasend = {...response.data};
        this.choise = response.data?.percent_off ? 'percent_off' : 'amount_off'
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },
    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();
      let objet = {
        code: this.datasend.code,
        duration: this.datasend.duration,
      };
      objet[this.choise] = this.datasend[this.choise];
      api
        .updateCouponOneAttr(this.$route.params.id, objet)
        .then((_) => {
          this.resetValidation();
          this.list();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        code: null,
        amount_off: null,
        percent_off: null,
        duration: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
