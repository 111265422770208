<template>
  <div class="row">
    <div>
      <div class="job-detail">
        <loader-spinner v-if="loading" />

        <div
          v-else-if="notFoundErrorMessage"
          class="row mt-4"
        >
          <div class="col-lg-8 offset-md-2">
            <alerte-danger
              :head-error-message="notFoundErrorMessage"
              @updateContent="notFoundErrorMessage = $event"
            />
          </div>
        </div>

        <section
          v-else
          class="content"
        >
          <div class="page-header">
            <div class="d-md-flex title-part mb-0">
              <router-link
                :to="{ name: 'CustomerInvoices' }"
                class="title me-1"
              >
                Invoices
              </router-link>

              <p class="title-content">
                / {{ invoice.invoice_no }}
              </p>
            </div>

            <div class="d-flex align-items-center">
              <div class="position-proposal-icon me-3">
                <app-invoice-status
                  v-if="invoice"
                  :status="invoice.status || ''"
                />
              </div>

              <submission-spinner
                v-if="downloading || requestingRefund || submittingExternalPayment"
                :color="'#209516'"
              />
              <div
                v-else
                class="btn-group btn-group-dropdown"
              >
                <button
                  type="button"
                  class="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="icons.actionsIcon"
                    alt="Actions Icon"
                    class="icon-white"
                  >
                  Actions
                </button>
                <ul class="dropdown-menu">
                  <li
                    v-for="option in actionOptions"
                    :key="option.value"
                    @click="handleActionSelection(option.value)"
                  >
                    <a class="dropdown-item cursor-pointer">{{
                      option.label
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="error"
            class="row mt-4"
          >
            <div class="col-lg-8 offset-md-2">
              <alerte-danger
                :head-error-message="error"
                @updateContent="error = $event"
              />
            </div>
          </div>

          <div
            id="element-to-convert"
            class="page-content"
          >
            <div
              v-if="provider"
              class="col-md-12 my-5"
            >
              <div class="row">
                <div class="col-md-6 text-start">
                  <div class="col-md-8 mb-3">
                    <h5 class="mb-3 text-primary">
                      {{ provider.full_name }}
                    </h5>
                    <p
                      v-if="provider?.location_description"
                      class="my-0"
                    >
                      {{ provider.location_description }}
                    </p>
                    <p class="my-0">
                      {{ getCountryLabel(provider.country) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 text-end">
                  <div
                    v-if="showBalancePaid"
                    class="col-md-12 align-self-end mb-3"
                  >
                    <h6 class="mb-2 text-secondary">
                      Balance Paid
                    </h6>
                    <h5 class="text-bold text-primary mb-0">
                      {{ formatPrice((invoice.payed_value || 0).toFixed(2)) }}
                    </h5>
                  </div>

                  <div
                    v-if="showBalanceDue"
                    class="col-md-12 align-self-end mb-3"
                  >
                    <h6 class="mb-2 text-secondary">
                      Balance Due
                    </h6>
                    <h5 class="text-bold mb-0">
                      {{ formatPrice(balanceDue) }}
                    </h5>
                  </div>

                  <div
                    v-if="invoice.payed_date"
                    class="col-md-12 align-self-end mb-3"
                  >
                    <h6 class="mb-2 text-secondary">
                      Payment Date
                    </h6>
                    <h6 class="fw-normal mb-0">
                      {{ formatDate(invoice.payed_date, "MMMM Do, YYYY hh:mm") }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="job?.owner"
              class="col-md-12 my-5"
            >
              <div class="row">
                <div class="col-md-6 text-start">
                  <div class="col-md-8 mb-3">
                    <h5 class="mb-3 text-primary">
                      {{ job.owner.user.full_name }}
                    </h5>
                    <p 
                      v-if="job.owner.user?.location_description"
                      class="my-0"
                    >
                      {{ job.owner.user.location_description }}
                    </p>
                    <p class="my-0">
                      {{ getCountryLabel(job.owner.user.country) }}
                    </p>
                    <p class="my-0">
                      {{ job.owner.user.phone }}
                    </p>
                    <p class="my-0">
                      {{ job.owner.user.email }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 text-end d-flex">
                  <div class="col-md-12 align-self-end mb-3">
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Invoice Date :
                      </p>
                      <p class="col-md-4">
                        {{ formatDate(invoice.created_at) }}
                      </p>
                    </div>
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Due Date :
                      </p>
                      <p class="col-md-4">
                        {{ formatDate(invoice.due_date) }}
                      </p>
                    </div>
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Job Name :
                      </p>
                      <p class="col-md-4">
                        {{ job.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="invoice?.client"
              class="col-md-12 my-5"
            >
              <div class="row">
                <div class="col-md-6 text-start">
                  <div class="col-md-8 mb-3">
                    <h5 class="mb-3 text-primary">
                      {{ invoice.client.full_name }}
                    </h5>
                    <p class="my-0">
                      {{ invoice.client.company_name }}
                    </p>
                    <p 
                      v-if="invoice.client.main_propriety?.location_description"
                      class="my-0"
                    >
                      {{ invoice.client.main_propriety.location_description }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 text-end d-flex">
                  <div class="col-md-12 align-self-end mb-3">
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Invoice Date :
                      </p>
                      <p class="col-md-4">
                        {{ formatDate(invoice.created_at) }}
                      </p>
                    </div>
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Due Date :
                      </p>
                      <p class="col-md-4">
                        {{ formatDate(invoice.due_date) }}
                      </p>
                    </div>
                    <div class="row text-end">
                      <p class="col-md-8 text-primary">
                        Primary contact :
                      </p>
                      <p class="col-md-4">
                        {{ invoice.client.primary_contact?.full_name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 md-3">
              <div class="table-responsive">
                <table class="table">
                  <head-table :list="thead" />
                  <tbody>
                    <tr
                      v-for="(item, index) in invoice.items"
                      :key="index"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        <h6>
                          {{ item.item }}
                        </h6>
                        <p class="mb-2 text-secondary">
                          {{ item.description }}
                        </p>
                      </td>
                      <td>
                        {{ item.quantity }}
                      </td>
                      <td>
                        {{ formatPrice(item.value) }}
                      </td>
                      <td>
                        {{ formatPrice(item.quantity * item.value) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-12 my-5 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="text-end">
                  <div class="d-inline-block text-bold">
                    <p>Sub Total : &nbsp;</p>
                    <p>Service Fee : &nbsp;</p>
                    <p>Total : &nbsp;</p>
                  </div>

                  <div class="d-inline-block">
                    <p>{{ formatPrice((invoice.subtotal || 0).toFixed(2)) }}</p>
                    <p>
                      {{ formatPrice((invoice.customer_fee || 0).toFixed(2)) }}
                    </p>
                    <p>{{ formatPrice((invoice.total || 0).toFixed(2)) }}</p>
                  </div>
                </div>

                <button
                  v-if="showBalanceDue"
                  class="btn-primary rounded-0 mt-3"
                  @click="openPaymentModal"
                >
                  Pay balance due
                  {{ formatPrice(balanceDue) }}
                </button>
              </div>
            </div>

            <div class="col-md-12 text-start">
              <div class="col-md-3 mb-3">
                <h5 class="mb-3 text-primary">
                  Note :
                </h5>
                <p class="my-0">
                  {{ invoice.message }}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      ok-button-title="Yes"
      :message="'Are you sure you want to request refund for this invoice ?'"
      @onConfirm="requestRefund"
    />

    <confirm-modal
      ref="externalPaymentConfirmModal"
      ok-button-title="Confirm"
      :message="'Confirm that the invoice has been paid outside of the ServiceLoop system'"
      @onConfirm="setInvoiceExternalPayment"
    />

    <invoice-payment-modal
      ref="paymentModal"
      :invoice-id="$route.params.id"
      :balance-due="Number(balanceDue)"
      @onConfirm="onPaid"
    />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

import actionsIcon from "@/assets/img/icons/actions.svg";

import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import InvoicePaymentModal from "@/components/customer/InvoicePaymentModal.vue";

import html2pdf from "html2pdf.js";

import PriceHelpers from "@/utils/price-format";
import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import { InvoiceStatus } from "@/configs/constants";
import { AllCountries } from "@/configs/countries";
import CountryHelpers from "@/utils/country";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CustomerInvoiceDetails",

  components: {
    HeadTable,
    LoaderSpinner,
    ConfirmModal,
    AppInvoiceStatus,
    SubmissionSpinner,
    InvoicePaymentModal,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      actionsIcon,
    },
    showModal: false,
    fadeModal: false,
    loading: false,
    downloading: false,
    requestingRefund: false,
    submittingExternalPayment: false,
    listData: [],
    invoice: {},
    job: {},
    provider: {},
    thead: ["#", "Item", "QTY", "Price", "Amount"],
    datasend: {
      items: [{ item: null, value: null, quantity: null, description: null }],
      job: null,
      message: "",
      due_date: null,
      send: null,
    },
    actions: {
      PAY: "pay",
      REFUND: "refund",
      DOWNLOAD: "download",
      EXTERNAL_PAYMENT: "external-payment",
    },
    error: null,
    notFoundErrorMessage: null,
  }),

  computed: {
    actionOptions() {
      const items = [];
      if (this.canPayInvoice) {
        items.push({
          label: "Pay",
          value: this.actions.PAY,
        });
      }
      if (this.canRefundInvoice) {
        items.push({
          label: "Refund",
          value: this.actions.REFUND,
        });
      }
      items.push({
        label: "Download",
        value: this.actions.DOWNLOAD,
      });
      if (this.canMakeExternalPayment) {
        items.push({
          label: "External payment",
          value: this.actions.EXTERNAL_PAYMENT,
        });
      }

      return items;
    },

    canPayInvoice() {
      if (!this.invoice) return false;
      return [InvoiceStatus.PENDING, InvoiceStatus.PARTIAL].includes(
        this.invoice.status
      )
    },

    canRefundInvoice() {
      if (!this.invoice) return false;
      return [InvoiceStatus.PAID, InvoiceStatus.PARTIAL].includes(
        this.invoice.status
      );
    },

    canMakeExternalPayment() {
      if (!this.invoice) return false;
      return this.invoice.status === InvoiceStatus.PENDING
    },

    balanceDue() {
      let amount = 0;

      switch (this.invoice.status) {
        case InvoiceStatus.PENDING:
          amount = (this.invoice.total || 0).toFixed(2)
          break;

        case InvoiceStatus.PARTIAL:
          amount = ((this.invoice.total || 0) - (this.invoice.payed_value || 0)).toFixed(2)
          break;
      }
      
      return amount
    },

    showBalancePaid() {
      if (!this.invoice) return false;
      return this.invoice.status !== InvoiceStatus.PENDING;
    },

    showBalanceDue() {
      if (!this.invoice) return false;
      return this.invoice.status !== InvoiceStatus.PAID;
    },

    showPaymentDate() {
      if (!this.invoice) return false;
      return this.invoice.status === InvoiceStatus.PAID;
    }
  },

  mounted() {
    this.fetchInvoiceById();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    ...CountryHelpers,

    back() {
      this.$router.back();
    },

    getCountryLabel(code) {
      const country = this.getCountry(code)
      return country?.label || code;
    },

    handleActionSelection(type) {
      switch (type) {
        case this.actions.PAY:
          this.openPaymentModal();
          break;

        case this.actions.REFUND:
          this.$refs.confirmModal.openModal();
          break;

        case this.actions.DOWNLOAD:
          this.donwloadInvoice();
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.$refs.externalPaymentConfirmModal.openModal();
          break;
      }
    },

    donwloadInvoice() {
      if (this.downloading) return;

      this.downloading = true;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: `${this.invoice.invoice_no}.pdf`,
      });
      setTimeout(() => {
        this.downloading = false;
      }, 2000);
    },

    async fetchInvoiceById() {
      this.loading = true;

      try {
        let response = await api.fetchInvoiceById(this.$route.params.id);
        this.invoice = response.data;
        this.job = response.data.job;
        this.provider = response.data.bid.owner.user;
      } catch (err) {
        this.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    openPaymentModal() {
      this.$refs.paymentModal.openModal();
    },

    onPaid(data) {
      if (data) {
        this.invoice.status = data.status;
        this.invoice.payed_value = data.paidValue;
        this.invoice.payed_date = data.paymentDate;

        let message = ""
        switch (this.invoice.status) {
          case InvoiceStatus.PAID:
            message = `Invoice successfully paid.`
            break;

          case InvoiceStatus.PARTIAL:
            message = `Invoice Partially Paid successfully.`
            break;
        }
        toast.success(message);
      }
    },

    requestRefund() {
      if (this.requestingRefund) return;
      this.requestingRefund = true;

      api
        .requestRefund(this.$route.params.id)
        .then((_) => {
          toast.success(
            `Request for refund successfully sent for invoice ${this.invoice.invoice_no}.`
          );
        })
        .catch((error) => {
          console.error(error);
          this.errorHandler(error);
        })
        .then(() => {
          this.requestingRefund = false;
        });
    },

    setInvoiceExternalPayment() {
      this.submittingExternalPayment = true;

      api
        .setExternalPayment(this.invoice.id)
        .then((response) => {
          this.invoice.payed_value = response.data.payed_value;
          this.invoice.payed_date = response.data.payed_date;
          toast.success(`Invoice has been externally paid.`);
          this.invoice.status = InvoiceStatus.PAID;
        })
        .catch((err) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.submittingExternalPayment = false;
        });
    },

    errorHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error = errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 404) {
          this.notFoundErrorMessage = errorMessages.INVOICE_NOT_FOUND;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.error = err.response.data[error];
          }
        } else {
          this.error = errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
