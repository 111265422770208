<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Create Bulk</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <p class="title mb-3">
                Bulk property
                <button
                  class="btn-primary float-end"
                  type="button"
                  :disabled="loadingDownload"
                  @click="downloadClientCSV"
                >
                  <submission-spinner v-if="loadingDownload" />
                  Download Format
                </button>
              </p>

              <div class="mb-3 col-md-12">
                <label class="pl-3 pt-3 mb-1">CSV / XLS / XLSX</label>
                <label class="form-control d-flex align-items-center justify-content-between">
                  <input 
                    class="d-none shadow-none" 
                    type="file"
                    accept=".csv"
                    :class="{
                      'is-invalid':
                        v$.datasend.csv_url.$invalid && v$.datasend.csv_url.$dirty,
                    }"
                    @blur="v$.datasend.csv_url.$touch"
                    @change="csv_($event)"
                  >
                  <span class="">
                    {{ name }}
                  </span>
                  <span class="border border-success rounded text-primary px-3 py-2">
                    Upload
                  </span>
                </label>
              </div>
              <template
                v-if="
                  (v$.datasend.csv_url.$invalid &&
                    v$.datasend.csv_url.$dirty) ||
                    errors.csv_url
                "
              >
                <small
                  v-if="v$.datasend.csv_url.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="v$.datasend.csv_url.minLength.$invalid"
                  class="invalid-feedback"
                >
                  The minimum size for this field is 3 characters
                </small>

                <small
                  v-else-if="errors.csv_url"
                  class="invalid-feedback"
                >
                  {{ errors.csv_url }}
                </small>
              </template>
              <div>
                <p>The first line will be considered the header of the file, and these columns are recognized:</p>
                <ul>
                  <li>Full Name</li>
                  <li>Company</li>
                  <li>E-mail</li>
                  <li>Phone</li>
                  <li>Address*</li>
                </ul>
                <p>* Address is required</p>
                <p>* Delimiter need to be a comma ""</p>
                <p>This action can take some time, please check the progress in the status field.</p>
              </div>
            </div>

            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="loading"
                >
                  <submission-spinner v-if="loading" />
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: { 
    SubmissionSpinner,
    AlerteDanger
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    name:'File',
    file: null,
    datasend: {
      csv_url: null,
      customer: null,
    },
    errors: {
      csv_url: null,
      customer: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        csv_url: {
          required,
        },
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    downloadClientCSV() {
      this.exportloading = true;

      api.downloadClientCSV()
        .then((response) => {
          this.downloadFile(response);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.exportloading = false;
        });
    },

    downloadFile(response) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] }); // Create a Blob from the data
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customer-properties.csv'); // Set the download attribute with the filename

      // Append the link to the document and trigger the click
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link from the document
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },

    csv_(e) {
      this.file = e.target.files[0];
      this.name = e.target.files[0]?.name;
      this.datasend.csv_url = e.target.files[0];
    },

    save() {
      if (this.loading) {
        return;
      }

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api.bulkAddProperties(this.file)
        .then(() => {
          this.resetValidation();
          this.name = 'File';
          this.back();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        customer: null,
        csv_url: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
