<template>
  <div>
    <div class="autocomplete">
      <div>
        <ul
          v-if="selectedItems.length !== 0"
          class="row element px-3 mb-0"
        >
          <li
            v-for="(item, index) in selectedItems"
            :key="index"
            class="list-unstyled col-md-4 position-relative me-2"
          >
            <template v-if="item?.user">
              <span class="fs-1"> {{ item.user.full_name }} </span>
            </template>
            <template v-else>
              <span class="fs-1"> {{ item }}</span>
            </template>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              viewBox="0 0 14 14"
              role="img"
              class="remove-button"
              @click="removeItem(index)"
            >
              <polygon
                fill-rule="evenodd"
                points="12.524 0 7 5.524 1.476 0 0 1.476 5.524 7 0 12.524 1.476 14 7 8.476 12.524 14 14 12.524 8.476 7 14 1.476"
              />
            </svg>
          </li>
        </ul>
      </div>
      <div>
        <input
          id="autocomplete"
          v-model="searchTerm"
          autocomplete="off"
          class="w-100 shadow-none"
          type="email"
          placeholder="Email"
          @input="search"
          @keydown.enter.prevent="selectItem()"
        >
        <small
          v-if="errorEmail"
          class="text-danger"
        >{{ errorEmail }}</small>
      </div>
    </div>

    <ul
      v-if="searchTerm && searchResults"
      class="mt-0 invited-list"
    >
      <template v-if="searchElement(searchResults)">
        <bar-spinner v-if="loading" />

        <autocomplete-provider-item
          v-for="(searchResult, index) in searchResults"
          v-else
          :key="index"
          :data="searchResult"
          @onSelectItem="selectItem"
        />
      </template>

      <template v-else>
        <div
          class="p-2"
          @mousedown="selectItem(searchResults[0])"
        >
          <bar-spinner v-if="loading" />

          <template v-else>
            <span class="mx-auto d-flex">{{ searchResults[0]?.email }}</span>
            <small v-if="searchResults[0]?.have_invitation">Already invited</small>
          </template>
        </div>
      </template>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";

import api from "@/services/api";
import BarSpinner from "@/components/BarSpinner.vue";
import AutocompleteProviderItem from "@/components/customer/jobs/AutocompleteProviderItem.vue";

export default {
  name: "AppAutocomplete",

  components: {
    BarSpinner,
    AutocompleteProviderItem,
  },

  props: {
    jobId: {
      type: String,
      required: true,
      default: null,
    },

    isSend: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props, { emit }) {
    const searchTerm = ref();
    const searchResults = ref([]);
    const selectedItems = ref([]);
    let loading = ref(false);
    let lastRequestEmail = ref("");
    let errorEmail = ref();
    let email = [];
    let emailNoAccount = [];
    let isSelect = false;

    async function search() {
      errorEmail.value = "";
      loading.value = true;
      lastRequestEmail.value = searchTerm.value;
      const dataSend = {
        job: props.jobId || null,
        email: searchTerm.value,
      };

      try {
        const res = await api.verifyprovider(dataSend);

        if (lastRequestEmail.value !== searchTerm.value) return

        searchResults.value = [];
        for (let i = 0; i < res.data.length; i++) {
          searchResults.value.push(res.data[i]);
        }
        if (res.data[0].is_provider === false) {
          setTimeout(() => {
            loading.value = false;
          }, 5000);
          res.data[0].email = searchTerm.value;
        } else {
          searchElement(searchResults.value);
          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
      } finally {
        lastRequestEmail.value = "";
      }
    }
    function searchElement(searchResults) {
      const index = searchResults.findIndex(
        (elmt) => elmt.is_provider === true
      );
      if (index !== -1) {
        return searchResults[index];
      } else {
        return;
      }
    }
    function selectItem(searchResults) {
      if (searchResults.have_invitation === true) {
        return;
      }
      if (
        email.includes(searchResults?.provider?.user.email) ||
        emailNoAccount.includes(searchResults.email)
      ) {
        searchTerm.value = "";
        searchResults.value = [];
        return;
      }
      if (searchResults.is_provider == false) {
        if (!/^\S+@\S+\.\S+$/.test(searchResults.email)) {
          errorEmail.value = "Please enter a valid email address.";
          return;
        }
        selectedItems.value.push(searchResults.email);
        emailNoAccount.push(searchResults.email);
        emit("emailNoAccount", emailNoAccount);
      } else {
        selectedItems.value.push(searchResults.provider);
        email.push(searchResults.provider.user.email);
        emit("email", email);
        searchTerm.value = "";
      }
      isSelected(selectedItems.value);
      searchResults.value = [];
      isSelect = true;
      searchTerm.value = null;
    }
    function isSelected(data) {
      if (data.length === 0) {
        isSelect = false;
      } else {
        isSelect = true;
      }
      emit("isSelect", isSelect);
    }
    function removeItem(index) {
      let element = selectedItems.value[index];
      if (element.id) {
        const indexe = email.findIndex((elt) => elt === element.user.email);
        if (indexe !== -1) {
          email.splice(indexe, 1);
        }
        emit("email", email);
      } else {
        const indexe = emailNoAccount.findIndex((elt) => elt === element);
        if (indexe !== -1) {
          emailNoAccount.splice(index, 1);
        }
        emit("emailNoAccount", emailNoAccount);
      }
      selectedItems.value.splice(index, 1);
      isSelected(selectedItems.value);
    }

    return {
      searchTerm,
      searchResults,
      searchElement,
      selectedItems,
      search,
      selectItem,
      removeItem,
      errorEmail,
      loading,
      isSelect,
    };
  },

  watch: {
    isSend(value) {
      if (value == true) {
        this.selectedItems = ref([]);
        this.email = [];
        this.emailNoAccount = [];
      } else {
      }
    },
  },
};
</script>

<style scoped>
.invited-list {
  overflow-y: scroll;
  max-height: 14rem;
  padding-left: 0;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}
.invited-list::-webkit-scrollbar {
  display: none;
}

li {
  list-style-type: none;
  padding: 0.5rem;
  cursor: pointer;
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: #209516;
  border-color: #dbdbdb;
  border: none;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding: 0rem;
  border-radius: 4px;
  text-align: center;
  transition: all 0.35s ease-in-out;
}
.autocomplete {
  background: #ffffff;
  border: none !important;
  border-radius: 4px;
}
input:focus,
input {
  border: 1px solid #fff;
  outline: none;
}

input[type="email"] {
  color: #16181a;
  border: 1px solid #fff;
  transition: all 0.35s ease-in-out;
  padding: 0.75rem 2.2.5rem 0.6rem 0.93rem !important;
  border-radius: 4px;
}
.element {
  padding-left: 0.6rem;
  box-shadow: none;
  border: none;
  padding: 0.31rem;
}
.element li {
  background-color: #108a00;
  color: #fff;
  overflow: hidden;
  width: -moz-max-content;
  width: max-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.625rem;
  display: inline-flex;
  cursor: text;
}
.full-name {
  font-weight: 600;
}
.remove-button {
  fill: #fff;
  width: 0.5rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
</style>
