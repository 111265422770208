import { Request } from "@/services/request";

export default {
  fetchLastMessages({ page = 2, search = null, roomId }) {
    if (!roomId) return

    return new Request()
      .append(`api/v1/chat/room/${roomId}/messages_room/`)
      .method("get")
      .params({
        page,
        search,
      });
  },

  sendMessage(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/chat/messages/`)
      .method("post")
      .setData(data);
  },
};
