<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Account refusal reason
          </h5>
        </div>

        <div class="modal-body">
          <p>
            The refusal reason entered will be sent by mail to the provider
          </p>

          <div class="col-md-12">
            <textarea
              v-model="reason"
              cols="30"
              rows="3"
              class="form-control pl-3"
              :class="{
                'is-invalid': v$.reason.$invalid && v$.reason.$dirty,
              }"
              @blur="v$.reason.$touch"
            />
            <small
              v-if="v$.reason.$invalid && v$.reason.$dirty"
              class="invalid-feedback"
            >
              This field is required
            </small>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-danger"
            @click="closeModal"
          >
            Close
          </button>

          <button
            type="button"
            class="btn-primary"
            @click="confirm"
          >
            Validate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import validationHelpers from "@/utils/validation";

export default {
  name: "AccountRefusalReasonModal",

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    reason: null,
    showModal: false,
    fadeModal: false,
  }),

  validations() {
    return {
      reason: {
        required,
      },
    };
  },

  methods: {
    ...validationHelpers,

    confirm() {
      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.$emit("onConfirm", this.reason);
      this.reason = null;
      this.closeModal();
    },

    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      this.resetValidation();
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
  },
};
</script>
