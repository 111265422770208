<template>
  <section class="content profile">
    <alerte-danger
      :head-error-message="error"
      @updateContent="error = $event"
    />

    <div class="float-end">
      <button
        class="btn-primary"
        type="button"
        @click="edit"
      >
        Edit Property
      </button>
    </div>
    <ul class="nav nav-tabs mb-5">
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'info' }"
          aria-current="page"
          @click="switchTag('info')"
        >
          Client info
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'jobs' }"
          @click="switchTag('jobs')"
        >
          Jobs
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'estimates' }"
          @click="switchTag('estimates')"
        >
          Estimates
        </div>
      </li>
      <!-- <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'invoices' }"
          @click="switchTag('invoices')"
        >
          Invoices
        </div>
      </li> -->
    </ul>
    <div
      v-if="client && submenu == 'info'"
      class="col-md-12 row page-content"
    >
      <div class="col-md-12 row">
        <div class="col-md-2">
          <avatar
            :name="client.full_name"
            :size="130"
            :color="'#fff'"
            :background="'#209516'"
            class="user-image"
            alt="User Image"
          />
        </div>
        <div class="col-md-10 pl-5">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Client Name</label>
              <p class="label-content username">
                {{ client.full_name }}
              </p>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Company Name</label>
              <p class="label-content">
                {{ client.company }}
              </p>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Created At</label>
              <p class="label-content">
                {{ formatDate(client.created_at) }}
              </p>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Email</label>
              <p class="label-content text-truncate">
                {{ client.email }}
              </p>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Phone No.</label>
              <p class="label-content">
                {{ client.phone }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="client.main_propriety"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Main property
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Address</label>
          <p class="label-content">
            {{ client.main_propriety.location_description }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Zip Code</label>
          <p class="label-content">
            {{ client.main_propriety.zip }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">City</label>
          <p class="label-content">
            {{ client.main_propriety.city }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">State</label>
          <p class="label-content">
            {{ client.main_propriety.state }}
          </p>
        </div>
      </div>

      <div
        v-if="client.primary_contact"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Primary Contact
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Name</label>
          <p class="label-content">
            {{ client.primary_contact.full_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Phone</label>
          <p class="label-content">
            {{ client.primary_contact.phone }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">E-mail</label>
          <p class="label-content text-truncate">
            {{ client.primary_contact.email }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ client.primary_contact.note }}
          </p>
        </div>
      </div>

      <div
        v-for="(contact, index) in client.additional_contacts"
        :key="index"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Additional Contact
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Name</label>
          <p class="label-content">
            {{ contact.full_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Phone</label>
          <p class="label-content">
            {{ contact.phone }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">E-mail</label>
          <p class="label-content text-truncate">
            {{ contact.email }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ contact.note }}
          </p>
        </div>
      </div>
      <div
        v-if="client.notes"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Notes
          </h4>
        </div>

        <div
          v-for="(note, index) in client.notes"
          :key="index"
          class="col-md-6 col-lg-6 col-xl-4"
        >
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ note.text }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="submenu == 'jobs'">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadJob" />

            <tbody v-if="!fetchingJobs">
              <tr
                v-for="(item, index) in listJob"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detailJob(item.job)"
                  >{{ item.job.title }}</span>
                </td>
                <td>
                  {{ item?.customer_propertie_associate?.location_description }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <submission-spinner
                    v-if="deletingJob && selectedJobId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="optionsAction"
                    @update:options="chooseJob(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingJobs" />

          <app-pagination
            v-if="jobPage.totalPages > 1"
            :align-end="true"
            :current-page="jobPage.currentPage"
            :has-previous-page="jobPage.hasPreviousPage"
            :previous-page="jobPage.currentPage - 1 || 1"
            :has-next-page="jobPage.hasNextPage"
            :next-page="jobPage.currentPage + 1"
            :per-page="jobPage.itemsPerPage"
            :total-elements="jobPage.itemsCount"
            :total-pages="jobPage.totalPages"
            @onGotoPage="fetchPropertiesJobs({ page: $event, search })"
            @onPrev="
              fetchPropertiesJobs({
                page: jobPage.currentPage - 1 || 1,
                search,
              })
            "
            @onNext="
              fetchPropertiesJobs({ page: jobPage.currentPage + 1, search })
            "
          />
        </div>
      </div>
    </div>
    <div v-if="submenu == 'estimates'">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadEstimate" />

            <tbody v-if="!fetchingEstimates">
              <tr
                v-for="(item, index) in listEstimate"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detailEstimate(item)"
                  >{{ item.title }}</span>
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <submission-spinner
                    v-if="deletingEstimate && selectedEstimateId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="optionsAction"
                    @update:options="chooseEstimate(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingEstimates" />

          <app-pagination
            v-if="estimatePage.totalPages > 1"
            :align-end="true"
            :current-page="estimatePage.currentPage"
            :has-previous-page="estimatePage.hasPreviousPage"
            :previous-page="estimatePage.currentPage - 1 || 1"
            :has-next-page="estimatePage.hasNextPage"
            :next-page="estimatePage.currentPage + 1"
            :per-page="estimatePage.itemsPerPage"
            :total-elements="estimatePage.itemsCount"
            :total-pages="estimatePage.totalPages"
            @onGotoPage="fetchPropertiesEstimates({ page: $event, search })"
            @onPrev="
              fetchPropertiesEstimates({
                page: estimatePage.currentPage - 1 || 1,
                search,
              })
            "
            @onNext="
              fetchPropertiesEstimates({ page: estimatePage.currentPage + 1, search })
            "
          />
        </div>
      </div>
    </div>
    <!-- End content -->

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </section>
</template>

<script>
import Avatar from "vue3-avatar";
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants, PaginationContants } from "@/configs/constants";
import { DateHelpers } from "@/utils/date-helpers";

import { CustomerStatusList } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperadminCustomerDetailsPage",

  components: {
    Avatar,
    HeadTable,
    OptionAction,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
    AlerteDanger,
    OptionAction,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      propertiesOption: {},
      CustomerStatusList,
      theadEstimate: ["Title", "Date sent", "Status", ""],
      theadJob: ["Title", "Client property", "Date sent", "Status", ""],
      listEstimate: [],
      listJob: [],
      client: null,
      loading: true,
      exportloading: false,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: null,
      fetchingEstimates: false,
      estimatePage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      fetchingInvoices: false,
      invoicePage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      fetchingJobs: false,
      jobPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      optionsAction: [
        {
          title: "Edit",
          value: "edit",
        },
        {
          title: "Delete",
          value: "delete",
        },
      ],
      actions: {
        DELETE_ESTIMATE: "delete-estimate",
        DELETE_JOB: "delete-job",
        DELETE_INVOICE: "delete-invoice",
      },
      modalConfigs: {
        confirmTitle: null,
        currentAction: null,
        confirmMessage: null,
        showConfirmModal: false,
      },
      deletingJob: false,
      selectedJobId: null,
      deletingEstimate: false,
      selectedEstimateId: null,
      deletingInvoice: false,
      selectedInvoiceId: null,
      error: null
    };
  },

  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  async mounted() {
    this.submenu = this.$route.params.tag;
    await this.init();
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,

    async init() {
      switch (this.submenu) {
        case "info":
          this.fetchPropriety();
          break;
        case "jobs":
          this.fetchPropertiesJobs({ page: 1 });
          break;
        case "estimates":
          this.fetchPropertiesEstimates({ page: 1 });
          break;
        case "invoices":
          // this.fetchPropertiesEstimates({ page: 1 });
          break;

        default:
          break;
      }
    },

    switchTag(tag) {
      this.$router.replace({
        name: "CustomerPropertiesDetail",
        params: { id: this.$route.params.id, tag: tag },
      });
      this.submenu = tag;
      this.init();
    },

    async fetchPropriety() {
      this.loading = true;

      try {
        let response = await api.fetchProperty(this.$route.params.id);
        this.client = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle erros
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(id, action) {
      switch (action) {
        case this.actions.DELETE_ESTIMATE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this estimate ?`;
          this.selectedEstimateId = id;
          break;

        case this.actions.DELETE_JOB:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this job ?`;
          this.selectedJobId = id;
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.DELETE_ESTIMATE:
          this.deleteEstimate(`Estimate successfully deleted.`);
          break;

        case this.actions.DELETE_JOB:
          this.deleteJob(`Job successfully deleted.`);
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    detailEstimate(item) {
      this.$router.push({
        name: "CustomerEstimateDetails",
        params: { id: item.id },
      });
    },

    chooseEstimate(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "CustomerEstimateEdit",
            params: { id: item.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.id, this.actions.DELETE_ESTIMATE);
          break;
      }
    },
    fetchPropertiesEstimates(params) {
      this.fetchingEstimates = true;

      api
        .fetchPropertiesEstimates({ ...params, id: this.$route.params.id })
        .then((response) => {
          this.listEstimate = response.data.data;
          this.estimatePage = {
            currentPage: params.page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingEstimates = false;
        });
    },

    deleteEstimate(successMessage) {
      this.deletingEstimate = true;

      api
        .deleteEstimate(this.selectedEstimateId)
        .then((_) => {
          toast.success(successMessage);
          this.selectedEstimateId = null;
          this.fetchPropertiesEstimates({ page: this.estimatePage.currentPage });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingEstimate = false;
        });
    },
    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.error = err.response.data[error]
          }
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    detailJob(item) {
      this.$router.push({
        name: "CustomerJobDetails",
        params: { id: item.id },
      });
    },
    chooseJob(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "ClientPortfolioJobEdit",
            params: { id: item.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.id, this.actions.DELETE_JOB);
          break;
      }
    },

    fetchPropertiesJobs(params) {
      this.fetchingJobs = true;

      api
        .fetchPropertiesJobs({ ...params, id: this.$route.params.id })
        .then((response) => {
          this.listJob = response.data.data;
          this.jobPage = {
            currentPage: params.page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
          this.fetchClientAllProperty()
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingJobs = false;
        });
    },

    deleteJob(successMessage) {
      this.deletingJob = true;

      api
        .deleteJob(this.selectedJobId)
        .then((_) => {
          toast.success(successMessage);
          this.selectedJobId = null;
          this.fetchPropertiesJobs({ page: this.jobPage.currentPage });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingJob = false;
        });
    },
    edit() {
      this.$router.push({
        name: "CustomerPropertiesEdit",
        params: { id: this.$route.params.id },
      });
    }
  },
};
</script>

<style>
.float-end {
  padding: 0.6rem;
}
</style>
