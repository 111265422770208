<template>
  <div
    v-if="provider.user"
    class="col-md-12"
  >
    <!-- Content Header (Page header) -->
    <section class="content">
      <h3>
        <router-link :to="{ name: 'SuperAdminProviders' }">
          Providers
        </router-link>
        <span class="fw-lighter"> / {{ provider.user.full_name }}</span>
      </h3>
      <alerte-danger
        :head-error-message="error"
        @updateContent="error = $event"
      />
      <!-- Start content -->
      <div class="col-md-12 row p-4 page-content">
        <div class="col-md-12 row mb-5">
          <div class="col-md-6 d-flex">
            <profile-avatar
              :size="50"
              :url="provider.user.photo?.url"
              :fullname="provider.user.full_name"
              :color="provider.user.profile_color"
              :background="provider.user.profile_background"
            />
            <div class="ms-3">
              <h5 class="text-primary">
                {{ provider.user.full_name }}
              </h5>

              <p>{{ formatDate(provider.user.created_at) }}</p>
            </div>
          </div>

          <div class="col-md-6 text-end">
            <span
              class="provider-status mx-4"
              :class="
                provider.status == 'deactivate' || provider.status == 'refused'
                  ? 'provider-status-danger'
                  : provider.status == 'active'
                    ? 'provider-status-primary'
                    : 'provider-status-warning'
              "
            >
              <span class="mb-0">{{ statusToUI(provider.status) }}</span>
            </span>

            <submission-spinner
              v-if="updatingStatus"
              :color="'#209516'"
            />
            <option-action
              v-else
              :status="provider.status"
              :options="ProviderStatusList"
              @update:options="confirmClaim($event)"
            />
          </div>
        </div>

        <ul class="nav nav-tabs my-5 me-3">
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'info' }"
              aria-current="page"
              @click="switchTag('info')"
            >
              Info
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'invoice' }"
              @click="switchTag('invoice')"
            >
              Invoices
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'jobsbids' }"
              @click="switchTag('jobsbids')"
            >
              Jobs bids
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'subcontract' }"
              @click="switchTag('subcontract')"
            >
              Subcontract
            </div>
          </li>
        </ul>

        <div
          v-if="submenu == 'info'"
          class="row"
        >
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Basic Details
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Full Name</label>
            <p class="label-content username">
              {{ provider.user.full_name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Email</label>
            <p class="label-content">
              {{ provider.user.email }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Phone No.</label>
            <p class="label-content">
              {{ provider.user.phone }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Website</label>
            <p class="label-content">
              {{ provider.user.website }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Business Type</label>
            <p
              v-if="getBusinessType(provider.user.business_type)"
              class="label-content"
            >
              {{ getBusinessType(provider.user.business_type).title }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Country</label>
            <p
              v-if="getCountry(provider.user.country)"
              class="label-content"
            >
              {{ getCountry(provider.user.country).label }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">SSN</label>
            <p class="label-content">
              {{ provider.user.ssn }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">EIN</label>
            <p class="label-content">
              {{ provider.user.ein }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Birthdate</label>
            <p class="label-content">
              {{ provider.user.date_of_birth }}
            </p>
          </div>
        </div>

        <div
          v-if="submenu == 'info'"
          class="row"
        >
          <div class="col-md-12 mt-3">
            <h4 class="border-bottom section-title">
              Profile Detail
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Company Name</label>
            <p class="label-content">
              {{ provider.user.company_name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Address Line 1</label>
            <p class="label-content">
              {{ provider.user.address_line_1 }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Address Line 2</label>
            <p class="label-content">
              {{ provider.user.address_line_2 }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Zip Code</label>
            <p class="label-content">
              {{ provider.user.pin_code }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">City</label>
            <p class="label-content">
              {{ provider.user.city }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">State</label>
            <p class="label-content">
              {{ provider.user.state }}
            </p>
          </div>

          <div class="col-md-12">
            <label class="input-label fw-bold">Profile Description</label>
            <p class="label-content">
              {{ provider.user.profile_description }}
            </p>
          </div>

          <div class="col-md-12 col-lg-6">
            <label class="input-label fw-bold">Insurance Document</label>
            <div
              v-if="provider.user.insurance"
              class="document"
            >
              <a
                :href="provider.user.insurance.url"
                target="_target"
              >
                <img
                  v-if="
                    provider.user.insurance.type === mediaTypesConstants.IMAGE
                  "
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(provider.user.insurance.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6">
            <label class="input-label fw-bold">W9 Document</label>
            <div
              v-if="provider.user.w9document"
              class="document"
            >
              <a
                :href="provider.user.w9document.url"
                target="_target"
              >
                <img
                  v-if="
                    provider.user.w9document.type === mediaTypesConstants.IMAGE
                  "
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(provider.user.w9document.original_name) }}
              </a>
            </div>
          </div>


          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Front</label>
            <div
              v-if="provider.user.idfront"
              class="document"
            >
              <a
                :href="provider.user.idfront.url"
                target="_target"
              >
                <img
                  v-if="provider.user.idfront.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(provider.user.idfront.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Back</label>
            <div
              v-if="provider.user.idback"
              class="document"
            >
              <a
                :href="provider.user.idback.url"
                target="_target"
              >
                <img
                  v-if="provider.user.idback.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(provider.user.idback.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label class="input-label fw-bold">Portfolio</label>
            <div
              v-if="provider.user.portfolio?.length > 0"
              class="row"
            >
              <div
                v-for="(file, i) in provider.user.portfolio"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < provider.user.portfolio.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.portfolio?.url"
                    target="_target"
                  >
                    <img
                      v-if="file.portfolio?.type === mediaTypesConstants.IMAGE"
                      :src="icons.imageUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    <img
                      v-else
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.portfolio?.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label class="input-label fw-bold">CVs</label>
            <div
              v-if="provider.user.cv?.length > 0"
              class="row"
            >
              <div
                v-for="(file, i) in provider.user.cv"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < provider.user.cv.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.user_cv?.url"
                    target="_target"
                  >
                    <img
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.user_cv?.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="submenu == 'invoice'"
        >
          <div class="col-12">
            <div class="table-responsive">
              <loader-spinner v-if="loading" />
              <table
                v-if="!loading"
                class="table"
              >
                <head-table :list="invoice.thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in invoice.listData"
                    :key="index"
                  >
                    <td class="py-3">
                      <span
                        class="cursor-pointer text-primary"
                        @click="detailinvoice(item)"
                      >{{ item.invoice_no }}</span>
                    </td>
                    <td>
                      {{ item.job_name }}
                    </td>
                    <td>
                      {{ formatPrice(item.total) }}
                    </td>
                    <td>
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ statusToUI(item.status) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <page-action
            :index="invoice.page"
            :infos="invoice.pages"
            @update:index="
              providerInvoices({ page: $event }), (invoice.page = $event)
            "
          />
        </div>
        <div
          v-if="submenu == 'jobsbids'"
        >
          <div class="col-12">
            <div class="table-responsive">
              <loader-spinner v-if="loading" />
              <table
                v-if="!loading"
                class="table"
              >
                <head-table :list="jobsbids.thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in jobsbids.listData"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'SuperAdminJobDetails',
                          params: { id: item.id }
                        }"
                        class="text-primary"
                      >
                        {{ item.title }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="item.budget_type === jobBudgetTypes.FIXED">
                        <template
                          v-if="item.budget !== null && item.budget !== undefined"
                        >
                          {{ formatPrice(item.budget) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ formatPrice(item.budget_from ?? 0) }}
                        -
                        {{ formatPrice(item.budget_to ?? 0) }}
                      </template>
                    </td>
                    <td>
                      {{ item.level.name }}
                    </td>
                    <td>
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ statusToUI(item.status) }}
                    </td>
                    <td>
                      <option-action
                        :status="item.status"
                        :options="JobActionsList"
                        @update:options="confirmClaimJob(item, $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <page-action
            :index="jobsbids.page"
            :infos="jobsbids.pages"
            @update:index="fetchProviderJob({ page: $event }), (jobsbids.page = $event)"
          />
        </div>
        <div
          v-if="submenu == 'subcontract'"
        >
          <div class="col-12">
            <div class="table-responsive">
              <loader-spinner v-if="loading" />
              <table
                v-if="!loading"
                class="table"
              >
                <head-table :list="subcontract.thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in subcontract.listData"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'SuperAdminJobDetails',
                          params: { id: item.id }
                        }"
                        class="text-primary"
                      >
                        {{ item.title }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="item.budget_type === jobBudgetTypes.FIXED">
                        <template
                          v-if="item.budget !== null && item.budget !== undefined"
                        >
                          {{ formatPrice(item.budget) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ formatPrice(item.budget_from ?? 0) }}
                        -
                        {{ formatPrice(item.budget_to ?? 0) }}
                      </template>
                    </td>
                    <td>
                      {{ item.level.name }}
                    </td>
                    <td>
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ statusToUI(item.status) }}
                    </td>
                    <td>
                      <option-action
                        :status="item.status"
                        :options="JobActionsList"
                        @update:options="confirmClaimJob(item, $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <page-action
            :index="subcontract.page"
            :infos="subcontract.pages"
            @update:index="fetchProviderJob({ page: $event }), (subcontract.page = $event)"
          />
        </div>
      </div>

      <account-refusal-reason-modal
        ref="accountRefusalReasonModal"
        @onConfirm="onConfirmRefusal"
      />
      <confirm-modal
        ref="confirmModal"
        :ok-button-title="confirmationOkBtnText"
        :message="confirmationMessage"
        @onConfirm="updateStatus"
      />
      <confirm-modal
        ref="confirmModalJob"
        :ok-button-title="confirmationOkBtnText"
        :message="confirmationMessage"
        @onConfirm="updateStatusJob"
      />
    </section>
  </div>
</template>

<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import OptionAction from"@/components/OptionAction.vue";

import user from "@/assets/img/user.svg";
import documentIcon from "@/assets/img/icons/document-icon.svg";

import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import FileUtils from "@/utils/file";
import CountryUtils from "@/utils/country";
import BusinessTypesUtils from "@/utils/business-type";

import {
  JobStatusList,
  JobBudgetTypes,
  JobActionsList,
} from "@/configs/constants/jobs";
import { MediaTypesConstants } from "@/configs/constants";
import { DateHelpers } from "@/utils/date-helpers";
import api from "@/services/api";
import statusToUI from "@/utils/statusFormate";
import { ProviderStatusList, ProviderStatus } from "@/configs/constants/users";

import PriceHelpers from "@/utils/price-format";
import PageAction from "@/components/PageAction.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import HeadTable from "@/components/HeadTable.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AccountRefusalReasonModal from "@/components/provider/AccountRefusalReasonModal.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";

import errorMessages from '@/utils/error-messages';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperadminProviderDetailsPage",

  components: {
    ConfirmModal,
    ProfileAvatar,
    PageAction,
    LoaderSpinner,
    HeadTable,
    SubmissionSpinner,
    AccountRefusalReasonModal,
    OptionAction,
    AlerteDanger
  },

  data() {
    return {
      JobStatusList,
      JobActionsList,
      headerOptions: {
        title: JobStatusList[0].title,
        list: JobStatusList,
      },
      images: {
        user,
      },
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      ProviderStatusList,
      provider: {},
      loading: true,
      updatingStatus: false,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      status: null,
      submenu: null,
      invoice: {
        thead: [
          "Invoice Title",
          "Project name",
          "Amount",
          "Date sent",
          "Status",
        ],
        listData: [],
        page: 1,
        pages: {
          count: null,
          max_page_size: null,
          total_pages: null,
        },
      },
      jobsbids: {
        thead: ["Job Title", "Budget", "Level", "Creation date", "Status", ""],
        listData: [],
        page: 1,
        pages: {
          count: null,
          max_page_size: null,
          total_pages: null,
        },
      },
      subcontract: {
        thead: ["Job Title", "Budget", "Level", "Creation date", "Status", ""],
        listData: [],
        page: 1,
        pages: {
          count: null,
          max_page_size: null,
          total_pages: null,
        },
      },
      error: null
    };
  },

  computed: {
    providerStatus() {
      return ProviderStatus;
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },
    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    approvalNumber() {
      return this.$store.getters["sDashState/approvalNumber"];
    },
  },

  async mounted() {
    this.submenu = this.$route.params.tag;
    await this.fetchProvider();
    await this.init();
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,
    ...PriceHelpers,
    ...FileUtils,
    ...CountryUtils,
    ...BusinessTypesUtils,

    async init() {
      switch (this.submenu) {
        case "invoice":
          this.providerInvoices({ page: this.page });
          break;
        case "jobsbids":
          this.fetchProviderJob({ page: this.page });
          break;
        case "subcontract":
          this.fetchProviderSubcontract({ page: this.page });
          break;

        default:
          break;
      }
    },

    switchTag(tag) {
      this.$router.replace({
        name: "SuperAdminProviderDetails",
        params: { id: this.$route.params.id, tag: tag },
      });
      this.submenu = tag;
      this.error = null;
      this.init();
    },

    providerInvoices(param) {
      this.loading = true;

      api
        .providerInvoices({ id: this.$route.params.id, page: param })
        .then((response) => {
          this.invoice.listData = response.data.data;
          this.invoice.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    detailinvoice(item) {
      this.$router.push({
        name: "SuperAdminInvoiceDetails",
        params: { id: item.id },
      });
    },

    async fetchProvider() {
      let id = this.$route.params.id;
      this.loading = true;

      try {
        let response = await api.fetchProvider(id);
        this.provider = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(status) {
      this.status = status;
      let message = "";
      const fullname = this.provider.user.full_name;

      switch (this.status) {
        case this.providerStatus.ACTIVE:
          message = `Are you sure you want to activate ${fullname} account ?`;
          break;

        case this.providerStatus.DEACTIVATE:
          message = `Are you sure you want to deactivate ${fullname} account ?`;
          break;

        case this.providerStatus.REFUSED:
          this.$refs.accountRefusalReasonModal.openModal();
          break;

        default:
          message = `Are you sure you want to make ${this.statusToUI(
            status
          )} ${fullname} account ?`;
          break;
      }

      if (this.status !== this.providerStatus.REFUSED) {
        this.confirmationMessage = message;
        this.confirmationOkBtnText = "Yes";
        this.$refs.confirmModal.openModal();
      }
    },

    onConfirmRefusal(reason) {
      this.updateStatus(reason);
    },

    updateStatus(refusalReason) {
      if (this.updatingStatus) return;

      this.updatingStatus = true;
      const oldStatus = this.provider.status;
      this.error = null;

      let _request = api.updateProviderStatus(this.provider.id, this.status);
      if (refusalReason) {
        _request = api.updateProviderStatus(
          this.provider.id,
          this.status,
          refusalReason
        );
      }

      _request
        .then((_) => {
          if (this.status === ProviderStatus.NEED_APPROVAL) {
            this.$store.commit(
              "sDashState/setApproval",
              this.approvalNumber + 1
            );
          }
          if (oldStatus === ProviderStatus.NEED_APPROVAL) {
            this.$store.commit(
              "sDashState/setApproval",
              this.approvalNumber - 1
            );
          }

          this.provider.status = this.status;
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((error) => {
          this.errorsHandler(error)
        })
        .then(() => {
          this.updatingStatus = false;
        });
    },

    confirmClaimJob(element, status) {
      this.selected = element;
      this.status = status;

      this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
        status
      )} ${this.selected.title} account ?`;
      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModalJob.openModal();
    },

    updateStatusJob() {
      if (this.loading) return;

      this.loading = true;

      api
        .makeJob(this.selected.id, { status: this.status })
        .then((response) => {
          this.fetchProviderJob({ page: this.page });
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.selected = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    fetchProviderJob(param) {
      this.loading = true;

      api
        .fetchProviderJob({id: this.$route.params.id, ...param})
        .then((response) => {
          this.jobsbids.listData = response.data.data.map(el=> (el.job) );
          this.jobsbids.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
    fetchProviderSubcontract(param) {
      this.loading = true;

      api
        .fetchProviderSubcontract({id: this.$route.params.id, ...param})
        .then((response) => {
          this.subcontract.listData = response.data.data;
          this.subcontract.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
    
    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          this.error = err.response.data.error
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
