import { createWebHistory, createRouter } from "vue-router";

import routes from "@/router/routes";
import store from "@/store";
import { ProviderStatus } from "@/configs/constants/users";

const router = createRouter({
  history: createWebHistory(),
  // @ts-ignore
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  const isLogged = store.getters["token/isAuthenticated"];

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (!authRequired) {
    if (isLogged) {
      const mustRedirectIfAuthenticated = routeTo.matched.some(
        (route) => route.meta.redirectIfAuthenticated
      );
      if (mustRedirectIfAuthenticated) {
        redirectIfAuthenticated();
      } else {
        return next();
      }
    } else {
      return next();
    }
  }

  if (isLogged) {
    checkScope(routeTo, routeFrom, next);
  } else {
    redirectToLogin();
  }

  function redirectIfAuthenticated() {
    const isProvider = store.getters["token/isProvider"];
    if (isProvider) {
      const status = store.getters["user/status"];
      if (status === ProviderStatus.NEED_COMPLETE_PROFILE) {
        return next({
          name: "ProviderProfile",
        });
      } else {
        return next({
          name: "ProviderDashboard",
        });
      }
    }

    const isCustomer = store.getters["token/isCustomer"];
    if (isCustomer) {
      return next({
        name: "CustomerDashboard",
      });
    }

    const isSuperAdmin = store.getters["token/isSuperAdmin"];
    if (isSuperAdmin) {
      return next({
        name: "SuperAdminDashboard",
      });
    }
  }

  function redirectToLogin() {
    if (routeTo.name.toString().includes("SuperAdmin")) {
      next({
        name: "SuperAdminLogin",
        query: {
          redirectFrom: routeTo.fullPath,
        },
      });
    } else {
      next({
        name: "Login",
        query: {
          redirectFrom: routeTo.fullPath,
        },
      });
    }
  }

  function checkStatus(routeTo, routeFrom, next) {
    let status = store.getters["user/status"];
    let requiredStatus = [];
    let refuseStatus = [];

    routeTo.matched.forEach((route) => {
      if (route.meta.needStatus != null) {
        requiredStatus.push(...route.meta.needStatus);
      }
      if (route.meta.refuseStatus != null) {
        refuseStatus.push(...route.meta.refuseStatus);
      }
    });

    if (refuseStatus.length > 0 && refuseStatus.includes(status)) {
      next({ name: "ProviderDashboard" });
    }

    if (requiredStatus.length === 0) {
      return next();
    } else if (requiredStatus.length > 0 && requiredStatus.includes(status)) {
      return next();
    } else {
      if (
        status === ProviderStatus.NEED_APPROVAL ||
        status === ProviderStatus.NEED_STRIPE_APPROVAL
      ) {
        return next({
          name: "ProviderWaitingForApproval",
        });
      }

      /**
       * TODO
       * Check this route
       */
      return next({
        path: "/provider/approval/first-step",
      });
    }
  }

  function checkScope(routeTo, routeFrom, next) {
    let token = store.getters["token/token"];
    let scopes = [];
    routeTo.matched.forEach((route) => {
      if (route.meta.neededScope != null) {
        scopes.push(...route.meta.neededScope);
      }
    });

    let canAccess = scopes.every((scope) => token.scope === scope);
    if (canAccess) return checkStatus(routeTo, routeFrom, next);

    store.dispatch("token/logout");
    next({ name: "Login" });
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    const tableResponsive = document.querySelector(".table-responsive");
    if (tableResponsive) {
      tableResponsive.addEventListener("click", function (e) {
        const t = this;
        const m = document.querySelector(".dropdown-menu.show") as HTMLElement;
        if (m) {
          const tb = t.offsetHeight;
          const mb = m.offsetHeight;
          const mbt = m.offsetTop;
          const d = 10;
          const parentTr = m.parentNode.parentNode.parentNode as HTMLElement;
          const parentTable = tableResponsive.querySelector("table");
          const distanceToBottom =
            parentTable.offsetHeight -
            (parentTr.offsetTop + parentTr.offsetHeight);
          const paddingBottom = mb - distanceToBottom - mbt;

          setTimeout(() => {
            if (t.scrollWidth > t.clientWidth) {
              if (mb + d > tb) {
                t.style.paddingBottom = mb + d - tb + "px";
              } else {
                t.style.paddingBottom =
                  (paddingBottom > 0 ? paddingBottom : 0) + "px";
              }
            } else {
              t.style.overflow = "visible";
            }
          }, 10);
        }
      });

      tableResponsive.addEventListener("hidden.bs.dropdown", function () {
        this.style.paddingBottom = "";
        this.style.overflow = "";
      });
    }
  }, 1000)
});

export default router;
