export const GeneralHelpers = {
  formatPhoneNumber(phone) {
    if (!phone) return "";
  
    let numericPhone = phone.replace(/\D/g, "");
  
    let indicator = "";
    if (numericPhone.length > 10) {
      indicator = "1";
      numericPhone = numericPhone.slice(1, 11);
    }

    const formattedPhone = numericPhone.replace(
      /(\d{1,3})(\d{0,3})(\d{0,4})/,
      function (_, p1, p2, p3) {
        let formatted = `(${p1}) ${p2}`;
        if (p3){
          formatted += `-${p3}`
        }
        return formatted;
      }
    );

    if (indicator) {
      return `+${indicator} ${formattedPhone}`;
    }
    
    return formattedPhone
  },
  
  formatEmailAddress(email) {
    if (!email) return "";

    const trimmedEmail = email.trim();
    return trimmedEmail.toLowerCase();
  },

  formatPositiveNumber(number) {
    if (!number) return "";

    const numericValue = Math.abs(parseFloat(number.replace(/\D/g, "")));
    return numericValue.toString();
  },

  stripHtmlTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  },
};
