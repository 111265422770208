import store from "@/store";

class SocketService {
  url: string;
  socket: WebSocket;

  constructor(url) {
    this.url = url;
    this.socket = null;
  }

  connect() {
    const token = store.getters["token/token"]?.accessToken || '';
    this.socket = new WebSocket(`${this.url}?token=${encodeURIComponent(token)}`);

    this.socket.addEventListener("open", (event) => {
      console.log("WebSocket connection opened", event);
    });

    this.socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed", event);
    });

    this.socket.addEventListener("error", (event) => {
      console.error("WebSocket error", event);
    });

    return this.socket
  }

  disconnect() {
    this.socket.close();
  }

  emit(data) {
    if (!data) return
    this.socket.send(JSON.stringify(data));
  }

  on(callback) {
    this.socket.addEventListener("message", callback);
  }
}

export default SocketService;
