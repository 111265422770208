<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div 
      class="modal-dialog modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <div
            v-if="priority"
            class="col-md-12 mb-3"
          >
            <ul class="list-group">
              <li
                v-for="(item, index) in priority"
                :key="index"
                class="list-group-item" 
                style="min-height: 1rem;"
              >
                <input
                  :value="item.color"
                  type="color"
                  class="form-control-color p-0 d-inline"
                  title="Choose your color"
                  style="width: 10%; height: 1rem;"
                  @input="updatePriority('color', {...item, value: $event.target.value})"
                >
                <input
                  v-model="item.name"
                  type="text"
                  class="p-0 d-inline ms-3 border-white"
                  style="width: 80%;"
                  @blur="updatePriority('text', item)"
                >
                <img
                  alt="smallclose "
                  :src="smallclose"
                  style="height: 1rem;"
                  @click="removeSkill(item)"
                >
              </li>
            </ul>
          </div>

          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div
            v-if="type == false"
            class="col-12 mb-4 text-end"
          >
            <button
              class="btn-primary"
              @click="type = true"
            >
              <img
                :src="icons.plusVectorIcon"
                alt="Plus Icon"
                class="me-2"
              >
              Add
            </button>
          </div>
          <div
            v-if="type"
            class="row mb-4 align-items-center"
          >
            <div class="row">
              <div class="col-4">
                <label class="form-label"> 
                  Choose color 
                </label>
                <input
                  v-model="datasend.color"
                  type="color"
                  class="form-control form-control-color p-1"
                  title="Choose your color"
                >
              </div>
              <div class="col-8">
                <label
                  for=""
                  class="mb-2 question-detail"
                >
                  Priority <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.name"
                  type="text"
                  placeholder="Write your priority here..."
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid': v$.datasend.name.$invalid && v$.datasend.name.$dirty,
                  }"
                  @blur="v$.datasend.name.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
                      errors.nameErrorMessage
                  "
                >
                  <small
                    v-if="v$.datasend.name.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.nameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.nameErrorMessage }}
                  </small>
                </template>
              </div>
            </div>
            <div class="col-md-4">
              <button
                class="btn-primary"
                @click="save"
              >
                <img
                  :src="icons.plusVectorIcon"
                  alt="Plus Icon"
                  class="me-2"
                >
                Add
              </button>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import croixVectorIcon from "@/assets/img/icons/croix-red.svg";
import searchVectorIcon from "@/assets/img/icons/search-black.svg";
import smallclose from "@/assets/img/icons/poubell-red.svg";
import plusVectorIcon from "@/assets/img/icons/plus.svg";

import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import AlerteDanger from '@/components/AlerteDanger.vue';
export default {
  name: "PriorityToDo",
  components: { AlerteDanger },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    priority: {
      type: Array,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    icons: {
      searchVectorIcon,
      croixVectorIcon,
      plusVectorIcon,
    },
    smallclose,
    color: '#209516',
    showModal: false,
    fadeModal: false,
    loading: false,
    type: false,
    datasend: {
      name: null,
      job: null,
      color: '#209516',
    },
    errors: {
      nameErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),
  validations() {
    return {
      datasend: {
        name: {
          required,
          minLength: minLength(3),
        },

      }
    };
  },

  methods: {
    ...validationHelpers,

    openModal(el) {
      this.datasend.job = el.job;
      if (this.priority.length == 0) {
        this.type = true
      }
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
    
    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api.createPriority(this.datasend)
        .then((response) => {
          this.datasend.name = null;
          this.datasend.color= '#209516';
          this.type = false;
          this.resetValidation();
          this.$emit('updateList');
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
    clearErrorsMessages() {
      this.errors.nameErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.nameErrorMessage = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
    removeSkill(el) {
      
      api.deletePriority(el.id)
        .then((response) => {
          this.$emit('updateList');
          this.type = false;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error);
        })
    },
    updatePriority(type, el) {
      api.updatePriority(el.id, type == 'color' ? {color: el.value} : {name: el.name})
      .then((response) => {
          this.$emit('updateList');
      })
      .catch(error => {
        console.error(error);
      })
    },

  },
};
</script>
