<template>
  <div class="job-list row">
    <div
      class="col-xl-4 filter-col filter-container"
      :class="showFilter && 'd-block'"
    >
      <a
        type="button"
        class="btn-close bg-primary"
        aria-label="Close"
        @click="showFilter = false"
      />
      <job-filters
        :displayed-jobs-type="selectedJobsStatus"
        @onCriteriasChange="filterJos"
      />
    </div>

    <div
      class="col-xl-8 job-list-col position-relative"
      :class="showFilter && 'not'"
    >
      <div class="section position-relative">
        <div class="title-section row">
          <div class="col-6">
            <p
              v-if="selectedJobStatusData"
              class="title-content"
            >
              {{ selectedJobStatusData.title }}
            </p>
          </div>

          <div
            class="col-6 d-inline-flex justify-content-end align-items-center"
          >
            <div
              v-if="selectedJobStatusLabel"
              class="btn-group btn-group-dropdown"
              :class="{'filter-blur': !access.canAccessMarketplace()}"
            >
              <button
                type="button"
                class="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :disabled="!access.canAccessMarketplace()"
              >
                {{ !access.canAccessMarketplace() ? "Locked" : selectedJobStatusLabel }}
              </button>
              <ul class="dropdown-menu">
                <li 
                  v-for="option in filterOptions"
                  :key="option.value"
                  @click="!access.canAccessMarketplace() ? false : handleJobsStatusChange(option.value)"
                >
                  <a
                    class="dropdown-item cursor-pointer"
                    :class="{ active: option.value === selectedJobsStatus }"
                  >{{ !access.canAccessMarketplace() ? "Locked" : option.label }}</a>
                </li>
              </ul>
            </div>

            <a
              class="ms-3 navbar-toggler search-btn d-none"
              aria-label="Toggle navigation"
              @click="showFilter = true"
            >
              <img
                :src="icons.searchVector"
                alt="Search Icon"
              >
            </a>
          </div>
        </div>

        <template v-if="selectedJobsStatus === filterOptions[0].value">
          <jobs-list
            :jobs="availableJobs"
            :hide="!access.canAccessMarketplace()"
            @fetchNextPage="onFetchNextAvailableJobsPage"
          />
          <loader-spinner v-if="fetchingAvailableJobs" />
        </template>

        <template v-if="selectedJobsStatus === filterOptions[1].value">
          <jobs-list
            :jobs="jobsInProgress"
            :hide="!access.canAccessMarketplace()"
            @fetchNextPage="onFetchNextJobsInProgressPage"
          />
          <loader-spinner v-if="fetchingJobsInProgress" />
        </template>

        <template v-else-if="selectedJobsStatus === filterOptions[2].value">
          <jobs-list
            :jobs="proposalJobs"
            :hide="!access.canAccessMarketplace()"
            :proposals-data="proposalsData"
            @fetchNextPage="onFetchNextProposalJobsPage"
          />
          <loader-spinner v-if="fetchingProposalJobs" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import access from "@/services/access";
import api from "@/services/api";

import plusVector from "@/assets/img/icons/plus.svg";
import searchVector from "@/assets/img/icons/search.svg";

import JobsList from "@/components/provider/jobs/JobsList.vue";
import JobFilters from "@/components/provider/jobs/JobFilters.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { PaginationContants } from "@/configs/constants";

export default {
  name: "ProviderJobsPage",

  components: {
    JobsList,
    JobFilters,
    LoaderSpinner,
  },

  data: () => ({
    access: access,
    showFilter: false,
    icons: {
      plusVector,
      searchVector,
    },
    filters: {},
    selectedJobsStatus: null,
    availableJobs: [],
    availableJobsPagination: {
      currentPage: 1,
      itemsCount: 0,
      totalPages: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      itemsPerPage: PaginationContants.itemsPerPage,
      maxPagesReached: false,
    },
    fetchingAvailableJobs: false,
    isFilteringAvailableJobs: false,
    jobsInProgress: [],
    jobsInProgressPagination: {
      currentPage: 1,
      itemsCount: 0,
      totalPages: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      itemsPerPage: PaginationContants.itemsPerPage,
      maxPagesReached: false,
    },
    fetchingJobsInProgress: false,
    isFilteringJobsInProgress: false,
    proposalJobs: [],
    proposalsData: [],
    proposalJobsPagination: {
      currentPage: 1,
      itemsCount: 0,
      totalPages: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      itemsPerPage: PaginationContants.itemsPerPage,
      maxPagesReached: false,
    },
    fetchingProposalJobs: false,
    isFilteringProposalJobs: false,
    services: []
  }),

  computed: {
    filterOptions() {
      const items = [
        { title: "Available jobs", label: "Available", value: "AVAILABLE" },
        { title: "Jobs In Progress", label: "In Progress", value: "ONGOING" },
        {
          title: "Jobs Already Applied",
          label: "Already Applied",
          value: "ALREADY_APPLIED",
        },
      ];

      return items;
    },

    selectedJobStatusLabel() {
      return this.filterOptions.find(
        (option) => option.value === this.selectedJobsStatus
      )?.label;
    },

    selectedJobStatusData() {
      return this.filterOptions.find(
        (option) => option.value === this.selectedJobsStatus
      );
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    }
  },

  watch: {
    isFilteringAvailableJobs(val) {
      if (!val) {
        this.availableJobs = [];
      }
    },

    isFilteringJobsInProgress(val) {
      if (!val) {
        this.jobsInProgress = [];
      }
    },

    isFilteringProposalJobs(val) {
      if (!val) {
        this.proposalJobs = [];
      }
    },
  },

  mounted() {
    this.selectedJobsStatus = this.filterOptions[0].value;
    this.fetchAvailableJobs({
      page: 1,
    });
    api.fetchSubscriptionPlanServices().then(s => this.services = s);
  },

  methods: {
    handleJobsStatusChange(status) {
      const params = {
        page: 1,
      };
      this.selectedJobsStatus = status;
      switch (this.selectedJobsStatus) {
        case this.filterOptions[0].value:
          this.fetchAvailableJobs(params);
          break;

        case this.filterOptions[1].value:
          this.fetchJobsInProgress(params);
          break;

        case this.filterOptions[2].value:
          this.fetchProposalJobs(params);
          break;
      }
    },

    filterJos(filters) {
      let allFiltersVoided = true;
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (filters[key]) {
            allFiltersVoided = false;
          }
        }
      }
      const params = {
        ...filters,
        page: 1,
      };

      switch (this.selectedJobsStatus) {
        case this.filterOptions[0].value:
          this.isFilteringAvailableJobs = !allFiltersVoided;
          this.jobsInProgressPagination.maxPagesReached = false;
          this.fetchAvailableJobs(params);
          break;

        case this.filterOptions[1].value:
          this.isFilteringJobsInProgress = !allFiltersVoided;
          this.availableJobsPagination.maxPagesReached = false;
          this.fetchJobsInProgress(params);
          break;

        case this.filterOptions[2].value:
          this.isFilteringProposalJobs = !allFiltersVoided;
          this.proposalJobsPagination.maxPagesReached = false;
          this.fetchProposalJobs(params);
          break;
      }
    },

    async fetchAvailableJobs(params) {
      try {
        this.fetchingAvailableJobs = true;

        const response = await api.fetchProposedJobsForProvider(params);
        const jobs = response.data.data;

        if (this.isFilteringAvailableJobs) {
          this.availableJobs = jobs;
        } else {
          this.availableJobs = this.availableJobs.concat(jobs);
        }

        this.availableJobsPagination = this.getPaginationData(
          params.page,
          response.data,
          this.availableJobsPagination.maxPagesReached
        );
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(error)
      } finally {
        this.fetchingAvailableJobs = false;
      }
    },

    async onFetchNextAvailableJobsPage() {
      if (
        this.availableJobsPagination.currentPage >=
        this.availableJobsPagination.totalPages
      ) {
        this.availableJobsPagination.maxPagesReached = true;
      }

      if (
        this.fetchingAvailableJobs ||
        this.availableJobsPagination.maxPagesReached
      ) {
        return;
      }

      await this.fetchAvailableJobs({
        ...this.filters,
        page: this.availableJobsPagination.currentPage + 1,
      });
    },

    async fetchJobsInProgress(params) {
      try {
        this.fetchingJobsInProgress = true;

        const response = await api.fetchJobInProgress(params);
        const jobs = response.data.data.map((x) => x.job);

        if (this.isFilteringJobsInProgress) {
          this.jobsInProgress = jobs;
        } else {
          this.jobsInProgress = this.jobsInProgress.concat(jobs);
        }

        this.jobsInProgressPagination = this.getPaginationData(
          params.page,
          response.data,
          this.jobsInProgressPagination.maxPagesReached
        );
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(error)
      } finally {
        this.fetchingJobsInProgress = false;
      }
    },

    async onFetchNextJobsInProgressPage() {
      if (
        this.jobsInProgressPagination.currentPage >=
        this.jobsInProgressPagination.totalPages
      ) {
        this.jobsInProgressPagination.maxPagesReached = true;
      }

      if (
        this.fetchingJobsInProgress ||
        this.jobsInProgressPagination.maxPagesReached
      ) {
        return;
      }

      await this.fetchJobsInProgress({
        ...this.filters,
        page: this.jobsInProgressPagination.currentPage + 1,
      });
    },

    async fetchProposalJobs(params) {
      try {
        this.fetchingProposalJobs = true;

        const response = await api.getProposals(params);
        const jobs = response.data.data.map((x) => x.job);
        const proposalsData = response.data.data.map((x) => ({
          id: x.id,
          status: x.status,
        }));

        if (this.isFilteringProposalJobs) {
          this.proposalJobs = jobs;
          this.proposalsData = proposalsData;
        } else {
          this.proposalJobs = this.proposalJobs.concat(jobs);
          this.proposalsData = this.proposalsData.concat(proposalsData);
        }

        this.proposalJobsPagination = this.getPaginationData(
          params.page,
          response.data,
          this.proposalJobsPagination.maxPagesReached
        );
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(error)
      } finally {
        this.fetchingProposalJobs = false;
      }
    },

    async onFetchNextProposalJobsPage() {
      if (
        this.proposalJobsPagination.currentPage >=
        this.proposalJobsPagination.totalPages
      ) {
        this.proposalJobsPagination.maxPagesReached = true;
      }

      if (
        this.fetchingProposalJobs ||
        this.proposalJobsPagination.maxPagesReached
      ) {
        return;
      }

      await this.fetchProposalJobs({
        ...this.filters,
        page: this.proposalJobsPagination.currentPage + 1,
      });
    },

    getPaginationData(currentPage, data, maxPagesReached) {
      return {
        currentPage,
        maxPagesReached,
        itemsCount: data.count,
        totalPages: data.total_pages,
        hasNextPage: data.links?.next ? true : false,
        hasPreviousPage: data.links?.previous ? true : false,
        itemsPerPage: data.max_page_size || PaginationContants.itemsPerPage,
      };
    },
  },
};
</script>
