import Cookies from "js-cookie";

import { generateGuid } from "@/utils/guid";

export const setToken = ({ commit, state }, payload) => {
  commit("setToken", payload);
  commit("setDeviceId", generateGuid());

  Cookies.set("auth", `Bearer ${payload.accessToken}`, {
    expires: payload.exp * 1000,
    ...(process.env.VUE_APP_ENABLE_COOKIE_URI
      ? { domain: process.env.VUE_APP_COOKIE_URI }
      : {}),
  });

  Cookies.set("device", state.deviceId, {
    expires: payload.exp * 1000,
    ...(process.env.VUE_APP_ENABLE_COOKIE_URI
      ? { domain: process.env.VUE_APP_COOKIE_URI }
      : {}),
  });
};

export const logout = ({ commit, dispatch }) => {
  commit("setToken", null);
  dispatch("user/forgetUser", null, { root: true });

  Cookies.remove("auth", {
    ...(process.env.VUE_APP_ENABLE_COOKIE_URI
      ? { domain: process.env.VUE_APP_COOKIE_URI }
      : {}),
  });
  Cookies.remove("device", {
    ...(process.env.VUE_APP_ENABLE_COOKIE_URI
      ? { domain: process.env.VUE_APP_COOKIE_URI }
      : {}),
  });
};
