<template>
  <div class="job-detail">
    <loader-spinner v-if="loading" />

    <section
      v-else
      class="content"
    >
      <div class="page-content box-form">
        <div class="col-md-12 my-5">
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  GLOR
                </h5>
                <p class="my-0">
                  Floav clop
                </p>
                <p class="my-0">
                  BLOUNS
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end">
              <div class="col-md-12 align-self-end mb-3">
                <h6 class="mb-2 text-secondary">
                  Balance Due
                </h6>
                <h5 class="text-bold mb-0">
                  43567 32456
                </h5>
              </div>

              <div

                class="col-md-12 align-self-end mb-3"
              >
                <h6 class="mb-2 text-secondary">
                  Payment Date
                </h6>
                <h6 class="fw-normal mb-0">
                  45.34.43
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 my-5">
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  Forl
                </h5>
                <p class="my-0">
                  Crow
                </p>
                <p class="my-0">
                  Blop
                </p>
                <p class="my-0">
                  +34 324 34 324
                </p>
                <p class="my-0">
                  oar@ew.ex
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Creation Date :
                  </p>
                  <p class="col-md-4">
                    MMMM Do, YYYY h:mm a
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Due Date :
                  </p>
                  <p class="col-md-4">
                    23.44.3
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Job :
                  </p>
                  <p class="col-md-4">
                    Lorem, ipsum dolor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 md-3">
          <div class="table-responsive">
            <table class="table">
              <head-table :list="thead" />
              <tbody>
                <tr>
                  <td>
                    3
                  </td>
                  <td>
                    <h6>
                      Lorem
                    </h6>
                    <p class="mb-2 text-secondary">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>
                  </td>
                  <td>
                    2
                  </td>
                  <td>
                    $32 233
                  </td>
                  <td>
                    45 3543
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="col-md-12 my-5 text-end d-flex"
        >
          <div class="col-md-12 align-self-end mb-3">
            <div class="text-end">
              <div class="d-inline-block text-bold">
                <p>Sub Total : &nbsp;</p>
                <p>Tax : &nbsp;</p>
                <p>Service Fee : &nbsp;</p>
                <p>You will receive : &nbsp;</p>
                <p>Total : &nbsp;</p>
              </div>
              <div class="d-inline-block">
                <p>234 3245</p>
                <p>%43</p>
                <p>234 3245</p>
                <p>
                  234 3245
                </p>
                <p>234 3245</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-start">
          <div class="mb-3">
            <h5 class="mb-3 text-primary">
              Notes :
            </h5>
            <p class="my-0">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro expedita rem molestiae, illo sint tempora, minima deserunt sunt vel, iusto quasi autem! Repellendus blanditiis aut amet quas quo sint enim.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";
import PriceHelpers from "@/utils/price-format";
import CountryUtils from "@/utils/country";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import HeadTable from "@/components/HeadTable.vue";


export default {
  name: "SharedInvoiceDetailsPage",
  components: {
    LoaderSpinner,
    HeadTable,
  },
  data: () => ({
    loading: false,
    thead: ["#", "Item", "QTY", "Price", "Amount"],
  }),
  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    ...CountryUtils,
  },
};
</script>
