<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3
          v-if="step3"
          class="mb-3 fs-3"
        >
          Sharing option
        </h3>

        <h3 v-else>
          Post a Job
        </h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="createJob">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <template v-if="step1">
            <div class="row">
              <div class="col-md-12">
                <p class="title mb-3">
                  Job Details
                </p>
                <div class="mb-3">
                  <div class="col-12 mt-4">
                    <button
                      class="float-end btn-circle mb-2"
                      type="button"
                      @click="createjobcategory"
                    >
                      <img
                        :src="icons.plusVectorIcon"
                        alt=""
                      >
                    </button>
                    <label for="Category pb-1">
                      Category <span class="text-danger">*</span>
                    </label>
                  </div>
                  <one-select
                    :value="datasend.category"
                    :options="job.category"
                    placeholder="Type a category name"
                    :classstat="{
                      'is-invalid':
                        (v$.datasend.category.$invalid &&
                          v$.datasend.category.$dirty) ||
                        errors.category,
                    }"
                    class="shadow-none"
                    @blur="v$.datasend.category.$touch"
                    @update:value="datasend.category = $event"
                  />
                  <template
                    v-if="
                      (v$.datasend.category.$invalid &&
                        v$.datasend.category.$dirty) ||
                        errors.category
                    "
                  >
                    <small
                      v-if="v$.datasend.category.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.category"
                      class="invalid-feedback"
                    >
                      {{ errors.category }}
                    </small>
                  </template>
                </div>

                <div class="mb-3">
                  <label
                    for="title"
                    class="form-label"
                  >
                    Title <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.title"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Write a job title to describe your needs"
                    :class="{
                      'is-invalid':
                        (v$.datasend.title.$invalid &&
                          v$.datasend.title.$dirty) ||
                        errors.title,
                    }"
                    @blur="v$.datasend.title.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.title.$invalid &&
                        v$.datasend.title.$dirty) ||
                        errors.title
                    "
                  >
                    <small
                      v-if="v$.datasend.title.minLength.$invalid"
                      class="invalid-feedback"
                    >
                      Must contain at least 3 characters
                    </small>

                    <small
                      v-if="v$.datasend.title.maxLength.$invalid"
                      class="invalid-feedback"
                    >
                      Must contain no more than 255 characters
                    </small>

                    <small
                      v-if="v$.datasend.title.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.title"
                      class="invalid-feedback"
                    >
                      {{ errors.title }}
                    </small>
                  </template>
                </div>

                <div class="mb-3">
                  <label
                    for="Description"
                    class="form-label"
                  >
                    Description <span class="text-danger">*</span>
                  </label>
                  <ckeditor
                    v-model="datasend.description"
                    :editor="editor"
                    :config="editorConfig"
                    tag-name="textarea"
                    :class="{
                      'is-invalid':
                        (v$.datasend.description.$invalid &&
                          v$.datasend.description.$dirty) ||
                        errors.description,
                    }"
                    placeholder="You can use up to 5000 words to explain and share details on what you need to be done"
                    @blur="v$.datasend.description.$touch"
                  />
                  <template
                    v-if="
                      (v$.datasend.description.$invalid &&
                        v$.datasend.description.$dirty) ||
                        errors.description
                    "
                  >
                    <small
                      v-if="v$.datasend.description.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-if="v$.datasend.description.maxLength.$invalid"
                      class="invalid-feedback"
                    >
                      Must contain no more than 5000 characters
                    </small>

                    <small
                      v-else-if="errors.description"
                      class="invalid-feedback"
                    >
                      {{ errors.description }}
                    </small>
                  </template>
                </div>
                <p class="title pt-3 mb-3">
                  More Informations
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <label
                  for="contract"
                  class="form-label"
                >
                  Contract Type <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.contract_type"
                  class="form-select shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.datasend.contract_type.$invalid &&
                        v$.datasend.contract_type.$dirty) ||
                      errors.contract_type,
                  }"
                  @blur="v$.datasend.contract_type.$touch"
                >
                  <option
                    v-for="(contract_type, index) in job.contract_type"
                    :key="index"
                    :value="contract_type.id"
                    :disabled="contract_type.id == null"
                  >
                    {{ contract_type.name }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.contract_type.$invalid &&
                      v$.datasend.contract_type.$dirty) ||
                      errors.contract_type
                  "
                >
                  <small
                    v-if="v$.datasend.contract_type.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.contract_type"
                    class="invalid-feedback"
                  >
                    {{ errors.contract_type }}
                  </small>
                </template>
              </div>
              <div class="col-md-6 pb-3">
                <label
                  for="level"
                  class="form-label"
                >
                  Level <span class="text-danger">*</span>
                </label>
                <div class="input-group has-validation">
                  <select
                    v-model="datasend.level"
                    class="form-select shadow-none"
                    :class="{
                      'is-invalid':
                        (v$.datasend.level.$invalid &&
                          v$.datasend.level.$dirty) ||
                        errors.level,
                    }"
                    @blur="v$.datasend.level.$touch"
                  >
                    <option
                      v-for="(level, index) in job.level"
                      :key="index"
                      :value="level.id"
                      :disabled="level.id == null"
                    >
                      {{ level.name }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.level.$invalid &&
                        v$.datasend.level.$dirty) ||
                        errors.level
                    "
                  >
                    <small
                      v-if="v$.datasend.level.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.level"
                      class="invalid-feedback"
                    >
                      {{ errors.level }}
                    </small>
                  </template>
                </div>
              </div>
              <div class="d-block pb-3">
                <label
                  for="skills"
                  class="form-label"
                >
                  Skills <span class="text-danger">*</span>
                </label>
                <multi-select
                  :value="datasend.skills"
                  :options="job.skills"
                  :category="datasend.category"
                  placeholder="Write a skill name"
                  :classstat="{ 'is-invalid': errors.skills }"
                  @blur="handleSkillSelectionBlur"
                  @update:value="datasend.skills = $event"
                  @update:options="fetchJobSetting()"
                  @hideOtherErros="errors.skills = null"
                  @newSkillAdded="errors.skills = null"
                />
                <small
                  v-if="errors.skills"
                  class="invalid-feedback"
                >
                  {{ errors.skills }}
                </small>
              </div>
              <div class="mb-3">
                <label
                  for="questions"
                  class="form-label"
                >Screening Questions</label>
                <multi-select-list
                  :value="datasend.screening_question"
                  :options="job.screening_question"
                  placeholder="Write a screening question"
                  :classstat="errors.screening_question"
                  :errorsstat="errors.screening_question"
                  @update:value="datasend.screening_question = $event"
                  @update:options="job.screening_question.push($event)"
                />
              </div>
              <div class="row m-0 p-0">
                <div class="pb-3 text-end">
                  <button
                    class="btn-save me-3"
                    :disabled="savingAsDraft"
                    @click="confirmClaim"
                  >
                    <div class="d-flex align-items-center">
                      <submission-spinner
                        v-if="savingAsDraft"
                        :color="'#209516'"
                      />
                      <span>Save as Draft</span>
                    </div>
                  </button>

                  <button
                    class="btn-primary btn-next"
                    :disabled="disableNext"
                    @click="next"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="step2">
            <div class="row">
              <p class="title pt-3 mb-3">
                Budget
              </p>

              <div class="col-md-6 mb-3">
                <label
                  for="type"
                  class="form-label"
                >
                  Type <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.budget_type"
                  class="form-select shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.datasend.budget_type.$invalid &&
                        v$.datasend.budget_type.$dirty) ||
                      errors.budget_type,
                  }"
                  @blur="v$.datasend.budget_type.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select an Option
                  </option>
                  <option
                    v-for="type in jobBudgetTypesOptions"
                    :key="type.value"
                    :value="type.value"
                  >
                    {{ capitalizeFirstLetter(type.label) }}
                  </option>
                </select>

                <template
                  v-if="
                    (v$.datasend.budget_type.$invalid &&
                      v$.datasend.budget_type.$dirty) ||
                      errors.budget_type
                  "
                >
                  <small
                    v-if="v$.datasend.budget_type.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.budget_type"
                    class="invalid-feedback"
                  >
                    {{ errors.budget_type }}
                  </small>
                </template>
              </div>

              <div
                v-if="datasend.budget_type !== jobBudgetTypes.NOT_SURE"
                class="col-md-6 pb-3"
              >
                <label
                  for="payement"
                  class="form-label"
                >
                  Payment Frequency <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.payment_frequency"
                  class="form-select shadow-none"
                  :class="{ 'is-invalid': errors.payment_frequency }"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select an Option
                  </option>
                  <option
                    v-for="frequency in jobPaymentFrequencies"
                    :key="frequency"
                    :value="frequency"
                  >
                    {{ capitalizeFirstLetter(frequency) }}
                  </option>
                </select>
                <small
                  v-if="errors.payment_frequency"
                  class="invalid-feedback"
                >
                  {{ errors.payment_frequency }}
                </small>
              </div>

              <div
                v-if="datasend.budget_type == jobBudgetTypes.FIXED"
                class="col-md-6 pb-3"
              >
                <label
                  for="from"
                  class="form-label"
                >Amount</label>
                <div class="d-flex align-items-center">
                  <money3
                    v-model="datasend.budget"
                    prefix="$"
                  />
                  <span
                    v-if="
                      datasend.payment_frequency &&
                        formatJobPaymentFrequencies(datasend.payment_frequency)
                          ?.short
                    "
                    class="ms-2"
                  >
                    /
                    {{
                      formatJobPaymentFrequencies(datasend.payment_frequency)
                        .short
                    }}
                  </span>
                </div>
                <template v-if="errors.budget">
                  <small
                    v-if="errors.budget"
                    class="invalid-feedback"
                  >
                    {{ errors.budget }}
                  </small>
                </template>
              </div>

              <div
                v-if="datasend.budget_type == jobBudgetTypes.RANGE"
                class="col-12 col-md-6 pb-3"
              >
                <label
                  for="from"
                  class="form-label"
                >From</label>
                <div class="d-flex align-items-center">
                  <money3
                    v-model="datasend.budget_from"
                    prefix="$"
                  />

                  <span
                    v-if="
                      datasend.payment_frequency &&
                        formatJobPaymentFrequencies(datasend.payment_frequency)
                          ?.short
                    "
                    class="ms-2"
                  >
                    /
                    {{
                      formatJobPaymentFrequencies(datasend.payment_frequency)
                        .short
                    }}</span>
                </div>

                <template v-if="errors.budget_from">
                  <small
                    v-if="errors.budget_from"
                    class="invalid-feedback"
                  >
                    {{ errors.budget_from }}
                  </small>
                </template>
              </div>

              <div
                v-if="datasend.budget_type == jobBudgetTypes.RANGE"
                class="col-md-6 pb-3"
              >
                <label
                  for="to"
                  class="form-label"
                >To</label>
                <div class="d-flex align-items-center">
                  <money3
                    v-model="datasend.budget_to"
                    prefix="$"
                  />

                  <span
                    v-if="
                      datasend.payment_frequency &&
                        formatJobPaymentFrequencies(datasend.payment_frequency)
                          ?.short
                    "
                    class="ms-2"
                  >
                    /
                    {{
                      formatJobPaymentFrequencies(datasend.payment_frequency)
                        .short
                    }}
                  </span>
                </div>

                <template v-if="errors.budget_to">
                  <small
                    v-if="errors.budget_to"
                    class="invalid-feedback"
                  >
                    {{ errors.budget_to }}
                  </small>
                </template>
              </div>

              <p class="title pt-3 mb-3">
                When
              </p>
              <div class="col-md-6 mb-3">
                <label
                  for="contract"
                  class="form-label"
                >Start Date (Optional)</label>

                <vue-date-picker
                  v-model="datasend.start_date"
                  placeholder="Choose a Start Date"
                  class="datepicker form-control"
                  :class="{
                    'is-invalid': errors.start_date,
                  }"
                  auto-apply
                  :enable-time-picker="false"
                  :min-date="new Date()"
                  :max-date="datasend.end_date"
                  :format="'MM-dd-yyyy'"
                />

                <template v-if="errors.start_date">
                  <small
                    v-if="errors.start_date"
                    class="invalid-feedback"
                  >
                    {{ errors.start_date }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 pb-3">
                <label
                  for="level"
                  class="form-label"
                >End Date (Optional)</label>

                <vue-date-picker
                  v-model="datasend.end_date"
                  placeholder="Choose an End Date"
                  class="datepicker form-control"
                  :class="{
                    'is-invalid': errors.end_date,
                  }"
                  auto-apply
                  :enable-time-picker="false"
                  :min-date="datasend.start_date || new Date()"
                  :start-date="datasend.start_date || new Date()"
                  :format="'MM-dd-yyyy'"
                />

                <template v-if="errors.end_date">
                  <small
                    v-if="errors.end_date"
                    class="invalid-feedback"
                  >
                    {{ errors.end_date }}
                  </small>
                </template>
              </div>
              <p class="title pt-3 mb-3">
                Location
              </p>

              <div class="d-block pb-3">
                <label
                  for="skills"
                  class="form-label"
                >
                  Language
                </label>
                <one-select
                  :value="datasend.job_language"
                  :options="job.job_language"
                  placeholder="Choose a Language"
                  :classstat="{
                    'is-invalid':
                      errors.job_language,
                  }"
                  @update:value="datasend.job_language = $event"
                />
                <template
                  v-if="
                    errors.job_language
                  "
                >
                  <small
                    v-if="errors.job_language"
                    class="invalid-feedback"
                  >
                    {{ errors.job_language }}
                  </small>
                </template>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="Category mb-1">
                    Job Type <span class="text-danger">*</span>
                  </label>
                  <select
                    v-model="datasend.job_type"
                    class="form-select shadow-none"
                    :class="{
                      'is-invalid':
                        (v$.datasend.job_type.$invalid &&
                          v$.datasend.job_type.$dirty) ||
                        errors.job_type,
                    }"
                    @blur="v$.datasend.job_type.$touch"
                    @change="validateLocation"
                  >
                    <option
                      v-for="type in jobTypes"
                      :key="type.id"
                      :value="type.value"
                      :disabled="!type.value"
                      :selected="!type.value"
                    >
                      {{ type.label }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.job_type.$invalid &&
                        v$.datasend.job_type.$dirty) ||
                        errors.job_type
                    "
                  >
                    <small
                      v-if="v$.datasend.job_type.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.job_type"
                      class="invalid-feedback"
                    >
                      {{ errors.job_type }}
                    </small>
                  </template>
                </div>

                <div class="mb-3">
                  <label
                    for="title"
                    class="form-label title"
                  >
                    Job Location
                    <span
                      v-if="datasend.job_type === jobTypes[1].value"
                      class="text-danger"
                    >*</span>
                  </label>
                  <GMapAutocomplete
                    :value="datasend.location_description"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Enter your Job Location"
                    :class="{ 'is-invalid': errors.location_description }"
                    @blur="validateLocation"
                    @change="handleLocationChange"
                    @place_changed="setJobLocation"
                  />
                  <small
                    v-if="errors.location_description"
                    class="invalid-feedback"
                  >
                    {{ errors.location_description }}
                  </small>
                </div>
              </div>

              <div class="col-md-12 mb-5">
                <label class="form-label">Photos and Documents</label>
                <div
                  class="drap-and-drop-file"
                  @drop.prevent="addDocumentsFile"
                  @dragover.prevent
                >
                  <div v-if="!datasend.documents || !datasend.documents.length">
                    <div class="py-2">
                      Drag or Upload your Files
                    </div>

                    <img
                      :src="icons.cloudIcon"
                      alt="icon Upload"
                      class="d-block mx-auto mb-2"
                    >
                  </div>

                  <div
                    v-else
                    class="row file-list"
                  >
                    <div
                      v-for="(file, i) in datasend.documents"
                      :key="i"
                      class="col-md-12 col-lg-6"
                    >
                      <div class="file-thumb">
                        <div class="file-description">
                          <img
                            v-if="file.isImage"
                            :src="icons.imageUploadedIcon"
                            alt="File Uploaded"
                            class="file-icon"
                          >
                          <img
                            v-else
                            :src="icons.documentUploadedIcon"
                            alt="File Uploaded"
                            class="file-icon"
                          >
                          <span>{{ file.filename }}</span>
                        </div>

                        <div
                          class="remove-file"
                          @click="removeDocumentsFile(file)"
                        >
                          <img
                            :src="icons.closeIcon"
                            alt="File Uploaded"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <input
                    ref="documentsFiles"
                    class="d-none shadow-none"
                    type="file"
                    accept="application/pdf, .docx, image/*"
                    multiple
                    @change="chooseDocumentsFile"
                  >
                  <button
                    type="button"
                    class="btn-upload"
                    :disabled="creatingJob"
                    @click="$refs.documentsFiles.click()"
                  >
                    Upload
                  </button>
                </div>
              </div>

              <div class="col-4 mb-3">
                <button
                  class="btn-outline"
                  :disabled="savingAsDraft"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-8 pb-3 text-end">
                <button
                  class="btn-save"
                  :disabled="savingAsDraft || creatingJob"
                  @click="confirmClaim"
                >
                  <div class="d-flex align-items-center">
                    <submission-spinner
                      v-if="savingAsDraft"
                      :color="'#209516'"
                    />
                    <span>Save as Draft</span>
                  </div>
                </button>

                <button
                  type="submit"
                  class="btn-primary btn-next ms-3"
                  :disabled="v$.datasend.$invalid || creatingJob"
                >
                  <submission-spinner v-if="creatingJob" />
                  Publish a Job
                </button>
              </div>
            </div>
          </template>

          <template v-else-if="step3 && isCreate">
            <invite-subcontract
              :job-id="idPost"
              :bid="$route.params.proposalId"
              :id-post="idPost"
              classes="mb-0"
            />
          </template>
        </form>
      </div>
    </section>

    <app-create
      ref="CreateSetting"
      title="Categories"
      label="jobcategory"
      :existing-categories="job?.category ?? []"
      @updateList="fetchJobSetting"
    />
    <confirm-modal
      ref="confirmModal"
      ok-button-title="Draft"
      message="Are you sure you want to make this job as draft ?"
      @onConfirm="saveAsDraft"
    />
  </div>
</template>

<script>
import plusVectorIcon from "@/assets/img/icons/plus.svg";
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";
import ItalicVectorIcon from "@/assets/img/icons/italic.svg";
import alignVectorIcon from "@/assets/img/icons/align.svg";
import listVectorIcon from "@/assets/img/icons/listNumber.svg";
import linkVectorIcon from "@/assets/img/icons/link.svg";
import calendarVectorIcon from "@/assets/img/icons/calendar-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";
import closeIcon from "@/assets/img/icons/remove.svg";
import cloudIcon from "@/assets/img/icons/cloud.svg";

import api from "@/services/api";
import MultiSelect from "@/components/MultiSelect.vue";
import { Money3Component } from "v-money3";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Create from "@/components/superadmin/setting/Create";
import MultiSelectList from "@/components/MultiSelectList.vue";
import OneSelect from "@/components/OneSelect.vue";
import errorMessages from "@/utils/error-messages";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InviteSubcontract from "@/components/provider/jobs/InviteSubcontract.vue";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import JobHelper from "@/utils/job";
import FileUtils from "@/utils/file";
import { DateHelpers } from "@/utils/date-helpers";
import LocationUtils from "@/utils/location";
import { GeneralHelpers } from "@/utils/general";

import {
  JobStatus,
  JobTypes,
  JobBudgetTypes,
  JobPaymentFrequencies,
} from "@/configs/constants/jobs";
import { GlobalEvents } from '@/configs/constants';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CreateSubcontractJob",

  components: {
    AppCreate: Create,
    MultiSelect,
    money3: Money3Component,
    MultiSelectList,
    OneSelect,
    ConfirmModal,
    InviteSubcontract,
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      plusVectorIcon,
      croixVectorIcon,
      ItalicVectorIcon,
      alignVectorIcon,
      listVectorIcon,
      linkVectorIcon,
      calendarVectorIcon,
      imageUploadedIcon,
      documentUploadedIcon,
      closeIcon,
      cloudIcon,
    },
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "bold",
        "italic",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "undo",
        "redo",
      ],
    },
    step1: true,
    step2: false,
    step3: false,
    loading: false,
    creatingJob: false,
    savingAsDraft: false,
    isCreate: false,
    idPost: null,
    datasend: {
      skills: [],
      category: null,
      job_language: null,
      level: null,
      contract_type: null,
      screening_question: [],
      title: null,
      description: "",
      description_plain_text: null,
      budget_type: null,
      payment_frequency: null,
      budget: 0,
      budget_from: 0,
      budget_to: 0,
      start_date: null,
      end_date: null,
      job_type: null,
      location_description: null,
      job_parent: null,
      documents: [],
    },
    defaultSelectedCategory: { id: null, name: "Select your job category" },
    defaultSelected: { id: null, name: "Select an Option" },
    job: {
      category: [],
      contract_type: [],
      level: [],
      skills: [],
      screening_question: [],
      job_language: [],
    },
    errors: {
      category: null,
      level: null,
      contract_type: null,
      title: null,
      description: null,
      budget_type: null,
      payment_frequency: null,
      budget: null,
      budget_from: null,
      budget_to: null,
      start_date: null,
      end_date: null,
      job_type: null,
      location_description: null,
      skills: null,
      job_language: null,
      screening_question: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        category: {
          required,
        },

        level: {
          required,
        },

        contract_type: {
          required,
        },

        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },

        description: {
          required,
          maxLength: maxLength(5000),
        },

        budget_type: {
          required,
        },
        
        job_type: {
          required,
        },

      },
    };
  },

  computed: {
    disableNext() {
      if (
        !this.datasend.category ||
        !this.datasend.title ||
        !this.datasend.description ||
        !this.datasend.contract_type ||
        !this.datasend.level ||
        this.datasend.skills.length == 0 ||
        this.savingAsDraft
      ) {
        return true;
      }

      return false;
    },

    jobTypes() {
      const items = [
        { id: 1, label: "Select a Job Type", value: null },
        { id: 2, label: "On Site", value: JobTypes.ON_SITE },
        { id: 3, label: "Remote", value: JobTypes.REMOTE },
      ];

      return items;
    },

    jobPaymentFrequencies() {
      return JobPaymentFrequencies;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobBudgetTypesOptions() {
      const items = [
        { label: "Fixed", value: JobBudgetTypes.FIXED },
        { label: "Range", value: JobBudgetTypes.RANGE },
        { label: "Not Sure", value: JobBudgetTypes.NOT_SURE },
      ];

      return items;
    },
  },

  watch: {
    "datasend.budget_type": function (val) {
      if (val === JobBudgetTypes.NOT_SURE) {
        this.datasend.budget = null;
        this.datasend.budget_to = null;
        this.datasend.budget_from = null;
        this.datasend.payment_frequency = null;
      }
    },

    "datasend.description": function () {
      this.datasend.description_plain_text = this.stripHtmlTags(this.datasend.description)
    }
  },

  mounted() {
    this.fetchJobSetting();
    if (this.$route.query.step3) {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.isCreate = true;
    }
    this.fetchJob();
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...LocationUtils,
    ...validationHelpers,
    ...GeneralHelpers,

    handleSkillSelectionBlur() {
      if (!this.datasend.skills.length) {
        this.errors.skills = "You must select at least one skill";
      } else {
        this.errors.skills = null;
      }
    },

    handleLocationChange(e) {
      if (!e.target.value) {
        this.datasend.location_description = null;
      }
    },

    validateLocation() {
      setTimeout(() => {
        if (!this.isLocationValid()) {
          this.errors.location_description = "This field is required";
          return
        } else {
          this.errors.location_description = null;
        }
      }, 200);
    },

    isLocationValid() {
      if (
        this.datasend.job_type === this.jobTypes[1].value &&
        !this.datasend.location_description
      ) {
        return false;
      } else {
        return true;
      }
    },

    setJobLocation(values) {
      if (values) {
        const locationInfo = this.extractLocationInfo(values.address_components);
        if (locationInfo) {
          this.datasend.country = locationInfo.country.shortName;
        }

        const lat = values.geometry?.location?.lat();
        const lng = values.geometry?.location?.lng();
        this.datasend.location = {
          type: "Point",
          coordinates: [lng, lat],
        };
        this.datasend.location_description = values.formatted_address;
      }
    },

    validatePaymentFrequency() {
      setTimeout(() => {
        if (!this.isPaymentFrequencyValid()) {
          this.errors.payment_frequency = "This field is required";
          return;
        } else {
          this.errors.payment_frequency = null;
        }
      }, 200);
    },

    isPaymentFrequencyValid() {
      if (!this.datasend.payment_frequency) {
        if (this.datasend.budget_type === JobBudgetTypes.NOT_SURE) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    createjobcategory() {
      this.$refs.CreateSetting.openModal();
    },

    next() {
      this.step1 = false;
      this.step2 = true;
      this.$emitter.emit(GlobalEvents.SCROLL_TO_TOP);
    },

    gotoInvite() {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.isCreate = true;
      this.$emitter.emit(GlobalEvents.SCROLL_TO_TOP);
    },

    back() {
      this.step1 = true;
      this.step2 = false;
      this.$emitter.emit(GlobalEvents.SCROLL_TO_TOP);
    },

    addDocumentsFile(e) {
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      const filesToUpload = [];

      [...droppedFiles].forEach((f) => {
        const fileAlreadyAdded = this.datasend.documents.find((emt) => {
          return emt == f;
        });
        if (fileAlreadyAdded) return;
        filesToUpload.push(f);
        this.datasend.documents.push({
          file: f,
          isImage: this.isImage(f.type),
          filename: this.getFileName(f.name),
        });
      });
    },

    chooseDocumentsFile(e) {
      let uploadedFiles = e.target.files;
      if (!uploadedFiles) return;

      const filesToUpload = [];

      [...uploadedFiles].forEach((f) => {
        const fileAlreadyAdded = this.datasend.documents.find((emt) => {
          return emt == f;
        });
        if (fileAlreadyAdded) return;
        filesToUpload.push(f);
        this.datasend.documents.push({
          file: f,
          isImage: this.isImage(f.type),
          filename: this.getFileName(f.name),
        });
      });
    },

    async removeDocumentsFile(file) {
      this.datasend.documents = this.datasend.documents.filter((f) => {
        return f != file;
      });
    },

    async fetchJobSetting() {
      if (this.loading) return;

      this.loading = true;

      try {
        await api.fetchJobSetting((data) => {
          this.job = data;
          this.job.skills = this.job.skills.sort((skillA, skillB) => {
            return skillA.name.localeCompare(skillB.name);
          });
          this.job.category = this.job.category.sort((categoryA, categoryB) => {
            return categoryA.name.localeCompare(categoryB.name);
          });
          this.job.category.unshift(this.defaultSelectedCategory);
          this.job.contract_type.unshift(this.defaultSelected);
          this.job.level.unshift(this.defaultSelected);
        });
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchJob() {
      try {
        this.loading = true;
        const response = await api.fetchJob(this.$route.params.id);

       this.job.skills = [...this.job.skills, ...response.data.skills];
       this.job.screening_question = [...this.job.screening_question, ...response.data.screening_question]
        this.datasend = {
          title: response.data.title,
          description: response.data.description,
          budget_type: response.data.budget_type,
          payment_frequency: response.data.payment_frequency,
          budget: response.data.budget,
          budget_from: response.data.budget_from,
          budget_to: response.data.budget_to,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          job_type: response.data.job_type,
          location_description: response.data.location_description,
          skills: response.data.skills
            ? response.data.skills.map((el) => el.id)
            : [],
          category: response.data.category?.id || null,
          job_language: response.data.job_language?.id || null,
          level: response.data.level?.id || null,
          contract_type: response.data.contract_type?.id || null,
          screening_question: response.data.screening_question
            ? response.data.screening_question.map((el) => el.id)
            : [],
          documents: response.data.files.map((file) => ({
            file,
            isImage: this.isImage(file.type),
            filename: this.getFileName(file.original_name),
            existed: true,
          })),
        };

      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.errors.jobFetchingErrorMessage = errorMessages.JOB_NOT_FOUND;
        } else {
          this.errors.jobFetchingErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } finally {
        this.loading = false;
      }
    },

    async createJob() {
      if (this.creatingJob) return;

      if (!this.isPaymentFrequencyValid()) {
        this.errors.payment_frequency = "This field is required";
        if (this.v$.datasend.$invalid) {
          this.forceValidation();
          return;
        }
        return;
      } else {
        this.errors.payment_frequency = null;
      }
      
      if (!this.isLocationValid()) {
        this.errors.location_description = "This field is required";
        return
      } else {
        this.errors.location_description = null;
      }

      this.creatingJob = true;
      this.clearErrorsMessages();

      if (this.datasend.start_date) {
        this.datasend.start_date = this.formatDate(
          this.datasend.start_date,
          "YYYY-MM-DD"
        );
      }
      if (this.datasend.end_date) {
        this.datasend.end_date = this.formatDate(
          this.datasend.end_date,
          "YYYY-MM-DD"
        );
      }

      this.datasend.job_parent = this.$route.params.id;

      try {
        /**
         * @description
         * Fisrt updload the files
         */
        const files = await this.uploadJobFiles();

        const { documents, ...formdata } = this.datasend;
        await api.createJob({ ...formdata, files }, (data) => {
          this.idPost = data.id;
          this.gotoInvite();
        });
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.creatingJob = false;
      }
    },

    async saveAsDraft() {
      if (this.savingAsDraft) return;

      this.savingAsDraft = true;

      if (this.datasend.start_date) {
        this.datasend.start_date = this.formatDate(
          this.datasend.start_date,
          "YYYY-MM-DD"
        );
      }
      if (this.datasend.end_date) {
        this.datasend.end_date = this.formatDate(
          this.datasend.end_date,
          "YYYY-MM-DD"
        );
      }

      try {
        /**
         * @description
         * Fisrt updload the files
         */
        const files = await this.uploadJobFiles();

        const { documents, ...formdata } = this.datasend;
        await api.createJob(
          { ...formdata, files, status: JobStatus.DRAFT },
          (data) => {
            this.$router.push({
              name: "ProviderSubcontractDetails",
              params: { id: data.id },
            });
          }
        );
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.savingAsDraft = false;
      }
    },

    async uploadJobFiles() {
      if (!this.datasend.documents.length) return [];

      const files = [];
      if (this.datasend.documents.length) {
        for (let index = 0; index < this.datasend.documents.length; index++) {
          const document = this.datasend.documents[index];

          if (!document.existed) {
            const response = await api.uploadFile({
              file: document.file,
            });
            files.push(response.data.file_id);
          } else {
            files.push(document.file.id);
          }
        }
      }

      return files;
    },

    clearErrorsMessages() {
      this.errors = {
        category: null,
        level: null,
        contract_type: null,
        title: null,
        description: null,
        budget_type: null,
        payment_frequency: null,
        budget: null,
        budget_from: null,
        budget_to: null,
        start_date: null,
        end_date: null,
        job_type: null,
        location_description: null,
        skills: null,
        job_language: null,
        screening_question: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] = err.response.data[error][0];
            if (
              [
                "category",
                "level",
                "contract_type",
                "title",
                "description",
                "skills",
                "screening_question",
              ].includes(error)
            ) {
              this.back();
            } else {
              this.next();
            }
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.input-date {
  width: 200px;
  padding: 0.75rem 0.125rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  color: red !important;
}
</style>
