export default {
  forceValidation() {
    for (const v in this.v$) {
      if (this.v$[v] == null) {
        continue;
      }
      if (typeof this.v$[v].$touch === "function") {
        this.v$[v].$touch();
      }
    }
  },

  resetValidation() {
    for (const v in this.v$) {
      if (this.v$[v] == null) {
        continue;
      }
      if (typeof this.v$[v].$reset === "function") {
        this.v$[v].$reset();
      }
    }
  },
};
