<template>
  <div class="job-list row">
    <div
      class="col-xl-4 filter-col filter-container"
      :class="showFilter && 'd-block'"
    >
      <a
        type="button"
        class="btn-close bg-primary"
        aria-label="Close"
        @click="showFilter = false"
      />
      <job-filters @onCriteriasChange="filterJos($event)" />
    </div>

    <div
      class="col-xl-8 job-list-col position-relative"
      :class="showFilter && 'not'"
    >
      <div class="section position-relative">
        <div class="title-section d-sm-flex justify-content-between">
          <p class="title-content">
            {{ pageTitle }}
          </p>

          <div
            class="col d-flex align-items-center justify-content-end gap-1"
          >
            <div
              v-if="status"
              class="btn-group btn-group-dropdown me-1"
            >
              <button
                type="button"
                class="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ selectedJobStatusLabel }}
              </button>
              <ul class="dropdown-menu">
                <li 
                  v-for="option in filterOptions"
                  :key="option.value"
                  @click="handleJobsStatusChange(option.value)"
                >
                  <a
                    class="dropdown-item cursor-pointer"
                    :class="{ active: option.value === status }"
                  >{{ option.label }}</a>
                </li>
              </ul>
            </div>

            <router-link
              :to="{ name: 'CustomerJobCreate' }"
              class="post-job-button text-decoration-none me-2"
            >
              <img
                alt="Add Icon"
                :src="icons.plusVector"
              >
              Post a Job
            </router-link>

            <a
              class="navbar-toggler search-btn d-none"
              aria-label="Toggle navigation"
              @click="showFilter = !showFilter"
            >
              <img
                :src="icons.searchVector"
                alt="Search Icon"
              >
            </a>
          </div>
        </div>

        <jobs-list
          :jobs="jobs"
          @fetchNextPage="onFetchNextJobsPage"
        />

        <loader-spinner v-if="fetching" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import plusVector from "@/assets/img/icons/plus.svg";
import searchVector from "@/assets/img/icons/search.svg";

import JobsList from "@/components/customer/jobs/JobsList";
import JobFilters from "@/components/customer/jobs/JobFilters";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { JobStatus } from "@/configs/constants/jobs";

export default {
  name: "CustomerJobs",

  components: {
    JobsList,
    JobFilters,
    LoaderSpinner,
  },

  mixins: [ListMixin(api.fetchMyJobs, true, false)],

  data: () => ({
    showFilter: false,
    status: JobStatus.ACTIVE,
    pageTitle: "Active Jobs",
    icons: {
      plusVector,
      searchVector,
    },
    jobs: [],
    fetching: false,
    maxPagesReached: false,
    isFilteringJobs: false,
  }),

  computed: {
    filterOptions() {
      const items = [
        { label: "Active", value: JobStatus.ACTIVE },
        { label: "In Progress", value: JobStatus.ONGOING },
        { label: "Completed", value: JobStatus.COMPLETED },
        { label: "Draft", value: JobStatus.DRAFT },
      ];

      return items;
    },

    selectedJobStatusLabel() {
      return this.filterOptions.find(
        (option) => option.value === this.status
      )?.label;
    },
  },

  watch: {
    $route() {
      this.fetchJobs();
    },

    listData(val) {
      if (this.isFilteringJobs) {
        this.jobs = val;
      } else {
        this.jobs = this.jobs.concat(val);
      }
      this.fetching = false;
    },

    isFilteringJobs(val) {
      if (!val) {
        this.jobs = []
      }
    }
  },

  mounted() {
    this.fetchJobs();
  },

  methods: {
    handleJobsStatusChange(status) {
      this.$router.push({
        query: {
          ...this.$route.query,
          status,
        },
      });
      this.jobs = []
      this.fetching = true;
      this.fetchJobs()
    },

    fetchJobs() {
      if (this.$route.query?.status) {
        switch (this.$route.query.status.toLowerCase()) {
          case JobStatus.ONGOING:
            this.status = JobStatus.ONGOING;
            this.pageTitle = "Jobs In Progress";
            break;

          case JobStatus.ACTIVE:
            this.status = JobStatus.ACTIVE;
            this.pageTitle = "Active Jobs";
            break;

          case JobStatus.COMPLETED:
            this.status = JobStatus.COMPLETED;
            this.pageTitle = "Completed Jobs";
            break;

          case JobStatus.DRAFT:
            this.status = JobStatus.DRAFT;
            this.pageTitle = "Draft Jobs";
            break;
        }
      }
      this.fetching = true;
      this.updateListFilters({
        page: 1,
        status: this.status,
      });
    },

    onFetchNextJobsPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        ...this.filters,
        status: this.status,
        page: this.currentPage + 1,
      });
    },

    filterJos(filters) {
      this.filters = filters;
      let allFiltersVoided = true;
      for (const key in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, key)) {
          if (this.filters[key]) {
            allFiltersVoided = false;
          }
        }
      }
      this.maxPagesReached = false;
      this.isFilteringJobs = !allFiltersVoided;
      this.fetching = true;
      this.updateListFilters({
        ...this.filters,
        page: 1,
        status: this.status,
      });
    },
  },
};
</script>
