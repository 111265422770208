<template>
  <div
    ref="providerRef"
    class="saved-provider-item"
  >
    <template v-if="savedProvider.provider.user">
      <div class="row">
        <div class="col-md-6 d-flex align-items-center">
          <profile-avatar
            :url="savedProvider.provider.user.photo?.url"
            :fullname="savedProvider.provider.user?.full_name"
            :color="savedProvider.provider.user?.profile_color"
            :background="savedProvider.provider.user?.profile_background"
          />

          <div class="ms-2">
            <router-link
              :to="{ 
                name: 'ProviderPublicProfile',
                params: {
                  username: `@${savedProvider.provider.user.username}`,
                },
              }"
              class="provider-name"
            >
              {{ savedProvider.provider.user?.full_name }}
            </router-link>

            <div class="rating">
              <span>Rate :</span>
              <rating-stars :rating="savedProvider.provider.rating ?? 0" />
            </div>
          </div>
        </div>

        <div class="col-md-6 text-end">
          <button
            class="btn-favorite added"
            :disabled="removingFromFavorites"
            @click="confirmClaim"
          >
            <submission-spinner
              v-if="removingFromFavorites"
              :color="'#209516'"
            />
            <img
              v-else
              :src="icons.heartVectorIcon"
              alt="Favorite Icon"
            >
          </button>

          <router-link
            :to="{
              name: 'CustomerSavedProvidersDetails',
              params: { id: savedProvider?.id },
            }"
            class="btn-primary"
          >
            View
          </router-link>
        </div>
      </div>

      <p
        v-if="shortDescription.length > 0"
        class="profile-description"
      >
        {{ shortDescription }}
      </p>

      <confirm-modal
        ref="confirmModal"
        :ok-button-title="'Yes'"
        :message="`Are you sure you want to remove ${savedProvider.provider.user?.full_name} from favorites ?`"
        @onConfirm="removeProviderFromFavorites"
      />
    </template>
  </div>
</template>

<script>
import { nextTick } from "vue";
import api from "@/services/api";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import RatingStars from "@/components/RatingStars.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";

import heartVectorIcon from "@/assets/img/icons/heart.svg";

import { DateHelpers } from "@/utils/date-helpers";

import { GlobalEvents } from "@/configs/constants";

export default {
  name: "ProviderItem",

  components: {
    RatingStars,
    ConfirmModal,
    ProfileAvatar,
    SubmissionSpinner,
  },

  beforeRouteLeave(to, from) {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  props: {
    savedProvider: {
      type: Object,
      required: true,
      default: null,
    },

    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      heartVectorIcon,
    },
    removingFromFavorites: false,
  }),

  computed: {
    shortDescription() {
      if (
        this.savedProvider.provider.user?.profile_description?.length <= 230
      ) {
        return this.savedProvider.provider.user.profile_description;
      } else if (
        this.savedProvider.provider.user?.profile_description?.length > 230
      ) {
        return this.savedProvider.provider.user.profile_description.slice(
          0,
          230
        );
      }
      return "";
    },
  },

  created() {
    nextTick(() => {
      if (this.$refs.providerRef) {
        this.observer = new IntersectionObserver(this.callback);
        this.observer.observe(this.$refs.providerRef);
      }
    });    
  },

  methods: {
    ...DateHelpers,

    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.isLastItem) {
          this.$emit("fetchNextPage");
        }
      });
    },

    confirmClaim() {
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    async removeProviderFromFavorites() {
      try {
        this.removingFromFavorites = true;
        await api.removeProviderFromFavorites(this.savedProvider.id);
        this.$emitter.emit(
          GlobalEvents.PROVIDER_REMOVED_FROM_FAVORITES,
          this.savedProvider.id
        );
      } catch (_) {
        /**
         * TODO
         * Handle error
         */
        // this.errorHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },
  },
};
</script>
