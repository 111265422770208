<template>
  <div
    v-if="options"
    class="col-md-12 position-relative multiselect"
  >
    <div
      v-if="value"
      class="d-sm-inline-block skill-choice mt-3 mb-0 px-3 py-2 skill text-dark"
    >
      {{ options.find((el) => el.id == value)?.name }}
      <img
        alt="smallclose "
        :src="smallclose"
        @click="removeSkill()"
      >
    </div>

    <div class="input-group mt-2">
      <img
        alt="smallclose"
        :src="icons.searchVectorIcon"
        class="cursor-pointeur search"
      >
      <input
        v-model="text"
        type="text"
        class="input-multiselect form-control ps-5 shadow-none"
        :placeholder="placeholder"
        :class="classstat"
        @blur="$emit('blur')"
        @click="show = !show"
      >
    </div>

    <ul
      v-if="show"
      v-click-outside="closeIt"
      class="list-group w-100 mt-0 cursor-pointeur"
    >
      <li
        v-if="options.length == 0"
        class="list-group-item w-100 text-dark text-center"
      >
        No item
      </li>
      <li
        v-for="(item, index) in options.filter(
          (el) =>
            el.name &&
            el.name.toLowerCase().search(text.toLowerCase()) != -1 &&
            value != el.id
        )"
        :key="index"
        class="list-group-item w-100 text-dark"
        @click="addSkill(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import searchVectorIcon from "@/assets/img/icons/search-black.svg";
import smallclose from "@/assets/img/icons/smallclose.svg";
export default {
  name: "OneSelect",
  props: {
    options: {
      type: Array,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    classstat: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    icons: {
      searchVectorIcon,
    },
    text: "",
    stock: null,
    click: false,
    smallclose,
    show: false,
    close: false,
  }),
  methods: {
    closeIt() {
      this.show = false;
    },
    addSkill(el) {
      this.$emit("update:value", el);
      this.click = false;
      this.closeIt()
    },
    removeSkill() {
      this.$emit("update:value", null);
      this.close = false;
    },
    onClickAway() {},
  },
};
</script>
