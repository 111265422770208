<template>
  <div class="customer-dashboard">
    <div class="row">
      <div class="col-md-12 text-end mb-5">
        <router-link
          :to="{ name: 'CustomerJobCreate' }"
          class="post-job-button"
        >
          <img
            :src="icons.plusVector"
            alt="icon plus"
            class="me-2"
          >
          Post a Job
        </router-link>
      </div>

      <div
        class="col-md-12"
      >
        <div
          class="row"
        >
          <dashboard-statistic
            v-for="statistic in statistics"
            :key="statistic.title"
            :title="statistic.title"
            :count="statistic.count"
            :route="statistic.route"
            :length="statisticsLength"
            :color="statistic.color"
            :is-amount="statistic.isAmount"
          />
        </div>
      </div>
    </div>
    <loader-spinner v-if="loading" />

    <div
      v-else
      class="row"
    >
      <div class="col-md-12">
        <section
          v-if="
            pendingJobs.length > 0 ||
              activeJobs.length > 0 ||
              completedJobs.length > 0 ||
              draftJobs.length > 0 ||
              inProgressJobs.length > 0
          "
          class="content row"
        >
          <div
            v-if="inProgressJobs.length > 0"
            class="col-md-6 col-sm-12"
            :class="{ 'mb-4': pendingJobs.length > 0 }"
          >
            <div class="page-header mb-4">
              <h3 class="page-title">
                Jobs In Progress
              </h3>

              <router-link
                :to="{
                  name: 'CustomerJobs',
                  query: { status: jobStatus.ONGOING },
                }"
                class="btn-primary"
              >
                View All
              </router-link>
            </div>

            <div class="row job-list">
              <job-item-card
                v-for="job in inProgressJobs"
                :key="job.id"
                :slug="job.id"
                :title="job.title"
                :created-at="job.created_at"
                :status="job.status"
                :number-of-proposal="job.total_bids"
              />
            </div>
          </div>

          <div
            v-if="pendingJobs.length > 0"
            class="col-md-6 col-sm-12"
            :class="{ 'mb-4': activeJobs.length > 0 }"
          >
            <div class="page-header mb-4">
              <h3 class="page-title">
                Pending Jobs
              </h3>

              <router-link
                :to="{
                  name: 'CustomerJobs',
                  query: { status: jobStatus.WAITING },
                }"
                class="btn-primary"
              >
                View All
              </router-link>
            </div>

            <div class="row job-list">
              <job-item-card
                v-for="job in pendingJobs"
                :key="job.id"
                :slug="job.id"
                :title="job.title"
                :created-at="job.created_at"
                :status="job.status"
                :number-of-proposal="job.total_bids"
              />
            </div>
          </div>

          <div
            v-if="activeJobs.length > 0"
            class="col-md-6 col-sm-12"
            :class="{ 'mb-4': completedJobs.length > 0 }"
          >
            <div class="page-header d-sm-flex mb-4">
              <h3 class="page-title">
                Active Jobs
              </h3>

              <router-link
                :to="{
                  name: 'CustomerJobs',
                  query: { status: jobStatus.ACTIVE },
                }"
                class="btn-primary"
              >
                View All
              </router-link>
            </div>

            <div class="row job-list">
              <job-item-card
                v-for="job in activeJobs"
                :key="job.id"
                :slug="job.id"
                :title="job.title"
                :created-at="job.created_at"
                :status="job.status"
                :number-of-proposal="job.total_bids"
              />
            </div>
          </div>

          <div
            v-if="completedJobs.length > 0"
            class="col-md-6 col-sm-12"
            :class="{ 'mb-4': draftJobs.length > 0 }"
          >
            <div class="page-header d-sm-flex mb-4">
              <h3 class="page-title">
                Completed Jobs
              </h3>

              <router-link
                :to="{
                  name: 'CustomerJobs',
                  query: { status: jobStatus.COMPLETED },
                }"
                class="btn-primary"
              >
                View All
              </router-link>
            </div>

            <div class="row job-list">
              <job-item-card
                v-for="job in completedJobs"
                :key="job.id"
                :slug="job.id"
                :title="job.title"
                :created-at="job.created_at"
                :status="job.status"
                :number-of-proposal="job.total_bids"
              />
            </div>
          </div>

          <div
            v-if="draftJobs.length > 0"
            class="col-md-6 col-sm-12"
          >
            <div class="page-header d-sm-flex mb-4">
              <h3 class="page-title">
                Draft Jobs
              </h3>

              <router-link
                :to="{
                  name: 'CustomerJobs',
                  query: { status: jobStatus.DRAFT },
                }"
                class="btn-primary"
              >
                View All
              </router-link>
            </div>

            <div class="row job-list">
              <job-item-card
                v-for="job in draftJobs"
                :key="job.id"
                :slug="job.id"
                :title="job.title"
                :created-at="job.created_at"
                :status="job.status"
                :number-of-proposal="0"
              />
            </div>
          </div>

          <div
            v-if="hasInvoices"
            class="row mb-4"
          >
            <div class="col-md-12">
              <div class="page-header d-sm-flex mb-4">
                <h3 class="page-title">
                  Invoices
                </h3>
              </div>
              <div>
                <Chart :invoice-stat="invoiceStat" />
              </div>
            </div>
          </div>
        </section>

        <section
          v-else
          class="content"
        >
          <div class="text-center py-4">
            <p>You have not posted any jobs yet.</p>

            <router-link
              :to="{ name: 'CustomerJobCreate' }"
              class="post-job-button"
            >
              <img
                :src="icons.plusVector"
                alt="icon plus"
                class="me-2"
              >
              Post a Job
            </router-link>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

import plusVector from "@/assets/img/icons/plus.svg";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { JobStatus } from "@/configs/constants/jobs";
import Chart from "@/components/Chart";

import JobItemCard from "@/components/customer/jobs/JobItemCard";
import DashboardStatistic from "@/components/customer/DashboardStatistic.vue";

export default {
  name: "CustomerDashboardPage",

  components: {
    JobItemCard,
    DashboardStatistic,
    LoaderSpinner,
    Chart,
  },

  data: () => (
    {
    icons: {
      plusVector,
    },
    loading: false,
    statistics: {
      jobsPosted: {
        title: "JOBS POSTED",
        count: 0,
        isAmount: false,
        route: "CustomerJobs",
      },
      bidsAwarded: {
        title: "BIDS AWARDED",
        count: 0,
        isAmount: false,
        route: "CustomerDashboard",
      },
      invoicesPending: {
        title: "INVOICES PENDING",
        count: 0,
        isAmount: false,
        route: "CustomerInvoices",
      },
    },
    activeJobs: [],
    pendingJobs: [],
    inProgressJobs: [],
    completedJobs: [],
    draftJobs: [],
    invoiceStat: null,
    hasInvoices: false,
  }),

  computed: {
    jobStatus() {
      return JobStatus;
    },

    statisticsLength() {
      return Object.keys(this.statistics).length;
    },
  },

  mounted() {
    this.fetchCustomerDashboardData();
  },

  methods: {
    async fetchCustomerDashboardData() {
      try {
        this.loading = true;
        const response = await api.customerDashboard();
        if (response.data) {
          this.statistics.jobsPosted.count = response.data.projects_posted ?? 0;
          this.statistics.bidsAwarded.count = response.data.bids_awared ?? 0;
          this.statistics.invoicesPending.count =
            response.data.invoice_pending ?? 0;
          this.activeJobs = response.data.active_jobs ?? [];
          this.pendingJobs = response.data.pending_jobs ?? [];
          this.completedJobs = response.data.completed_jobs ?? [];
          this.draftJobs = response.data.draft_jobs ?? [];
          this.inProgressJobs = response.data.ongoing_jobs ?? [];
          this.invoiceStat = response.data.invoive_stat ?? null;
          this.hasInvoices = response.data.has_invoice ?? false;
        }
      } catch (_) {
        /**
         * TODO
         * Handle error
         */
        // this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>