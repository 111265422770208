import store from "@/store";

export default {
  canAccessMarketplace() {
    return store.getters.hasService('marketplace');
  },
  canAccessCalendar() {
    return store.getters.hasService('calendar');
  },
  canAccessSubcontract() {
    return store.getters.hasService('subcontracting');
  },
  canAccessInvoicing() {
    return store.getters.hasService('invoicing');
  },
  canAccessEstimates() {
    return store.getters.hasService('estimates');
  },
  canAccessCrm() {
    return store.getters.hasService('crm');
  },
  canAccessCommmunication() {
    return store.getters.hasService('communication');
  }
};
