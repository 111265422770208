<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="{ 'modal-lg': label == 'screeningquestion' }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div
            v-if="label == 'skill'"
            class="mb-3 col-md-12"
          >
            <label for="Category pb-1">Category</label>
            <select
              v-model="datasend.category"
              class="form-select mt-2 shadow-none"
              :class="{
                'is-invalid': errors.category,
              }"
            >
              <option
                :value="null"
                disabled
              >
                Select your job category
              </option>
              <option
                v-for="(category, index) in categories"
                :key="index"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>

            <template v-if="errors.category">
              <small class="invalid-feedback">
                {{ errors.category }}
              </small>
            </template>
          </div>

          <div
            v-if="label != 'screeningquestion'"
            class="mb-3 col-md-12"
          >
            <label class="pb-1">
              Name <span class="text-danger">*</span>
            </label>
            <input
              v-model="datasend.name"
              type="text"
              class="form-control pl-3 shadow-none"
              placeholder="Name"
              :class="{
                'is-invalid':
                  (v$.datasend.name.$invalid &&
                    v$.datasend.name.$dirty) ||
                  errors.nameErrorMessage,
              }"
              @blur="v$.datasend.name.$touch"
              @input="checkDuplicates"
            >
            <template
              v-if="
                (v$.datasend.name.$invalid &&
                  v$.datasend.name.$dirty) ||
                  errors.nameErrorMessage
              "
            >
              <small
                v-if="v$.datasend.name.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="v$.datasend.name.minLength.$invalid"
                class="invalid-feedback"
              >
                The minimum size for this field is 3 characters
              </small>

              <small
                v-else-if="errors.nameErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.nameErrorMessage }}
              </small>
            </template>
          </div>

          <div
            v-else
            class="col-md-12"
          >
            <div class="col-md-12">
              <label class="mb-2 question-detail">Type: </label>
              <select
                v-model="datasend.type"
                class="form-select mb-2 shadow-none"
              >
                <option
                  value="null"
                  disabled
                >
                  Select an Option
                </option>
                <option value="SHORT">
                  Short
                </option>
                <option value="LONG">
                  Long
                </option>
                <option value="SINGLE">
                  Single
                </option>
                <option value="MULTIPLE">
                  Multiple
                </option>
              </select>
            </div>
            <div class="mb-4">
              <label class="mb-2 question-detail">
                Question details
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12">
                <textarea
                  v-model="datasend.name"
                  cols="30"
                  rows="3"
                  placeholder="Write your question here..."
                  :class="{
                    'is-invalid':
                      v$.datasend.name.$invalid &&
                      v$.datasend.name.$dirty,
                  }"
                  @blur="v$.datasend.name.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.name.$invalid &&
                      v$.datasend.name.$dirty) ||
                      errors.nameErrorMessage
                  "
                >
                  <small
                    v-if="
                      v$.datasend.name.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="
                      v$.datasend.name.minLength.$invalid
                    "
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3
                    characters
                  </small>

                  <small
                    v-else-if="errors.nameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.nameErrorMessage }}
                  </small>
                </template>
              </div>
            </div>
            <div
              v-if="datasend.type == 'SINGLE'"
              class="mb-4"
            >
              <div class="col-md-12">
                <label class="mt-3 mb-2 question-detail">
                  Suggested responses - Check the right answers(s):
                </label>
                <div
                  v-for="(item, index) in datasend.responses"
                  :key="index"
                  class="form-check d-flex align-items-center mb-2"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="radio"
                    :checked="item.right"
                    @change="
                      updateResponse(index, !item.right)
                    "
                  >
                  <input
                    v-model="item.response"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Write here..."
                  >
                  <img
                    :src="croixVectorIcon"
                    alt=""
                    class="ms-3"
                    @click="removeResponse(item)"
                  >
                </div>
                <div
                  class="form-check d-flex align-items-center mb-2"
                  @click="addResponse"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="radio"
                    disabled
                  >
                  <input
                    type="text"
                    class="form-control me-3 shadow-none"
                    placeholder="Add option"
                    disabled
                  >
                  <div class="ms-3" />
                </div>
              </div>
            </div>
            <div
              v-if="datasend.type == 'MULTIPLE'"
              class="mb-4"
            >
              <div class="col-md-12">
                <label class="mt-3 mb-2 question-detail">Suggested responses - Check the wright
                  answers(s):</label>
                <div
                  v-for="(item, index) in datasend.responses"
                  :key="index"
                  class="form-check d-flex align-items-center mb-2"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="checkbox"
                    :checked="item.right"
                    @change="
                      updateResponse(index, !item.right)
                    "
                  >
                  <input
                    v-model="item.response"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Write here..."
                  >
                  <img
                    :src="croixVectorIcon"
                    alt=""
                    class="ms-3"
                    @click="removeResponse(item)"
                  >
                </div>
                <div
                  class="form-check d-flex align-items-center mb-2"
                  @click="addResponse"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="checkbox"
                    disabled
                  >
                  <input
                    type="text"
                    class="form-control me-3 shadow-none"
                    placeholder="Add option"
                    disabled
                  >
                  <div class="ms-3" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            :disabled="submitting"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="btn-primary"
            :disabled="submitting || duplicateFound"
            @click="save"
          >
            <submission-spinner v-if="submitting" />
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
    name: "CreateSetting",

    components: {
        SubmissionSpinner,
        AlerteDanger,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },

        label: {
            type: String,
            required: false,
            default: null,
        },

        existingCategories: {
            type: Array,
            required: false,
            default: () => [],
        },

        existingSkills: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    data: () => ({
        croixVectorIcon,
        showModal: false,
        fadeModal: false,
        loading: false,
        submitting: false,
        duplicateFound: false,
        categories: [],
        datasend: {
            name: null,
            type: null,
            responses: [],
            category: null,
        },
        errors: {
            category: null,
            nameErrorMessage: null,
            serverSideErrorMessage: null,
        },
    }),

    validations() {
        return {
            datasend: {
                name: {
                    required,
                    minLength: minLength(3),
                },
            },
        };
    },

    methods: {
        ...validationHelpers,

        checkDuplicates() {
            let searchingList = [];
            let error = null;

            switch (this.label) {
                case "jobcategory":
                    searchingList = [...this.existingCategories];
                    error = "A category of the same already exist.";
                    break;

                case "skill":
                    searchingList = [...this.existingSkills];
                    error = "A skill of the same already exist.";
                    break;

                default:
                    break;
            }

            const duplicate = searchingList.find((item) => {
                return (
                    item?.name?.toLowerCase() ===
                    this.datasend.name?.toLowerCase()
                );
            });
            if (duplicate) {
                this.duplicateFound = true;
                this.errors.nameErrorMessage = error;
            } else {
                this.duplicateFound = false;
                this.errors.nameErrorMessage = null;
            }
        },

        openModal() {
            this.showModal = true;
            if (this.label == "skill") {
                this.fetchJobSetting();
            }
            setTimeout(() => {
                this.fadeModal = true;
            }, 200);
        },

        async fetchJobSetting() {
            if (this.loading) return;

            this.loading = true;

            try {
                await api.fetchJobSetting((data) => {
                    this.categories = data.category;
                });
            } catch (_) {
                /**
                 * TODO
                 * Handle errors
                 */
            } finally {
                this.loading = false;
            }
        },

        closeModal() {
            this.fadeModal = false;
            setTimeout(() => {
                this.showModal = false;
                this.datasend = {
                    name: null,
                    type: null,
                    responses: [],
                    category: null,
                };
                this.clearErrorsMessages();
                this.resetValidation();
            }, 200);
        },

        save() {
            if (this.submitting) return;

            if (this.v$.$invalid) {
                this.forceValidation();
                return;
            }

            this.submitting = true;
            this.clearErrorsMessages();

            api.createJobConfigType(this.datasend, this.label)
                .then((_) => {
                    this.datasend = { name: null, type: null, responses: [] };
                    this.resetValidation();
                    this.closeModal();
                    this.$emit("updateList");
                })
                .catch((error) => {
                    this.errorsHandler(error);
                })
                .then(() => {
                    this.submitting = false;
                });
        },

        clearErrorsMessages() {
            this.errors.nameErrorMessage = null;
            this.errors.serverSideErrorMessage = null;
        },

        errorsHandler(err) {
            if (err.response) {
                if (err.response.status === 401) {
                    this.errors.serverSideErrorMessage =
                        errorMessages.INVALID_CREDENTIALS;
                } else if (err.response.status === 400) {
                    for (const error in err.response.data) {
                        this.errors.nameErrorMessage =
                            err.response.data[error];
                    }
                } else {
                    this.errors.serverSideErrorMessage =
                        errorMessages.AN_ERROR_HAS_OCCURED;
                }
            } else {
                this.errors.serverSideErrorMessage =
                    errorMessages.AN_ERROR_HAS_OCCURED;
            }
        },

        addResponse() {
            this.datasend.responses.push({ response: null, right: false });
        },

        removeResponse(item) {
            this.datasend.responses = this.datasend.responses.filter(
                (el) => el != item
            );
        },

        updateResponse(index, value) {
            if (this.datasend.type == "SINGLE") {
                this.datasend.responses.forEach((el, i) => {
                    if (index == i) {
                        el.right = value;
                    } else {
                        el.right = false;
                    }
                });
            } else {
                this.datasend.responses[index].right = value;
            }
        },
    },
};
</script>
