<template>
  <section class="content profile">
    <div class="page-header">
      <h3 class="page-title">
        Profile
      </h3>

      <settings-menu />
    </div>

    <div
      v-if="currentUser"
      class="page-content"
    >
      <div class="row box-form">
        <div class="col-md-12 my-3">
          <div class="photo-wrapper">
            <div
              :style="{
                'background-image': `url(${
                  currentUser.photo?.url || images.user
                })`,
              }"
              class="profile-photo"
            />

            <h4 class="username">
              {{ currentUser.full_name }}
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Basic Details
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Full Name</label>
            <p class="label-content username">
              {{ currentUser.full_name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Email</label>
            <p class="label-content">
              {{ currentUser.email }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Phone No.</label>
            <p class="label-content">
              {{ currentUser.phone }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Website</label>
            <p class="label-content">
              {{ currentUser.website }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Business Type</label>
            <p
              v-if="getBusinessType(currentUser.business_type)"
              class="label-content"
            >
              {{ getBusinessType(currentUser.business_type).title }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Country</label>
            <p
              v-if="getCountry(currentUser.country)"
              class="label-content"
            >
              {{ getCountry(currentUser.country).label }}
            </p>
          </div>

          <div
            v-if="currentUser.ssn"
            class="col-md-6 col-lg-6 col-xl-4 mb-3"
          >
            <label class="input-label">SSN</label>
            <p class="label-content">
              {{ currentUser.ssn }}
            </p>
          </div>

          <div
            v-if="currentUser.ein"
            class="col-md-6 col-lg-6 col-xl-4 mb-3"
          >
            <label class="input-label">EIN</label>
            <p class="label-content">
              {{ currentUser.ein }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label">Birthdate</label>
            <p
              v-if="currentUser.date_of_birth"
              class="label-content"
            >
              {{ formatDate(currentUser.date_of_birth, "MMMM Do, YYYY") }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <h4 class="border-bottom section-title">
              Profile Details
            </h4>
          </div>

          <div class="col-md-6">
            <label class="input-label">Company Name</label>
            <p class="label-content">
              {{ currentUser.company_name }}
            </p>
          </div>

          <div class="col-md-6">
            <label class="input-label">Address Line 1</label>
            <p class="label-content">
              {{ currentUser.address_line_1 }}
            </p>
          </div>

          <div class="col-md-6">
            <label class="input-label">Address Line 2</label>
            <p class="label-content">
              {{ currentUser.address_line_2 }}
            </p>
          </div>

          <div class="col-md-6 mb-3">
            <label class="input-label">State</label>
            <p class="label-content">
              {{ currentUser.state }}
            </p>
          </div>

          <div class="col-md-6 mb-3">
            <label class="input-label">City</label>
            <p class="label-content">
              {{ currentUser.city }}
            </p>
          </div>

          <div class="col-md-6">
            <label class="input-label">Zip Code</label>
            <p class="label-content">
              {{ currentUser.pin_code }}
            </p>
          </div>

          <div class="col-md-12">
            <label class="input-label">Profile Description</label>
            <p class="label-content">
              {{ currentUser.profile_description }}
            </p>
          </div>

          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Front</label>
            <div
              v-if="currentUser.idfront"
              class="document"
            >
              <a
                :href="currentUser.idfront.url"
                target="_target"
              >
                <img
                  v-if="currentUser.idfront.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(currentUser.idfront.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Back</label>
            <div
              v-if="currentUser.idback"
              class="document"
            >
              <a
                :href="currentUser.idback.url"
                target="_target"
              >
                <img
                  v-if="currentUser.idback.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(currentUser.idback.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label class="input-label">Portfolio (upload recent work)</label>
            <div
              v-if="currentUser.portfolio?.length > 0"
              class="row"
            >
              <div
                v-for="(file, i) in currentUser.portfolio"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < currentUser.portfolio.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.portfolio?.url"
                    target="_target"
                  >
                    <img
                      v-if="file.portfolio?.type === mediaTypesConstants.IMAGE"
                      :src="icons.imageUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    <img
                      v-else
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.portfolio?.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import user from "@/assets/img/user.svg";
import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import FileUtils from "@/utils/file";
import CountryUtils from "@/utils/country";
import BusinessTypesUtils from "@/utils/business-type";
import { DateHelpers } from "@/utils/date-helpers";

import { MediaTypesConstants } from "@/configs/constants";

import SettingsMenu from "@/components/customer/SettingsMenu.vue";

export default {
  name: "CustomerProfilePage",

  components: {
    SettingsMenu,
  },

  data: () => ({
    images: {
      user,
    },
    icons: {
      imageUploadedIcon,
      documentUploadedIcon,
      documentIcon,
    },
    loading: false,
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  methods: {
    ...FileUtils,
    ...DateHelpers,
    ...CountryUtils,
    ...BusinessTypesUtils,
  },
};
</script>
