<template>
  <div class="row">
    <div class="col-lg-9 mx-auto box-form">
      <section class="content">
        <div class="page-header">
          <h3>Add Credit Card</h3>
        </div>

        <div class="page-content">
          <div class="card-wrapper mt-3 mb-6" />

          <form class="d-none">
            <div>
              <input
                id="card-name"
                class="shadow-none"
              >
            </div>
            <div>
              <input
                id="card-number"
                class="shadow-none"
              >
            </div>
            <div>
              <input
                id="card-exp"
                class="shadow-none"
              >
            </div>
            <div>
              <input
                id="card-cvc"
                class="shadow-none"
              >
            </div>
          </form>

          <form
            class="row"
            @submit.prevent="save"
          >
            <div class="col-lg-6 offset-lg-3 mb-3">
              <alerte-danger
                :head-error-message="errors.serverSideErrorMessage"
                @updateContent="errors.serverSideErrorMessage = $event"
              />
            </div>

            <div class="col-lg-6 mb-3">
              <label class="form-label">
                Card Number <span class="text-danger">*</span>
              </label>

              <div
                id="card-number-element"
                class="form-control"
                :class="{ 'is-invalid': cardErrors.number !== '' }"
              />

              <span
                v-if="cardErrors.number !== ''"
                class="invalid-feedback"
              >
                {{ cardErrors.number }}
              </span>
            </div>

            <div class="col-lg-6 mb-3">
              <label class="form-label">
                Card Holder Name <span class="text-danger">*</span>
              </label>
              <input
                v-model="card.holderName"
                type="text"
                class="form-control shadow-none"
                autocomplete="off"
                :class="{
                  'is-invalid':
                    v$.card.holderName.$invalid && v$.card.holderName.$dirty,
                }"
                @focus="handleCardHolderFocus"
                @blur="handleCardHolderBlur"
                @input="handleCardHolderInput"
              >

              <template
                v-if="v$.card.holderName.$invalid && v$.card.holderName.$dirty"
              >
                <small
                  v-if="v$.card.holderName.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-if="v$.card.holderName.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Must contain at least 3 characters
                </small>
              </template>
            </div>

            <div class="col-lg-6 mb-3">
              <label class="form-label">
                Card Expiration Date <span class="text-danger">*</span>
              </label>

              <div
                id="card-exp-element"
                class="form-control"
                :class="{ 'is-invalid': cardErrors.expiry !== '' }"
              >
                <span>
                  {{ cardErrors.expiry }}
                </span>
              </div>
            </div>

            <div class="col-lg-6 mb-3">
              <label class="form-label">
                Card CVC <span class="text-danger">*</span>
              </label>

              <div
                id="card-cvc-element"
                class="form-control"
                :class="{ 'is-invalid': cardErrors.cvc !== '' }"
              />

              <span
                v-if="cardErrors.cvc !== ''"
                class="invalid-feedback"
              >
                {{ cardErrors.cvc }}
              </span>
            </div>

            <div class="col-12 text-end mt-4">
              <button
                class="px-5 btn-primary"
                type="submit"
                :disabled="submitting"
              >
                <submission-spinner v-if="submitting" />
                Add
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as Card from "card";
import { useVuelidate } from "@vuelidate/core";
import { isMobile } from "mobile-device-detect";
import { required, minLength } from "@vuelidate/validators";

import api from "@/services/api";
import { getStripe } from "@/utils/stripe";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CreateCreditCardPage",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    submitting: false,
    cardConfig: {
      creditCard: true,
      delimiter: "-",
    },
    card: {
      holderName: null,
      number: null,
      expiry: null,
      cvc: null,
    },
    cardErrors: {
      cvc: "",
      expiry: "",
      number: "",
    },
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    isProvider() {
      return this.$store.getters["token/isProvider"];
    },
  },

  validations() {
    return {
      card: {
        holderName: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },

  mounted() {
    const { elements, stripe } = getStripe();
    this.stripe = stripe;

    new Card({
      form: "form",
      container: ".card-wrapper",

      formSelectors: {
        numberInput: "input#card-number",
        expiryInput: "input#card-exp",
        cvcInput: "input#card-cvc",
        nameInput: "input#card-name",
      },

      formatting: true,

      messages: {
        validDate: "valid\ndate",
        monthYear: "mm/yyyy",
      },

      width: isMobile ? 220 : 380,

      placeholders: {
        number: "•••• •••• •••• ••••",
        name: "Full Name",
        expiry: "••/••",
        cvc: "•••",
      },

      masks: {
        cardNumber: "•",
      },

      number: "4242 4242 4242 4242",
      expiry: "11/29",
      cvc: "123",

      debug: false,
    });

    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "14px",
        color: "#32325d",
      },
    };

    this.card.expiry = elements.create("cardExpiry", {
      style: style,
      placeholder: "",
    });
    this.card.expiry.mount("#card-exp-element");

    this.card.cvc = elements.create("cardCvc", {
      style: style,
      placeholder: "",
    });
    this.card.cvc.mount("#card-cvc-element");

    this.card.number = elements.create("cardNumber", {
      style: style,
      placeholder: "",
    });
    this.card.number.mount("#card-number-element");

    this.card.cvc.on("focus", function (event) {
      document.querySelector(".jp-card").classList.add("jp-card-flipped");
      document.querySelector(".jp-card-cvc").classList.add("jp-card-focused");
    });
    this.card.cvc.on("blur", function (event) {
      document.querySelector(".jp-card").classList.remove("jp-card-flipped");
      document
        .querySelector(".jp-card-cvc")
        .classList.remove("jp-card-focused");
    });

    this.card.number.on("focus", function (event) {
      document
        .querySelector(".jp-card-number")
        .classList.add("jp-card-focused");
    });
    this.card.number.on("blur", function (event) {
      document
        .querySelector(".jp-card-number")
        .classList.remove("jp-card-focused");
    });

    this.card.expiry.on("focus", function (event) {
      document
        .querySelector(".jp-card-expiry")
        .classList.add("jp-card-focused");
    });
    this.card.expiry.on("blur", function (event) {
      document
        .querySelector(".jp-card-expiry")
        .classList.remove("jp-card-focused");
    });

    this.handleErrors();
  },

  beforeDestroy() {
    this.card.expiry.unmount();
    this.card.number.unmount();
    this.card.cvc.unmount();
    this.card.expiry.destroy();
    this.card.number.destroy();
    this.card.cvc.destroy();
  },

  methods: {
    ...validationHelpers,

    handleErrors() {
      this.card.number.on("change", (event) => {
        this.cardErrors.number = "";
        if (event.error) {
          this.cardErrors.number = event.error.message || "";
        }
        this.updateCardBrand(event);
      });

      this.card.cvc.on("change", (event) => {
        this.cardErrors.cvc = "";
        if (event.error) {
          this.cardErrors.cvc = event.error.message || "";
        }
      });

      this.card.expiry.on("change", (event) => {
        this.cardErrors.expiry = "";
        if (event.error) {
          this.cardErrors.expiry = event.error.message || "";
        }
      });
    },

    updateCardBrand(event) {
      let cardClass = "unknown";

      if (event.brand && event.brand !== "unknown") {
        cardClass = "jp-card-" + event.brand;
        document.querySelector(".jp-card").setAttribute("class", "jp-card");
        document.querySelector(".jp-card").classList.add("jp-card-identified");
      }

      if (cardClass === "unknown") {
        document
          .querySelector(".jp-card")
          .classList.remove("jp-card-identified");
      }

      document.querySelector(".jp-card").classList.add(cardClass);
    },

    handleCardHolderFocus() {
      document.querySelector(".jp-card-name").classList.add("jp-card-focused");
    },

    handleCardHolderBlur() {
      document
        .querySelector(".jp-card-name")
        .classList.remove("jp-card-focused");
      this.v$.card.holderName.$touch();
    },

    handleCardHolderInput() {
      const cardNameInput = document.querySelector("#card-name");
      cardNameInput.value = this.card.holderName;
      const event = new Event("change");
      cardNameInput.dispatchEvent(event);
    },

    async save() {
      if (this.submitting) return;

      if (
        this.cardErrors.number !== "" ||
        this.cardErrors.cvc !== "" ||
        this.cardErrors.cvc !== ""
      ) {
        return;
      }

      try {
        this.submitting = true;
        this.clearErrorsMessages();

        const result = await this.stripe.createToken(this.card.number, {
          name: this.card.holderName,
        });

        await api.createCreditCard(result.token["id"], this.card.holderName);

        this.$router.push({
          name: this.isProvider
            ? "ProviderCreditCardsList"
            : "CustomerCreditCardsList",
        });
      } catch (error) {
        this.submitting = false;
        this.errorsHandler(error);
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 400) {
          this.errors.serverSideErrorMessage = err.response.data.token;
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    clearErrorsMessages() {
      this.errors = {
        serverSideErrorMessage: null,
      };
    },
  },
};
</script>
