import AuthApis from "@/services/auth";
import CustomerApis from "@/services/customer";
import ProviderApis from "@/services/provider";
import ContentApis from "@/services/content";
import JobConfig from "@/services/jobconfig";
import BidApis from "@/services/bid";
import ReportsApis from "@/services/reports";
import UploadApis from "@/services/upload";
import ChatMessagesApis from "@/services/chat/messages";
import RoomMessageApis from "@/services/chat/rooms";
import InvoiceApis from "@/services/invoice";
import PlanApis from "@/services/plan";
import StripeApis from "@/services/stripe";
import ClientPortfolio from "@/services/client-portfolio";
import EstimatesApis from "@/services/estimates";
import CallApis from "@/services/call";
import CalendarApis from "@/services/calendar";
import BalanceApis from "@/services/balance";
import WithdrawApis from "@/services/withdraw";
import RatingApis from "@/services/rating";
import SuperAdminApis from "@/services/superadmin";
import JobApis from "@/services/job";
import CouponApis from "@/services/coupon";
import ServicesApis from "@/services/plan_services";
import SavedProvidersApis from "@/services/saved-provider";
import FavoriteProviderApis from "@/services/favorite-provider";
import ProposalApis from "@/services/proposal";
import FavoriteJobApis from "@/services/favorite-job";
import UserFilesApis from "@/services/user-files";
import ToDoApis from "@/services/todo";
import NotificationApis from "@/services/notification";
import MediaApis from "@/services/media";
import PropertiesApis from "@/services/properties";
import VersionApis from "@/services/version";

export default {
  ...AuthApis,
  ...CustomerApis,
  ...ProviderApis,
  ...ContentApis,
  ...JobConfig,
  ...BidApis,
  ...ReportsApis,
  ...ChatMessagesApis,
  ...RoomMessageApis,
  ...UploadApis,
  ...InvoiceApis,
  ...PlanApis,
  ...CouponApis,
  ...ServicesApis,
  ...CalendarApis,
  ...BalanceApis,
  ...WithdrawApis,
  ...RatingApis,
  ...SuperAdminApis,
  ...StripeApis,
  ...EstimatesApis,
  ...CallApis,
  ...JobApis,
  ...ClientPortfolio,
  ...SavedProvidersApis,
  ...FavoriteProviderApis,
  ...ProposalApis,
  ...FavoriteJobApis,
  ...UserFilesApis,
  ...ToDoApis,
  ...NotificationApis,
  ...MediaApis,
  ...PropertiesApis,
  ...VersionApis
};
