<template>
  <span
    v-if="getJobStatusStyle(status).class"
    class="job-status mx-4"
    :class="getJobStatusStyle(status).class"
  >
    <div
      v-if="showActiveCircle"
      class="circle me-2"
    />
    <span class="mb-0">{{ formatJobStatus(status) }}</span>
  </span>
</template>

<script>
import JobHelper from "@/utils/job";

import { JobStatus } from "@/configs/constants/jobs";

export default {
  name: "JobStatus",

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    showActiveCircle() {
      return [JobStatus.ACTIVE, JobStatus.ONGOING].includes(this.status);
    },
  },

  methods: {
    ...JobHelper,
  },
};
</script>
