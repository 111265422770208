import { Request } from "@/services/request";

export default {
  fetchHistoricalCostData(type_filter = null) {
    return new Request()
      .append("api/v1/report/historical/cost/")
      .method("get")
      .params({ type_filter });
  },

  fetchHistoricalTimeData(type_filter = null) {
    return new Request()
      .append("api/v1/report/historical/time/")
      .method("get")
      .params({ type_filter });
  },

  fetchBidHistory() {
    return new Request().append("api/v1/report/historical/bid/").method("get");
  },

  fetchOverallRevenue() {
    return new Request()
      .append("api/v1/report/historical/overall-revenue/")
      .method("get");
  },

  fetchTotalProjectsHistory() {
    return new Request()
      .append("api/v1/report/historical/total-jobs/")
      .method("get");
  },

  fetchCustomerReportData() {
    return new Request().append("api/v1/customer/report/").method("get");
  },
  fetchProviderReportData() {
    return new Request().append("api/v1/provider/report/").method("get");
  },
};
