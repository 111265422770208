<template>
  <div
    v-if="options"
    class="col-md-12 position-relative multiselect"
  >
    <div
      v-for="(item, index) in value"
      :key="index"
      class="d-sm-inline-block skill-choice mt-3 me-2 mb-0 px-3 py-2 skill text-dark"
    >
      {{ options.find((el) => el.id == item || el.id == item.id)?.name }}
      <img
        alt="smallclose"
        class="cursor-pointer"
        :src="smallclose"
        @click="removeSkill(item)"
      >
    </div>

    <div class="input-group mt-2">
      <img
        alt="smallclose"
        :src="icons.searchVectorIcon"
        class="cursor-pointeur search"
      >
      <input
        v-model="text"
        type="text"
        class="input-multiselect form-control ps-5 shadow-none"
        :placeholder="placeholder"
        :class="classstat"
        @blur="onBlur"
        @click="openOptionsList"
      >
      <button
        v-if="!!text && noItemMatchUserSearch"
        class="btn-primary rounded-circle btn-circle position-absolute p-2"
        style="top: 25%; right: 1rem; z-index: 10"
        type="button"
        @click="createJobSkill"
      >
        <img
          :src="icons.plusVectorIcon"
          alt="Add Icon"
          class="text-primary"
        >
      </button>
      <template v-if="!!text || filteredSkills.length > 0">
        <ul
          v-if="show && !close"
          v-click-outside="closeIt"
          class="list-group w-100 mt-0"
        >
          <li
            v-if="!!text && filteredSkills.length == 0"
            class="list-group-item w-100 text-dark text-center"
          >
            No item found
          </li>
          <li
            v-for="(item, index) in filteredSkills"
            :key="index"
            class="list-group-item w-100 text-dark"
            @click="addSkill(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </template>
    </div>

    <small
      v-if="errors.name"
      class="invalid-feedback"
    >
      {{ errors.name }}
    </small>
  </div>
</template>

<script>
import searchVectorIcon from "@/assets/img/icons/search-black.svg";
import smallclose from "@/assets/img/icons/smallclose.svg";
import plusVectorIcon from "@/assets/img/icons/plus.svg";
import api from "@/services/api";

export default {
  name: "MultiSelect",

  props: {
    options: {
      type: Array,
      required: false,
      default: null,
    },

    value: {
      type: Array,
      required: false,
      default: null,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    classstat: {
      type: Object,
      required: false,
      default: null,
    },

    category: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    icons: {
      searchVectorIcon,
      plusVectorIcon,
    },
    text: "",
    stock: [],
    click: false,
    smallclose,
    show: false,
    close: false,
    errors: {
      name: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    selectedCategorySkills() {
      let items = [];

      if (this.category) {
        items = this.options.filter((tx) => tx.category?.id == this.category);
      } else {
        items = this.options;
      }

      return items;
    },

    filteredSkills() {
      return this.selectedCategorySkills.filter(
        (el) =>
          el.name.toLowerCase().includes(this.text?.toLowerCase()) &&
          !this.value.map(v => v.id ?? v).includes(el.id)
      );
    },

    noItemMatchUserSearch() {
      const itemFound = this.selectedCategorySkills.find(
        (el) => el.name.toLowerCase() == this.text?.toLowerCase()
      );

      return !itemFound;
    },
  },

  watch: {
    "errors.name": function (val) {
      if (val) {
        this.$emit("hideOtherErros");
      }
    },
  },

  methods: {
    closeIt() {
      this.show = false;
    },

    onBlur() {
      this.$emit("blur");
      this.errors.name = null;
    },

    openOptionsList() {
      this.close = false;
      this.show = true;
    },

    addSkill(el) {
      this.$emit("update:value", [...this.value, el]);
      this.click = false;
      if (this.value.length === this.options.length) {
        this.close = true;
      }
      this.$emit("newSkillAdded");
    },

    removeSkill(item) {
      this.$emit(
        "update:value",
        this.value.filter((el) => el != item)
      );
      this.close = false;
    },

    onClickAway() {},

    createJobSkill() {
      this.clearErrorsMessages();
      api
        .createJobConfigType(
          { name: this.text, category: this.category },
          "skill"
        )
        .then((response) => {
          this.$emit("update:options", response.data);
          this.addSkill(response.data.id);
          this.text = "";
          this.$emit("newSkillAdded");
        })
        .catch((error) => {
          this.errorsHandler(error);
        });
    },

    clearErrorsMessages() {
      this.errors.name = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.name = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
