import { Request } from "@/services/request";

const baseUrl = "api/v1/provider_saved";

export default {
  addProviderToFavorites(data) {
    return new Request().append(`${baseUrl}/`).method("post").setData(data);
  },

  removeProviderFromFavorites(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("delete");
  },
};
