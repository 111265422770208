import * as getters from "@/store/modules/user/getters";
import * as actions from "@/store/modules/user/actions";
import * as mutations from "@/store/modules/user/mutations";

const state = {
  user: null,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
