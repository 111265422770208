<template>
  <div class="circonf-wrapper">
    <div
      class="submission-spinner"
      :style="{
        color,
        border: `2px solid ${color}`,
        'border-bottom-color': 'transparent',
        'border-left-color': 'transparent',
      }"
    />
  </div>
</template>

<script>
export default {
  name: "SubmissionSpinner",

  props: {
    color: {
      type: String,
      required: false,
      default: "#fff",
    },
  },
};
</script>

<style scoped>
.circonf-wrapper {
  display: inline-block;
  text-align: center;
  vertical-align: -0.125em;
  margin-right: 0.25rem;
}
.submission-spinner {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: circ-anim 0.7s linear infinite;
}
.submission-spinner:after {
  position: absolute;
  top: 50%;
  left: 50;
  content: "";
  width: 80%;
  height: 80%;
  background: transparent;
  margin-left: -40%;
  margin-top: -40%;
  border-radius: 50%;
  border: 1px solid;
  box-sizing: border-box;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: circ-anim 0.35s reverse linear infinite;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
