import { Request } from "@/services/request";

const baseUrl = "api/v1/file";

export default {
  uploadFile(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`${baseUrl}/upload/`)
      .method("post")
      .setData(data);
  },
};
