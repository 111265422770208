import { AllCountries } from "@/configs/countries";

export default {
  getCountry(code) {
    return AllCountries.find((country) => country.code === code)
  },

  getCountryCode(country) {
    return AllCountries.find((elmt) => elmt.label === country)
  },
};
