<template>
  <div class="post-job row">
    <section class="content ">
      <div class="page-header">
        <h3>Create Service</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label">
                Name <span class="text-danger">*</span>
              </label>
              <input
                v-model="datasend.name"
                type="text"
                class="form-control pl-3 shadow-none"
                placeholder="Enter your discount name here..."
                :class="{
                  'is-invalid':
                    (v$.datasend.name.$invalid &&
                      v$.datasend.name.$dirty) ||
                    errors.name
                }"
                @blur="v$.datasend.name.$touch"
              >
              <template
                v-if="
                  (v$.datasend.name.$invalid &&
                    v$.datasend.name.$dirty) ||
                    errors.name
                "
              >
                <small
                  v-if="v$.datasend.name.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="v$.datasend.name.minLength.$invalid"
                  class="invalid-feedback"
                >
                  The minimum size for this field is 3 characters
                </small>

                <small
                  v-else-if="errors.name"
                  class="invalid-feedback"
                >
                  {{ errors.name }}
                </small>
              </template>
            </div>

            <div class="col-md-6 mb-3">
              <label class="form-label">
                Price
                <span class="text-danger">*</span>
              </label>
              <money3
                v-model="datasend.value"
                prefix="$"
                class="form-control p-3"
                :class="{
                  'is-invalid':
                    (v$.datasend.value.$invalid &&
                      v$.datasend.value.$dirty) ||
                    errors.value
                }"
                @blur="v$.datasend.value.$touch"
              />
              <template
                v-if="
                  (v$.datasend.value.$invalid &&
                    v$.datasend.value.$dirty) ||
                    errors.value
                "
              >
                <small
                  v-if="v$.datasend.value.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.value"
                  class="invalid-feedback"
                >
                  {{ errors.value }}
                </small>
              </template>
            </div>


            <div 
              class="col-md-6 mb-3"
            >
              <label class="form-label">
                Description <span class="text-danger">*</span>
              </label>
              <input
                v-model="datasend.description"
                type="text"
                class="form-control pl-3 shadow-none"
                placeholder="Enter your discount code here..."
                :class="{
                  'is-invalid':
                    (v$.datasend.description.$invalid &&
                      v$.datasend.description.$dirty) ||
                    errors.description
                }"
                @blur="v$.datasend.description.$touch"
              >
              <template
                v-if="
                  (v$.datasend.description.$invalid &&
                    v$.datasend.description.$dirty) ||
                    errors.description
                "
              >
                <small
                  v-if="v$.datasend.description.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-if="errors.description"
                  class="invalid-feedback"
                >
                  {{ errors.description }}
                </small>
              </template>
            </div>

            <div 
              class="col-md-6 mb-3"
            >
              <label class="form-label">
                Recurrency type <span class="text-danger">*</span>
              </label>
              <select
                v-model="datasend.recurrency_type"
                class="form-select p-3 shadow-none"
                :class="{
                  'is-invalid':
                    v$.datasend.recurrency_type.$invalid && v$.datasend.recurrency_type.$dirty,
                }"
                @blur="v$.datasend.recurrency_type.$touch"
              >
                <option
                  value="null"
                  disabled
                >
                  Select a recurrency type
                </option>
                <option value="month">
                  MONTH
                </option>
                <option value="year">
                  YEAR
                </option>
              </select>
              <template
                v-if="
                  (v$.datasend.recurrency_type.$invalid && v$.datasend.recurrency_type.$dirty) ||
                    errors.recurrency_type
                "
              >
                <small
                  v-if="v$.datasend.recurrency_type.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.recurrency_type"
                  class="invalid-feedback"
                >
                  {{ errors.recurrency_type }}
                </small>
              </template>
            </div>
            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>
              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="loading"
                >
                  <submission-spinner v-if="loading" />
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from "@/components/AlerteDanger.vue";

export default {
  name: "ProviderInvoiceCreate",

  components: {
    SubmissionSpinner,
    AlerteDanger
},

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    choix: false,
    listData: [],
    listDataClient: [],
    datasend: {
      value: null,
      name: '',
      type: 'services',
      description: '',
      recurrency_type: null
    },
    errors: {
      value: null,
      name: null,
      type: null,
      description: null,
      recurrency_type: null
    },
  }),

  validations() {
    return {
      datasend: {
        name: {
          required,
          minLength: minLength(3),
        },
        value: {
          required,
        },
        description: {
          required,
        },
        recurrency_type: {
          required,
        },
      },
    };
  },

  mounted() {
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    list() {
      this.$router.push({
        name: "SuperAdminServices",
      });
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api
        .createService(this.datasend)
        .then((_) => {
          this.resetValidation();
          this.list();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        value: null,
        name: null,
        type: null,
        description: null,
        recurrency_type: null
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
