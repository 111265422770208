<template>
  <router-view />
</template>

<script>
import authHelpers from "@/utils/auth-helpers";
import { GlobalEvents } from "@/configs/constants";

export default {
  created() {
    this.$emitter.on(GlobalEvents.LOGOUT, this.logoutConnectedUser);
  },

  beforeDestroy() {
    this.$emitter.off(GlobalEvents.LOGOUT);
    this.$emitter.off(GlobalEvents.PROVIDER_REMOVED_FROM_FAVORITES);
  },

  methods: {
    ...authHelpers,
  },
};
</script>

<style>
@import "bootstrap/dist/css/bootstrap.min.css";
@import "@/assets/css/default.css";
@import "@/assets/css/theme.css";
@import "@/assets/css/style.css";
@import "@/assets/css/customer.css";
@import "@/assets/css/provider.css";
</style>
