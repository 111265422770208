<template>
  <div class="col-xl-8 mx-auto ">
    <p class="title-auth text-center text-primary">
      Log in and get to work
    </p>

    <form @submit.prevent="handleLogin">
      <alerte-danger
        :head-error-message="errors.serverSideErrorMessage"
        @updateContent="errors.serverSideErrorMessage = $event"
      />

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="icons.vectorEmail"
            alt="icon email"
            class="position-absolute ms-3 email"
          >
          <input
            v-model="email"
            type="email"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                (v$.email.$invalid && v$.email.$dirty) ||
                errors.emailErrorMessage,
            }"
            placeholder="Email"
            @blur="v$.email.$touch"
          >
        </div>
        <template
          v-if="
            (v$.email.$invalid && v$.email.$dirty) || errors.emailErrorMessage
          "
        >
          <small
            v-if="v$.email.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.email.email.$invalid"
            class="invalid-feedback"
          >
            Must be a valid email address
          </small>

          <small
            v-else-if="errors.emailErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.emailErrorMessage }}
          </small>
        </template>
      </div>

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="icons.vectorPassword"
            alt="Password Icon"
            class="position-absolute ms-3 email"
          >

          <img
            v-if="showPassword"
            :src="icons.vectorEye"
            alt="Toggle password visibility icon"
            class="position-absolute eye"
            @click="togglePasswordVisibility"
          >
          <img
            v-else
            :src="icons.vectorEyeOff"
            alt="Toggle password visibility icon"
            class="position-absolute eye-off"
            @click="togglePasswordVisibility"
          >

          <input
            v-model.trim="password"
            :type="showPassword ? 'text' : 'password'"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                (v$.password.$invalid && v$.password.$dirty) ||
                errors.passwordErrorMessage,
            }"
            placeholder="Password"
            @blur="v$.password.$touch"
          >
        </div>
        <template
          v-if="
            (v$.password.$invalid && v$.password.$dirty) ||
              errors.passwordErrorMessage
          "
        >
          <small
            v-if="v$.password.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.password.minLength.$invalid"
            class="invalid-feedback"
          >
            The password must be at least 6 characters
          </small>

          <small
            v-else-if="errors.passwordErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.passwordErrorMessage }}
          </small>
        </template>
      </div>

      <p class="text-end text-decoration-underline text-primary ">
        <router-link
          :to="{ name: 'ForgotPassword' }"
        >
          Lost your Password?
        </router-link>
      </p>

      <div class="text-center">
        <button
          type="submit"
          class="btn-primary submit-button"
          :disabled="loading"
        >
          <submission-spinner v-if="loading" />
          Login
        </button>
      </div>

      <p class="back-to-login">
        New to ServiceLoop?
        <router-link
          :to="{ name: 'Register' }"
          class="text-decoration-underline"
        >
          Sign Up
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

import api from "@/services/api";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";
import { ProviderStatus, UserScopes } from "@/configs/constants/users";

import vectorEmail from "@/assets/img/icons/symbols_mail.svg";
import vectorPassword from "@/assets/img/icons/symbols_password.svg";
import vectorEye from "@/assets/img/icons/symbols_eye.svg";
import vectorEyeOff from "@/assets/img/icons/symbols_eye_off.svg";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "LoginPage",
  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      vectorEmail,
      vectorPassword,
      vectorEye,
      vectorEyeOff,
    },
    loading: false,
    showPassword: false,
    email: null,
    password: null,
    errors: {
      emailErrorMessage: null,
      passwordErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      email: {
        required,
        email,
      },

      password: {
        required,
        minLength: minLength(6),
      },
    };
  },

  methods: {
    ...validationHelpers,

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    async handleLogin() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      const credentials = {
        email: this.email,
        password: this.password,
      };

      try {
        await api.login(credentials, (data) => {
          if (data.scope === UserScopes.PROVIDER) {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.CUSTOMER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            if (data.status === ProviderStatus.NEED_COMPLETE_PROFILE) {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderProfile",
                }
              );
            } else {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderDashboard",
                }
              );
            }
          } else {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.PROVIDER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            this.$router.push(
              this.$route.query.redirectFrom || { name: "CustomerDashboard" }
            );
          }
        });
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      this.errors.emailErrorMessage = null;
      this.errors.passwordErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 403) {
          this.errors.serverSideErrorMessage =
            errorMessages.ACCOUNT_DELETED_OR_SUSPENDED;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            if (error == "email") {
              this.errors.emailErrorMessage = err.response.data[error][0];
            }
            if (error == "password") {
              this.errors.passwordErrorMessage = err.response.data[error][0];
            }
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
