<template>
  <div class="job-detail">
    <section class="content">
      <loader-spinner v-if="loading" />

      <div
        v-else-if="errors.notFoundErrorMessage"
        class="col-md-8 offset-md-2 mt-7"
      >
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>

      <template v-else>
        <div class="page-header">
          <div class="d-md-flex title-part mb-0">
            <router-link
              :to="{ name: 'CustomerEstimates' }"
              class="title me-1"
            >
              Estimates
            </router-link>

            <p class="title-content">
              / {{ estimate.title }}
            </p>
          </div>

          <div class="d-flex align-items-center">
            <div class="position-proposal-icon me-3">
              <span
                v-if="estimate.title"
                class="proposal-status border-primary"
                @click="exportToPDF(estimate.title)"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
                <img
                  v-else
                  :src="download"
                  alt="Dosnload Icon"
                  class="me-2"
                >
                <span class="mb-0 text-primary fw-normal">Download</span>
              </span>
            </div>

            <submission-spinner
              v-if="updatingEstimate"
              :color="'#209516'"
            />
            <template v-else>
              <div
                v-if="estimate.status !== estimateStatus.APPROVED"
                class="dropdown drop nav-link"
              >
                <a
                  class="dropdown-toggle item"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="icons.dotVectorIcon"
                    alt="Menu Icon"
                  >
                </a>

                <ul class="dropdown-menu">
                  <li
                    v-if="canApproveEstimate"
                    class="border-bottom"
                  >
                    <a
                      class="dropdown-item cursor-pointer"
                      @click.prevent="confirmClaim(actions.APPROVE)"
                    >Approve</a>
                  </li>

                  <li
                    v-if="canDeclineEstimate"
                    class="border-bottom"
                  >
                    <a
                      class="dropdown-item cursor-pointer"
                      @click.prevent="confirmClaim(actions.DECLINE)"
                    >Decline</a>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>

        <div
          id="element-to-convert"
          class="page-content"
        >
          <div
            v-if="provider"
            class="col-md-12 my-5"
          >
            <div class="row">
              <div class="col-md-6 text-start">
                <div class="col-md-8 mb-3">
                  <h5 class="mb-3 text-primary">
                    {{ provider.full_name }}
                  </h5>
                  <p class="my-0">
                    {{ provider?.location_description }}
                  </p>
                  <p class="my-0">
                    {{ provider.country }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 text-end d-flex">
                <div class="col-md-12 align-self-end mb-3">
                  <h6 class="mb-2 text-secondary">
                    Balance Due
                  </h6>
                  <h5
                    v-if="estimate.items"
                    class="text-bold mb-0"
                  >
                    {{ formatPrice(total) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="job?.owner"
            class="col-md-12 my-5"
          >
            <div class="row">
              <div class="col-md-6 text-start">
                <div class="col-md-8 mb-3">
                  <h5 class="mb-3 text-primary">
                    {{ job.owner.user.full_name }}
                  </h5>
                  <p class="my-0">
                    {{ job.owner.user.location_description }}
                  </p>
                  <p class="my-0">
                    {{ job.owner.user.country }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 text-end d-flex">
                <div class="col-md-12 align-self-end mb-3">
                  <div class="row text-end">
                    <p class="col-md-9 text-primary">
                      Invoice Date :
                    </p>
                    <p class="col-md-3">
                      {{ formatDate(estimate.created_at) }}
                    </p>
                  </div>
                  <div class="row text-end">
                    <p class="col-md-9 text-primary">
                      Due Date :
                    </p>
                    <p class="col-md-3">
                      {{ formatDate(estimate.due_date) }}
                    </p>
                  </div>
                  <div class="row text-end">
                    <p class="col-md-9 text-primary">
                      Job Name :
                    </p>
                    <p class="col-md-3">
                      {{ job.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="estimate?.client"
            class="col-md-12 my-5"
          >
            <div class="row">
              <div class="col-md-6 text-start">
                <div class="col-md-8 mb-3">
                  <h5 class="mb-3 text-primary">
                    {{ estimate.client.full_name }}
                  </h5>
                  <p class="my-0">
                    {{ estimate.client.company_name }}
                  </p>
                  <p class="my-0">
                    {{ estimate.client.main_propriety?.location_description }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 text-end d-flex">
                <div class="col-md-12 align-self-end mb-3">
                  <div class="row text-end">
                    <p class="col-md-8 text-primary">
                      estimate Date :
                    </p>
                    <p class="col-md-4">
                      {{ formatDate(estimate.created_at) }}
                    </p>
                  </div>
                  <div class="row text-end">
                    <p class="col-md-8 text-primary">
                      Due Date :
                    </p>
                    <p class="col-md-4">
                      {{ formatDate(estimate.due_date) }}
                    </p>
                  </div>
                  <div class="row text-end">
                    <p class="col-md-8 text-primary">
                      Primary contact :
                    </p>
                    <p class="col-md-4">
                      {{ estimate.client.primary_contact?.full_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 md-3">
            <div class="table-responsive">
              <table class="table">
                <head-table :list="thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in estimate.items"
                    :key="index"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ item.item }}
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>
                      {{ formatValue(item.value) }}
                    </td>
                    <td>
                      {{ formatValue(item.quantity * item.value) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="estimate.items"
            class="col-md-12 my-5 text-end d-flex"
          >
            <div class="col-md-12 align-self-end mb-3">
              <div class="text-end">
                <div class="d-inline-block text-bold">
                  <p>Total : &nbsp;</p>
                </div>
                <div class="d-inline-block">
                  <p>{{ formatPrice(total) }}</p>
                </div>
              </div>

              <button
                v-if="!downloading"
                class="btn-primary rounded-0 mt-3"
              >
                Balance Due {{ formatPrice(total) }}
              </button>
            </div>
          </div>

          <div class="col-md-12 text-start">
            <div class="col-md-3 mb-3">
              <h5 class="mb-3 text-primary">
                Note :
              </h5>
              <p class="my-0">
                {{ estimate.message }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </section>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import download from "@/assets/img/icons/download.svg";
import HeadTable from "@/components/HeadTable.vue";
import errorMessages from "@/utils/error-messages";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";

import PriceHelpers from "@/utils/price-format";
import html2pdf from "html2pdf.js";
import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import { EstimateStatus } from "@/configs/constants";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateDetailsPage",

  components: {
    HeadTable,
    LoaderSpinner,
    SubmissionSpinner,
    ConfirmModal,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      dotVectorIcon,
    },
    download,
    showModal: false,
    fadeModal: false,
    loading: false,
    downloading: false,
    listData: [],
    estimate: {},
    job: {},
    provider: {},
    thead: ["#", "Item", "Quantity", "Value", "Amount"],
    datasend: {
      items: [{ item: null, value: null, quantity: null }],
      client: null,
      message: "",
      send: null,
    },
    errors: {
      items: null,
      job: null,
      mutableName: null,
      message: null,
      due_date: null,
      error: null,
      send: null,
      serverSideErrorMessage: null,
      notFoundErrorMessage: null,
    },
    actions: {
      DENIED: "denied",
      APPROVE: "approved",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    selectedEstimateId: null,
    updatingEstimate: false,
  }),

  computed: {
    subTotal() {
      return this.estimate.items.reduce((prev, curr) => {
        return prev + curr.quantity * curr.value;
      }, 0);
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    tax() {
      return (this.currentPlan?.tax_percentage / 100) * this.subTotal;
    },

    total() {
      return this.subTotal + this.tax;
    },

    serviceFee() {
      let total =
        (this.currentPlan?.invoice_fee_percentage / 100) * this.subTotal +
        this.currentPlan?.invoice_fee_fix;
      return total.toFixed(2);
    },

    willReceive() {
      let total = this.subTotal - this.serviceFee;
      return Math.max(total, 0);
    },

    estimateStatus() {
      return EstimateStatus;
    },

    canApproveEstimate() {
      if (!this.estimate) return false;
      return this.estimate.status !== EstimateStatus.APPROVED;
    },

    canDeclineEstimate() {
      if (!this.estimate) return false;
      return ![EstimateStatus.APPROVED, EstimateStatus.DENIED].includes(
        this.estimate.status
      );
    },
  },

  mounted() {
    this.fetchEstimatesDetail();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,

    back() {
      this.$router.back();
    },

    exportToPDF(title) {
      if (this.downloading) {
        return;
      }

      this.downloading = true;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: title + ".pdf",
      });
      setTimeout(() => {
        this.downloading = false;
      }, 2000);
    },

    async fetchEstimatesDetail() {
      this.loading = true;

      try {
        let response = await api.fetchEstimatesDetail(this.$route.params.id);
        this.estimate = response.data;

        if (
          [EstimateStatus.ARCHIVED, EstimateStatus.UNSENT].includes(
            this.estimate.status
          )
        ) {
          this.errors.notFoundErrorMessage =
            errorMessages.CANNOT_ACCESS_ESTIMATE;
        }
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.APPROVE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to approve this estimate ?";
          break;

        case this.actions.DECLINE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to decline this estimate ?";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.APPROVE:
          this.updateEstimatesStatus(
            EstimateStatus.APPROVED,
            `Estimate successfully approved.`
          );
          break;

        case this.actions.DECLINE:
          this.updateEstimatesStatus(
            EstimateStatus.DENIED,
            `Estimate successfully canceled.`
          );
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    updateEstimatesStatus(status, successMessage) {
      this.updatingEstimate = true;

      api
        .updateEstimatesStatus(this.estimate.id, status)
        .then((_) => {
          this.estimate.status = status;
          toast.success(successMessage);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.updatingEstimate = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        items: null,
        client: null,
        title: null,
        message: null,
        error: null,
        send: null,
        notFoundErrorMessage: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 404) {
          this.errors.notFoundErrorMessage = errorMessages.ESTIMATE_NOT_FOUND;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-menu .border-bottom:last-child {
  border-bottom: none !important;
}
</style>
