import AuthRoutes from "./auth";
import ProviderRoutes from "./provider";
import CustomerRoutes from "./customer";
import SuperadminRoutes from "./superadmin";
import PublicRoutes from "./public";

export default [
  ...AuthRoutes,
  ...ProviderRoutes,
  ...CustomerRoutes,
  ...SuperadminRoutes,
  ...PublicRoutes,
];
