import { Request } from "@/services/request";

const baseUrl = "api/v1/invoice";

export default {
  fetchInvoices({
    page = 1,
    startDate = null,
    endDate = null,
    project = null,
    status = null,
    search = null,
  }) {
    return new Request().append(`${baseUrl}/`).method("get").params({
      page,
      start_date: startDate,
      end_date: endDate,
      project,
      status,
      search,
    });
  },
  providerInvoices({
    page = 1,
    id
  }) {
    return new Request()
    .append(`${baseUrl}/${id}/provider_invoice/`)
    .method("get")
    .params({
      page,
    });
  },
  customerInvoices({
    page = 1,
    id
  }) {
    return new Request()
    .append(`${baseUrl}/${id}/customer_invoice/`)
    .method("get")
    .params({
      page,
    });
  },
  fetchInvoicesStatistics() {
    return new Request().append(`${baseUrl}/count_status/`).method("get");
  },

  createInvoice(data) {
    return new Request().append(`${baseUrl}/`).method("post").setData(data);
  },

  updateInvoice(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("put")
      .setData(data);
  },

  updateInvoiceOneAttr(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("patch")
      .setData(data);
  },

  remindInvoice(id) {
    return new Request().append(`${baseUrl}/${id}/remind/`).method("put");
  },

  sendInvoice(id, redirectLink) {
    return new Request()
      .append(`${baseUrl}/${id}/send/`)
      .method("put")
      .setData({ redirectLink });
  },

  requestRefund(id) {
    return new Request().append(`${baseUrl}/${id}/refund/`).method("post");
  },

  fetchInvoiceById(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  payInvoice(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/pay/`)
      .method("post")
      .setData(data);
  },

  setExternalPayment(id) {
    return new Request()
      .append(`${baseUrl}/${id}/external_pay/`)
      .method("post");
  },

  invoiceSummary() {
    return new Request().append(`${baseUrl}/summary/`).method("get");
  },

  deleteInvoice(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("delete");
  },
};
