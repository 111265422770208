<template>
  <div
    ref="tool"
    :class="className"
    class="d-inline-block"
    :data-bs-toggle="'tooltip'"
    :data-bs-placement="position"
    :title="infos"
  >
    <slot />
  </div>
</template>

<script>
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

export default {
  props: {
    infos: {
      type: String,
      require: true,
      default: null,
    },
    position: {
      type: String,
      require: false,
      default: "top",
    },
    className: {
      type: String,
      require: false,
      default: null,
    },
  },

  mounted() {
    new Tooltip(this.$refs.tool);
  },
};
</script>
