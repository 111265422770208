<template>
  <confirm-modal
    ref="confirmModal"
    title="No Credit Card"
    message="You don't have a credit card associated with your account."
    ok-button-title="Add card"
    @onConfirm="$router.push({ name: type })"
  />
</template>

<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  name: "NoCreditCardModal",

  components: {
    ConfirmModal
  },

  props: {
    type: {
      type: String,
      required: true,
      default: null,
    }
  },

  methods: {
    confirm() {
      this.$refs.confirmModal.confirm();
    },

    openModal() { 
      this.$refs.confirmModal.openModal();
    },

    closeModal() {
      this.$refs.confirmModal.closeModal();
    },
  },
}
</script>