<template>  
  <div 
    v-if="plan" 
    class="col-md-12"
  >
    <!-- Content Header (Page header) -->
    <section class="content">
      <h3>
        <router-link
          :to="{ name: 'SuperAdminPlans' }" 
        >
          Plans 
        </router-link>
        <span class="fw-lighter"> / {{ plan.name }}</span>
      </h3>
      
      <!-- Start content -->
      <div class="col-md-12 row p-4 page-content">
        <div class="col-md-12 row mb-3">
          <div class="col-md-6">
            <h4 class="text-primary">
              {{ plan.name }}
            </h4>

            <p>{{ formatDate(plan.created_at) }}</p>
          </div>

          <div class="col-md-6 text-end">
          <!-- <button
            class="btn d-block d-sm-inline-block ms-sm-3 mt-3 mt-sm-0 px-3 py-2"
            :class="plan.is_active == true ? 'btn-outline-primary' : 'btn-outline-danger'"
          >
            <span> 
              {{ statusToUI(plan.user.is_active) }}
            </span>
          </button> -->
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Basic Details
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Name</label>
            <p class="label-content username">
              {{ plan.name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Type</label>
            <p class="label-content">
              {{ plan.type }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Recurrency Type</label>
            <p class="label-content">
              {{ plan.recurrency_type }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Value</label>
            <p class="label-content">
              {{ plan.value }}
            </p>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Commercial description</label>
            <p class="label-content">
              {{ plan.commercial_description }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <h4 class="border-bottom section-title">
              Number Detail
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Streamlined bill percentage</label>
            <p class="label-content">
              {{ plan.streamlined_bill_percentage }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Per project service fee</label>
            <p class="label-content">
              {{ plan.per_project_service_fee }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Tax percentage</label>
            <p class="label-content">
              {{ plan.tax_percentage }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Invoice fee percentage</label>
            <p class="label-content">
              {{ plan.invoice_fee_percentage }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Invoice fee fix</label>
            <p class="label-content">
              {{ plan.invoice_fee_fix }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Withdraw value fix</label>
            <p class="label-content">
              {{ plan.withdraw_value_fix }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Interval count</label>
            <p class="label-content">
              {{ plan.interval_count }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Number of Bids</label>
            <p class="label-content">
              {{ plan.number_of_bids }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Services
            </h4>
          </div>

          <feature-item
            v-for="plan_service in plan.services"
            :key="plan_service.code"
            :title="plan_service.name"
            :checked="true"
          />

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.limited_access_to_project_list"
              >
              <label class="pl-2 text-wrap">Limited access to project list</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_access_calendar"
              >
              <label class="pl-2 text-wrap">Can access calendar</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.invoice_management"
              >
              <label class="pl-2 text-wrap">Invoice management</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.profile_searchable"
              >
              <label class="pl-2 text-wrap">Profile searchable</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_access_messaging"
              >
              <label class="pl-2 text-wrap">Can access messaging</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_access_historical_data"
              >
              <label class="pl-2 text-wrap">Can access historical data</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.job_tracking"
              >
              <label class="pl-2 text-wrap">Job tracking</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_see_stars_and_reviews"
              >
              <label class="pl-2 text-wrap">Can see stars and reviews</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_view_list_of_available_providers"
              >
              <label class="pl-2 text-wrap">Can view list of available providers</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.can_access_phone"
              >
              <label class="pl-2 text-wrap">Can access phone</label>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="d-flex">
              <input
                class="form-check-input ml-3 shadow-none"
                type="checkbox"
                disabled
                :checked="plan.default"
              >
              <label class="pl-2 text-wrap">Default</label>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import download from "@/assets/img/icons/download.svg";

import html2pdf from "html2pdf.js";
import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";

import FeatureItem from "@/components/subscription-plans/FeatureItem.vue";

export default {
  name: "ProviderInvoiceDetails",
  components: { 
    FeatureItem,
  },

  data: () => ({
    download,
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    plan: {},
    thead: [ "#", "Item & Description", "QTY", "Rate", "Amount" ],
  }),
  
  mounted() {
    this.fetchSubscriptionPlanById()
  },

  methods: {
    ...DateHelpers,
    
    back() {
      this.$router.back()
    },

    exportToPDF(invoice_no) {
      if (this.loading) { return }

      this.loading = true;
      html2pdf(document.getElementById("element-to-convert"), {
				margin: 10,
  			filename: invoice_no+".pdf",
			});
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    async fetchSubscriptionPlanById() {
      this.loading = true;

      try {
        let response = await api.fetchSubscriptionPlanById(this.$route.params.id);
        this.plan = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },


  },
};
</script>
