<template>
  <div class="col-md-12 row ">
    <!-- Content Header (Page header) -->
    <section class="d-flex align-items-center justify-content-between p-0">
      <h5>
        <router-link
          :to="{ name: 'SuperAdminJobsSettings' }"
          class="fw-medium text-decoration-none"
        >
          Job Configs
        </router-link>
        <span class="fw-lighter">/ Levels</span>
      </h5>

      <button
        type="button"
        class="btn-primary"
        data-toggle="modal"
        @click="create"
      >
        + Add Level
      </button>
    </section>

    <!-- Start content -->
    <div class="col-12 p-0 m-0">
      <div class="col-12 mt-4">
        <div class="table-responsive">
          <table class="table">
            <head-table
              :list="['Levels', 'Author', 'Status', 'Action']"
            />
            <tbody>
              <tr
                v-for="(type, index) in types"
                :key="index"
              >
                <td class="py-3  ">
                  {{ type.name }}
                </td>

                <td>
                  {{ type.created_by_admin ? "Admin" : "User" }}
                </td>

                <td>
                  {{ statusToUI(type.status) }}
                </td>

                <td>
                  <option-action
                    :status="type.status"
                    :options="actionsList"
                    @update:options="choose(type, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <page-action
        :index="page"
        :infos="pages"
        @update:index="fetchAllJobConfigTypes($event), (page = $event)"
      />
    </div>
    <!-- End content -->
    <app-create
      ref="CreateSetting"
      title="Levels"
      label="level"
      @updateList="fetchAllJobConfigTypes(page)"
    />
    <app-update
      ref="UpdateSetting"
      title="Levels"
      label="level"
      @updateList="fetchAllJobConfigTypes(page)"
    />
    <app-delete
      ref="DeleteSetting"
      title="Levels"
      label="level"
      @updateList="fetchAllJobConfigTypes(page)"
    />
    <app-status
      ref="StatusSetting"
      title="Levels"
      label="level"
      @updateList="fetchAllJobConfigTypes(page)"
    />
  </div>
</template>

<script>
import api from "@/services/api";

import Create from "@/components/superadmin/setting/Create";
import Update from "@/components/superadmin/setting/Update";
import Delete from "@/components/superadmin/setting/Delete";
import Status from "@/components/superadmin/setting/Status";
import { JobSettingsStatus, actionsList } from "@/configs/constants/jobs";
import HeadTable from "@/components/HeadTable.vue";
import PageAction from "@/components/PageAction.vue";
import statusToUI from '@/utils/statusFormate'
import OptionAction from '@/components/OptionAction.vue';

export default {
  name: "SuperadminJobsSettingsLevelsPage",
  components: {
    AppCreate: Create,
    AppUpdate: Update,
    AppDelete: Delete,
    AppStatus: Status,
    HeadTable,
    PageAction,
    OptionAction,
  },

  data() {
    return {
      actionsList,
      page: 1,
      pages: {
        count: null,
        max_page_size: null,
        total_pages: null,
      },
      loading: false,
      types: [],
      stockType: null,
      confirmationMessage: "",
      confirmationOkBtnText: "",
      stock: {},
    };
  },

  computed: {
    jobSettingsStatus() {
      return JobSettingsStatus;
    },
  },

  mounted() {
    this.fetchAllJobConfigTypes(this.page);
  },

  methods: {
    ...statusToUI,

    fetchAllJobConfigTypes(page) {
      this.loading = true;

      api
        .fetchJobConfigTypes(page, "level")
        .then((response) => {
          this.types = response.data.data;
          this.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    create() {
      this.$refs.CreateSetting.openModal();
    },
    update(item) {
      this.$refs.UpdateSetting.openModal(item);
    },
    deleted(item) {
      this.$refs.DeleteSetting.openModal(item);
    },
    setStatus(item) {
      this.$refs.StatusSetting.openModal(item);
    },

    onConfirm() {
      // this.$refs.CreateSetting.closeModal();
    },
    choose(item, event) {
      switch (event) {
        case "edit":
          this.update(item);
          break;

        case "delete":
          this.deleted(item);
          break;
        case "ACTIVE":
          this.setStatus(item);
          break;
      }
    },
  },
};
</script>
