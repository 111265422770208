<template>
  <div>
    <div
      v-if="options"
      class="col-md-12 position-relative multiselect mb-3"
    >
      <ol class="m-0">
        <li
          v-for="(item, index) in value"
          :key="index"
          class="fs-2 fw-normal ps-0 px-3 py-1 skill"
        >
          <div class="d-flex justify-content-between">
            {{ options.find((el) => el.id == item)?.name }}
            <img
              alt="smallclose "
              :src="smallclose"
              @click="removeSkill(item)"
            >
          </div>
        </li>
      </ol>

      <div class="input-group mt-2">
        <img
          alt="smallclose "
          :src="icons.searchVectorIcon"
          class="cursor-pointeur search"
        >
        <input
          v-model="text"
          type="text"
          class="input-multiselect form-control ps-5 shadow-none"
          :placeholder="placeholder"
          :class="{ 'is-invalid': classstat }"
          @focus="datasend.type = null"
          @blur="$emit('blur')"
          @click="show = !show"
        >
      </div>
      <template v-if="classstat">
        <small
          v-if="requiredstat"
          class="invalid-feedback"
        >
          This field is required
        </small>
        <small
          v-if="errorsstat"
          class="invalid-feedback"
        >
          {{ errorsstat }}
        </small>
      </template>

      <ul
        v-if="show && !close"
        v-click-outside="closeIt"
        class="list-group w-100 mt-0 cursor-pointeur"
      >
        <li
          v-if="options.length == 0"
          class="list-group-item w-100 text-dark text-center"
        >
          No item
        </li>
        <li
          v-for="(item, index) in options.filter(
            (el) =>
              el.name &&
              el.name.toLowerCase().search(text?.toLowerCase() ?? '') != -1 &&
              !value.includes(el.id)
          )"
          :key="index"
          class="list-group-item w-100"
          @click="addSkill(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>

    <alerte-danger
      :head-error-message="errors.serverSideErrorMessage"
      @updateContent="errors.serverSideErrorMessage = $event"
    />
    <div
      v-if="datasend.type == null"
      class="col-12 mb-4 text-end"
    >
      <button
        class="btn-primary text-nowrap"
        type="button"
        @click="datasend.type = 'SHORT'"
      >
        <img
          :src="icons.plusVectorIcon"
          alt=""
          class="me-1"
        >
        Add a Question
      </button>
    </div>
    <div
      v-if="['SHORT', 'LONG'].includes(datasend.type)"
      class="row mb-4"
    >
      <label class="mb-2 question-detail">
        Question details <span class="text-danger">*</span>
      </label>
      <div class="col-lg-7">
        <textarea
          v-model="datasend.name"
          cols="30"
          rows="3"
          placeholder="Write your question here..."
          :class="{
            'is-invalid': v$.datasend.name.$invalid && v$.datasend.name.$dirty,
          }"
          @blur="v$.datasend.name.$touch"
        />
        <template
          v-if="
            (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
              errors.nameErrorMessage
          "
        >
          <small
            v-if="v$.datasend.name.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.datasend.name.minLength.$invalid"
            class="invalid-feedback"
          >
            The minimum size for this field is 3 characters
          </small>

          <small
            v-else-if="errors.nameErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.nameErrorMessage }}
          </small>
        </template>
      </div>
      <div class="col-lg-5">
        <select
          v-model="datasend.type"
          class="form-select mb-2 shadow-none"
        >
          <option value="SHORT">
            Short
          </option>
          <option value="LONG">
            Long
          </option>
          <option value="SINGLE">
            Single
          </option>
          <option value="MULTIPLE">
            Multiple
          </option>
        </select>
        <button
          class="btn-primary w-100 text-nowrap"
          type="button"
          :disabled="loading"
          @click="save"
        >
          <submission-spinner v-if="loading" />
          <img
            v-else
            :src="icons.plusVectorIcon"
            alt="Add Icon"
            class="me-1"
          >
          Add a Question
        </button>
      </div>
    </div>
    
    <div
      v-if="datasend.type == 'SINGLE'"
      class="row mb-4"
    >
      <label class="mb-2 question-detail">
        Question details <span class="text-danger">*</span>
      </label>
      <div class="col-lg-7">
        <textarea
          v-model="datasend.name"
          cols="30"
          rows="3"
          placeholder="Write your question here..."
          :class="{
            'is-invalid': v$.datasend.name.$invalid && v$.datasend.name.$dirty,
          }"
          @blur="v$.datasend.name.$touch"
        />
        <template
          v-if="
            (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
              errors.nameErrorMessage
          "
        >
          <small
            v-if="v$.datasend.name.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.datasend.name.minLength.$invalid"
            class="invalid-feedback"
          >
            The minimum size for this field is 3 characters
          </small>

          <small
            v-else-if="errors.nameErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.nameErrorMessage }}
          </small>
        </template>
      </div>
      <div class="col-lg-5">
        <select
          v-model="datasend.type"
          class="form-select mb-2 shadow-none"
        >
          <option
            value="null"
            disabled
          >
            Select an Option
          </option>
          <option value="SHORT">
            Short
          </option>
          <option value="LONG">
            Long
          </option>
          <option value="SINGLE">
            Single
          </option>
          <option value="MULTIPLE">
            Multiple
          </option>
        </select>
      </div>
      <div class="col-md-12">
        <label class="mt-3 mb-2 question-detail">Suggested responses - Check the right answers(s):</label>
        <div
          v-for="(item, index) in datasend.responses"
          :key="index"
          class="form-check d-flex align-items-center mb-2"
        >
          <input
            class="form-check-input me-2 shadow-none"
            type="checkbox"
            :checked="item.right"
            @change="updateResponse(index, !item.right)"
          >
          <input
            v-model="item.response"
            type="text"
            class="form-control shadow-none"
            placeholder="Write here..."
          >
          <img
            :src="icons.croixVectorIcon"
            alt=""
            class="ms-3"
            @click="removeResponse(item)"
          >
        </div>
        <div
          class="form-check d-flex align-items-center mb-2"
          @click="addResponse"
        >
          <input
            class="form-check-input me-2 shadow-none"
            type="radio"
            disabled
          >
          <input
            type="text"
            class="form-control me-3 shadow-none"
            placeholder="Add option"
            disabled
          >
          <div class="ms-3" />
        </div>
      </div>

      <div class="col-12 mb-4 text-end">
        <button
          class="btn-primary text-nowrap"
          type="button"
          :disabled="loading"
          @click="save"
        >
          <submission-spinner v-if="loading" />
          <img
            v-else
            :src="icons.plusVectorIcon"
            alt="Add Icon"
            class="me-1"
          >
          Add a Question
        </button>
      </div>
    </div>
    <div
      v-if="datasend.type == 'MULTIPLE'"
      class="row mb-4"
    >
      <label class="mb-2 question-detail">Question details <span class="text-danger">*</span></label>
      <div class="col-lg-7">
        <textarea
          v-model="datasend.name"
          cols="30"
          rows="3"
          placeholder="Write your question here..."
          :class="{
            'is-invalid': v$.datasend.name.$invalid && v$.datasend.name.$dirty,
          }"
          @blur="v$.datasend.name.$touch"
        />
        <template
          v-if="
            (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
              errors.nameErrorMessage
          "
        >
          <small
            v-if="v$.datasend.name.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.datasend.name.minLength.$invalid"
            class="invalid-feedback"
          >
            The minimum size for this field is 3 characters
          </small>

          <small
            v-else-if="errors.nameErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.nameErrorMessage }}
          </small>
        </template>
      </div>
      <div class="col-lg-5">
        <select
          v-model="datasend.type"
          class="form-select mb-2 shadow-none"
        >
          <option
            value="null"
            disabled
          >
            Select an Option
          </option>
          <option value="SHORT">
            Short
          </option>
          <option value="LONG">
            Long
          </option>
          <option value="SINGLE">
            Single
          </option>
          <option value="MULTIPLE">
            Multiple
          </option>
        </select>
      </div>
      <div class="col-md-12">
        <label class="mt-3 mb-2 question-detail">Suggested responses - Check the true(s) answers(s):</label>
        <div
          v-for="(item, index) in datasend.responses"
          :key="index"
          class="form-check d-flex align-items-center mb-2"
        >
          <input
            class="form-check-input me-2 shadow-none"
            type="checkbox"
            :checked="item.right"
            @change="updateResponse(index, !item.right)"
          >
          <input
            v-model="item.response"
            type="text"
            class="form-control shadow-none"
            placeholder="Write here..."
          >
          <img
            :src="icons.croixVectorIcon"
            alt=""
            class="ms-3"
            @click="removeResponse(item)"
          >
        </div>
        <div
          class="form-check d-flex align-items-center mb-2"
          @click="addResponse"
        >
          <input
            class="form-check-input me-2 shadow-none"
            type="checkbox"
            disabled
          >
          <input
            type="text"
            class="form-control me-3 shadow-none"
            placeholder="Add option"
            disabled
          >
          <div class="ms-3" />
        </div>
      </div>

      <div class="col-12 mb-4 text-end">
        <button
          class="btn-primary text-nowrap"
          type="button"
          :disabled="loading"
          @click="save"
        >
          <submission-spinner v-if="loading" />
          <img
            v-else
            :src="icons.plusVectorIcon"
            alt="Add Icon"
            class="me-1"
          >
          Add a Question
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import plusVectorIcon from "@/assets/img/icons/plus.svg";
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";
import searchVectorIcon from "@/assets/img/icons/search-black.svg";
import smallclose from "@/assets/img/icons/poubell-red.svg";

import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import api from "@/services/api";
import AlerteDanger from './AlerteDanger.vue';

export default {
  name: "MultiSelect",

  components: { 
    SubmissionSpinner,
    AlerteDanger
  },

  props: {
    options: {
      type: Array,
      required: false,
      default: null,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    classstat: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredstat: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorsstat: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      searchVectorIcon,
      croixVectorIcon,
      plusVectorIcon,
    },
    text: "",
    stock: [],
    click: false,
    smallclose,
    show: false,
    close: false,
    loading: false,
    datasend: {
      name: null,
      type: null,
      responses: [],
    },
    errors: {
      nameErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        name: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },

  methods: {
    ...validationHelpers,

    closeIt() {
      this.show = false;
    },

    addSkill(el) {
      this.$emit("update:value", [...this.value, el]);
      this.click = false;
      if (this.value.length === this.options.length) {
        this.close = true;
      }
    },

    removeSkill(ele) {
      this.$emit(
        "update:value",
        this.value.filter((el) => el != ele)
      );
      this.close = false;
    },

    save() {
      if (this.loading) return;


      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;

      this.clearErrorsMessages();

      api
        .createJobConfigType(this.datasend, "screeningquestion")
        .then((response) => {
          this.$emit("update:options", response.data);
          this.addSkill(response.data.id);
          this.resetValidation();
          this.datasend = { name: null, type: null, responses: [] };
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors.nameErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.nameErrorMessage = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
    addResponse() {
      this.datasend.responses.push({ response: null, right: false });
    },
    removeResponse(item) {
      this.datasend.responses = this.datasend.responses.filter(
        (el) => el != item
      );
    },
    updateResponse(index, value) {
      if (this.datasend.type == "SINGLE") {
        this.datasend.responses.forEach((el, i) => {
          if (index == i) {
            el.right = value;
          } else {
            el.right = false;
          }
        });
      } else {
        this.datasend.responses[index].right = value;
      }
    },
  },
};
</script>
