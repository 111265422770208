<template>
  <div
    v-if="subcontracts.length > 0"
    class="job-list"
  >
    <subcontract-item
      v-for="(subcontract, index) in subcontracts"
      :key="subcontract.id"
      :subcontract="subcontract"
      :is-last-item="index === subcontracts.length - 1"
      @fetchNextPage="onFetchNextPage"
    />
  </div>
</template>

<script>
import SubcontractItem from "@/components/provider/jobs/SubcontractingItem";

export default {
  name: "SubcontractingList",

  components: {
    SubcontractItem,
  },

  props: {
    subcontracts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    onFetchNextPage() {
      this.$emit('fetchNextPage')
    }
  }
};
</script>