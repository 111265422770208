<template>
  <div
    class="stars"
    :title="`${rating ?? 'no'} stars`"
  >
    <span
      v-for="n in rated"
      :key="n"
    >★</span>
    <span
      v-if="partial"
      class="partial-star"
    >
      <span
        class="complete"
        :style="`width: ${partial}px`"
      >★</span>
      <span class="empty">☆</span>
    </span>
    <span
      v-for="n in unrated"
      :key="n"
    >☆</span>
  </div>
</template>

<script>
export default {
  name: "RatingStars",

  props: {
    rating: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    rated() {
      return Math.floor(this.rating ?? 0);
    },
    unrated() {
      return 5 - Math.ceil(this.rating ?? 0);
    },
    partial() {
      return (this.rating - Math.floor(this.rating)) * 20;
    }
  },
};
</script>

<style scoped>
.stars {
  color: #ebbd01;
  font-size: x-large;
}

.stars span {
  display: inline-block;
  width: 20px;
}

.partial-star {
  position: relative;
  top: -28px;
  left: 0;
}

.partial-star span {
  position: absolute;
  top: 0;
  left: 0;
}

.complete {
  z-index: 0;
  overflow: hidden;
}

.empty {
  z-index: 0;
}
</style>
