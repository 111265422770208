<template>
  <div
    ref="jobRef"
    class="job-content"
    :class="{ 'border-bottom-0': isLastItem }"
  >
    <template v-if="job">
      <div class="row">
        <div class="col-md-7">
          <h3>
            <a
              v-if="hide"
              href="#"
              class="subtitle"
            >
              {{ job.title }}
            </a>
            <router-link
              v-else
              :to="{
                name: 'ProviderJobDetails',
                params: { id: job.id },
                query: { ...$route.query },
              }"
              class="subtitle"
            >
              {{ job.title }}
            </router-link>
          </h3>
          <p class="date">
            Posted at {{ formatDate(job.created_at, "MMMM Do, YYYY") }}
          </p>
        </div>

        <div class="col d-flex justify-content-end">
          <div 
            :class="{'filter-blur': hide}"
          >
            <div class="d-flex align-items-center justify-content-end mb-1">
              <button
                v-if="job?.owner_provider?.user?.id !== currentProvider?.id"
                class="btn btn-secondary me-3"
                :class="{ added: isJobFavorite }"
                :disabled="hide || (addingToFavorites || removingFromFavorites)"
                @click="handleToggleFavorite"
              >
                <submission-spinner
                  v-if="addingToFavorites || removingFromFavorites"
                  :color="spinnerColor"
                />
                <img
                  v-else
                  :src="icons.heartVectorIcon"
                  alt="Favorite Icon"
                >
              </button>
              <button
                v-if="hide"
                disabled
                class="btn btn-primary"
              >
                Locked
              </button>
              <router-link
                v-else
                :to="{ name: 'ProviderJobDetails', params: { id: job.id } }"
                class="btn btn-primary"
              >
                View
              </router-link>
            </div>

            <router-link
              v-if="getProposalStatusStyle(proposalStatus).class"
              class="proposal-status mt-2"
              :class="getProposalStatusStyle(proposalStatus).class"
              :to="{
                name: 'ProviderProposalDetails',
                params: {
                  id: job.id,
                  proposalId: proposalId,
                },
              }"
            >
              <div
                v-if="proposalStatus === jobProposalStatus.ACCEPTED"
                class="circle me-2"
              />
              <span class="mb-0">
                {{ formatProposalStatus(proposalStatus) }}
              </span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div
          v-if="job.level"
          class="d-flex"
        >
          <p class="case">
            Level :
          </p>

          <p
            v-if="job.level"
            class="number-case me-4"
          >
            {{ job.level.name }}
          </p>
        </div>

        <template v-if="job.budget_type !== jobBudgetTypes.NOT_SURE">
          <div
            v-if="job.budget_type === jobBudgetTypes.FIXED"
            class="d-flex"
          >
            <template v-if="job.budget > 0">
              <p class="case">
                Budget :
              </p>

              <p class="number-case me-4">
                {{ formatPrice(job.budget) }}
              </p>
            </template>
          </div>

          <template v-else>
            <div
              v-if="job.budget_from !== null && job.budget_from !== undefined"
              class="d-flex"
            >
              <p class="case">
                Budget from :
              </p>

              <p class="number-case me-4">
                {{ formatPrice(job.budget_from) }}
                <template
                  v-if="
                    job.payment_frequency &&
                      formatJobPaymentFrequencies(job.payment_frequency).short
                  "
                >
                  / {{
                    formatJobPaymentFrequencies(job.payment_frequency).short
                  }}
                </template>
              </p>
            </div>

            <div
              v-if="job.budget_to !== null && job.budget_to !== undefined"
              class="d-flex"
            >
              <p class="case">
                Budget to :
              </p>

              <p class="number-case me-4">
                {{ formatPrice(job.budget_to) }}
                <template
                  v-if="
                    job.payment_frequency &&
                      formatJobPaymentFrequencies(job.payment_frequency).short
                  "
                >
                  / {{
                    formatJobPaymentFrequencies(job.payment_frequency).short
                  }}
                </template>
              </p>
            </div>
          </template>
        </template>

        <div
          v-else
          class="d-flex"
        >
          <p class="case">
            Budget Type :
          </p>

          <p
            v-if="job.budget_type"
            class="number-case me-4"
          >
            {{ formatJobBudgetTypes(job.budget_type) }}
          </p>
        </div>

        <div
          v-if="job.bids_end"
          class="d-flex me-4"
        >
          <p class="case">
            Bids ends :
          </p>

          <p class="number-case">
            {{ formatDate(job.bids_end, "MMMM Do, YYYY") }}
          </p>
        </div>

        <div
          v-if="job.job_type"
          class="d-flex me-4"
        >
          <p class="case">
            Job Type :
          </p>

          <p class="number-case">
            {{ formatJobType(job.job_type) }}
          </p>
        </div>
        <div
          v-if="job.status"
          class="d-flex me-4"
        >
          <p class="case">
            Job Status :
          </p>

          <p class="number-case">
            {{ statusToUI(job.status) }}
          </p>
        </div>
        <div
          v-if="job?.location_description"
          class="d-flex me-4"
        >
          <p class="case">
            Job location : 
          </p>

          <p class="number-case">
            {{ (job.location_description) }}
          </p>
        </div>
      </div>

      <div v-if="job.description">
        <div v-html="sanitizedDescription" />
        
        <!-- <router-link
          v-if="$store.getters['user/currentPlanServices']?.find(el=> el.code == 'marketplace') == false && job.description?.length > 154"
          :to="{ name: 'ProviderJobDetails', params: { id: job.id } }"
          class="text-primary"
        >
          more
        </router-link> -->
      </div>

      <div class="mt-4 auto-flex">
        <span
          v-for="skill in job.skills"
          :key="skill.id"
          class="type-job"
        >
          {{ skill.name }}
        </span>
      </div>

      <confirm-modal
        v-if="modalConfigs.showConfirmModal"
        ref="confirmModal"
        :ok-button-title="modalConfigs.confirmTitle"
        :message="modalConfigs.confirmMessage"
        @onConfirm="onModalConfirm"
      />
    </template>
  </div>
</template>

<script>
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import api from "@/services/api";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import heartVectorIcon from "@/assets/img/icons/heart.svg";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import { JobBudgetTypes } from "@/configs/constants/jobs";
import { GlobalEvents } from "@/configs/constants";
import statusToUI from "@/utils/statusFormate";
import { JobProposalStatus } from "@/configs/constants/jobs";

export default {
  name: "ProviderJobItem",

  components: {
    ConfirmModal,
    SubmissionSpinner,
  },

  beforeRouteLeave(to, from) {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  props: {
    job: {
      type: Object,
      required: true,
      default: null,
    },

    isFavorite: {
      type: Boolean,
      required: true,
      default: false,
    },

    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    proposalStatus: {
      type: String,
      required: false,
      default: "",
    },

    proposalId: {
      type: String,
      required: false,
      default: "",
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      heartVectorIcon,
    },
    isJobFavorite: false,
    addingToFavorites: false,
    removingFromFavorites: false,
    observer: null,
    actions: {
      ADD_TO_FAVORITES: "add-to-favorites",
      REMOVE_FROM_FAVORITES: "remove-from-favorites",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    shortDescription() {
      if (this.job.description?.length <= 154) {
        return this.job.description;
      } else if (this.job.description?.length > 154) {
        return `${this.job.description.slice(0, 154)}...`;
      }
      return "";
    },
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.shortDescription);
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    spinnerColor() {
      if (this.addingToFavorites) return "#209516";
      if (this.removingFromFavorites) return "#fff";
      return "";
    },

    jobProposalStatus() {
      return JobProposalStatus;
    },
  },

  created() {
    this.isJobFavorite = this.isFavorite;
    nextTick(() => {
      if (this.$refs.jobRef) {
        this.observer = new IntersectionObserver(this.callback);
        this.observer.observe(this.$refs.jobRef);
      }
    });
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,
    ...statusToUI,
    

    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.isLastItem) {
          this.$emit("fetchNextPage");
        }
      });
    },

    handleToggleFavorite() {
      if(!this.hide){
        if (this.isJobFavorite) {
          this.confirmClaim(this.actions.REMOVE_FROM_FAVORITES);
        } else {
          this.confirmClaim(this.actions.ADD_TO_FAVORITES);
        }
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.ADD_TO_FAVORITES:
          this.modalConfigs.confirmTitle = "Add";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to add this job to your favorites ?";
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.modalConfigs.confirmTitle = "Remove";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to remove this job from your favorites ?";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.currentAction) {
        case this.actions.ADD_TO_FAVORITES:
          this.addJobToFavorites();
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.removeJobFromFavorites();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    async addJobToFavorites() {
      try {
        this.addingToFavorites = true;
        this.clearErrorsMessages();

        await api.addJobToFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = true;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.addingToFavorites = false;
      }
    },

    async removeJobFromFavorites() {
      try {
        this.removingFromFavorites = true;
        this.clearErrorsMessages();
        
        await api.removeJobFromFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = false
        this.$emitter.emit(
          GlobalEvents.JOB_REMOVED_FROM_FAVORITES,
          this.job.id
          );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (
        err.response &&
        err.response.status !== 400 &&
        err.response.status !== 404
      ) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
