<template>
  <div class="msg-start d-flex justify-content-start text-start mb-4">
    <profile-avatar
      :classes="'mr-0'"
      :url="participant?.photo?.url"
      :fullname="participant.full_name"
      :color="participant.profile_color"
      :background="participant.profile_background"
    />

    <div>
      <div v-if="message.type == chatMessageTypes.TEXT">
        <p class="ms-receiver p-2 ms-3">
          {{ message.content }}
        </p>
      </div>

      <div
        v-else
        class="message-image their"
      >
        <template v-if="message.uploaded">
          <img
            class="message-image-display"
            :src="message.file?.url"
            @click="onDownloadFile(message)"
          >
        </template>

        <template v-else>
          <img
            class="message-image-display img-overlay"
            :src="message.preview"
          >
        </template>
      </div>

      <div>
        <p
          v-if="false"
          class="ms-receiver p-2 ms-3"
        >
          {{ message.content }}
        </p>

        <p class="ms-3 mb-3 rounded-3 hours">
          {{ formatDate(message.created_at, "HH:mm") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";

import { ChatMessageTypes } from "@/configs/constants/";

import ProfileAvatar from "@/components/ProfileAvatar.vue";

export default {
  name: "OtherMessage",

  components: {
    ProfileAvatar,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },

    participant: {
      type: Object,
      required: true,
    },
  },

  computed: {
    chatMessageTypes() {
      return ChatMessageTypes;
    },
  },

  methods: {
    ...DateHelpers,

    onDownloadFile(message) {
      this.$emit("onDownloadFile", message);
    },
  },
};
</script>
