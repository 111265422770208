<template>
  <div class="post-job row">
    <section class="content col-md-8 mx-auto">
      <div class="page-header">
        <h3>Rate</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div
              v-for="(proposal, index) in proposals"
              :key="index"
              class="col-md-12"
            >
              <div class="d-flex">
                <profile-avatar
                  :url="proposal.owner.user.photo?.url"
                  :fullname="proposal.owner.user.full_name"
                  :color="proposal.owner.user.profile_color"
                  :background="proposal.owner.user.profile_background"
                />

                <div>
                  <div>
                    <strong class="provider-name">{{
                      proposal.owner.user.full_name
                    }}</strong>
                  </div>

                  <div>
                    <p class="proposal-date">
                      Applied on
                      {{ formatDate(proposal.created_at, "MMMM Do, YYYY") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">Rating</label>
                <div class="d-flex justify-content-center">
                  <svg
                    v-for="(x, i) in [1, 2, 3, 4, 5]"
                    id="Capa_1"
                    :key="i"
                    height="64px"
                    width="64px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 47.94 47.94"
                    xml:space="preserve"
                    fill="#000000"
                    stroke="#000000"
                    stroke-width="0.00047939999999999994"
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    @click="datasend[index].rating = x"
                  >
                    <g
                      id="SVGRepo_bgCarrier"
                      stroke-width="0"
                    />
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="#CCCCCC"
                      stroke-width="0.38351999999999997"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        :style="
                          datasend[index].rating >= x
                            ? 'fill:#ED8A19;'
                            : 'fill:#eee;'
                        "
                        d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"
                      />
                    </g>
                  </svg>
                </div>
                <template v-if="errors.rating">
                  <small
                    v-if="errors.rating"
                    class="invalid-feedback"
                  >
                    {{ errors.rating }}
                  </small>
                </template>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">Feedback</label>
                <textarea
                  v-model="datasend[index].feedback"
                  cols="30"
                  rows="3"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write feedback..."
                />
                <template v-if="errors.feedback">
                  <small
                    v-if="errors.feedback"
                    class="invalid-feedback"
                  >
                    {{ errors.feedback }}
                  </small>
                </template>
              </div>
            </div>

            <div class="row mx-0 mt-5 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="submitting"
                >
                  <submission-spinner v-if="submitting" />
                  Complete the job
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import ProfileAvatar from "@/components/ProfileAvatar.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: { 
    ProfileAvatar,
    SubmissionSpinner,
    AlerteDanger,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    submitting: false,
    listData: [],
    proposals: null,
    datasend: [],
    errors: {
      rating: null,
      feedback: null,
      serverSideErrorMessage: null,
    },
  }),

  mounted() {
    this.fetchJobBids();
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.push({
        name: "CustomerJobDetails",
        params: { id: this.$route.params.id },
      });
    },

    async fetchJobBids() {
      try {
        this.loading = true;
        const response = await api.fetchJobBids({
          id: this.$route.params.id,
          status: "accepted",
        });
        this.proposals = response.data.data;
        this.datasend = response.data.data.map((el) => ({
          rating: null,
          feedback: "",
          provider: el.owner.id,
          job: this.$route.params.id,
        }));
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    save() {
      if (this.submitting) return;

      this.submitting = true;
      this.clearErrorsMessages();
      this.datasend.forEach((element, i, arr) => {
        api
          .makeRating(element)
          .then((_) => {
            if (i === arr.length - 1) {
              this.updateJobStatus();
            }
          })
          .catch((error) => {
            this.errorsHandler(error);
          })
          .then(() => {
            this.submitting = false;
          });
      });
    },

    clearErrorsMessages() {
      this.errors = {
        rating: null,
        feedback: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    async updateJobStatus() {
      try {
        await api.updateJob(
          this.$route.params.id,
          { status: "completed" },
          () => {
            this.back();
          }
        );
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(error);
      } finally {
      }
    },
  },
};
</script>
