<template>
  <div class="message-sender text-muted">
    <div class="message-sender-box">
      <div class="message-text-box">
        <div
          ref="userInput"
          class="message-input form-control form-control-lg"
          placeholder="Type message"
          tabindex="0"
          contenteditable="true"
          @input="handleType"
          @keyup.enter.exact="sendMessage"
        />
      </div>

      <img
        v-if="sendEmojis"
        :src="icons.emojisVectorIcon"
        alt="Add Emoji Icon"
        class="cursor-pointer me-2 ms-3"
      >

      <template v-if="sendImages">
        <input
          ref="inputImage"
          accept="application/pdf, image/*"
          type="file"
          style="display: none"
          class="shadow-none"
          @input="handleImageChange"
        >
        <img
          :src="icons.addVectorIcon"
          class="cursor-pointer me-2"
          :class="{ 'ms-3': !sendEmojis }"
          alt="Upload Image Icon"
          @click="pickImage"
        >
      </template>

      <img
        :src="icons.sendVectorIcon"
        alt="Send Message Icon"
        class="cursor-pointer"
        @click.prevent="sendMessage"
      >
    </div>

    <small
      v-if="isTyping"
      class="is-typing-messaage"
    >{{ userTyping }} is typing...</small>
  </div>
</template>

<script>
import addVectorIcon from "@/assets/img/icons/add.svg";
import sendVectorIcon from "@/assets/img/icons/send.svg";
import emojisVectorIcon from "@/assets/img/icons/emojis.svg";

import { ChatMessageTypes, GlobalEvents, WebSocketEvents } from "@/configs/constants";

export default {
  name: "MessageSender",

  props: {
    sendImages: {
      type: Boolean,
      required: false,
      default: true,
    },

    sendEmojis: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    icons: {
      addVectorIcon,
      sendVectorIcon,
      emojisVectorIcon,
    },
    textInput: null,
    isTyping: false,
    userTyping: null,
    userIsTypingTimer: null,
  }),

  computed: {
    myself() {
      return this.$store.getters["user/user"];
    },
  },

  created() {
    this.$emitter.on(GlobalEvents.chat.IS_TYPING, (user) => {
      this.isTyping = true;
      this.userTyping = user;

      if (this.userIsTypingTimer) {
        clearTimeout(this.userIsTypingTimer)
      }

      this.userIsTypingTimer = setTimeout(() => {
        this.isTyping = false
      }, 3000);
    });

    this.$emitter.on(GlobalEvents.chat.CHAT_MESSAGE_RECEIVED, (_) => {
        this.isTyping = false
    });
  },

  methods: {
    sendMessage() {
      this.textInput = this.$refs.userInput.textContent;
      this.$refs.userInput.textContent = "";

      if (this.textInput) {
        let messageContent = this.textInput;

        if (this.textInput.slice(-1) === "\n") {
          messageContent = messageContent.slice(0, this.textInput.length - 1);
        }

        const message = {
          content: messageContent,
          myself: true,
          uploaded: false,
          viewed: false,
          type: ChatMessageTypes.TEXT,
          participantId: this.myself.id,
        };

        this.$emit("onSubmitMessage", message);
      }
    },

    handleType() {
      this.$emit("onType", this.$refs.userInput.textContent);
    },

    pickImage() {
      this.$refs.inputImage.click();
    },

    async handleImageChange(e) {
      const files = e.target.files;

      let message = {
        file: null,
        preview: URL.createObjectURL(files[0]),
        content: null,
        participantId: this.myself.id,
        uploaded: false,
        viewed: false,
        type: ChatMessageTypes.FILE,
      };

      this.$emit("onImageSelected", { file: files[0], message });
    },
  },
};
</script>
