<template>
  <div class="col-lg-3">
    <router-link
      :to="{ name: route }"
      class="statistic-item"
    >
      <div class="card text-end">
        <h3 :style="colorCard">
          {{ count }}
        </h3>

        <p :style="colorCard">
          {{ title }}
        </p>

        <span class="linear-bar" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
    name: "DashboardStatistic",

    props: {
      title: {
        type: String,
        required: true,
        default: null,
      },

      count: {
        type: Number,
        required: true,
        default: 0,
      },

      route: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: null
      },
    },
    computed: {
      colorCard() {
        return { color: `${this.color}` };
      },
    },
};
</script>
