<template>
  <div class="col-xl-8 mx-auto">
    <p
      v-if="!passwordReset"
      class="title-auth text-center text-primary"
    >
      Reset password
    </p>

    <loader-spinner v-if="loading" />

    <alerte-danger
      :head-error-message="errors.serverSideErrorMessage"
      @updateContent="errors.serverSideErrorMessage = $event"
    />

    <form @submit.prevent="resetPassword">
      <div
        v-if="passwordReset"
        class="text-center fs-1 my-5"
      >
        <img
          :src="icons.FrameSuccess"
          alt="icon"
        >
        
        <p class="title-auth text-center text-primary">
          Reset Password
        </p>
        <p>
          Your password has been successfully reset 
        </p>
      </div>

      <template v-if="canResetPassword && !passwordReset">
        <div class="mb-3">
          <label class="mb-2">
            New Password <span class="text-danger">*</span>
          </label>
          <div class="mb-3">
            <div class="position-relative">
              <img
                :src="icons.vectorPassword"
                alt="Password Icon"
                class="position-absolute ms-3 email"
                :class="{
                  'is-invalid':
                    (v$.newPassword.$invalid && v$.newPassword.$dirty) ||
                    errors.passwordErrorMessage,
                }"
              >

              <img
                v-if="showNewPassword"
                :src="icons.vectorEye"
                alt="Toggle password visibility icon"
                class="position-absolute eye"
                @click="showNewPassword = !showNewPassword"
              >
              <img
                v-else
                :src="icons.vectorEyeOff"
                alt="Toggle password visibility icon"
                class="position-absolute eye-off"
                @click="showNewPassword = !showNewPassword"
              >

              <input
                v-model="newPassword"
                :type="showNewPassword ? 'text' : 'password'"
                class="form-control ps-5 shadow-none"
                placeholder="New Password"
                :class="{
                  'is-invalid':
                    (v$.newPassword.$invalid && v$.newPassword.$dirty) ||
                    errors.passwordErrorMessage,
                }"
                @blur="checkPasswordsValidity"
              >
            </div>
            <template
              v-if="
                (v$.newPassword.$invalid && v$.newPassword.$dirty) ||
                  errors.passwordErrorMessage
              "
            >
              <small
                v-if="v$.newPassword.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="v$.newPassword.minLength.$invalid"
                class="invalid-feedback"
              >
                The minimum size for this field is 6 characters
              </small>

              <small
                v-else-if="errors.passwordErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.passwordErrorMessage }}
              </small>
            </template>
          </div>
        </div>

        <div class="mb-3">
          <label class="mb-2">
            Confirm New Password <span class="text-danger">*</span>
          </label>
          <div class="mb-3">
            <div class="position-relative">
              <img
                :src="icons.vectorPassword"
                alt="Password Icon"
                class="position-absolute ms-3 email"
              >

              <img
                v-if="showConfirmPassword"
                :src="icons.vectorEye"
                alt="Toggle password visibility icon"
                class="position-absolute eye"
                @click="showConfirmPassword = !showConfirmPassword"
              >
              <img
                v-else
                :src="icons.vectorEyeOff"
                alt="Toggle password visibility icon"
                class="position-absolute eye-off"
                @click="showConfirmPassword = !showConfirmPassword"
              >

              <input
                v-model="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                class="form-control ps-5 shadow-none"
                placeholder="Confirm New Password"
                :class="{
                  'is-invalid':
                    v$.confirmPassword.$invalid && v$.confirmPassword.$dirty,
                }"
                @blur="checkPasswordsValidity"
              >
            </div>
            <template
              v-if="v$.confirmPassword.$invalid && v$.confirmPassword.$dirty"
            >
              <small
                v-if="v$.confirmPassword.sameAs.$invalid"
                class="invalid-feedback"
              >
                The passwords don't match
              </small>
            </template>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn-primary submit-button mb-3"
            :disabled="submitting"
          >
            <submission-spinner v-if="submitting" />
            Save
          </button>
        </div>
      </template>

      <p
        v-if="!loading"
        class="back-to-login"
      >
        Back to
        <router-link
          :to="{ name: 'Login' }"
          class="text-decoration-underline"
        >
          Log In
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import vectorPassword from "@/assets/img/icons/symbols_password.svg";
import vectorEye from "@/assets/img/icons/symbols_eye.svg";
import vectorEyeOff from "@/assets/img/icons/symbols_eye_off.svg";
import FrameSuccess from "@/assets/img/icons/FrameSuccess.svg";

import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import errorMessages from "@/utils/error-messages";
import api from "@/services/api";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ResetPaswordPage",

  components: {
    LoaderSpinner,
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      vectorPassword,
      vectorEye,
      vectorEyeOff,
      FrameSuccess
    },
    showNewPassword: false,
    showConfirmPassword: false,
    token: null,
    newPassword: null,
    confirmPassword: null,
    email: null,
    loading: false,
    submitting: false,
    canResetPassword: false,
    passwordReset: false,
    errors: {
      passwordErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      newPassword: {
        required,
        minLength: minLength(6),
      },

      confirmPassword: {
        sameAs: sameAs(this.newPassword),
      },
    };
  },

  created() {
    this.loading = true;
    const query = this.$route.query;

    if (!query.token) {
      this.canResetPassword = false;
      this.loading = false;
      this.errors.serverSideErrorMessage =
        "This link has already expired or is invalid.";
      return;
    }

    this.getUserByResetToken(query.token);
  },

  methods: {
    ...validationHelpers,

    checkPasswordsValidity() {
      this.v$.newPassword.$touch();
      this.v$.confirmPassword.$touch();
    },

    async resetPassword() {
      if (this.submitting) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }
      this.submitting = true;
      const formData = {
        email: this.email,
        password: this.newPassword,
        token: this.$route.query.token,
      };

      try {
        await api.resetPassword(formData);
        this.clearForm();
        this.clearErrorsMessages();
        this.passwordReset = true;
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.submitting = false;
      }
    },

    async getUserByResetToken(token) {
      try {
        const response = await api.getUserByResetToken(token, (data) => {
          this.token = data;
        });
        this.email = response.data.user;
        this.canResetPassword = true;
      } catch (err) {
        this.errors.serverSideErrorMessage =
          "This link has already expired or is invalid.";
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    clearForm() {
      this.newPassword = null;
      this.confirmPassword = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 400) {
          for (const error in err.response.data) {
            if (error == "password") {
              this.errors.passwordErrorMessage = err.response.data[error][0];
            }
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
