<template>
  <div class="autocomplete-section">
    <div class="autocomplete">
      <div class="d-flex">
        <input
          id="autocomplete"
          v-model="searchTerm"
          autocomplete="off"
          class="w-100 shadow-none"
          type="email"
          placeholder="Email"
          @blur="outSideInput"
          @click="search"
          @input="search"
          @keydown.enter.prevent="selectItem()"
        >
      </div>
    </div>
    <small
      v-if="errorEmail"
      class="text-danger"
    >{{ errorEmail }}</small>
    <ul
      v-else-if="isFocus"
      class="mt-0 invited-list"
    >
      <invited-subcontractor-item
        v-for="provider in subcontractor"
        :key="provider.id"
        :data="provider"
      />
    </ul>
    <ul
      v-else-if="searchTerm && searchResults"
      class="mt-0 invited-list"
    >
      <template v-if="searchElement(searchResults)">
        <bar-spinner v-if="loading" />

        <autocomplete-provider-item
          v-for="(searchResult, index) in searchResults"
          v-else
          :key="index"
          :data="searchResult"
          @onSelectItem="selectItem"
        />
      </template>

      <template v-else>
        <div
          class=" p-2"
          @mousedown="selectItem(searchResults[0])"
        >
          <bar-spinner v-if="loading" />

          <template v-else>
            <span class="mx-auto d-flex">{{ searchResults[0]?.email }}</span>
            <small v-if="searchResults[0]?.have_invitation">Already invited</small>
          </template>
        </div>
      </template>
    </ul>
    <div>
      <ul
        v-if="selectedItems.length !== 0"
        class="element px-3 mb-0"
      >
        <li
          v-for="(item, index) in selectedItems"
          :key="index"
          class="list-unstyled col-md-4 position-relative me-2 d-flex justify-content-between w-100 p-0"
        >
          <div class="email">
            <template v-if="item?.user">
              <span> {{ item.user.email }} </span>
            </template>
            <template v-else>
              <span> {{ item }}</span>
            </template>
          </div>
          <div>
            <img
              :src="icons.removeVectorIcon"
              alt="remove"
              
              @click="removeItem(index)"
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import InvitedSubcontractorItem from "@/components/provider/jobs/InvitedSubcontractorItem";

import api from "@/services/api";
import BarSpinner from "@/components/BarSpinner.vue";
import AutocompleteProviderItem from "@/components/customer/jobs/AutocompleteProviderItem.vue";
import removeVectorIcon from "@/assets/img/icons/poubell-red.svg";

export default {
  name: "AppAutocomplete",
  components: {
    BarSpinner,
    AutocompleteProviderItem,
    InvitedSubcontractorItem,
  },
  props: {
    isSend: {
      type: Boolean,
      required: true,
      default: false,
    },

    jobId: {
      type: String,
      required: true,
      default: null,
    },
  },

  setup(props, { emit }) {
    const searchTerm = ref();
    let emailNoAccount = ref("");
    const searchResults = ref([]);
    const selectedItems = ref([]);
    let loading = ref(false);
    let isFocus = ref(false);

    let errorEmail = ref();
    let email = [];
    let subcontractor = ref([]);
    let isSelect = false;

    async function search() {
      errorEmail.value = "";
      emailNoAccount.value = "";
      loading.value = true;
      const dataSend = {
        job: props.jobId || null,
        email: searchTerm.value,
      };
      if (searchTerm.value) {
        isFocus.value = false;
        try {
          const res = await api.verifyprovider(dataSend);
          searchResults.value = [];
          for (let i = 0; i < res.data.length; i++) {
            searchResults.value.push(res.data[i]);
          }
          if (res.data[0].is_provider === false) {
            setTimeout(() => {
              setTimeout(() => {
                loading.value = false;
              }, 5000);
            }, 0);
            res.data[0].email = searchTerm.value;
          } else {
            searchElement(searchResults.value);
            loading.value = false;
          }
        } catch (error) {
          loading.value = false;
        }
      } else {
        api
          .getInvitedProvider(props.jobId)
          .then((res) => {
            subcontractor.value = res.data;
            isFocus.value = true;
          })
          .catch((error) => {});
      }
    }

    function outSideInput() {
      isFocus.value = false;
    }

    function selectItem(searchResults) {
      if (searchResults.have_invitation === true) {
        return;
      }
      if (
        email.includes(searchResults?.provider?.user.email) ||
        email.includes(searchResults.email)
      ) {
        searchTerm.value = "";
        searchResults.value = [];
        return;
      }
      if (searchResults.is_provider == false) {
        if (!/^\S+@\S+\.\S+$/.test(searchResults.email)) {
          errorEmail.value = "Please enter a valid email address.";
          return;
        }
        selectedItems.value.push(searchResults.email);
        email.push(searchResults.email);
        emit("email", email);
      } else {
        selectedItems.value.push(searchResults.provider);
        email.push(searchResults.provider.user.email);
        emit("email", email);
        searchTerm.value = "";
        searchResults.value = [];
      }
      isSelect = true;
      searchTerm.value = null;
    }

    function removeItem(index) {
      selectedItems.value.splice(index, 1);
      email.splice(index, 1);
      emit("email", email);
    }

    function searchElement(searchResults) {
      const index = searchResults.findIndex(
        (elmt) => elmt.is_provider === true
      );
      if (index !== -1) {
        return searchResults[index];
      } else {
        return;
      }
    }
    
    return {
      searchTerm,
      searchResults,
      selectedItems,
      search,
      selectItem,
      removeItem,
      errorEmail,
      loading,
      isSelect,
      outSideInput,
      emailNoAccount,
      searchElement,
      subcontractor,
      isFocus,
    };
  },

  data() {
    return {
      icons: {
        removeVectorIcon,
      },
    };
  },

  watch: {
    isSend(value) {
      if (value == true) {
        this.selectedItems = ref([]);
        this.email = [];
        this.emailNoAccount = [];
      } else {
      }
    },
  },
};
</script>

<style scoped>
.invited-list {
  overflow-y: scroll;
  max-height: 14rem;
  padding-left: 0;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}
.invited-list::-webkit-scrollbar {
  display: none;
}

li {
  list-style-type: none;
  padding: 0.5rem;
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: #209516;
  border-color: #dbdbdb;
  border: none;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding: 0rem;
  border-radius: 4px;
  text-align: center;
  transition: all 0.35s ease-in-out;
}
.autocomplete {
  background: #ffffff;
  border: 1px solid rgba(180, 188, 196, 0.6);
  border-radius: 4px;
  /* border: 1px solid #dbdbdb; */
}
input:focus,
input {
  border: 1px solid #fff;
  outline: none;
}
/* input {
  padding: 12px 40px 11px;
  background: #ffffff;
  /* border: 1px solid rgba(180, 188, 196, 0.6); 
  border-radius: 4px;
    outline: none;

} */
input[type="email"] {
  color: #16181a;
  border: 1px solid #fff;
  transition: all 0.35s ease-in-out;
  padding: 12px 40px 10px 15px !important;
}
.element {
  padding-left: 10px;
  box-shadow: none;
  border: none;
  padding: 5px;
}
.element .email {
  color: #000;
  overflow: hidden;
  width: max-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 1rem;
  padding: 0.3rem 1rem 0.3rem;
}
.full-name {
  font-weight: 600;
}
.remove-button {
  position: absolute;
  top: -14%;
  right: 5%;
  padding: 0;
  background-color: transparent;
}
.btn-primary {
  border-radius: 0px !important;
}
.autocomplete-section .element li {
  background-color: #fff;
}
</style>
