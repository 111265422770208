<template>
  <div class="row">
    <div>
      <div class="provider-details ">
        <loader-spinner v-if="loading" />

        <div v-else>
          <div v-if="!loading && proposal">
            <div class="page-header d-flex justify-content-between">
              <nav aria-label="breadcrumb title-part">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item title">
                    <router-link
                      :to="{
                        name: 'CustomerJobDetails',
                        params: { id: $route.params.id },
                      }"
                    >
                      Proposals
                    </router-link>
                  </li>

                  <li
                    v-if="proposal"
                    class="breadcrumb-item active title-content"
                    aria-current="page"
                  >
                    {{ proposal.owner?.user?.full_name }}
                  </li>
                </ol>
              </nav>

              <div class="actions d-xxl-flex justify-content-xxl-end me-3">
                <div class="d-xxl-flex justify-content-xxl-end content-view">
                  <button
                    class="btn-favorite me-3"
                    :class="{ added: proposal.owner_liked }"
                    :disabled="addingToFavorites || removingFromFavorites"
                    @click="handleToggleFavorite"
                  >
                    <submission-spinner
                      v-if="addingToFavorites || removingFromFavorites"
                      :color="'#209516'"
                    />
                    <img
                      v-else
                      :src="icons.heartVectorIcon"
                      alt="Favorite Icon"
                    >
                  </button>

                  <template v-if="proposal.status === jobProposalStatus.SENDED">
                    <button
                      class="btn-primary btn-accept"
                      :disabled="acceptingProposal"
                      @click="confirmClaim(actions.ACCEPT_PROPOSAL)"
                    >
                      <submission-spinner v-if="acceptingProposal" />
                      Accept
                    </button>

                    <button
                      class="btn-danger btn-decline"
                      :disabled="rejectingProposal"
                      @click="confirmClaim(actions.REJECT_PROPOSAL)"
                    >
                      <submission-spinner v-if="rejectingProposal" />
                      Decline
                    </button>
                  </template>

                  <template v-else>
                    <span
                      v-if="getProposalStatusStyle(proposal.status).class"
                      class="proposal-status"
                      :class="getProposalStatusStyle(proposal.status).class"
                    >
                      <div
                        v-if="proposal.status === jobProposalStatus.ACCEPTED"
                        class="circle me-2"
                      />
                      <span class="mb-0">{{
                        formatProposalStatus(proposal.status)
                      }}</span>
                    </span>
                  </template>
                </div>

                <span class="dropdown ms-3 d-none">
                  <a
                    class="dropdown-toggle item"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      :src="icons.dotVectorIcon"
                      alt="Mobile Menu Icon"
                    >
                  </a>

                  <ul class="dropdown-menu">
                    <template v-if="proposal.status === jobProposalStatus.SENDED">
                      <li>
                        <a
                          class="dropdown-item"
                          @click.prevent="confirmClaim(actions.ACCEPT_PROPOSAL)"
                        >Accept</a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item"
                          @click.prevent="confirmClaim(actions.REJECT_PROPOSAL)"
                        >Decline</a>
                      </li>
                    </template>

                    <li v-if="proposal.owner_liked">
                      <a
                        class="dropdown-item"
                        :disabled="removingFromFavorites"
                        @click.prevent="
                          confirmClaim(actions.REMOVE_FROM_FAVORITES)
                        "
                      >Remove provider from favorites</a>
                    </li>

                    <li v-else>
                      <a
                        class="dropdown-item"
                        :disabled="addingToFavorites"
                        @click.prevent="confirmClaim(actions.ADD_TO_FAVORITES)"
                      >Save provider</a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>

            <div class="page-content">
              <div class="row mt-4">
                <div class="col-lg-8">
                  <alerte-danger
                    :head-error-message="errors.serverSideErrorMessage"
                    @updateContent="errors.serverSideErrorMessage = $event"
                  />
                </div>
              </div>

              <div
                v-if="proposal?.owner?.user"
                class="p-lg-4 provider-infos pm"
              >
                <div class="proposal">
                  <div class="d-flex justify-content-between mb-3">
                    <div>
                      <div class="d-flex">
                        <profile-avatar
                          :url="proposal.owner.user.photo?.url"
                          :fullname="proposal.owner.user.full_name"
                          :color="proposal.owner.user.profile_color"
                          :background="proposal.owner.user.profile_background"
                        />

                        <div class="ms-2">
                          <router-link
                            :to="{ 
                              name: 'ProviderPublicProfile',
                              params: {
                                username: `@${proposal.owner.user.username}`,
                              },
                            }"
                            class="provider-name"
                          >
                            <strong class="provider-name">
                              {{ proposal.owner.user.full_name }}
                            </strong>
                          </router-link>

                          <div>
                            <p class="proposal-date">
                              Applied on
                              {{
                                formatDate(proposal.created_at, "MMMM Do, YYYY")
                              }}
                            </p>

                            <div class="d-flex align-items-center">
                              <span>Rate:</span>
                              <rating-stars
                                :rating="proposal.owner.rating ?? 0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ps-6">
                      <a
                        v-if="proposal.cv"
                        :href="proposal.cv.url"
                        download
                        target="_blank"
                        class="btn-secondary download-btn mt-1"
                      >
                        View CV
                        <img
                          :src="icons.downloadVectorIcon"
                          :alt="proposal.cv.original_name"
                          class="ms-2"
                        >
                      </a>
                    </div>
                  </div>

                  <p>{{ proposal.message }}</p>
                </div>
              </div>

              <div
                class="proposal-details p-md-4 pm"
                :class="{
                  'border-bottom-0':
                    !proposal.sqresponse || proposal.sqresponse?.length === 0,
                }"
              >
                <p class="title">
                  Proposal Details :
                </p>

                <div class="row">
                  <div class="col-md-6 col-xl-4">
                    <div class="d-flex">
                      <p class="label">
                        Proposed Bid :&nbsp;
                      </p>
                      <p>{{ formatPrice(proposal.amount) }}</p>
                    </div>
                  </div>

                  <div
                    v-if="proposal.work_start_date"
                    class="col-md-6 col-xl-4"
                  >
                    <div class="d-flex">
                      <p class="label">
                        Work Start Date :&nbsp;
                      </p>

                      <p>
                        {{
                          formatDate(proposal.work_start_date, "MMMM Do, YYYY")
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="proposal.work_end_date"
                    class="col-md-6 col-xl-4"
                  >
                    <div class="d-flex">
                      <p class="label">
                        Work End Date :&nbsp;
                      </p>
                      <p>
                        {{
                          formatDate(proposal.work_end_date, "MMMM Do, YYYY")
                        }}
                      </p>
                    </div>
                  </div>

                  <div v-if="proposal.file">
                    <p class="label mt-3">
                      Your document (CV, Insurance, COI, etc)
                    </p>

                    <div class="document mb-2 col-lg-6">
                      <a
                        :href="proposal.file.url"
                        target="_target"
                      >
                        <img
                          v-if="
                            proposal.file.type === mediaTypesConstants.IMAGE
                          "
                          :src="icons.imageUploadedIcon"
                          alt="File Uploaded"
                          class="file-icon me-1"
                        >
                        <img
                          v-else
                          :src="icons.documentUploadedIcon"
                          alt="File Uploaded"
                          class="file-icon me-1"
                        >
                        {{ getFileName(proposal.file.original_name) }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="proposal.sqresponse?.length > 0"
                class="screening p-md-4 pm"
                :class="{ 'border-bottom-0': true }"
              >
                <p class="title">
                  Screening Questions :
                </p>

                <ol class="questions">
                  <li
                    v-for="screeningQuestion in proposal.sqresponse"
                    :key="screeningQuestion.id"
                    class="question"
                  >
                    {{ screeningQuestion.question?.name }}

                    <p
                      v-if="
                        screeningQuestion.question.type ===
                          jobScreeningQuestionTypes.SHORT ||
                          screeningQuestion.question.type ===
                          jobScreeningQuestionTypes.LONG
                      "
                    >
                      {{ screeningQuestion.response }}
                    </p>

                    <ul v-else>
                      <li
                        v-for="selectedResponse in screeningQuestion.response_selected"
                        :key="selectedResponse.id"
                        :class="{
                          'text-success': selectedResponse.right,
                          'text-danger': !selectedResponse.right,
                        }"
                      >
                        {{ selectedResponse.response }}
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              <!-- $store.getters['user/currentPlan']?.can_access_communication != null -->

              <div
                v-if="
                  proposal.status === jobProposalStatus.ACCEPTED &&
                    !fetchingProposalRoomId
                "
                class="interview p-md-4 pm"
              >
                <p class="title">
                  Project communication :
                </p>

                <interview-chat
                  :participants="participants"
                  :can-init-chat="true"
                  :provider="proposal.owner"
                  :proposal-room-id="proposalRoomId"
                  :customer="proposal.job?.owner"
                  :proposal-id="$route.params.proposalId"
                  :send-emojis="false"
                />
              </div>
            </div>
          </div>
        </div>

        <confirm-modal
          v-if="modalConfigs.showConfirmModal"
          ref="confirmModal"
          :ok-button-title="modalConfigs.confirmTitle"
          :message="modalConfigs.confirmMessage"
          @onConfirm="onModalConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import RatingStars from "@/components/RatingStars.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import InterviewChat from "@/components/InterviewChat.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";

import heartVectorIcon from "@/assets/img/icons/heart.svg";
import downloadVectorIcon from "@/assets/img/icons/download.svg";
import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import JobHelper from "@/utils/job";
import FileUtils from "@/utils/file";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";

import {
  JobProposalStatus,
  JobScreeningQuestionTypes,
} from "@/configs/constants/jobs";
import { MediaTypesConstants } from "@/configs/constants";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CustomerJobProposalPage",

  components: {
    ProfileAvatar,
    ConfirmModal,
    RatingStars,
    InterviewChat,
    SubmissionSpinner,
    LoaderSpinner,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      heartVectorIcon,
      downloadVectorIcon,
      dotVectorIcon,
      imageUploadedIcon,
      documentUploadedIcon,
    },
    loading: false,
    proposal: null,
    addingToFavorites: false,
    removingFromFavorites: false,
    acceptingProposal: false,
    rejectingProposal: false,
    proposalRoomId: null,
    participants: [],
    fetchingProposalRoomId: false,
    actions: {
      ADD_TO_FAVORITES: "add-to-favorites",
      REMOVE_FROM_FAVORITES: "remove-from-favorites",
      ACCEPT_PROPOSAL: "accept-proposal",
      REJECT_PROPOSAL: "reject-proposal",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    jobProposalStatus() {
      return JobProposalStatus;
    },

    jobScreeningQuestionTypes() {
      return JobScreeningQuestionTypes;
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  mounted() {
    this.fetchProposal();
    this.fetchRoomByProposalId();
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    confirmClaim(action) {
      switch (action) {
        case this.actions.ADD_TO_FAVORITES:
          this.modalConfigs.confirmTitle = "Add";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to save this provider's profile ?";
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.modalConfigs.confirmTitle = "Remove";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to remove this provider's profile from favorites ?";
          break;

        case this.actions.ACCEPT_PROPOSAL:
          this.modalConfigs.confirmTitle = "Accept proposal";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to accept this proposal ? This action is irreversible.";
          break;

        case this.actions.REJECT_PROPOSAL:
          this.modalConfigs.confirmTitle = "Reject proposal";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to reject this proposal ? This action is irreversible.";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.currentAction) {
        case this.actions.ADD_TO_FAVORITES:
          this.addProviderToFavorites();
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.removeProviderFromFavorites();
          break;

        case this.actions.ACCEPT_PROPOSAL:
          this.acceptProviderProposal();
          break;

        case this.actions.REJECT_PROPOSAL:
          this.rejectProviderProposal();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    handleToggleFavorite() {
      if (this.proposal.owner_liked) {
        this.confirmClaim(this.actions.REMOVE_FROM_FAVORITES);
      } else {
        this.confirmClaim(this.actions.ADD_TO_FAVORITES);
      }
    },

    async fetchProposal() {
      try {
        this.loading = true;
        const response = await api.fetchProposal(this.$route.params.proposalId);
        this.proposal = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchRoomByProposalId() {
      try {
        this.fetchingProposalRoomId = true;

        const response = await api.fetchRoomByProposalId(
          this.$route.params.proposalId
        );
        this.proposalRoomId = response.data.data?.id || "";
        this.participants = response.data.data?.participants || [];
      } catch (_) {
      } finally {
        this.fetchingProposalRoomId = false;
      }
    },

    async addProviderToFavorites() {
      try {
        this.addingToFavorites = true;
        this.clearErrorsMessages();

        await api.addProviderToFavorites({
          provider: this.proposal.owner.id,
        });
        this.proposal.owner_liked = true;

        toast.success(`Provider's profile successfully added to favorites.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.addingToFavorites = false;
      }
    },

    async removeProviderFromFavorites() {
      try {
        this.removingFromFavorites = true;
        this.clearErrorsMessages();

        await api.removeProviderFromFavorites(this.proposal.provider_saved_id);
        this.proposal.owner_liked = false;

        toast.success(
          `Provider's profile successfully removed from favorites.`
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    async acceptProviderProposal() {
      try {
        this.acceptingProposal = true;
        this.clearErrorsMessages();

        await api.updateProposal(this.$route.params.proposalId, {
          status: JobProposalStatus.ACCEPTED,
        });
        this.proposal.status = JobProposalStatus.ACCEPTED;

        toast.success(`Provider's job application successfully accepted.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.acceptingProposal = false;
      }
    },

    async rejectProviderProposal() {
      try {
        this.rejectingProposal = true;
        this.clearErrorsMessages();

        await api.updateProposal(this.$route.params.proposalId, {
          status: JobProposalStatus.REJECTED,
        });
        this.proposal.status = JobProposalStatus.REJECTED;

        toast.success(`Provider's job application successfully rejected.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.rejectingProposal = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (
        err.response &&
        err.response.status !== 400 &&
        err.response.status !== 404
      ) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.btn-decline {
  padding: 0.6rem 0.85rem;
}
.btn-accept {
  padding: 0.6rem 0.85rem;
  margin-right: 0.75rem;
}
.download-btn {
  margin-bottom: 0.67rem;
}
@media (max-width: 1100.98px) {
  .actions .content-view {
    display: flex;
    justify-content: space-between !important;
    display: none !important;
  }
  .actions .dropdown {
    display: block !important;
  }
}
</style>
