<template>
  <section class="chat">
    <div
      id="chat2"
    >
      <message-display
        :messages="messages"
        :can-init-chat="canInitChat"
        :scroll-bottom="scrollBottom"
        :load-more-messages="loadMoreMessages"
        :stop-fetching-last-messages="stopFetchingLastMessages"
        @onDownloadFile="onDownloadFile"
      />

      <message-sender
        :send-images="sendImages"
        :send-emojis="sendEmojis"
        @onType="onType"
        @onImageSelected="onImageSelected"
        @onSubmitMessage="onSubmitMessage"
      />
    </div>
  </section>
</template>

<script>
import MessageSender from "@/components/chat/MessageSender.vue";
import MessageDisplay from "@/components/chat/MessageDisplay.vue";

export default {
  name: "ChatInitiator",

  components: {
    MessageSender,
    MessageDisplay,
  },

  props: {
    messages: {
      type: Array,
      required: true,
      default: () => [],
    },

    sendImages: {
      type: Boolean,
      required: false,
      default: true,
    },

    sendEmojis: {
      type: Boolean,
      required: false,
      default: true,
    },

    canInitChat: {
      type: Boolean,
      required: false,
      default: true,
    },

    scrollBottom: {
      type: Object,
      required: false,
      default: () => {
        return {
          messageSent: true,
          messageReceived: false
        };
      }
    },

    loadMoreMessages: {
      type: Function,
      required: false,
      default: null
    },

    stopFetchingLastMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onSubmitMessage(message) {
      this.$emit("onSubmitMessage", message);
    },

    onImageSelected(message) {
      this.$emit("onImageSelected", message);
    },

    onType(event) {
      this.$emit("onType", event);
    },

    onDownloadFile(message) {
      this.$emit("onDownloadFile", message);
    },
  },
};
</script>
