import axios, { AxiosRequestConfig } from "axios";
import * as qs from "qs";

import store from "@/store";
import router from "@/router";
import { UserScopes } from "@/configs/constants/users";

export const client = axios.create();

export class Request {
  config: AxiosRequestConfig;
  static baseURL: string;

  constructor(base = Request.baseURL) {
    let source = axios.CancelToken.source();
    this.config = {
      url: base,
      headers: {
        "Content-type": "application/json",
      },
      cancelToken: source.token
    };

    if (store.getters["token/isAuthenticated"]) {
      const token = store.getters["token/token"];
      this.config.headers["Authorization"] = `Bearer ${token.accessToken}`;
    }

    try {
      client.interceptors.response.use(
        (response) => response,
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401 && error.config?.url) {
            const mustLogout =
              !error.config.url.includes("login") &&
              !error.config.url.includes("register");

            if (mustLogout) {
              store.dispatch("token/logout");
              router.push({ name: "Login" });
            }
          }
          return Promise.reject(error);
        }
      );
    } catch (_) {
    }

    return this;
  }

  contentType(type) {
    this.config.headers["Content-type"] = type;
    return this;
  }

  responseType(type) {
    this.config["responseType"] = type;
    return this;
  }

  append(url) {
    this.config.url = this.config.url + url;
    return this;
  }

  method(name) {
    this.config.method = name;
    return this;
  }

  params(params) {
    this.config.params = params;
    return this;
  }

  setData(data) {
    this.config.data = data;

    if (
      this.config.headers["Content-type"] ===
      "application/x-www-form-urlencoded"
    ) {
      this.config.data = qs.stringify(data);
    }

    if (this.config.headers["Content-type"] === "multipart/form-data") {
      const formData = new FormData();
      for (let key in data) {
        const fieldValue = data[key]
        if (Array.isArray(fieldValue)) {
          fieldValue.forEach((x) => {
            if (x) {
              formData.append(`${key}[]`, x)
            } else {
              formData.append(`${key}[]`, '')
            }
          })
        } else {
          if (data[key]) {
            formData.append(key, data[key]);
          } else {
            formData.append(key, '');
          }
        }
      }
      this.config.data = formData;
    }

    return this;
  }

  then(...args) {
    const connectedUser = store.getters["user/user"];
    if (
      (connectedUser && connectedUser?.scope === UserScopes.CUSTOMER) ||
      connectedUser?.scope === UserScopes.PROVIDER
    ) {
      this.config.params = {
        ...this.config.params,
        scope: connectedUser.scope,
      };
    }

    return client(this.config).then(...args);
  }
}

Request.baseURL = process.env.VUE_APP_REST_ENDPOINT;
