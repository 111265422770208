<template>
  <span class="dropdown fix-mobile-menu">
    <a
      class="dropdown-toggle item p-2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        :src="icons.dotVectorIcon"
        alt="Menu Icon"
      >
    </a>

    <ul class="dropdown-menu">
      <li>
        <router-link
          :to="{ name: 'CustomerProfileEdit' }"
          class="dropdown-item"
        >Edit Profile</router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'CustomerChangePassword' }"
          class="dropdown-item"
        >Change Password</router-link>
      </li>
      <li>
        <div
          class="dropdown-item text-primary cursor-pointer"
          @click="switchAccount"
        >Switch to {{ currentUser?.scope == "CUSTOMER" ? "Provider" : "Customer" }} Account</div>
      </li>

      <li>
        <router-link
          :to="{ name: 'CustomerDeleteAccount' }"
          class="dropdown-item text-danger"
        >Delete Account</router-link>
      </li>
    </ul>
  </span>
</template>

<script>
import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import api from '@/services/api';
import { ProviderStatus, UserScopes } from "@/configs/constants/users";

export default {
  name: "CustomerSettingsMenu",

  data: () => ({
    icons: {
      dotVectorIcon,
    },
    loading: false
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
  },
  mounted(){
  },
  methods: {

    async switchAccount() {
      this.loading = true;

      try {
        await api.switchAccount({
          scope: this.currentUser.scope == "CUSTOMER" ? "PROVIDER" : "CUSTOMER"
          }, (data) => {
           if (data.scope === UserScopes.PROVIDER) {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.CUSTOMER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            if (data.status === ProviderStatus.NEED_COMPLETE_PROFILE) {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderProfile",
                }
              );
            } else {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderDashboard",
                }
              );
            }
          } else {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.PROVIDER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            this.$router.push(
              this.$route.query.redirectFrom || { name: "CustomerDashboard" }
            );
          }
        });
      } catch (err) {
        console.error(err);
        // this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
