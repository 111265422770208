<template>
  <div>
    <div
      class="offcanvas offcanvas-end fade p-3"
      :class="{ 'd-block': showCanvas, show: fadeCanvas }"
    >
      <span
        class="close"
        @click="closeCanvas"
      >
        <img :src="image.arrowLeft">
      </span>

      <div class="offcanvas-body">
        <slot />
      </div>
    </div>
    <div
      :class="showCanvas && 'offcanvas-backdrop fade show'"
      @click.stop="closeCanvas"
    />
  </div>
</template>

<script>
import arrowLeft from "@/assets/img/icons/arrowLeft.svg";

export default {
  name: "RightCanvas",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    body: {
      type: HTMLElement,
      required: false,
      default: null,
    },
  },

  data: () => ({
    showCanvas: false,
    fadeCanvas: false,
    image: {
      arrowLeft,
    },
  }),

  methods: {
    openCanvas() {
      this.showCanvas = true;
      setTimeout(() => {
        this.fadeCanvas = true;
      }, 200);
    },

    closeCanvas() {
      this.fadeCanvas = false;
      setTimeout(() => {
        this.showCanvas = false;
      }, 200);
    },
  },
};
</script>

<style scoped>
.offcanvas-end {
  width: 75%;
  top: 0.5rem;
  overflow-y: scroll;
  border-radius: 0.5rem 0 0 0.5rem;
}

.close {
  position: sticky;
  top: 0;
}
.close img {
  width: 1.5rem;
}

@media screen and (max-width: 720px) {
  .offcanvas-end {
    width: 100%;
  }
}
</style>
