<template>
  <div class="dashboard-provider">
    <list-header
      title="Estimates"
      :add="true"
      :hide="!access.canAccessEstimates()"
      :options="headerOptions"
      @create="create"
      @update:options="onListHeaderUpdate"
    />

    <div
      class="row mb-3"
      :class="{'filter-blur': !access.canAccessEstimates()}"
    >
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg-3"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ !access.canAccessEstimates() ? 0 : statistic.count }}</h3>

            <p>{{ !access.canAccessEstimates() ? 'Locked' : statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table"
            :class="{'filter-blur': !access.canAccessEstimates()}"
          >
            <head-table
              :list="thead"
              :hide="!access.canAccessEstimates()"
            />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="text-primary cursor-pointer"
                    @click="detail(item)"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span v-if="item.send">
                    {{ formatDate(item.send, "MMMM Do, YYYY h:mm a") }}
                  </span>
                </td>

                <td>
                  {{ statusToUI(item.status) }}
                </td>

                <td>
                  <submission-spinner
                    v-if="
                      (deletingEstimate || updatingEstimate || sendingEstimate) &&
                        selectedEstimateId === item.id
                    "
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="getActions(item.status)"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchEstimates({ page: $event, search })"
            @onPrev="fetchEstimates({ page: currentPage - 1 || 1, search })"
            @onNext="fetchEstimates({ page: currentPage + 1, search })"
          />
        </div>
      </div>
    </div>
    
    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import access from "@/services/access";
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import AppPagination from "@/components/AppPagination.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import { EstimateStatus, GlobalConstants } from "@/configs/constants";
import errorMessages from '@/utils/error-messages';

export default {
  name: "ProviderEstimatesPage",

  components: {
    ListHeader,
    HeadTable,
    OptionAction,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
  },

  mixins: [ListMixin(api.fetchEstimates, true)],

  data: () => ({
    access: access,
    headerOptions: {
      title: "Status",
      list: [
        {
          title: "Status",
        },
        {
          title: "Active",
          value: "active",
        },
        {
          title: "Unsent",
          value: "unsent",
        },
      ],
    },
    thead: ["Title", "Send Date", "Status", ""],
    status: null,
    search: null,
    fetching: false,
    sendingEstimate: false,
    deletingEstimate: false,
    selectedEstimateId: null,
    updatingEstimate: false,
    statistics: {
      unsent: {
        title: "Unsent",
        value: "unsent",
        count: 0,
      },
      pending: {
        title: "Pending",
        value: "pending",
        count: 0,
      },
      approved: {
        title: "Approved",
        value: "approved",
        count: 0,
      },
      dinied: {
        title: "Denied",
        value: "denied",
        count: 0,
      },
      won: {
        title: "Won",
        value: "won",
        count: 0,
      },
      archived: {
        title: "Archived",
        value: "archived",
        count: 0,
      },
    },
    actions: {
      SEND: "send-estimate",
      CANCEL: "cancel-estimate",
      DELETE: "delete-estimate",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },
  watch: {
    listData() {
      this.fetching = false;
    },
  },

  mounted() {
    if (this.$route.params?.successToastMessage) {
      toast.success(this.$route.params.successToastMessage);
    }
    if (!access.canAccessEstimates()) {
      
    } else {
      this.fetchEstimatesStatistics();
      this.fetchEstimates({ page: Number(this.$route.query.page) || 1 });
    }

  },

  methods: {
    ...DateHelpers,
    ...statusToUI,

    getActions(status) {
      let items = [
        {
          title: "Details",
          value: "details",
        },
      ];
      if (this.canEditEstimate(status)) {
        items.push({
          title: "Edit",
          value: "edit",
        });
      }
      if (this.canSendEstimate(status)) {
        items.push({
          title: "Send",
          value: "send",
        });
      }
      if (this.canCancelEstimate(status)) {
        items.push({
          title: "Cancel",
          value: "cancel",
        });
      }
      if (this.canDeleteEstimate(status)) {
        items.push({
          title: "Delete",
          value: "delete",
        });
      }

      return items;
    },

    canEditEstimate(status) {
      if (!status) return false;
      return [EstimateStatus.UNSENT, EstimateStatus.DENIED].includes(status);
    },

    canDeleteEstimate(status) {
      if (!status) return false;
      return status === EstimateStatus.UNSENT;
    },

    canSendEstimate(status) {
      if (!status) return false;
      return [EstimateStatus.UNSENT, EstimateStatus.PENDING].includes(status);
    },

    canCancelEstimate(status) {
      if (!status) return false;
      return status === EstimateStatus.PENDING;
    },

    fetchEstimates(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async fetchEstimatesStatistics() {
      try {
        const response = await api.fetchEstimatesSummary();
        const statistics = response.data;
        for (const key in this.statistics) {
          if (Object.hasOwnProperty.call(this.statistics, key)) {
            const element = this.statistics[key];
            this.statistics[key] = {
              ...element,
              count: statistics[element.value] ?? 0,
            };
          }
        }
      } catch (_) {}
    },

    onListHeaderUpdate(event) {
      this.fetchEstimates({ page: 1, status: event.value ?? undefined });
      this.headerOptions.title = event.title;
      this.status = event.value;
    },

    create() {
      this.$router.push({
        name: "ProviderEstimateCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "ProviderEstimateDetails",
        params: { id: item.id },
      });
    },

    choose(item, event) {
      switch (event) {
        case "details":
          this.detail(item);
          break;

        case "edit":
          this.$router.push({
            name: "ProviderEstimateEdit",
            params: { id: item.id },
          });
          break;

        case "send":
          this.confirmClaim(this.actions.SEND, item);
          break;

        case "cancel":
          this.confirmClaim(this.actions.CANCEL, item);
          break;

        case "delete":
          this.confirmClaim(this.actions.DELETE, item);
          break;
      }
    },

    confirmClaim(action, item) {
      switch (action) {
        case this.actions.SEND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to send this estimate ?";
          break;

        case this.actions.CANCEL:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to cancel this estimate ?";
          break;

        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to delete this estimate ?";
          break;
      }
      this.selectedEstimateId = item.id;
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.SEND:
          this.instantlySendEstimate();
          break;

        case this.actions.CANCEL:
          this.updateEstimatesStatus(
            EstimateStatus.ARCHIVED,
            `Estimate successfully canceled.`
          );
          break;

        case this.actions.DELETE:
          this.deleteEstimate();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    updateEstimatesStatus(status, successMessage) {
      this.updatingEstimate = true;

      api
        .updateEstimatesStatus(this.selectedEstimateId, status)
        .then((_) => {
          const index = this.listData.findIndex(
            (x) => x.id === this.selectedEstimateId
          );
          if (index !== -1) {
            this.listData[index].status = status;
          }
          this.selectedEstimateId = null;
          toast.success(successMessage);
          this.fetchEstimatesStatistics();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.updatingEstimate = false;
        });
    },

    instantlySendEstimate() {
      this.sendingEstimate = true;
      const path = this.$router.resolve({
        name: "SharedEstimateDetails",
        params: {
          id: this.selectedEstimateId,
        },
      })?.path;
      const redirectLink = `${GlobalConstants.BASE_URL + path}`

      api
        .sendEstimate(this.selectedEstimateId, redirectLink)
        .then(({ data }) => {
          const index = this.listData.findIndex(
            (x) => x.id === this.selectedEstimateId
          );
          if (index !== -1) {
            this.listData[index].send = data.data?.send;
            this.listData[index].status = EstimateStatus.PENDING;
          }
          this.selectedEstimateId = null;

          toast.success(`Estimate successfully sent.`);
        })
        .catch((err) => {
          let message = "";
          if (err.response && err.response.status) {
            if (err.response.status === 400) {
              message = err.response.data.error;
            }
          } else {
            message = errorMessages.AN_ERROR_HAS_OCCURED;
          }
          toast.error(message);
        })
        .then(() => {
          this.sendingEstimate = false;
        });
    },

    deleteEstimate() {
      this.deletingEstimate = true;

      api
        .deleteEstimate(this.selectedEstimateId)
        .then((_) => {
          this.selectedEstimateId = null;
          this.fetchEstimates({
            page: Number(this.$route.query.page) || this.currentPage,
          });
          this.fetchEstimatesStatistics();
          toast.success(`Estimate successfully deleted.`);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingEstimate = false;
        });
    },
  },
};
</script>
