<template>
  <div class="job-detail">
    <loader-spinner v-if="loading" />

    <div
      v-else-if="errors.notFoundErrorMessage"
      class="row mt-4"
    >
      <div class="col-lg-8 offset-md-2">
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>
    </div>

    <section
      v-else
      class="content"
    >
      <div class="page-header">
        <div class="d-md-flex title-part mb-0">
          <router-link
            :to="{ name: 'ProviderEstimates' }"
            class="title me-1"
          >
            Estimates
          </router-link>

          <p
            v-if="estimate.title"
            class="title-content"
          >
            / {{ estimate.title }}
          </p>
        </div>

        <div class="d-flex align-items-center">
          <div class="position-proposal-icon me-3">
            <span
              v-if="estimate.title"
              class="proposal-status border-primary cursor-pointer"
              @click="exportToPDF(estimate.title)"
            >
              <submission-spinner
                v-if="downloading"
                :color="'#209516'"
              />
              <img
                v-else
                :src="download"
                alt="Download Icon"
                class="me-2"
              >
              <span class="mb-0 text-primary fw-normal">Download</span>
            </span>
          </div>

          <submission-spinner
            v-if="deletingEstimate || updatingEstimate || sendingEstimate"
            :color="'#209516'"
          />
          <template v-else>
            <div
              v-if="estimate?.status !== estimateStatus.ARCHIVED"
              class="dropdown drop nav-link"
            >
              <a
                class="dropdown-toggle item"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="icons.dotVectorIcon"
                  alt="Menu Icon"
                >
              </a>

              <ul class="dropdown-menu">
                <li
                  v-if="canEditEstimate"
                  class="border-bottom"
                >
                  <router-link
                    :to="{
                      name: 'ProviderEstimateEdit',
                      params: { id: estimate.id },
                    }"
                    class="dropdown-item border-bottom"
                  >
                    Edit
                  </router-link>
                </li>

                <li
                  v-if="canSendEstimate"
                  class="border-bottom"
                >
                  <a
                    class="dropdown-item cursor-pointer"
                    @click.prevent="confirmClaim(actions.SEND)"
                  >Send</a>
                </li>

                <li
                  v-if="canCancelEstimate"
                  class="border-bottom"
                >
                  <a
                    class="dropdown-item cursor-pointer"
                    @click.prevent="confirmClaim(actions.CANCEL)"
                  >Cancel</a>
                </li>

                <li
                  v-if="canDeleteEstimate"
                  class="dropdown-item cursor-pointer"
                  @click.prevent="confirmClaim(actions.DELETE)"
                >
                  Delete
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>

      <div
        id="element-to-convert"
        class="page-content"
      >
        <div
          v-if="provider"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ provider.full_name }}
                </h5>
                <p class="my-0">
                  {{ provider?.location_description }}
                </p>
                <p class="my-0">
                  {{ provider.country }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <h6 class="mb-2 text-secondary">
                  Balance Due
                </h6>
                <h5
                  v-if="estimate.items"
                  class="text-bold mb-0"
                >
                  {{ formatPrice(total) }}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="estimate?.client"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-12 col-lg-5 text-start">
              <h5 class="mb-3 text-primary">
                {{ estimate.client.full_name }}
              </h5>
              <p class="my-0">
                {{ estimate.client.company_name }}
              </p>
              <p class="my-0">
                {{ estimate.client.main_propriety?.location_description }}
              </p>
            </div>

            <div class="col-12 col-lg-7 text-end">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="row text-end">
                    <p class="col-md-6 col-lg-8 text-primary">
                      Status :
                    </p>
                    <p class="col-md-6 col-lg-4">
                      {{ statusToUI(estimate.status) }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="estimate.send"
                  class="col-12"
                >
                  <div class="row text-end">
                    <p class="col-md-6 col-lg-8 text-primary">
                      Send Date :
                    </p>
                    <p class="col-md-6 col-lg-4">
                      {{ formatDate(estimate.send, "MMMM Do, YYYY h:mm a") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 md-3">
          <div class="table-responsive">
            <table class="table">
              <head-table :list="thead" />
              <tbody>
                <tr
                  v-for="(item, index) in estimate.items"
                  :key="index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ item.item }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ formatPrice(item.value) }}
                  </td>
                  <td>
                    {{ formatPrice(item.quantity * item.value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="estimate.items"
          class="col-md-12 my-5 text-end d-flex"
        >
          <div class="col-md-12 align-self-end mb-3">
            <div class="text-end">
              <div class="d-inline-block text-bold">
                <p>Sub Total : &nbsp;</p>
                <p>Tax(8%) : &nbsp;</p>
                <p>Service Fee : &nbsp;</p>
                <p>You will receive : &nbsp;</p>
                <p>Total : &nbsp;</p>
              </div>
              <div class="d-inline-block">
                <p>{{ formatPrice(subTotal) }}</p>
                <p>{{ tax }}</p>
                <p>{{ formatPrice(serviceFee) }}</p>
                <p>{{ formatPrice(willReceive) }}</p>
                <p>{{ formatPrice(total) }}</p>
              </div>
            </div>

            <button
              v-if="!downloading"
              class="btn-primary rounded-0 mt-3"
            >
              Balance Due {{ formatPrice(total) }}
            </button>
          </div>
        </div>

        <div class="col-md-12 text-start">
          <div class="col-md-3 mb-3">
            <h5 class="mb-3 text-primary">
              Note :
            </h5>
            <p class="my-0">
              {{ estimate.message }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import download from "@/assets/img/icons/download.svg";
import errorMessages from "@/utils/error-messages";

import PriceHelpers from "@/utils/price-format";
import html2pdf from "html2pdf.js";
import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import { EstimateStatus } from "@/configs/constants";
import { GlobalConstants } from "@/configs/constants";
import statusToUI from "@/utils/statusFormate";

import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateDetailsPage",

  components: {
    HeadTable,
    LoaderSpinner,
    SubmissionSpinner,
    ConfirmModal,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      dotVectorIcon,
    },
    download,
    showModal: false,
    fadeModal: false,
    loading: false,
    downloading: false,
    listData: [],
    estimate: {},
    job: {},
    provider: {},
    thead: ["#", "Item", "Quantity", "Price", "Total"],
    datasend: {
      items: [{ item: null, value: null, quantity: null }],
      client: null,
      message: "",
      send: null,
    },
    errors: {
      items: null,
      job: null,
      mutableName: null,
      message: null,
      due_date: null,
      error: null,
      send: null,
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
    actions: {
      SEND: "send-estimate",
      CANCEL: "cancel-estimate",
      DELETE: "delete-estimate",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    sendingEstimate: false,
    deletingEstimate: false,
    updatingEstimate: false,
  }),

  computed: {
    subTotal() {
      return this.estimate.items.reduce((prev, curr) => {
        return prev + curr.quantity * curr.value;
      }, 0);
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    tax() {
      return (this.currentPlan?.tax_percentage / 100) * this.subTotal;
    },

    total() {
      return this.subTotal + this.tax;
    },

    serviceFee() {
      let total =
        (this.currentPlan?.invoice_fee_percentage / 100) * this.subTotal +
        this.currentPlan?.invoice_fee_fix;
      return total.toFixed(2);
    },

    willReceive() {
      let total = this.subTotal - this.serviceFee;
      return Math.max(total, 0);
    },

    estimateStatus() {
      return EstimateStatus;
    },

    canEditEstimate() {
      if (!this.estimate) return false;
      return [EstimateStatus.UNSENT, EstimateStatus.DENIED].includes(
        this.estimate.status
      );
    },

    canDeleteEstimate() {
      if (!this.estimate) return false;
      return this.estimate.status === EstimateStatus.UNSENT;
    },

    canSendEstimate() {
      if (!this.estimate) return false;
      return [EstimateStatus.UNSENT, EstimateStatus.PENDING].includes(
        this.estimate.status
      );
    },

    canCancelEstimate() {
      if (!this.estimate) return false;
      return this.estimate.status === EstimateStatus.PENDING;
    },
  },

  mounted() {
    this.fetchEstimatesDetail();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    ...statusToUI,

    exportToPDF(title) {
      if (this.downloading) {
        return;
      }

      this.downloading = true;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: title + ".pdf",
      });
      setTimeout(() => {
        this.downloading = false;
      }, 2000);
    },

    async fetchEstimatesDetail() {
      this.loading = true;

      try {
        let response = await api.fetchEstimatesDetail(this.$route.params.id);
        this.estimate = response.data;
      } catch (e) {
        this.errorsHandler(e);
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.SEND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to send this estimate ?";
          break;

        case this.actions.CANCEL:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to cancel this estimate ?";
          break;

        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to delete this estimate ?";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.SEND:
          this.instantlySendEstimate();
          break;

        case this.actions.CANCEL:
          this.updateEstimatesStatus(
            EstimateStatus.ARCHIVED,
            `Estimate successfully canceled.`
          );
          break;

        case this.actions.DELETE:
          this.deleteEstimate();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    updateEstimatesStatus(status, successMessage) {
      this.updatingEstimate = true;

      api
        .updateEstimatesStatus(this.estimate.id, status)
        .then((_) => {
          this.estimate.status = status;
          toast.success(successMessage);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.updatingEstimate = false;
        });
    },

    instantlySendEstimate() {
      this.sendingEstimate = true;
      const path = this.$router.resolve({
        name: "SharedEstimateDetails",
        params: {
          id: this.$route.params.id,
        },
      })?.path;
      const redirectLink = `${GlobalConstants.BASE_URL + path}`;

      api
        .sendEstimate(this.estimate.id, redirectLink)
        .then(({ data }) => {
          this.estimate.status = EstimateStatus.PENDING;
          this.estimate.send = data.data?.send;
          toast.success(`Estimate successfully sent.`);
        })
        .catch((err) => {
          let message = "";
          if (err.response && err.response.status) {
            if (err.response.status === 400) {
              message = err.response.data.error;
            }
          } else {
            message = errorMessages.AN_ERROR_HAS_OCCURED;
          }
          toast.error(message);
        })
        .then(() => {
          this.sendingEstimate = false;
        });
    },

    deleteEstimate() {
      this.deletingEstimate = true;

      api
        .deleteEstimate(this.estimate.id)
        .then((_) => {
          this.$router.push({
            name: "ProviderEstimates",
            params: { successToastMessage: `Estimate successfully deleted.` },
          });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingEstimate = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        items: null,
        client: null,
        title: null,
        message: null,
        error: null,
        send: null,
        notFoundErrorMessage: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.ESTIMATE_NOT_FOUND;
            break;

          case 400:
            for (const error in err.response.data) {
              this.errors[error] =
                error == "error"
                  ? err.response.data[error]
                  : err.response.data[error][0];
            }
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-menu .border-bottom:last-child {
  border-bottom: none !important;
}
</style>
