import { Request } from "@/services/request";

export default {
  fetchBidList({
    page = 1,
    startDate = null,
    endDate = null,
    project = null,
    status = null,
    search = null,
  }) {
    return new Request()
      .append("api/v1/plan_services/bid_list/")
      .method("get")
      .params({ 
        page, 
        start_date: startDate, 
        end_date: endDate, 
        project, 
        status, 
        search });
  },
  fetchServices({
    page = 1,
    startDate = null,
    endDate = null,
    project = null,
    status = null,
    search = null,
  }) {
    return new Request()
      .append("api/v1/plan_services/")
      .method("get")
      .params({ page, start_date: startDate, end_date: endDate, project, status, search });
  },

  createService(data) {
    return new Request().append("api/v1/plan_services/").method("post").setData(data);
  },
  updateService(id, data) {
    return new Request()
      .append(`api/v1/plan_services/${id}/`)
      .method("put")
      .setData(data);
  },
  updateServiceOneAttr(id, data) {
    return new Request()
      .append(`api/v1/plan_services/${id}/`)
      .method("patch")
      .setData(data);
  },

  fetchServiceById(id) {
    return new Request().append(`api/v1/plan_services/${id}/`).method("get");
  },

  deleteService(id) {
    return new Request().append(`api/v1/plan_services/${id}/`).method("delete");
  },
  servicesByCode(code) {
    return new Request().append(`api/v1/plan_services/servicesByCode/`).method("get").params({code});
  },
};
