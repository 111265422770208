export const GlobalEvents = {
  LOGOUT: "logout",
  PROVIDER_REMOVED_FROM_FAVORITES: "provider-removed-from-favorites",
  JOB_REMOVED_FROM_FAVORITES: "job-removed-from-favorites",
  chat: {
    IS_TYPING: "is-typing",
    CHAT_MESSAGE_RECEIVED: "chat-message-received",
  },
  NEW_NOTIFICATION: "new-notification",
  FETCH_LATEST_NOTIFICATIONS: "fetch-latest-notifications",
  EMIT_WEBSOCKET_EVENT: "emit-websocket-event",
  SCROLL_TO_TOP: "scroll-to-top",
  todo: {
    TASK_CREATED: "task-create",
    TASK_UPDATED: "task-update",
    TASK_DELETE: "task-delete",
    TASK_CHECK_LIST_CREATED: "task-check-list-create",
    TASK_CHECK_LIST_UPDATED: "task-check-list-update",
    TASK_CHECK_LIST_DELETE: "task-check-list-delete",
    STATUS_CREATE: "status-create",
    STATUS_UPDATED: "status-update",
    STATUS_DELETED: "status-delete",
    PRIORITY_CREATE: "priority-create",
    PRIORITY_UPDATED: "priority-update",
    PRIORITY_DELETE: "priority-delete",
  },
  job: {
    PROPOSAL_RESPONSE: "bid-response",
  },
};

export const WebsocketConstants = {
  max_loss_heartbeat: 3,
  heartbeat_time: 10000,
  echo_time: 30000,
};

export const UploadConstants = {
  imagesMime: [
    "image/bmp",
    "image/jpeg",
    "image/x-citrix-jpeg",
    "image/png",
    "image/x-citrix-png",
    "image/x-png",
    "image/tiff",
    "image/gif",
  ],
  previewEnabledExtension: ["bmp", "jpg", "jpeg", "png", "tiff", "gif"],
  csvMime: ["text/csv", "application/vnd.ms-excel"],
};

export const PaginationContants = {
  itemsPerPage: 12,
  paginatoinBreakSize: 3,
};

export const MimeTypesConstants = {
  images: {
    message:
      "For image upload is only allowed this extensions: bmp, jpg, png, tiff and gif",
    multipleFilesErrorMessage:
      "%d files failed, for image upload is only allowed this extensions: bmp, jpg, png, tiff and gif",
    mimeTypes: UploadConstants.imagesMime,
  },

  csv: {
    message: "For this upload is only allowed this extensions: csv",
    multipleFilesErrorMessage:
      "%d files failed, for image upload is only allowed this extensions: csv",
    mimeTypes: UploadConstants.csvMime,
  },

  documents: {
    message: "For documents upload is only allowed pdf files.",
    multipleFilesErrorMessage:
      "%d files failed, for documents upload is only allowed pdf files.",
    mimeTypes: [
      "application/pdf",
      "application/application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/excel",
    ],
  },

  imageAndDocuments: {
    message:
      "For image and documents upload is only allowed this extensions: pdf, xlsx, docx, bmp, jpg, png, tiff and gif.",
    multipleFilesErrorMessage:
      "%d files failed, for image and documents upload is only allowed this extensions: pdf, bmp, jpg, png, tiff and gif.",
    mimeTypes: [
      ...UploadConstants.imagesMime,
      "application/pdf",
      "application/application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/excel",
    ],
  },
};

export const GlobalConstants = {
  SL_LANDING_URL: process.env.VUE_APP_SL_LANDING_URL,
  BASE_URL: process.env.VUE_APP_BASE_URL || "http://localhost:8080",
  WEB_SOCKET_SERVER_URL: process.env.VUE_APP_WS_SERVER,
  landingPagesUrls: {
    about: process.env.VUE_APP_SL_LANDING_ABOUT_URL || "about-us",
    pricing: process.env.VUE_APP_SL_LANDING_PRICING_URL || "pricing",
    howItWork:
      process.env.VUE_APP_SL_LANDING_HOW_IT_WORKS_URL || "how-it-works",
  },
};

export const MediaTypesConstants = {
  PDF: "PDF",
  IMAGE: "IMAGE",
};

export const ChatMessageTypes = {
  TEXT: "text",
  FILE: "attach",
  NOTIFICATION: "notif",
};

export const NotificationTypes = {
  JOB: "job",
  BID: "bid",
  SIMPLE: "simple",
  TODO_LIST: "todolist",
  START_REQUEST: "start_request",
  JOB_INVITATION: "jobinvitation",
  CLIENT_INVITATION: "clientinvitation",
  INVOICE: "invoice",
};

export const WebSocketEvents = {
  chat: {
    IS_TYPING: "is_typing",
    MESSAGE_RECEIVED: "chat",
  },
  todo: {
    TASK_CREATED: "task_create",
    TASK_UPDATED: "task_update",
    TASK_DELETE: "task_delete",
    TASK_CHECK_LIST_CREATED: "task_check_list_create",
    TASK_CHECK_LIST_UPDATED: "task_check_list_update",
    TASK_CHECK_LIST_DELETE: "task_check_list_delete",
    STATUS_CREATE: "status_create",
    STATUS_UPDATED: "status_update",
    STATUS_DELETED: "status_delete",
    PRIORITY_CREATE: "priority_create",
    PRIORITY_UPDATED: "priority_update",
    PRIORITY_DELETE: "priority_delete",
  },
  NOTIFICATION_RECEIVED: "notification",
  PROVIDER_STATUS_UPDATED: "provider_status",
  CUSTOMER_STATUS_UPDATED: "customer_status",
  PROFILE_UPDATED: "profile_updated",
  job: {
    PROPOSAL_RESPONSE: "bid_response",
  },
};

export const EstimateStatus = {
  DENIED: "denied",
  UNSENT: "unsent",
  PENDING: "pending",
  APPROVED: "approved",
  ARCHIVED: "archived",
};

export const InvoiceStatus = {
  PAID: "paid",
  UNSENT: "unsend",
  REFUND: "reversed",
  PENDING: "pending",
  PARTIAL: "partial",
  EXTERNAL_PAY: "external_pay"
};

export const InvoiceStatusList = [
  {
    title: "Status",
  },
  {
    title: "Unsent",
    value: InvoiceStatus.UNSENT,
  },
  {
    title: "Pending",
    value: InvoiceStatus.PENDING,
  },
  {
    title: "Paid",
    value: InvoiceStatus.PAID,
  },
  {
    title: "Partial",
    value: InvoiceStatus.PARTIAL,
  },
  {
    title: "Reversed",
    value: InvoiceStatus.REFUND,
  },
  {
    title: "External Pay",
    value: InvoiceStatus.EXTERNAL_PAY,
  },
];