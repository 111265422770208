import { Request } from "@/services/request";

export default {
  fetchAllJobs({
    page = 1,
    typeFilter = null,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
  }) {
    return new Request().append("api/v1/job/").method("get").params({
      type_filter: typeFilter,
      page,
      start_date: startDate,
      end_date: endDate,
      search,
      status,
    });
  },

  fetchProviderSubcontract({
    id = null,
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
  }) {
    return new Request().append(`api/v1/job/${id}/provider_subcontract/`)
    .method("get")
    .params({
      start_date: startDate,
      end_date: endDate,
      page,
      search,
      status,
    });
  },

  fetchProviderJob({
    id = null,
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
  }) {
    return new Request().append(`api/v1/job/${id}/provider_job/`)
    .method("get")
    .params({
      start_date: startDate,
      end_date: endDate,
      page,
      search,
      status,
    });
  },

  fetchJobBids({
    id = null,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
  }) {
    return new Request().append(`api/v1/job/${id}/bids/`).method("get").params({
      start_date: startDate,
      end_date: endDate,
      search,
      status,
    });
  },

  fetchJob(id) {
    return new Request().append(`api/v1/job/${id}/`).method("get");
  },

  fetchJobSetting(callback) {
    return new Request()
      .append(`api/v1/job/default_datas/`)
      .method("get")
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },
   
  fetchMyJobs({
    page = 1,
    typeFilter = null,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
    category = null,
    contractType = null,
    skills = null,
    budgetType = null,
    paymentFrequency = null,
    jobType = null,
    budgetFrom = null,
    budgetTo = null,
    radius = null,
    level = null,
    lat = null,
    lng = null,
  }) {
    return new Request().append("api/v1/job/my_jobs/").method("get").params({
      type_filter: typeFilter,
      page,
      start_date: startDate,
      end_date: endDate,
      search,
      status,
      category,
      contract_type: contractType,
      level,
      skills,
      budget_type: budgetType,
      payment_frequency: paymentFrequency,
      job_type: jobType,
      budget_from: budgetFrom,
      budget_to: budgetTo,
      distance_max: radius,
      lat,
      lng,
    });
  },

  fetchProposedJobsForProvider({
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
    category = null,
    contractType = null,
    level = null,
    skills = null,
    budgetType = null,
    paymentFrequency = null,
    jobType = null,
    budgetFrom = null,
    budgetTo = null,
    radius = null,
    lat = null,
    lng = null,
  }) {
    return new Request()
      .append("api/v1/job/provider_default/")
      .method("get")
      .params({
        page,
        start_date: startDate,
        end_date: endDate,
        search,
        status,
        category,
        contract_type: contractType,
        level,
        skills,
        budget_type: budgetType,
        payment_frequency: paymentFrequency,
        job_type: jobType,
        budget_from: budgetFrom,
        budget_to: budgetTo,
        distance_max: radius,
        lat,
        lng,
      });
  },

  fetchProviderCompletedJobs({
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
  }) {
    return new Request()
      .append("api/v1/job/provider_job_completed/")
      .method("get")
      .params({
        page,
        start_date: startDate,
        end_date: endDate,
        search,
      });
  },

  makeJob(id, data) {
    return new Request()
      .append(`api/v1/job/${id}/`)
      .method("patch")
      .setData(data);
  },

  updateJobStatuses(ids, status) {
    return new Request()
      .append(`api/v1/job/status/`)
      .method("post")
      .setData({
        ids: ids,
        status: status
      });
  },

  treatProviderProposal(id, data) {
    return new Request()
      .append(`api/v1/job/${id}/awarded_to/`)
      .method("post")
      .setData(data);
  },

  applyJob(data) {
    return new Request()
      .append(`api/v1/bid/`)
      .method("post")
      .setData(data);
  },

  deleteJob(id) {
    return new Request().append(`api/v1/job/${id}/`).method("delete");
  },

  duplicateJob(id) {
    return new Request()
      .append(`api/v1/job/${id}/duplicate/`)
      .method("post")
  },

  fetchProposal(id) {
    return new Request().append(`api/v1/bid/${id}/`).method("get");
  },

  fetchAllJobsFromSuperAdmin(
    page = 1,
    customer,
    provider,
    type_filter = null,
    start_date = null,
    end_date = null,
    search = null,
    is_active = "True",
    status = "waiting"
  ) {
    return new Request()
      .append("api/v1/job/super_admin_filters/")
      .method("get")
      .params({
        type_filter,
        page,
        start_date,
        end_date,
        search,
        is_active,
        status,
        customer,
        provider,
      });
  },

  createJob(data, callback) {
    return new Request()
      .append("api/v1/job/")
      .method("post")
      .setData(data)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },

  updateJob(id, data, callback) {
    return new Request()
      .append(`api/v1/job/${id}/`)
      .method("patch")
      .setData(data)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },

  requestStartJob(id, callback) {
    return new Request()
      .append(`api/v1/request_start/`)
      .method("post")
      .setData({job: id})
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },

  requestCompleteJob(id, callback) {
    return new Request()
      .append(`api/v1/request_complete/`)
      .method("post")
      .setData({job: id})
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },

  approveStartJobRequest(id) {
    return new Request()
      .append(`api/v1/request_start/${id}/accept_request/`)
      .method("patch");
  },
};
