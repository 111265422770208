<template>
  <section class="content">
    <div class="page-header ps-0">
      <div class="title-part mb-0">
        <p class="mb-0 mind">
          Saved Providers
        </p>
      </div>

      <Datepicker v-if="false" />
    </div>

    <saved-providers
      v-if="!fetching"
      :saved-providers="providers"
      @fetchNextPage="onFetchNextSavedProvidersPage"
    />

    <loader-spinner v-if="fetching" />
  </section>
</template>

<script>
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import Datepicker from "@/components/AppDatePicker.vue";
import SavedProviders from "@/components/customer/saved-providers/ProvidersList";

import filterVectorIcon from "@/assets/img/icons/filter.svg";
import searchVector from "@/assets/img/icons/search.svg";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { GlobalEvents } from "@/configs/constants";

export default {
  name: "CustomerSavedProvidersPage",

  components: {
    Datepicker,
    SavedProviders,
    LoaderSpinner,
  },

  mixins: [ListMixin(api.fetchAllSavedProviders, true, false)],

  data: () => ({
    icons: {
      filterVectorIcon,
      searchVector,
    },
    providers: [],
    fetching: false,
    maxPagesReached: false,
  }),

  watch: {
    listData(val) {
      this.providers = this.providers.concat(val);
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchSavedProviders()
  },

  created() {
    this.$emitter.on(GlobalEvents.PROVIDER_REMOVED_FROM_FAVORITES, (id) => {
      this.listData = this.listData.filter((x) => {
        return x.id != id;
      });
    });
  },

  methods: {
    fetchSavedProviders() {
      this.fetching = true;
      this.updateListFilters({
        page: 1,
      });
    },

    onFetchNextSavedProvidersPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        page: this.currentPage + 1,
      });
    },
  },
};
</script>
