<template>
  <div class="post-job row">
    <div class="col-lg-8 ">
      <section class="content">
        <invite-subcontract
          :job-id="$route.params.id"
          :bid="$route.params.proposalId"
        />
      </section>
    </div>
  </div>
</template>

<script>
import InviteSubcontract from "@/components/provider/jobs/InviteSubcontract.vue";

export default {
  name: "ProviderSendInvitation",
  components: {
    InviteSubcontract,
  },
};
</script>
