export const SubscriptionPlanFeatures = [
  {
    title: "Can access calendar",
    condition: "can_access_calendar",
  },
  {
    title: "Access to project list",
    condition: "limited_access_to_project_list",
  },
  {
    title: "Can access messaging",
    condition: "can_access_messaging",
  },
  {
    title: "Can access phone",
    condition: "can_access_phone",
  },
  {
    title: "Can see stars and reviews",
    condition: "can_see_stars_and_reviews",
  },
  {
    title: "Can view list of available providers",
    condition: "can_view_list_of_available_providers",
  },
  {
    title: "Profile searchable",
    condition: "profile_searchable",
  },
  {
    title: "Invoice management",
    condition: "invoice_management",
  },
  {
    title: "Can access historical data",
    condition: "can_access_historical_data",
  },
  {
    title: "Job tracking",
    condition: "job_tracking",
  },
];
