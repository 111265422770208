<template>
  <section class="content profile">
    <div class="page-header">
      <h3 class="page-title">
        Profile
      </h3>

      <settings-menu />
    </div>

    <div
      v-if="currentUser"
      class="page-content"
    >
      <div class="row box-form">
        <div class="col-md-12 my-3">
          <div class="photo-wrapper">
            <div
              :style="{
                'background-image': `url(${
                  currentUser.photo?.url || images.user
                })`,
              }"
              class="profile-photo"
            />

            <div>
              <h4 class="username">
                {{ currentUser.full_name }}
              </h4>
              <div>
                <rating-stars :rating="ratingStats.average" />
                <div>{{ ratingStats.count }} reviews</div>
                <div>{{ remainingBids }} bid<span v-if="remainingBids !== 1">s</span> remaining this month</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 my-3">
          <h4 class="border-bottom section-title">
            Basic Details
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Full Name</label>
          <p class="label-content username">
            {{ currentUser.full_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Email</label>
          <p class="label-content">
            {{ currentUser.email }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Phone No.</label>
          <p class="label-content">
            {{ currentUser.phone }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Website</label>
          <p class="label-content">
            {{ currentUser.website }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Business Type</label>
          <p
            v-if="getBusinessType(currentUser.business_type)"
            class="label-content"
          >
            {{ getBusinessType(currentUser.business_type).title }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Country</label>
          <p
            v-if="getCountry(currentUser.country)"
            class="label-content"
          >
            {{ getCountry(currentUser.country).label }}
          </p>
        </div>

        <div
          v-if="currentUser.ssn"
          class="col-md-6 col-lg-6 col-xl-4 mb-3"
        >
          <label class="input-label">SSN</label>
          <p class="label-content">
            {{ currentUser.ssn }}
          </p>
        </div>

        <div
          v-if="currentUser.ein"
          class="col-md-6 col-lg-6 col-xl-4 mb-3"
        >
          <label class="input-label">EIN</label>
          <p class="label-content">
            {{ currentUser.ein }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Birthdate</label>
          <p
            v-if="currentUser.date_of_birth"
            class="label-content"
          >
            {{ formatDate(currentUser.date_of_birth, "MMMM Do, YYYY") }}
          </p>
        </div>

        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Profile Details
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Company Name</label>
          <p class="label-content">
            {{ currentUser.company_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Address Line 1</label>
          <p class="label-content">
            {{ currentUser.address_line_1 }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Address Line 2</label>
          <p class="label-content">
            {{ currentUser.address_line_2 }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">State</label>
          <p class="label-content">
            {{ currentUser.state }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">City</label>
          <p class="label-content">
            {{ currentUser.city }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
          <label class="input-label">Zip Code</label>
          <p class="label-content">
            {{ currentUser.pin_code }}
          </p>
        </div>

        <div class="col-md-12">
          <label class="input-label">Profile Description</label>
          <p class="label-content">
            {{ currentUser.profile_description }}
          </p>
        </div>

        <div
          v-for="document in documents"
          :key="document.field"
          class="col-md-12 col-lg-6 mt-3"
        >
          <label class="input-label">{{ document.header }}</label>
          <div
            v-if="currentUser[document.field]"
            class="document"
          >
            <button
              @click="downloadDocument(document.field)"
            >
              <img
                :src="currentUser.insurance.type === mediaTypesConstants.IMAGE ? icons.imageUploadedIcon : icons.documentUploadedIcon"
                alt="File Uploaded"
                class="file-icon me-1"
              >
              {{ getFileName(currentUser[document.field].original_name) }}
            </button>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="input-label">Portfolio</label>
          <div
            v-if="currentUser.portfolio?.length > 0"
            class="row"
          >
            <div
              v-for="(file, i) in currentUser.portfolio"
              :key="i"
              class="col-md-12 col-lg-6"
              :class="{ 'mb-3': i < currentUser.portfolio.length - 1 }"
            >
              <div class="document">
                <a
                  :href="file.portfolio?.url"
                  target="_target"
                >
                  <img
                    v-if="file.portfolio?.type === mediaTypesConstants.IMAGE"
                    :src="icons.imageUploadedIcon"
                    alt="File Uploaded"
                    class="file-icon me-1"
                  >
                  <img
                    v-else
                    :src="icons.documentUploadedIcon"
                    alt="File Uploaded"
                    class="file-icon me-1"
                  >
                  {{ getFileName(file.portfolio?.original_name) }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="input-label">CVs</label>
          <div
            v-if="currentUser.cv?.length > 0"
            class="row"
          >
            <div
              v-for="(file, i) in currentUser.cv"
              :key="i"
              class="col-md-12 col-lg-6"
              :class="{ 'mb-3': i < currentUser.cv.length - 1 }"
            >
              <div class="document">
                <a
                  :href="file.user_cv?.url"
                  target="_target"
                >
                  <img
                    :src="icons.documentUploadedIcon"
                    alt="File Uploaded"
                    class="file-icon me-1"
                  >
                  {{ getFileName(file.user_cv?.original_name) }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="listData"
          class="col-12"
        >
          <div class="col-md-12 mt-3">
            <h4 class="border-bottom section-title">
              Rating List
            </h4>
          </div>
          <div class="table-responsive">
            <table class="table">
              <head-table
                :list="thead"
                :error="error"
              />
              <tbody v-if="!fetching">
                <tr
                  v-for="(item, index) in listData"
                  :key="index"
                >
                  <td>
                    <router-link
                      :to="{
                        name: 'ProviderJobDetails',
                        params: { id: item.job.id, }
                      }"
                      class="text-primary"
                    >
                      {{ item.job.title }}
                    </router-link>
                  </td>

                  <td>
                    <rating-stars :rating="item.rating ?? 0" />
                  </td>
                  <td class="text-wrap">
                    {{ item?.feedback }}
                  </td>
                  <td>
                    {{ formatDate(item.updated_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <loader-spinner v-if="fetching" />
          </div>
        </div>

        <app-pagination
          v-if="totalPages > 1"
          :align-end="true"
          :current-page="currentPage"
          :has-previous-page="hasPreviousPage"
          :previous-page="currentPage - 1 || 1"
          :has-next-page="hasNextPage"
          :next-page="currentPage + 1"
          :per-page="itemsPerPage"
          :total-elements="itemsCount"
          :total-pages="totalPages"
          @onGotoPage="fetchRatings({ page: $event, search })"
          @onPrev="fetchRatings({ page: currentPage - 1 || 1, search })"
          @onNext="fetchRatings({ page: currentPage + 1, search })"
        />
      </div>
    </div>
  </section>
</template>

<script>
import user from "@/assets/img/user.svg";
import documentIcon from "@/assets/img/icons/document-icon.svg";

import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import FileUtils from "@/utils/file";
import CountryUtils from "@/utils/country";
import BusinessTypesUtils from "@/utils/business-type";
import { DateHelpers } from "@/utils/date-helpers";
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import SettingsMenu from "@/components/provider/SettingsMenu.vue";

import { MediaTypesConstants } from "@/configs/constants";;
import AppPagination from '@/components/AppPagination.vue';
import HeadTable from '@/components/HeadTable.vue';
import RatingStars from '@/components/RatingStars.vue';

export default {
  name: "CustomerProfilePage",

  components: {
    SettingsMenu,
    AppPagination,
    HeadTable,
    RatingStars,
  },

  mixins: [ListMixin(api.fetchRatings, true)],

  data: () => ({
    documents: [
      ['Insurance Document', 'insurance'],
      ['W9 Document', 'w9document'],
      ['Identity Document - Front', 'idfront'],
      ['Identity Document - Back', 'idback']
    ].map(([header, field]) => ({header: header, field: field})),
    images: {
      user,
    },
    icons: {
      imageUploadedIcon,
      documentUploadedIcon,
      documentIcon,
    },
    thead: ["Job Title", "Rating", "Feedback", "Update date"],
    listData: null,
    loading: false,
    ratingStats: {},
    remainingBids: undefined
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    }
  },

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchRatings({ page: this.page });

    api.fetchRatingStats({ page: this.page }).then(response => this.ratingStats = response.data);

    api.fetchRemainingBidCount().then(response => this.remainingBids = response.data);
  },

  methods: {
    ...FileUtils,
    ...DateHelpers,
    ...CountryUtils,
    ...BusinessTypesUtils,

    downloadDocument(document) {
      api.downloadDocument(document);
    },

    fetchRatings(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          this.error = err.response.data.error
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

  },
};
</script>
