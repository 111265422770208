export default {
  getFileName(value) {
    if (value.length <= 28) {
      return value;
    }
    return (
      (value || "").split("/").slice(-1)[0].slice(0, 10) +
      "..." +
      (value || "").split("/").slice(-1)[0].slice(-15)
    );
  },

  getLongFileName(value) {
    if (value.length <= 28) {
      return value;
    }
    return (value || "").split("/").slice(-1)[0];
  },

  isImage(type) {
    const validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/bmp",
      "image/x-citrix-jpeg",
      "image/x-citrix-png",
      "image/x-png",
      "image/tiff",
      "image/gif",
    ];
    return validImageTypes.includes(type);
  },
};
