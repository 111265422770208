<template>
  <section class="content profile">
    <list-header
      title="Properties"
      :add="true"
      :search="true"
      @update:search="onListHeaderUpdate"
      @create="addProperty"
      @bulkCreate="bulkAddProperty"
    />
    <alerte-danger
      :head-error-message="error"
      @updateContent="error = $event"
    />

    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <head-table :list="thead" />

          <tbody v-if="!fetchingClientProperties">
            <tr
              v-for="(item, index) in listData"
              :key="index"
            >
              <td class="py-3">
                <span
                  class="cursor-pointer text-primary"
                  @click="detail(item)"
                >
                  {{ item.company }}
                </span>
              </td>
              <td class="">
                {{ item.location_description }}
              </td>
              <td>
                {{ item.zip }}
              </td>

              <td>
                {{ item.city }}
              </td>
              <td>
                {{ item.state }}
              </td>
              <td>
                <submission-spinner
                  v-if="
                    deletingClientProperty &&
                      selectedClientPropertyId === item.id
                  "
                  :color="'#209516'"
                />
                <option-action
                  v-else
                  :status="item.status"
                  :options="optionsAction"
                  @update:options="chooseProperty(item, $event)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <loader-spinner v-if="fetchingClientProperties" />

        <app-pagination
          v-if="clientPropertiesPage.totalPages > 1"
          :align-end="true"
          :current-page="clientPropertiesPage.currentPage"
          :has-previous-page="clientPropertiesPage.hasPreviousPage"
          :previous-page="clientPropertiesPage.currentPage - 1 || 1"
          :has-next-page="clientPropertiesPage.hasNextPage"
          :next-page="clientPropertiesPage.currentPage + 1"
          :per-page="clientPropertiesPage.itemsPerPage"
          :total-elements="clientPropertiesPage.itemsCount"
          :total-pages="clientPropertiesPage.totalPages"
          @onGotoPage="fetchProprieties($event)"
          @onPrev="
            fetchProprieties(clientPropertiesPage.currentPage - 1 || 1)
          "
          @onNext="
            fetchProprieties(clientPropertiesPage.currentPage + 1)
          "
        />
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </section>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants, PaginationContants } from "@/configs/constants";
import { DateHelpers } from "@/utils/date-helpers";

import { CustomerStatusList } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import ListHeader from '@/components/ListHeader.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperadminCustomerDetailsPage",

  components: {
    HeadTable,
    OptionAction,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
    ListHeader,
    AlerteDanger,
    OptionAction,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      propertiesOption: {},
      CustomerStatusList,
      thead: ["Name","Address", "Zip", "City", "State", ""],
      listData: [],
      client: null,
      loading: true,
      search: null,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: null,
      fetchingClientProperties: true,
      clientPropertiesPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      options: [
        {
          title: "Edit",
          value: "edit",
        },
        {
          title: "Invite",
          value: "invite",
        },
      ],
      optionsAction: [
        {
          title: "Edit",
          value: "edit",
        },
        {
          title: "Delete",
          value: "delete",
        },
      ],
      actions: {
        DELETE_ESTIMATE: "delete-estimate",
        DELETE_JOB: "delete-job",
        DELETE_CLIENT_PROPERTY: "delete-client-property",
        INVITE_CLIENT: "invite-client",
      },
      modalConfigs: {
        confirmTitle: null,
        currentAction: null,
        confirmMessage: null,
        showConfirmModal: false,
      },
      deletingJob: false,
      selectedJobId: null,
      deletingClientProperty: false,
      selectedClientPropertyId: null,
      error: null
    };
  },

  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  mounted() {
    this.fetchProprieties({page: 1});
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,

    detail(item) {
      this.$router.push({
        name: "CustomerPropertiesDetail",
        params: { id: item.id, tag: "info" },
      });
    },
    onListHeaderUpdate(event) {
      this.fetchProprieties({ page: 1, search: event });
    },
    fetchProprieties(params) {
      this.fetchingClientProperties = true;

      api
        .fetchProperties(params)
        .then((response) => {
          this.listData = response.data.data;
          this.clientPropertiesPage = {
            currentPage: page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingClientProperties = false;
        });
    },

    confirmClaim(id, action) {
      this.modalConfigs.confirmTitle = "Yes";
      this.modalConfigs.confirmMessage = `Are you sure you want to delete this client property ?`;
      this.selectedClientPropertyId = id;
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      this.deletePropriety();
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    addProperty() {
      this.$router.push({
        name: "CustomerPropertiesCreate",
        params: { id: this.$route.params.id },
      });
    },

    bulkAddProperty() {
      this.$router.push({
        name: "CustomerBulkPropertiesCreate",
        params: { id: this.$route.params.id },
      });
    },

    chooseProperty(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "CustomerPropertiesEdit",
            params: { id: item.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.id);
          break;
      }
    },

    deletePropriety() {
      this.deletingClientProperty = true;

      api
        .deleteProperty(this.selectedClientPropertyId)
        .then((_) => {
          toast.success(`Client property successfully deleted.`);
          this.selectedClientPropertyId = null;
          this.fetchProprieties(this.clientPropertiesPage.currentPage);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingClientProperty = false;
        });
    },
    
  },
};
</script>

<style>
.float-end {
  padding: 0.6rem;
}
</style>
