<template>
  <div
    ref="jobRef"
    class="job-content"
  >
    <template v-if="subcontract">
      <div class="row">
        <div class="col-sm-6">
          <h3>
            <router-link
              :to="{
                name: 'ProviderSubcontractDetails',
                params: { id: subcontract.id },
              }"
              class="subtitle"
            >
              {{ subcontract.title }}
            </router-link>
          </h3>

          <p class="date mb-0">
            Posted at
            {{ formatDate(subcontract.created_at, "MMMM Do, YYYY") }}
          </p>
        </div>
        <div class="col text-sm-end">
          <router-link
            :to="{
              name: 'ProviderSubcontractDetails',
              params: { id: subcontract.id },
            }"
            class="btn-primary"
          >
            View
          </router-link>
        </div>
      </div>
    
      <div class="mt-2">
        <div
          v-if="subcontract.level"
          class="d-flex"
        >
          <p class="case">
            Level :
          </p>

          <p
            v-if="subcontract.level"
            class="number-case me-4"
          >
            {{ subcontract.level.name }}
          </p>
        </div>

        <div
          v-if="subcontract.budget_type === jobBudgetTypes.FIXED"
          class="d-flex"
        >
          <template
            v-if="subcontract.budget !== null && subcontract.budget !== undefined"
          >
            <p class="case">
              Budget :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(subcontract.budget) }}
            </p>
          </template>
        </div>

        <template v-else>
          <div
            v-if="
              subcontract.budget_from !== null &&
                subcontract.budget_from !== undefined
            "
            class="d-flex"
          >
            <p class="case">
              Budget from :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(subcontract.budget_from) }}
              <template
                v-if="
                  subcontract.payment_frequency &&
                    formatJobPaymentFrequencies(subcontract.payment_frequency).short
                "
              >
                /{{
                  formatJobPaymentFrequencies(subcontract.payment_frequency).short
                }}
              </template>
            </p>
          </div>

          <div
            v-if="
              subcontract.budget_to !== null &&
                subcontract.budget_to !== undefined
            "
            class="d-flex"
          >
            <p class="case">
              Budget to :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(subcontract.budget_to) }}
              <template
                v-if="
                  subcontract.payment_frequency &&
                    formatJobPaymentFrequencies(subcontract.payment_frequency).short
                "
              >
                /{{
                  formatJobPaymentFrequencies(subcontract.payment_frequency).short
                }}
              </template>
            </p>
          </div>
        </template>

        <div
          v-if="subcontract.bids_end"
          class="d-flex me-4"
        >
          <p class="case">
            Bids ends :
          </p>

          <p class="number-case">
            {{ formatDate(subcontract.bids_end, "MMMM Do, YYYY") }}
          </p>
        </div>
      </div>

      <div v-if="subcontract.description">
        <div v-html="sanitizedDescription" />

        <router-link
          v-if="subcontract.description?.length > 154"
          :to="{
            name: 'ProviderSubcontractDetails',
            params: { id: subcontract.id },
          }"
          class="text-primary"
        >
          more
        </router-link>
      </div>
      <div class="my-4">
        <span
          v-for="skill in subcontract.skills"
          :key="skill.id"
          class="type-job"
        >
          {{ skill.name }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import heartVectorIcon from "@/assets/img/icons/heart.svg";
import { JobBudgetTypes } from "@/configs/constants/jobs";

export default {
  name: "SubcontractItem",

  beforeRouteLeave(to, from) {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  props: {
    subcontract: {
      type: Object,
      required: true,
      default: null,
    },

    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      heartVectorIcon,
    },

    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    shortDescription() {
      if (this.subcontract.description?.length <= 154) {
        return this.subcontract.description;
      } else if (this.subcontract.description?.length > 154) {
        return `${this.subcontract.description.slice(0, 154)}...`;
      }
      return "";
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.shortDescription);
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },
  },

  created() {
    nextTick(() => {
      if (this.$refs.jobRef) {
        this.observer = new IntersectionObserver(this.callback);
        this.observer.observe(this.$refs.jobRef);
      }
    });
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.isLastItem) {
          this.$emit("fetchNextPage");
        }
      });
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (
        err.response &&
        err.response.status !== 400 &&
        err.response.status !== 404
      ) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
