<template>
  <div class="subscription-plans-boxes">
    <div class="container-fluid">
      <div class="row customize-bundle">
        <h3 class="pricing-title my-5">
          Your ServiceLoop Bids Tool
        </h3>

        <loader-spinner v-if="fetchingPlans" />

        <alerte-danger
          :head-error-message="errors.serverSideErrorMessage"
          @updateContent="errors.serverSideErrorMessage = $event"
        />
        <div class="col-md-12 row">
          <div
            v-for="(plan, index) in plans"
            :key="index"
            class="col-md-4 col-xl-3 mb-3"
          >
            <div class="item bg-white">
              <div
                class="pricing-card"
              >
                <p class="pricing-title">
                  {{ plan.name }}
                </p>

                <div class="price-card p-3">
                  <p class="price">
                    <span v-if="coupon"> 
                      {{ 
                        plan.value - (coupon?.percent_off ? ((plan.value * coupon?.percent_off)/100) : coupon?.amount_off) < 0 ?
                          formatPrice(0)
                          :
                          formatPrice(plan.value - (coupon?.percent_off ? ((plan.value * coupon?.percent_off)/100) : coupon?.amount_off) || 0)
                      }} 
                    </span>

                    <span v-else> 
                      {{ formatPrice(plan.value || 0) }} 
                    </span>
                  </p>

                  <span class="sub-title">
                    {{ plan.commercial_description }}
                  </span>

                  <ul
                    v-if="coupon"
                    class="list-group"
                  >
                    <li class="list-group-item">
                      <span class="fw-bold">Coupon :</span> 
                      {{ coupon.code }}
                      {{ coupon?.percent_off ? coupon?.percent_off+'%' : formatPrice(coupon?.amount_off) }}
                      <img
                        alt="smallclose"
                        class="float-end p-0"
                        :src="icons.smallclose"
                        @click="coupon = null"
                      >
                    </li>
                  </ul>
                  <button
                    v-else
                    class="btn btn-outline-primary d-block mt-3 mx-auto"       
                    @click="create"
                  >
                    <submission-spinner v-if="submitting" />
                    Apply Coupon
                  </button>
                  <button
                    class="btn btn-primary d-block mt-3 mx-auto"
                    :disabled="submitting" 
                    @click="buyBids(plan.id)"
                  >
                    <!-- <submission-spinner v-if="submitting" /> -->
                    Buy Bid
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <subscription-code
      ref="SubscriptionCode"
      title="Subscription"
      @update:coupon="coupon = $event"
    />
    <no-credit-card-modal
      ref="confirmModal"
      type="ProviderCreateCreditCard"
    />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

import vectorCircleGreen from "@/assets/img/icons/circle-green.svg";
import vectorCircleGris from "@/assets/img/icons/circle-gris.svg";

import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import PriceHelpers from "@/utils/price-format";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import smallclose from "@/assets/img/icons/poubell-red.svg";

import { SubscriptionPlanFeatures } from "@/configs/constants/subscription-plans";
import NoCreditCardModal from '@/components/modals/NoCreditCardModal.vue';
import SubmissionSpinner from '@/components/SubmissionSpinner.vue';
import SubscriptionCode from '@/components/SubscriptionCode.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';
export default {
  name: "CustomerSubscriptionPage",

  components: {
    LoaderSpinner,
    NoCreditCardModal,
    SubmissionSpinner,
    SubscriptionCode,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      vectorCircleGreen,
      vectorCircleGris,
      smallclose
    },
    plans: [],
    services: [],
    fetchingPlans: false,
    fetchingServices: false,
    submitting: false,
    selectedPlan: null,
    coupon: null,
    selectedServices: [],
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    features() {
      return SubscriptionPlanFeatures;
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    currentCustomer() {
      return this.$store.getters["user/user"];
    },

    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },

  },

  mounted() {
    this.fetchCreditCards();
    this.fetchPlanServicesBidList();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,

    create() {
      this.$refs.SubscriptionCode.openModal();
    },
    fetchCreditCards() {
      api.fetchCreditCards({page: 1,})
        .then((response) => {
          if(response?.data?.data.length === 0){
            this.$refs.confirmModal.openModal();
          }
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
        })
    },

    fetchPlanServicesBidList() {
      this.fetchingPlans = true;

      api
        .fetchPlanServicesBidList()
        .then((response) => {
          this.plans = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingPlans = false;
        });
    },

    async buyBids(id) {
      this.submitting = true;
      try {
        const response = await api.buyBids(id, this.coupon ? { coupon: this.coupon.code } :{});
        this.$store.dispatch("user/saveUser", {
          ...this.currentCustomer,
          my_current_plan: response.data.my_current_plan,
          my_current_planservices: response.data.my_current_planservices,
        });
        toast.success("Buy Bid successfully done.");
        this.coupon = null;
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.submitting = false;
      }
    },

    clearErrorsMessages() {
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            toast.error(err.response.data[error]);
            this.errors.serverSideErrorMessage = err.response.data[error];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
