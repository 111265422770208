<template>
  <div class="saved-providers page-content">
    <provider-item
      v-for="(savedProvider, index) in savedProviders"
      :key="savedProvider.id"
      :saved-provider="savedProvider"
      :is-last-item="index === savedProviders.length - 1"
      @fetchNextPage="onFetchNextPage"
    />
  </div>
</template>

<script>
import ProviderItem from "@/components/customer/saved-providers/ProviderItem.vue";

export default {
  name: "ProviderList",

  components: {
    ProviderItem,
  },

  props: {
    savedProviders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    onFetchNextPage() {
      this.$emit('fetchNextPage')
    }
  },
};
</script>
