import { Request } from "@/services/request";

export default {
  updloadUserCV(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/user_cv/`)
      .method("post")
      .setData(data);
  },

  deleteUserCvFile(id) {
    return new Request()
      .append(`api/v1/user_cv/${id}/`)
      .method("delete");
  },
  
  updloadUserPortfolioFile(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/user_portfolio/`)
      .method("post")
      .setData(data);
  },

  deleteUserPortfolioFile(id) {
    return new Request()
      .append(`api/v1/user_portfolio/${id}/`)
      .method("delete");
  },

  downloadDocument(document_param) {
    return new Request()
      .append(`api/v1/user/download/?document=${document_param}`)
      .responseType('blob')
      .method('get')
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        const filename = response.headers['content-disposition']
          .replace(/.*filename="/, '')
          .replace(/".*/, '')

        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        link.remove();
      });
  }
};