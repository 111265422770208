<template>
  <div class="auth">
    <nav class="navbar navbar-expand-lg">
      <div class="container align-items-center">
        <a
          class="navbar-brand"
          target="_blank"
          :href="`${landingSiteUrl}`"
        ><img
          :src="images.appLogo"
          alt="white logo"
          class="logo"
        ></a>
        <a
          class="navbar-toggler p-0 border-0 text-white collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsCollapase"
          aria-controls="navbarsCollapase"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img
            :src="images.toogle"
            alt="Toggle Menu Icon"
            class="user"
          >
        </a>

        <div
          id="navbarsCollapase"
          class="navbar-collapse justify-content-between collapse"
        >
          <ul class="navbar-nav mb-2 mb-lg-0 mx-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                :href="`${landingSiteUrl}/${landingPagesUrls.about}`"
              >About Us</a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                :href="`${landingSiteUrl}/${landingPagesUrls.howItWork}`"
              >How it works</a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                :href="`${landingSiteUrl}/${landingPagesUrls.pricing}`"
              >Pricing</a>
            </li>
          </ul>

          <ul class="navbar-nav mb-2 mb-lg-0 align-items-center">
            <router-link
              v-if="!isAuthenticated"
              :to="{ name: 'Login' }"
              class="btn btn-nav"
            >
              Log In
            </router-link>

            <router-link
              :to="{ name: signupLink }"
              class="btn btn-nav-primary"
            >
              <img
                class="user"
                :src="images.user"
                alt="Icon User"
              >
              <template v-if="isAuthenticated">
                Dashboard
              </template>
              <template v-else>
                Sign up
              </template>
            </router-link>
          </ul>
        </div>
      </div>
    </nav>

    <div class="container py-6">
      <div
        id="auth-section"
        class="row align-items-center"
      >
        <div class="col-lg-10 col-xl-8 mx-auto mt-6">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appLogo from "@/assets/img/white_logo.svg";
import user from "@/assets/img/sig-user.svg";
import toogle from "@/assets/img/align-justify.svg";

import { GlobalConstants } from "@/configs/constants";

export default {
  name: "DefaultLayout",

  data: () => ({
    images: {
      appLogo,
      user,
      toogle,
    },
  }),

  computed: {
    landingSiteUrl() {
      return GlobalConstants.SL_LANDING_URL;
    },

    landingPagesUrls() {
      return GlobalConstants.landingPagesUrls;
    },

    isAuthenticated() {
      return this.$store.getters["token/isAuthenticated"];
    },

    isCustomer() {
      return this.$store.getters["token/isCustomer"];
    },

    isProvider() {
      return this.$store.getters["token/isProvider"];
    },

    signupLink() {
      if (!this.isAuthenticated) return "Register";
      if (this.isCustomer) return "CustomerDashboard";
      else if (this.isProvider) return "ProviderDashboard";
      else return "Register";
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

html {
  font-size: 16px;
}

.navbar {
  font-family: Poppins, sans-serif;
  --color-dark: #222a36;
  --color-primary: #209516;
  --color-white: #fff;
  --bg-primary: #f5f5f5;
  --color-gradient: linear-gradient(
    88.54deg,
    rgba(32, 149, 22, 0.92) 1.27%,
    rgba(15, 122, 154, 0.95) 96%
  );
  --color-gradient-reverse: linear-gradient(
    88.54deg,
    rgba(15, 122, 154, 0.95) 1.27%,
    rgba(32, 149, 22, 0.92) 96%
  );
  --color-gray: #737c84;
  --border-color: #e5e8eb;
  --heading-color: rgba(34, 42, 54, 0.8);
  font-size: 1rem;
  background-color: var(--bg-primary);
  color: var(--color-white);
  z-index: 1;
}

.logo {
  width: 166px;
  height: 40px;
}

img {
  width: 100%;
}

.btn-primary,
.btn-secondary,
.btn-group {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  padding: 0.6rem 0.85rem;
  border: none;
  border-radius: 0.375rem;
}
.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.btn-secondary {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.btn-group {
  border: 1.5px solid var(--border-color);
  color: var(--color-primary);
  justify-content: center;
}
.btn-group.active {
  background: var(--color-primary);
  color: var(--color-white);
}

.btn-group:hover,
.btn-group:active {
  color: var(--color-white) !important;
}
.btn-group:hover,
.btn-primary:hover,
.btn-secondary:hover {
  background: var(--color-gradient);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.btn-group:active,
.btn-group:focus,
.btn-primary:active,
.btn-secondary:active,
.btn-primary:focus,
.btn-secondary:focus {
  background: var(--color-gradient-reverse);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

a.btn-nav:hover {
  color: var(--color-primary);
}

a.btn-nav-primary {
  border: 1.5px solid var(--color-primary);
  padding: 0.5rem 1rem;
}

.btn-nav-primary {
  border: 1.5px solid var(--color-primary);
  padding: 0.5rem 1rem;
}
a.btn-nav-primary:active,
a.btn-nav-primary:focus,
a.btn-nav-primary:hover {
  color: var(--color-primary);
  border: 1.5px solid var(--color-primary);
  padding: 0.5rem 1rem;
}

.navbar {
  background-color: var(--color-dark);
  color: white !important;
  position:absolute;
  width: 100%;
}

#navbarNav ul li a {
  color: var(--color-white) !important;
  text-decoration: none !important;
  margin-left: 2rem;
}

#navbarNav ul li a:hover,
#navbarNav ul li a.active {
  color: var(--color-primary) !important;
  text-decoration: none;
}

.nav-link {
  color: white;
  font-weight: 400;
}

.nav-link:hover,
.nav-link.active {
  color: var(--color-primary) !important;
  text-decoration: none;
}

ul {
  margin: 0;
}

.nav-item {
  text-align: center;
}
.btn {
  color: var(--color-white) !important;
}
.user {
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;
}
</style>
