<template>
  <div
    v-if="data && avatarColorConfigs"
    class="d-flex mb-3"
  >
    <div class="position-relative">
      <profile-avatar
        :url="data.provider?.user?.photo?.url"
        :fullname="username"
        :color="avatarColor"
        :background="avatarBackgroundColor"
      />
    </div>

    <div class="ms-3">
      <p class="mb-0 full-name">
        {{ data.provider?.user?.full_name ?? `@${emailUsername}` }}
      </p>
      <small>{{ data.provider?.user?.email ?? data.email }}</small>
    </div>
  </div>
</template>

<script>
import ProfileAvatar from "@/components/ProfileAvatar.vue";

import UserAvatarHelper from "@/utils/user-avatar";

export default {
  name: "InvitedProviderItem",

  components: {
    ProfileAvatar,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    avatarColorConfigs: null,
  }),

  computed: {
    username() {
      return this.data.provider?.user?.full_name ?? this.emailUsername;
    },

    avatarColor() {
      return (
        this.data.provider?.user?.profile_color ?? this.avatarColorConfigs.color
      );
    },

    avatarBackgroundColor() {
      return (
        this.data.provider?.user?.profile_background ??
        this.avatarColorConfigs.background
      );
    },

    emailUsername() {
      return this.data.email.substring(0, this.data.email.indexOf("@"));
    },
  },

  mounted() {
    this.avatarColorConfigs = this.getAvatarColorsConfigs();
  },

  methods: {
    ...UserAvatarHelper,
  },
};
</script>
