<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="{ 'modal-lg': label == 'screeningquestion' }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div
            v-if="label != 'screeningquestion'"
            class="mb-3 col-md-12"
          >
            <label class="pb-1">
              Name <span class="text-danger">*</span>
            </label>
            <input
              v-model="datasend.name"
              type="text"
              class="form-control pl-3 shadow-none"
              placeholder="Name"
              :class="{
                'is-invalid':
                  v$.datasend.name.$invalid &&
                  v$.datasend.name.$dirty,
              }"
              @blur="v$.datasend.name.$touch"
            >
            <template
              v-if="
                (v$.datasend.name.$invalid &&
                  v$.datasend.name.$dirty) ||
                  errors.nameErrorMessage
              "
            >
              <small
                v-if="v$.datasend.name.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="v$.datasend.name.minLength.$invalid"
                class="invalid-feedback"
              >
                The minimum size for this field is 3 characters
              </small>

              <small
                v-else-if="errors.nameErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.nameErrorMessage }}
              </small>
            </template>
          </div>
          <div
            v-else
            class="col-md-12"
          >
            <div class="col-md-12">
              <label class="mb-2 question-detail">type: </label>
              <select
                v-model="datasend.type"
                class="form-select mb-2 shadow-none"
              >
                <option
                  value="null"
                  disabled
                >
                  Select an Option
                </option>
                <option value="SHORT">
                  Short
                </option>
                <option value="LONG">
                  Long
                </option>
                <option value="SINGLE">
                  Single
                </option>
                <option value="MULTIPLE">
                  Multiple
                </option>
              </select>
            </div>
            <div class="mb-4">
              <label class="mb-2 question-detail">
                Question details
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12">
                <textarea
                  v-model="datasend.name"
                  cols="30"
                  rows="3"
                  :class="{
                    'is-invalid':
                      v$.datasend.name.$invalid &&
                      v$.datasend.name.$dirty,
                  }"
                  @blur="v$.datasend.name.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.name.$invalid &&
                      v$.datasend.name.$dirty) ||
                      errors.nameErrorMessage
                  "
                >
                  <small
                    v-if="
                      v$.datasend.name.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="
                      v$.datasend.name.minLength.$invalid
                    "
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3
                    characters
                  </small>

                  <small
                    v-else-if="errors.nameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.nameErrorMessage }}
                  </small>
                </template>
              </div>
            </div>
            <div
              v-if="datasend.type == 'SINGLE'"
              class="mb-4"
            >
              <div class="col-md-12">
                <label class="mt-3 mb-2 question-detail">Suggested responses - Check the right
                  answers(s):</label>
                <div
                  v-for="(item, index) in datasend.responses"
                  :key="index"
                  class="form-check d-flex align-items-center mb-2"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="radio"
                    :checked="item.right"
                    @change="
                      updateResponse(index, !item.right)
                    "
                  >
                  <input
                    v-model="item.response"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Write here..."
                  >
                  <img
                    :src="croixVectorIcon"
                    alt=""
                    class="ms-3"
                    @click="removeResponse(item)"
                  >
                </div>
                <div
                  class="form-check d-flex align-items-center mb-2"
                  @click="addResponse"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="radio"
                    disabled
                  >
                  <input
                    type="text"
                    class="form-control me-3 shadow-none"
                    placeholder="Add option"
                    disabled
                  >
                  <div class="ms-3" />
                </div>
              </div>
            </div>
            <div
              v-if="datasend.type == 'MULTIPLE'"
              class="mb-4"
            >
              <div class="col-md-12">
                <label class="mt-3 mb-2 question-detail">Suggested responses - Check the wright
                  answers(s):</label>
                <div
                  v-for="(item, index) in datasend.responses"
                  :key="index"
                  class="form-check d-flex align-items-center mb-2"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="checkbox"
                    :checked="item.right"
                    @change="
                      updateResponse(index, !item.right)
                    "
                  >
                  <input
                    v-model="item.response"
                    type="text"
                    class="form-control shadow-none"
                    placeholder="Write here..."
                  >
                  <img
                    :src="croixVectorIcon"
                    alt=""
                    class="ms-3"
                    @click="removeResponse(item)"
                  >
                </div>
                <div
                  class="form-check d-flex align-items-center mb-2"
                  @click="addResponse"
                >
                  <input
                    class="form-check-input me-2 shadow-none"
                    type="checkbox"
                    disabled
                  >
                  <input
                    type="text"
                    class="form-control me-3 shadow-none"
                    placeholder="Add option"
                    disabled
                  >
                  <div class="ms-3" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn-primary"
            :disabled="loading"
            @click="update"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
    name: "UpdateSetting",

    components: {
        SubmissionSpinner,
        AlerteDanger,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    data: () => ({
        croixVectorIcon,
        showModal: false,
        fadeModal: false,
        loading: false,
        datasend: {
            name: null,
            type: null,
            responses: [],
        },
        id: null,
        errors: {
            mutableNameErrorMessage: null,
            serverSideErrorMessage: null,
        },
    }),

    validations() {
        return {
            datasend: {
                name: {
                    required,
                    minLength: minLength(3),
                },
            },
        };
    },

    methods: {
        ...validationHelpers,

        openModal(item) {
            this.id = item.id;
            this.datasend = { ...item, responses: item.response || [] };
            delete this.datasend.response;
            this.showModal = true;
            setTimeout(() => {
                this.fadeModal = true;
            }, 200);
        },

        closeModal() {
            this.fadeModal = false;
            setTimeout(() => {
                this.showModal = false;
            }, 200);
        },

        update() {
            if (this.loading) return;

            if (this.v$.$invalid) {
                this.forceValidation();
                return;
            }

            this.loading = true;
            this.clearErrorsMessages();
            let _api =
                this.label == "screeningquestion"
                    ? api.updateJobConfigTypePut(
                          this.id,
                          this.datasend,
                          this.label
                      )
                    : api.updateJobConfigType(
                          this.id,
                          { name: this.datasend.name },
                          this.label
                      );
            _api.then((_) => {
                this.datasend = { name: null, type: null, responses: [] };
                this.resetValidation();
                this.closeModal();
                this.$emit("updateList");
            })
                .catch((error) => {
                    this.errorsHandler(error);
                })
                .then(() => {
                    this.loading = false;
                });
        },

        clearErrorsMessages() {
            this.errors.mutableNameErrorMessage = null;
            this.errors.serverSideErrorMessage = null;
        },

        errorsHandler(err) {
            if (err.response) {
                if (err.response.status === 401) {
                    this.errors.serverSideErrorMessage =
                        errorMessages.INVALID_CREDENTIALS;
                } else if (err.response.status === 400) {
                    for (const error in err.response.data) {
                        this.errors.mutableNameErrorMessage =
                            err.response.data[error];
                    }
                } else {
                    this.errors.serverSideErrorMessage =
                        errorMessages.AN_ERROR_HAS_OCCURED;
                }
            } else {
                this.errors.serverSideErrorMessage =
                    errorMessages.AN_ERROR_HAS_OCCURED;
            }
        },
        addResponse() {
            this.datasend.responses.push({ response: null, right: false });
        },
        removeResponse(item) {
            this.datasend.responses = this.datasend.responses.filter(
                (el) => el != item
            );
        },
        updateResponse(index, value) {
            if (this.datasend.type == "SINGLE") {
                this.datasend.responses.forEach((el, i) => {
                    if (index == i) {
                        el.right = value;
                    } else {
                        el.right = false;
                    }
                });
            } else {
                this.datasend.responses[index].right = value;
            }
        },
    },
};
</script>
