import { Request } from "@/services/request";

const baseUrl = "api/v1/favorite_job";

export default {
  fetchAllFavoritesJobs({
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
  }) {
    return new Request().append(`${baseUrl}/provider/`).method("get").params({
      page,
      start_date: startDate,
      end_date: endDate,
      search,
      status,
    });
  },

  addJobToFavorites(data) {
    return new Request().append(`${baseUrl}/`).method("post").setData(data);
  },

  removeJobFromFavorites(data) {
    return new Request()
      .append(`${baseUrl}/remove_favorite/`)
      .method("post")
      .setData(data);
  },
};
