<template>
  <div class="d-flex align-items-center">
    <vue-date-picker
      v-model="date"
      :placeholder="label"
      auto-apply
      range
      multi-calendars
      :enable-time-picker="false"
      :format="'MM-dd-yyyy'"
      @update:model-value="handleDateChange"
    >
      <template #trigger>
        <div class="d-block">
          <img
            :src="icons.calendarIcon"
            alt="Calendar Icon"
            class="me-1 calendar"
          >

          <label class=" me-2"> {{ label }} </label>

          <img
            :src="icons.bottomIcon"
            alt="Chevron Icon"
          >
        </div>
      </template>
    </vue-date-picker>
  </div>
</template>

<script>
import bottomIcon from "@/assets/img/icons/bottom.svg";
import calendarIcon from "@/assets/img/icons/calendar.svg";

import { DateHelpers } from "@/utils/date-helpers";

export default {
  props: {
    displayLabel: {
      type: String,
      required: false,
      default: "Select Dates",
    },

    displayFormat: {
      type: String,
      required: false,
      default: "MM-DD-YYYY",
    },
  },

  data: () => ({
    icons: {
      calendarIcon,
      bottomIcon,
    },
    date: [],
    label: null,
  }),

  watch: {
    displayLabel(val) {
      this.label = val;
    },
  },

  mounted() {
    this.label = this.displayLabel;

    let startDate = null;
    let endDate = null;
    let isQueryRangeDefined = true;

    if (this.$route.query.start_date) {
      startDate = new Date(this.$route.query.start_date);
    } else {
      startDate = new Date();
      isQueryRangeDefined = false;
    }

    if (this.$route.query.end_date) {
      endDate = new Date(this.$route.query.end_date);
    } else {
      endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      isQueryRangeDefined = false;
    }

    this.date = [startDate, endDate];

    if (isQueryRangeDefined) {
      this.formatSeletedRangeLabel(startDate, endDate);
      this.emitDateRangeChangeEvent(startDate, endDate);
    }
  },

  methods: {
    ...DateHelpers,

    handleDateChange(range) {
      if (range && range.length === 2) {
        this.formatSeletedRangeLabel(range[0], range[1]);

        this.$router.push({
          query: {
            ...this.$route.query,
            start_date: this.toURI(range[0]),
            end_date: this.toURI(range[1]),
          },
        });

        this.emitDateRangeChangeEvent(range[0], range[1]);
      }
    },

    formatSeletedRangeLabel(startDate, endDate) {
      startDate = this.formatDate(startDate, this.displayFormat);
      endDate = this.formatDate(endDate, this.displayFormat);
      this.label = `${startDate} - ${endDate}`;
    },

    emitDateRangeChangeEvent(start, end) {
      this.$emit("range:change", {
        startDate: this.toURI(start),
        endDate: this.toURI(end),
      });
    },
  },
};
</script>

<style scoped>
.date {
  border: none;
  height: 1px;
  width: 1px;
}
.calendar{
  width: 1.5rem;
  filter: brightness(0) saturate(100%) invert(37%) sepia(70%) saturate(647%) hue-rotate(69deg) brightness(105%) contrast(97%);
}
</style>
