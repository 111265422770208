<template>
  <section class="content profile dashboard-provider">
    <div class="page-header">
      <div class="title-part mb-0">
        <h4 class="title-content">
          Withdrawals
        </h4>

        <router-link
          class="btn btn-primary float-end"
          :to="{ name: 'ProviderBankAccount' }"
        >
          <span class="mb-0 text-white fw-normal">Bank Account</span>
        </router-link>
      </div>
    </div>

    <div class="col-md-12  py-4">
      <div class="row">
        <!-- <div class="col-md-12 mt-3">
          <h4 class="border-bottom text-primary">
            Balance
          </h4>
        </div> -->

        <div class="row mb-3">
          <div class="col-md-3">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ formatPrice(0) }}</h3>

                <p>Your Balance</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ formatPrice(0) }}</h3>

                <p>Available in Soon</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ formatPrice(0) }}</h3>

                <p>Withdrawal Tax</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ formatPrice(0) }}</h3>

                <p>Net Values</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
    >
      <div class="col-md-12 mt-3">
        <h4 class="title-content">
          Withdrawal Account
        </h4>
      </div>

      <div class="col-6">
        <div class="col-md-12 mt-3">
          <p class="">
            Last Withdrawals
          </p>
        </div>

        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody>
              <tr
                v-for="(item, index) in listWithdraw"
                :key="index"
              >
                <td>
                  {{ item.zip }}
                </td>

                <td>
                  {{ item.city }}
                </td>
                <td>
                  {{ item.state }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <page-action
          :index="pageWithdraw"
          :infos="pagesWithdraw"
          @update:index="fetchClientProperties($event), (page = $event)"
        />
      </div>

      <div class="col-6">
        <div class="col-md-12 mt-3">
          <p class="">
            Last Invoices
          </p>
        </div>

        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody>
              <tr
                v-for="(item, index) in listInvoice.filter(el => el.status !== 'pending')"
                :key="index"
              >
                <td>
                  {{ formatPrice(item.total) }}
                </td>
                <td>
                  {{ formatDate(item.send) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <loader-spinner v-if="loadingInvoices" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchCreditCards($event)"
            @onPrev="fetchCreditCards(currentPage - 1 || 1)"
            @onNext="fetchCreditCards(currentPage + 1)"
          /> -->
        </div>
        <page-action
          :index="pageInvoice"
          :infos="pagesInvoice"
          @update:index="
            fetchEstimates({ page: $event, status }), (page = $event)
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants } from "@/configs/constants";
import PriceHelpers from "@/utils/price-format";

import { DateHelpers } from "@/utils/date-helpers";
import { CustomerStatusList } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import PageAction from "@/components/PageAction.vue";
import HeadTable from "@/components/HeadTable.vue";

export default {
  name: "SuperadminCustomerDetailsPage",

  components: { 
    PageAction, 
    HeadTable,
    // AppPagination,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      CustomerStatusList,
      thead: ["Amount", "Date Sent", "Paid or Unpaid"], 
      listWithdraw: [],
      listInvoice: [],
      client: null,
      loadingInvoices: false,
      loading: true,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: "Client info",
      pageWithdraw: 1,
      pageInvoice: 1,
      pagesWithdraw: {
        count: null,
        max_page_size: null,
        total_pages: null,
      },
      pagesInvoice: {
        count: null,
        max_page_size: null,
        total_pages: null,
      },
    };
  },

  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  async mounted() {
    this.fetchInvoices({ page: 1 });
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,
    ...PriceHelpers,

    fetchInvoices(params) {
      this.loading = true;

      api
        .fetchInvoices(params)
        .then((response) => {
          this.listInvoice = response.data.data;
          this.pagesInvoice = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    changeBankAccount() {
      this.$router.push({ name: "ProviderChangeBankAccount" });
    },
  },
};
</script>
