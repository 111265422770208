<template>
  <div
    id="provider-layout"
    :class="{
      'position-fixed': isShow,
      'need-to-complete-profile':
        currentProvider?.status === providerStatus.NEED_COMPLETE_PROFILE,
    }"
  >
    <provider-header
      :toggle-menu="toggleMenu"
      @toggleMenu="toggleMenu = !toggleMenu"
      @toggleMobileMenu="toggleMobileMenu"
    />

    <div class="provider-dashboard-layout">
      <div
        class="provider-navbar"
        :class="{ 'd-block ': isShow, 'width80px': toggleMenu }"
      >
        <provider-menu 
          :toggle-menu="toggleMenu"
          @closeMobileMenu="closeMobileMenu"
        />
      </div>

      <div
        ref="scrollContainer"
        :class="toggleMenu ? 'provider-dashboard-content-width' : 'provider-dashboard-content'"
      >
        <div class="row">
          <div
            class="col-lg-12"
            :class="{'mt-5': !toggleMenu}"
          >
            <div class="vh-80">
              <router-view />
            </div>
          </div>
        </div>

        <app-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";

import AppFooter from "@/components/Footer.vue";
import ProviderHeader from "@/components/provider/ProviderHeader.vue";
import ProviderMenu from "@/components/provider/Menu";

import { ProviderStatus } from "@/configs/constants/users";
import { GlobalEvents } from '@/configs/constants';

export default {
  name: "ProviderLayout",

  components: {
    AppFooter,
    ProviderHeader,
    ProviderMenu,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.$refs.scrollContainer.scrollTop = 0;
      });
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (!this.alreadyScrolledToTop) {
      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = 0;
        this.alreadyScrolledToTop = true;
      });
    }
    next();
  },

  data: () => ({
    isShow: false,
    toggleMenu: false,
    alreadyScrolledToTop: false,
  }),

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    providerStatus() {
      return ProviderStatus;
    },
  },

  watch: {
    $route() {
      this.alreadyScrolledToTop = false;
    },
  },

  mounted() {
    this.$emitter.on(GlobalEvents.SCROLL_TO_TOP, () => {
      nextTick(() => {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTop = 0;
        }
      });
    });
  },

  methods: {
    toggleMobileMenu() {
      this.isShow = !this.isShow;
    },

    closeMobileMenu() {
      this.isShow = false;
    },
  },
};
</script>
