<template>
  <div class="col-xl-8 mx-auto">
    <p
      v-if="!emailVerified"
      class="title-auth text-center text-primary"
    >
      Email Address Verification
    </p>
    <loader-spinner v-if="loading" />

    <alerte-danger
      :head-error-message="errors.serverSideErrorMessage"
      @updateContent="errors.serverSideErrorMessage = $event"
    />

    <form
      v-if="canVerifyEmail"
      @submit.prevent="verifyEmail"
    >
      <template v-if="emailVerified">
        <div class="text-center fs-1 my-5">
          <img
            :src="icons.FrameSuccess"
            alt="icon"
          >
          
          <p class="title-auth text-center text-primary">
            Congratulations!
          </p>
          <p>
            Your email address has been successfully verified.
          </p>
        </div>

        <p
          v-if="!isAuthenticated"
          class="back-to-login"
        >
          Back to
          <router-link
            :to="{ name: 'Login' }"
            class="text-decoration-underline"
          >
            Log In
          </router-link>
        </p>
      </template>

      <div
        v-else
        class="text-center"
      >
        <p class="fs-1 mb-5">
          Click to the following button to verify your account.
          <br>
          Please verify the email address by clicking the button below.
        </p>

        <button
          type="submit"
          class="btn-primary submit-button mb-3"
          :disabled="submitting"
        >
          <submission-spinner v-if="submitting" />
          Verify Account
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/services/api";

import errorMessages from "@/utils/error-messages";
import FrameSuccess from "@/assets/img/icons/FrameSuccess.svg";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "VerifyEmailPage",

  components: {
    LoaderSpinner,
    SubmissionSpinner,
    AlerteDanger,
  },

  data: () => ({
    icons: {FrameSuccess},
    showNewPassword: false,
    showConfirmPassword: false,
    loading: false,
    email: null,
    submitting: false,
    emailVerified: false,
    canVerifyEmail: false,
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    isAuthenticated() {
      return this.$store.getters["token/isAuthenticated"];
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },
  },

  created() {
    this.loading = true;
    const query = this.$route.query;

    if (!query.token) {
      this.canVerifyEmail = false;
      this.loading = false;
      this.errors.serverSideErrorMessage =
        "This link has already expired or is invalid.";
      return;
    }

    this.getUserByToken(query.token);
  },

  methods: {
    async getUserByToken(token) {
      try {
        const response = await api.checkUserTokenFOrEmailVerification(token);
        this.email = response.data.user;
        this.canVerifyEmail = true;
      } catch (err) {
        this.errors.serverSideErrorMessage =
          "This link has already expired or is invalid.";
      } finally {
        this.loading = false;
      }
    },

    async verifyEmail() {
      if (this.submitting) return;

      this.submitting = true;
      const formData = {
        token: this.$route.query.token,
      };

      try {
        await api.verifyEmailAddress(formData);
        this.emailVerified = true;
      } catch (err) {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
