import moment, { unitOfTime } from "moment";

export const DateHelpers = {
  formatDate(date, format = "MMMM Do YYYY") {
    return moment(date).format(format);
  },

  formatDateHourToDay(date) {
    const startDate = moment(new Date());
    const endDate = moment(date);

    const duration = moment.duration(endDate.diff(startDate));
    return duration.humanize(true)
  },

  fromNow(date) {
    return moment(date).fromNow();
  },

  removeDays(date, days) {
    let _date = date !== null ? moment(date) : moment();
    return _date.subtract(days, "days");
  },

  addDays(date, days) {
    let _date = date !== null ? moment(date) : moment();
    return _date.add(days, "days");
  },

  createMoment(date) {
    if (date == null) {
      return null;
    }
    return moment(date);
  },

  today() {
    return moment();
  },

  toURI(date = null) {
    if (date == null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  },

  diffDays(date1, date2, unitOfTime: unitOfTime.Diff = "days") {
    return Math.abs(moment(date1).diff(moment(date2), unitOfTime));
  },

  replaceFormat(value) {
    return (
      value.substring(4, 8) +
      "/" +
      value.substring(0, 2) +
      "/" +
      value.substring(2, 4)
    );
  },

  rangeDate(value) {
    return moment(value).format("MMMM DD,YYYY");
  },

  isSame(date1, date2, granularity: unitOfTime.StartOf = "day") {
    return moment(date1).isSame(moment(date2), granularity);
  },

  formatPublishDate(date) {
    const diffDate = moment().diff(moment(date), 'days')
    if (diffDate >= 1) {
      if (diffDate >= 30) {
        if (diffDate >= 365) {
          return `${moment(date).format('DD MMMM YYYY')}`
        }
        return `${Math.floor(diffDate / 30)} months ago`
      }
      return `${diffDate} ${diffDate > 1 ? 'days' : 'day'} ago`
    }
    const diffHour = moment().diff(moment(date), 'hours')
    if (diffHour >= 1) {
      return `${diffHour} ${diffHour > 1 ? 'hours' : 'hour'} ago`
    }
    const diffMinute = moment().diff(moment(date), 'minutes')
    if (diffMinute === 0) {
      return `Just now`
    }
    return `${diffMinute} ${diffMinute > 1 ? 'minutes' : 'minute'} ago`
  }
};
