<template>
  <div class="section filters">
    <div class="head">
      <img
        :src="icons.filterVectorIcon"
        alt="Filter Icon"
      >
      Filter by
    </div>

    <div
      class="mt-2"
    >
      <input
        v-model="filterData.search"
        type="text"
        class="py-2 input-search w-100 shadow-none"
        placeholder="Search..."
        @input="handleSearchChange"
      >
    </div>
    <div
      class="job-filter-box"
    >
      <div class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <label class="filter-part">Category</label>

          <a
            v-if="categories.length > 4"
            href="javascript:void(0)"
            @click="(showAllCategories = !showAllCategories)"
          >
            {{ (!showAllCategories ? 'View All':'View less') }}
          </a>
        </div>

        <div class="check-list">
          <div
            v-for="(category, index) in displayedCategories"
            :key="index"
            class="form-check"
          >
            <input
              :id="`category-${index}`"
              v-model="filterData.categories"
              class="form-check-input shadow-none"
              type="checkbox"
              :value="category.id"

              @change="handleCategoryChange"
            >
            <label
              class="form-check-label"
              :for="`category-${index}`"
            >
              {{ category.name }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <label class="filter-part contract-type">Contract Type</label>

          <a
            v-if="contractTypes.length > 4"
            href="javascript:void(0)"
            @click="(showAllContractTypes = !showAllContractTypes)"
          >
            {{ (!showAllContractTypes ? 'View All':'View less') }}
          </a>
        </div>

        <div class="check-list">
          <div
            v-for="(contract, index) in displayedContractTypes"
            :key="index"
            class="form-check"
          >
            <input
              :id="`contract-type-${index}`"
              v-model="filterData.contractTypes"
              class="form-check-input shadow-none"
              type="checkbox"
              :value="contract.id"

              @change="handleContractTypeChange"
            >
            <label
              class="form-check-label"
              :for="`contract-type-${index}`"
            >
              {{ contract.name }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <label class="filter-part contract-type">Level</label>

          <a
            v-if="levels.length > 4"
            href="javascript:void(0)"
            @click="(showAllLevels = !showAllLevels)"
          >
            {{ (!showAllLevels ? 'View All':'View less') }}
          </a>
        </div>

        <div class="check-list">
          <div
            v-for="(level, index) in displayedLevels"
            :key="index"
            class="form-check"
          >
            <input
              :id="`level-${index}`"
              v-model="filterData.levels"
              class="form-check-input shadow-none"
              type="checkbox"
              :value="level.id"

              @change="handleLevelChange"
            >
            <label
              class="form-check-label"
              :for="`level-${index}`"
            >
              {{ level.name }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <label class="filter-part">Skills</label>

          <a
            v-if="skills.length > 4"
            href="javascript:void(0)"
            @click="(showAllSkills = !showAllSkills)"
          >
            {{ (!showAllSkills ? 'View All':'View less') }}
          </a>
        </div>

        <div class="ml-3 language">
          <button
            v-for="(skill, index) in displayedSkills"
            :key="index"
            class="type-job m-1"
            :class="{ selected: isSkillSelected(skill.id) }"

            @click="toggleSkillSelection(skill.id)"
          >
            {{ skill.name }}
          </button>
        </div>
      </div>

      <div class="mt-4">
        <p class="filter-part">
          Budget Type
        </p>

        <div class="check-list">
          <div
            v-for="(budgetType, index) in jobBudgetTypes"
            :key="index"
            class="form-check"
          >
            <input
              :id="`jobBudgetType-${index}`"
              v-model="filterData.budgetTypes"
              class="form-check-input shadow-none"
              type="checkbox"
              name="flexRadioDefault"
              :value="budgetType.value"

              @change="handleBudgetTypeChange"
            >
            <label
              class="form-check-label capitalize"
              :for="`jobBudgetType-${index}`"
            >
              {{ capitalizeFirstLetter(budgetType.label) }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="filter-part">
          Payment Frequency
        </p>

        <div class="check-list">
          <div
            v-for="(paymentFrequency, index) in jobPaymentFrequencies"
            :key="index"
            class="form-check"
          >
            <input
              :id="`jobPaymentFrequencies-${index}`"
              v-model="filterData.paymentFrequencies"
              class="form-check-input shadow-none"
              type="checkbox"
              name="flexRadioDefault"
              :value="paymentFrequency"

              @change="handlePaymentFrequencyChange"
            >
            <label
              class="form-check-label"
              :for="`jobPaymentFrequencies-${index}`"
            >
              {{ capitalizeFirstLetter(paymentFrequency) }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="filter-part">
          Price
        </p>

        <div class="row">
          <div class="col-lg-6">
            <p>From</p>

            <money
              v-model="filterData.budgetFrom"
              prefix="$"

              @change="handleBudgetFromChange"
            />
          </div>

          <div class="col-lg-6">
            <p>To</p>

            <money
              v-model="filterData.budgetTo"
              prefix="$"

              @change="handleBudgetToChange"
            />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="filter-part">
          Job Type
        </p>

        <div class="check-list">
          <div
            v-for="(jobType, index) in jobTypes"
            :key="index"
            class="form-check"
          >
            <input
              :id="`jobType-${index}`"
              v-model="filterData.jobTypes"
              class="form-check-input shadow-none"
              type="checkbox"
              name="flexRadioDefault"
              :value="jobType.value"

              @change="handleJobTypeChange"
            >
            <label
              class="form-check-label"
              :for="`jobType-${index}`"
            >
              {{ capitalizeFirstLetter(jobType.label) }}
            </label>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="filter-part">
          Location
        </p>

        <div class="row">
          <div class="col-lg-12 mb-3">
            <p>Address</p>
            <GMapAutocomplete
              :value="filterData.location"
              type="text"
              class="py-2 mt-0 input-search w-100 shadow-none"
              placeholder="Search by location..."

              @place_changed="handleLocationChange"
              @change="handleLocationChange"
            />
          </div>

          <div class="col-lg-12">
            <p class="text-nowrap">
              Radius (miles)
            </p>
            <input
              v-model="filterData.radius"
              type="number"
              class="py-2 shadow-none"
              placeholder="Enter radius..."

              @input="handleRadiusChange"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money3Component } from "v-money3";

import api from "@/services/api";

import {
  JobTypes,
  JobBudgetTypes,
  JobPaymentFrequencies,
} from "@/configs/constants/jobs";

import filterVectorIcon from "@/assets/img/icons/search.svg";

export default {
  name: "CustomerJobFilters",

  components: {
    money: Money3Component,
  },

  data: () => ({
    icons: {
      filterVectorIcon,
    },
    filterData: {
      categories: [],
      contractTypes: [],
      levels: [],
      skills: [],
      budgetTypes: [],
      paymentFrequencies: [],
      jobTypes: [],
      budgetFrom: 0,
      budgetTo: 0,
      search: null,
      location: null,
      radius: null,
      lat: null,
      lng: null,
    },
    showAllSkills: false,
    showAllCategories: false,
    showAllContractTypes: false,
    showAllLevels: false,
    skills: [],
    levels: [],
    contractTypes: [],
    categories: [],
    filters: {
      category: undefined,
      contractType: undefined,
      level: undefined,
      skills: undefined,
      budgetType: undefined,
      paymentFrequency: undefined,
      jobType: undefined,
      budgetFrom: undefined,
      budgetTo: undefined,
      search: undefined,
      radius: undefined,
    },
  }),

  computed: {
    jobBudgetTypes() {
      const items = [
        { label: "Fixed", value: JobBudgetTypes.FIXED },
        { label: "Range", value: JobBudgetTypes.RANGE },
        { label: "Not Sure", value: JobBudgetTypes.NOT_SURE },
      ];

      return items;
    },

    jobPaymentFrequencies() {
      return JobPaymentFrequencies;
    },

    jobTypes() {
      const items = [
        { label: "On Site", value: JobTypes.ON_SITE },
        { label: "Remote", value: JobTypes.REMOTE },
        { label: "Multi-site", value: JobTypes.MULTISITE },
      ];

      return items;
    },

    displayedSkills() {
      return this.showAllSkills ? this.skills : this.skills.slice(0, 4);
    },

    displayedContractTypes() {
      return this.showAllContractTypes
        ? this.contractTypes
        : this.contractTypes.slice(0, 4);
    },

    displayedCategories() {
      return this.showAllCategories
        ? this.categories
        : this.categories.slice(0, 4);
    },

    displayedLevels() {
      return this.showAllLevels ? this.levels : this.levels.slice(0, 4);
    },
  },

  mounted() {
    if (this.$route.query.search) {
      this.filters.search = decodeURIComponent(this.$route.query.search);
      this.filterData.search = decodeURIComponent(this.$route.query.search);
    }

    if (this.$route.query.categories) {
      this.filters.category = this.$route.query.categories;
      this.filterData.categories = this.$route.query.categories.split(",");
    }

    if (this.$route.query.contract_types) {
      this.filters.contractType = this.$route.query.contract_types;
      this.filterData.contractTypes =
        this.$route.query.contract_types.split(",");
    }

    if (this.$route.query.levels) {
      this.filters.level = this.$route.query.levels;
      this.filterData.levels = this.$route.query.levels.split(",");
    }

    if (this.$route.query.skills) {
      this.filters.skills = this.$route.query.skills;
      this.filterData.skills = this.$route.query.skills.split(",");
    }

    if (this.$route.query.budget_types) {
      this.filters.budgetType = this.$route.query.budget_types;
      this.filterData.budgetTypes = this.$route.query.budget_types.split(",");
    }

    if (this.$route.query.payment_frequencies) {
      this.filters.paymentFrequency = this.$route.query.payment_frequencies;
      this.filterData.paymentFrequencies =
        this.$route.query.payment_frequencies.split(",");
    }

    if (this.$route.query.budget_from) {
      this.filters.budgetFrom = Number(this.$route.query.budget_from);
      this.filterData.budgetFrom = this.$route.query.budget_from;
    }

    if (this.$route.query.budget_to) {
      this.filters.budgetTo = Number(this.$route.query.budget_to);
      this.filterData.budgetTo = this.$route.query.budget_to;
    }

    if (this.$route.query.job_types) {
      this.filters.jobType = this.$route.query.job_types;
      this.filterData.jobTypes = this.$route.query.job_types.split(",");
    }

    if (this.$route.query.location) {
      this.filters.location = decodeURIComponent(this.$route.query.location);
      this.filterData.location = decodeURIComponent(this.$route.query.location);

      if (this.$route.query.lat) {
        this.filters.lat = Number(this.$route.query.lat);
        this.filterData.lat = this.$route.query.lat;
      }

      if (this.$route.query.lng) {
        this.filters.lng = Number(this.$route.query.lng);
        this.filterData.lng = this.$route.query.lng;
      }
    }

    if (!this.$route.query.lat || !this.$route.query.lng) {
      this.filters.location = undefined;
      this.filterData.location = undefined;
    }

    if (this.$route.query.radius) {
      this.filters.radius = Number(this.$route.query.radius);
      this.filterData.radius = this.$route.query.radius;
    }

    this.fetchJobSetting();
  },

  methods: {
    async fetchJobSetting() {
      if (this.loading) return;
      this.loading = true;
      try {
        await api.fetchJobSetting((data) => {
          this.categories = data.category;
          this.skills = data.skills;
          this.contractTypes = data.contract_type;
          this.levels = data.level;
        });
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    isSkillSelected(id) {
      return (
        this.filterData.skills.findIndex((skillId) => skillId === id) !== -1
      );
    },

    toggleSkillSelection(id) {
      const index = this.filterData.skills.findIndex(
        (skillId) => skillId === id
      );
      if (index === -1) {
        this.filterData.skills.push(id);
      } else {
        this.filterData.skills.splice(index, 1);
      }

      this.handleSkillsChange();
    },

    handleSearchChange() {
      if (this.filterData.search) {
        this.filters.search = encodeURIComponent(this.filterData.search);
      } else {
        this.filters.search = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          search: this.filters.search ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleCategoryChange() {
      if (this.filterData.categories.length > 0) {
        this.filters.category = this.filterData.categories.toString();
      } else {
        this.filters.category = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          categories: this.filters.category ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleContractTypeChange() {
      if (this.filterData.contractTypes.length > 0) {
        this.filters.contractType = this.filterData.contractTypes.toString();
      } else {
        this.filters.contractType = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          contract_types: this.filters.contractType ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleLevelChange() {
      if (this.filterData.levels.length > 0) {
        this.filters.level = this.filterData.levels.toString();
      } else {
        this.filters.level = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          levels: this.filters.level ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleSkillsChange() {
      if (this.filterData.skills.length > 0) {
        this.filters.skills = this.filterData.skills.toString();
      } else {
        this.filters.skills = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          skills: this.filters.skills ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleBudgetTypeChange() {
      if (this.filterData.budgetTypes.length > 0) {
        this.filters.budgetType = this.filterData.budgetTypes.toString();
      } else {
        this.filters.budgetType = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          budget_types: this.filters.budgetType ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handlePaymentFrequencyChange() {
      if (this.filterData.paymentFrequencies.length > 0) {
        this.filters.paymentFrequency =
          this.filterData.paymentFrequencies.toString();
      } else {
        this.filters.paymentFrequency = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          payment_frequencies: this.filters.paymentFrequency ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleBudgetFromChange() {
      if (Number(this.filterData.budgetFrom)) {
        this.filters.budgetFrom = Number(this.filterData.budgetFrom);
      } else {
        this.filters.budgetFrom = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          budget_from: this.filters.budgetFrom ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleBudgetToChange() {
      if (Number(this.filterData.budgetTo)) {
        this.filters.budgetTo = Number(this.filterData.budgetTo);
      } else {
        this.filters.budgetTo = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          budget_to: this.filters.budgetTo ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleJobTypeChange() {
      if (this.filterData.jobTypes.length > 0) {
        this.filters.jobType = this.filterData.jobTypes.toString();
      } else {
        this.filters.jobType = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          job_types: this.filters.jobType ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    setLocation(values) {
      if (values) {
        const lat = values.geometry?.location?.lat();
        const lng = values.geometry?.location?.lng();

        this.filterData.lat = lat
        this.filterData.lng = lng
        this.filterData.location = values.formatted_address
      }
    },

    handleLocationChange(values) {
      this.setLocation(values)

      if (this.filterData.location) {
        this.filters.location = encodeURIComponent(this.filterData.location);
      } else {
        this.filters.location = null;
      }

      if (this.filterData.lat) {
        this.filters.lat = Number(this.filterData.lat);
      } else {
        this.filters.lat = null;
      }

      if (this.filterData.lng) {
        this.filters.lng = Number(this.filterData.lng);
      } else {
        this.filters.lng = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          lng: this.filters.lng ?? undefined,
          lat: this.filters.lat ?? undefined,
          location: this.filters.location ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },

    handleRadiusChange() {
      if (this.filterData.radius) {
        this.filters.radius = Number(this.filterData.radius);
      } else {
        this.filters.radius = null;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          radius: this.filters.radius ?? undefined,
        },
      });

      this.$emit("onCriteriasChange", this.filters);
    },
  },
};
</script>

<style scoped>
.capitalize:first-letter {
  text-transform: uppercase;
}
.type-job.selected {
  background: #d2d5db;
}
.form-check-label {
  word-break: break-word;
}
</style>
