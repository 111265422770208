<template>
  <div>
    <list-header
      title="Credit Cards"
      :add="true"
      @create="
        $router.push({
          name: isProvider
            ? 'ProviderCreateCreditCard'
            : 'CustomerCreateCreditCard',
        })
      "
    />

    <div class="row">
      <div class="col-md-8 offset-md-2">
        <alerte-danger
          :head-error-message="errors.serverSideErrorMessage"
          @updateContent="errors.serverSideErrorMessage = $event"
        />
      </div>

      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  {{ item.brand }}
                </td>

                <td>
                  {{ item.holder_name }}
                </td>

                <td>
                  {{ `**** **** **** ${item.last4}` }}
                </td>

                <td>{{ item.exp_month }}/{{ item.exp_year }}</td>

                <td>
                  {{ item.card_default ? "Yes" : "No" }}
                </td>

                <td>
                  <submission-spinner
                    v-if="submitting && selectedCreditCard?.id === item.id"
                    :color="'#209516'"
                  />

                  <div
                    v-else
                    class="dropdown nav-links"
                  >
                    <a
                      class="item p-2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        :src="icons.dotVectorIcon"
                        alt="Menu Icon"
                      >
                    </a>

                    <ul class="dropdown-menu">
                      <li v-if="!item.card_default">
                        <a
                          class="dropdown-item"
                          @click.prevent="
                            confirmClaim(actions.SET_AS_DEFAULT, item)
                          "
                        >
                          Set as default
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item cursor-pointer"
                          @click.prevent="confirmClaim(actions.DELETE, item)"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchCreditCards($event)"
            @onPrev="fetchCreditCards(currentPage - 1 || 1)"
            @onNext="fetchCreditCards(currentPage + 1)"
          />
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";

import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import errorMessages from "@/utils/error-messages";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CreditCardsListPage",

  components: {
    ListHeader,
    HeadTable,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
    AppPagination,
    AlerteDanger,
  },

  mixins: [ListMixin(api.fetchCreditCards, true)],

  data: () => ({
    thead: [
      "Brand",
      "Card Holder Name",
      "Last 4 Digits",
      "Exp Date",
      "Default",
      "",
    ],
    icons: {
      dotVectorIcon,
    },
    fetching: false,
    submitting: false,
    selectedCreditCard: null,
    actions: {
      DELETE: "delete-credit-card",
      SET_AS_DEFAULT: "set-as-default",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    isProvider() {
      return this.$store.getters["token/isProvider"];
    },
  },

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchCreditCards(Number(this.$route.query.page) || 1);
  },

  methods: {
    fetchCreditCards(page) {
      this.fetching = true;
      this.updateListFilters({
        page,
      });
    },

    confirmClaim(action, card) {
      switch (action) {
        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete **** **** **** ${card.last4} ?`;
          break;

        case this.actions.SET_AS_DEFAULT:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to set **** **** **** ${card.last4} as default ?`;
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      this.selectedCreditCard = card;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.DELETE:
          this.deleteCreditCard();
          break;

        case this.actions.SET_AS_DEFAULT:
          this.makeCreditCardAsDefault();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    async makeCreditCardAsDefault() {
      try {
        this.submitting = true;
        await api.makeCreditCardDefault(this.selectedCreditCard.id);

        this.listData.forEach((card) => {
          card.card_default = card.id === this.selectedCreditCard.id;
        });
        this.selectedCreditCard = null;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.submitting = false;
      }
    },

    async deleteCreditCard() {
      try {
        this.submitting = true;
        await api.deleteCreditCard(this.selectedCreditCard.id);

        this.fetchCreditCards();
        this.selectedCreditCard = null;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.submitting = false;
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            console.error(err.response.data.error);
            this.errors.serverSideErrorMessage = err.response.data.error;
            break;

          case 404:
            this.errors.notFoundErrorMessage =
              errorMessages.CREDIT_CARD_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
