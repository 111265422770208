<template>
  <div class="row">
    <div class="col-lg-10 offset-lg-1">
      <section class="content">
        <div class="page-header">
          <h3>Create Plan</h3>
        </div>

        <div class="page-content">
          <form @submit.prevent="save">
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />

            <div class="row">
              <p class="title mb-3">
                Plan details
              </p>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Name <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.name"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write name..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
                      errors.name,
                  }"
                  @blur="v$.datasend.name.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
                      errors.name
                  "
                >
                  <small
                    v-if="v$.datasend.name.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.name"
                    class="invalid-feedback"
                  >
                    {{ errors.name }}
                  </small>
                </template>
              </div>

              <div class="mb-3 col-md-6">
                <label class="form-label">
                  Price <span class="text-danger">*</span>
                </label>
                <money3
                  v-model="datasend.value"
                  prefix="$"
                  class="form-control p-3"
                  :class="{
                    'is-invalid':
                      (v$.datasend.value.$invalid &&
                        v$.datasend.value.$dirty) ||
                      errors.value,
                  }"
                  @blur="v$.datasend.value.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.value.$invalid && v$.datasend.value.$dirty) ||
                      errors.value
                  "
                >
                  <small
                    v-if="v$.datasend.value.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.value.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.value"
                    class="invalid-feedback"
                  >
                    {{ errors.value }}
                  </small>
                </template>
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Type <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.type"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.datasend.type.$invalid && v$.datasend.type.$dirty) ||
                      errors.type,
                  }"
                  @blur="v$.datasend.type.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a type
                  </option>
                  <option value="customer">
                    Customer
                  </option>
                  <option value="provider">
                    Provider
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.type.$invalid && v$.datasend.type.$dirty) ||
                      errors.type
                  "
                >
                  <small
                    v-if="v$.datasend.type.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.type"
                    class="invalid-feedback"
                  >
                    {{ errors.type }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Recurrency Type <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.recurrency_type"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.datasend.recurrency_type.$invalid &&
                        v$.datasend.recurrency_type.$dirty) ||
                      errors.recurrency_type,
                  }"
                  @blur="v$.datasend.recurrency_type.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a recurrency type
                  </option>
                  <option
                    v-for="(recurrency, index) in [
                      'day',
                      'week',
                      'month',
                      'year',
                    ]"
                    :key="index"
                    :value="recurrency"
                  >
                    {{ recurrency }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.recurrency_type.$invalid &&
                      v$.datasend.recurrency_type.$dirty) ||
                      errors.recurrency_type
                  "
                >
                  <small
                    v-if="v$.datasend.recurrency_type.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.recurrency_type"
                    class="invalid-feedback"
                  >
                    {{ errors.recurrency_type }}
                  </small>
                </template>
              </div>

              <div class="col-md-12 mb-3">
                <label class="form-label">
                  Commercial description <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.commercial_description"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write commercial description..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.commercial_description.$invalid &&
                        v$.datasend.commercial_description.$dirty) ||
                      errors.commercial_description,
                  }"
                  @blur="v$.datasend.commercial_description.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.commercial_description.$invalid &&
                      v$.datasend.commercial_description.$dirty) ||
                      errors.commercial_description
                  "
                >
                  <small
                    v-if="v$.datasend.commercial_description.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="
                      v$.datasend.commercial_description.minLength.$invalid
                    "
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.commercial_description"
                    class="invalid-feedback"
                  >
                    {{ errors.commercial_description }}
                  </small>
                </template>
              </div>
            </div>

            <div class="mb-3 row">
              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Streamlined bill percentage
                  <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.streamlined_bill_percentage"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write streamlined bill percentage..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.streamlined_bill_percentage.$invalid &&
                        v$.datasend.streamlined_bill_percentage.$dirty) ||
                      errors.streamlined_bill_percentage,
                  }"
                  @blur="v$.datasend.streamlined_bill_percentage.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.streamlined_bill_percentage.$invalid &&
                      v$.datasend.streamlined_bill_percentage.$dirty) ||
                      errors.streamlined_bill_percentage
                  "
                >
                  <small
                    v-if="
                      v$.datasend.streamlined_bill_percentage.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.streamlined_bill_percentage"
                    class="invalid-feedback"
                  >
                    {{ errors.streamlined_bill_percentage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Per project service fee <span class="text-danger">*</span>
                </label>
                <money3
                  v-model="datasend.per_project_service_fee"
                  prefix="$"
                  class="form-control p-3"
                  :class="{
                    'is-invalid':
                      (v$.datasend.per_project_service_fee.$invalid &&
                        v$.datasend.per_project_service_fee.$dirty) ||
                      errors.per_project_service_fee,
                  }"
                  @blur="v$.datasend.per_project_service_fee.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.per_project_service_fee.$invalid &&
                      v$.datasend.per_project_service_fee.$dirty) ||
                      errors.per_project_service_fee
                  "
                >
                  <small
                    v-if="v$.datasend.per_project_service_fee.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.per_project_service_fee"
                    class="invalid-feedback"
                  >
                    {{ errors.per_project_service_fee }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Tax percentage <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.tax_percentage"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write tax percentage..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.tax_percentage.$invalid &&
                        v$.datasend.tax_percentage.$dirty) ||
                      errors.tax_percentage,
                  }"
                  @blur="v$.datasend.tax_percentage.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.tax_percentage.$invalid &&
                      v$.datasend.tax_percentage.$dirty) ||
                      errors.tax_percentage
                  "
                >
                  <small
                    v-if="v$.datasend.tax_percentage.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.tax_percentage"
                    class="invalid-feedback"
                  >
                    {{ errors.tax_percentage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Invoice fee percentage <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.invoice_fee_percentage"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write invoice fee percentage..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.invoice_fee_percentage.$invalid &&
                        v$.datasend.invoice_fee_percentage.$dirty) ||
                      errors.invoice_fee_percentage,
                  }"
                  @blur="v$.datasend.invoice_fee_percentage.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.invoice_fee_percentage.$invalid &&
                      v$.datasend.invoice_fee_percentage.$dirty) ||
                      errors.invoice_fee_percentage
                  "
                >
                  <small
                    v-if="v$.datasend.invoice_fee_percentage.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.invoice_fee_percentage"
                    class="invalid-feedback"
                  >
                    {{ errors.invoice_fee_percentage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Invoice fee fix <span class="text-danger">*</span>
                </label>
                <money3
                  v-model="datasend.invoice_fee_fix"
                  prefix="$"
                  class="form-control p-3"
                  :class="{
                    'is-invalid':
                      (v$.datasend.invoice_fee_fix.$invalid &&
                        v$.datasend.invoice_fee_fix.$dirty) ||
                      errors.invoice_fee_fix,
                  }"
                  @blur="v$.datasend.invoice_fee_fix.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.invoice_fee_fix.$invalid &&
                      v$.datasend.invoice_fee_fix.$dirty) ||
                      errors.invoice_fee_fix
                  "
                >
                  <small
                    v-if="v$.datasend.invoice_fee_fix.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.invoice_fee_fix"
                    class="invalid-feedback"
                  >
                    {{ errors.invoice_fee_fix }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Withdraw value fix <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.withdraw_value_fix"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write withdraw value fix..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.withdraw_value_fix.$invalid &&
                        v$.datasend.withdraw_value_fix.$dirty) ||
                      errors.withdraw_value_fix,
                  }"
                  @blur="v$.datasend.withdraw_value_fix.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.withdraw_value_fix.$invalid &&
                      v$.datasend.withdraw_value_fix.$dirty) ||
                      errors.withdraw_value_fix
                  "
                >
                  <small
                    v-if="v$.datasend.withdraw_value_fix.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.withdraw_value_fix"
                    class="invalid-feedback"
                  >
                    {{ errors.withdraw_value_fix }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Interval count <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.interval_count"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write interval count..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.interval_count.$invalid &&
                        v$.datasend.interval_count.$dirty) ||
                      errors.interval_count,
                  }"
                  @blur="v$.datasend.interval_count.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.interval_count.$invalid &&
                      v$.datasend.interval_count.$dirty) ||
                      errors.interval_count
                  "
                >
                  <small
                    v-if="v$.datasend.interval_count.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.interval_count"
                    class="invalid-feedback"
                  >
                    {{ errors.interval_count }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">
                  Number of Bids <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.number_of_bids"
                  type="number"
                  step="any"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write Number of Bids..."
                  :class="{
                    'is-invalid':
                      (v$.datasend.number_of_bids.$invalid &&
                        v$.datasend.number_of_bids.$dirty) ||
                      errors.number_of_bids,
                  }"
                  @blur="v$.datasend.number_of_bids.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.number_of_bids.$invalid &&
                      v$.datasend.number_of_bids.$dirty) ||
                      errors.number_of_bids
                  "
                >
                  <small
                    v-if="v$.datasend.number_of_bids.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.number_of_bids"
                    class="invalid-feedback"
                  >
                    {{ errors.number_of_bids }}
                  </small>
                </template>
              </div>
            </div>

            <div class="col-12 mb-3">
              <label for="Category pb-1">
                Services
              </label>

              <multi-select
                :value="datasend.services"
                :options="services"
                placeholder="select services"
                :classstat="{'is-invalid': errors.services }"
                class="shadow-none"
                @blur="false"
                @update:value="datasend.services = $event"
              />
              <template v-if="errors.services">
                <small
                  v-if="errors.services"
                  class="invalid-feedback"
                >
                  {{ errors.services }}
                </small>
              </template>
            </div>
            <div class="mb-3 row">
              <div class="col-md-6 mb-3 text-nowrap">
                <div class="d-flex">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    :checked="datasend.profile_searchable"
                    @change="
                      datasend.profile_searchable = !datasend.profile_searchable
                    "
                  >
                  <label class="pl-3 text-wrap">Profile searchable</label>
                  <template v-if="errors.profile_searchable">
                    <small class="invalid-feedback">
                      {{ errors.profile_searchable }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="col-md-6 mb-3 text-nowrap">
                <div class="d-flex">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    :checked="datasend.can_access_historical_data"
                    @change="
                      datasend.can_access_historical_data =
                        !datasend.can_access_historical_data
                    "
                  >
                  <label class="pl-3 text-wrap">Can access historical data</label>
                  <template v-if="errors.can_access_historical_data">
                    <small class="invalid-feedback">
                      {{ errors.can_access_historical_data }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="col-md-6 mb-3 text-nowrap">
                <div class="d-flex">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    :checked="datasend.can_see_stars_and_reviews"
                    @change="
                      datasend.can_see_stars_and_reviews =
                        !datasend.can_see_stars_and_reviews
                    "
                  >
                  <label class="pl-3 text-wrap">Can see stars and reviews</label>
                  <template v-if="errors.can_see_stars_and_reviews">
                    <small class="invalid-feedback">
                      {{ errors.can_see_stars_and_reviews }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="col-md-6 mb-3 text-nowrap">
                <div class="d-flex">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    :checked="datasend.can_view_list_of_available_providers"
                    @change="
                      datasend.can_view_list_of_available_providers =
                        !datasend.can_view_list_of_available_providers
                    "
                  >
                  <label class="pl-3 text-wrap">Can view list of available providers</label>
                  <template v-if="errors.can_view_list_of_available_providers">
                    <small class="invalid-feedback">
                      {{ errors.can_view_list_of_available_providers }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="col-md-6 mb-3 text-nowrap">
                <div class="d-flex">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    :checked="datasend.default"
                    @change="datasend.default = !datasend.default"
                  >
                  <label class="pl-3 text-wrap">Default</label>
                  <template v-if="errors.default">
                    <small class="invalid-feedback">
                      {{ errors.default }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="row m-0 p-0">
                <div class="col-6 pb-3 text-start">
                  <button
                    class="btn-outline"
                    @click="back"
                  >
                    Back
                  </button>
                </div>
                <div class="col-6 pb-3 text-end">
                  <button
                    class="btn-primary"
                    type="submit"
                    :disabled="loading"
                  >
                    <submission-spinner v-if="loading" />
                    Create
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Money3Component } from "v-money3";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";

import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import MultiSelect from '@/components/MultiSelect.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperAdminPlanCreate",

  components: { 
    money3: Money3Component, 
    SubmissionSpinner,
    MultiSelect,
    AlerteDanger
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    choix: false,
    listData: [],
    listDataClient: [],
    datasend: {
      // advantages: [],
      name: null,
      commercial_description: null,
      type: null,
      recurrency_type: null,
      value: null,
      streamlined_bill_percentage: null,
      per_project_service_fee: null,
      tax_percentage: null,
      invoice_fee_percentage: null,
      invoice_fee_fix: null,
      withdraw_value_fix: null,
      interval_count: null,
      number_of_bids: null,

      profile_searchable: false,
      can_access_historical_data: false,
      can_see_stars_and_reviews: false,
      can_view_list_of_available_providers: false,
      default: false,
      // disabled: true,
      services: []
    },
    errors: {
      services: null,
      advantages: null,
      name: null,
      commercial_description: null,
      type: null,
      recurrency_type: null,
      value: null,
      streamlined_bill_percentage: null,
      per_project_service_fee: null,
      tax_percentage: null,
      invoice_fee_percentage: null,
      invoice_fee_fix: null,
      withdraw_value_fix: null,
      interval_count: null,
      number_of_bids: null,
      limited_access_to_project_list: null,
      can_access_calendar: null,
      invoice_management: null,
      profile_searchable: null,
      can_access_messaging: null,
      can_access_historical_data: null,
      job_tracking: null,
      can_see_stars_and_reviews: null,
      can_view_list_of_available_providers: null,
      can_access_phone: null,
      default: null,
      serverSideErrorMessage: null,
    },
    services: null
  }),

  validations() {
    return {
      datasend: {
        commercial_description: {
          required,
          minLength: minLength(3),
        },
        name: {
          required,
          minLength: minLength(3),
        },
        type: {
          required,
        },
        recurrency_type: {
          required,
        },
        value: {
          required,
        },
        streamlined_bill_percentage: {
          required,
        },
        per_project_service_fee: {
          required,
        },
        tax_percentage: {
          required,
        },
        invoice_fee_percentage: {
          required,
        },
        invoice_fee_fix: {
          required,
        },
        withdraw_value_fix: {
          required,
        },
        interval_count: {
          required,
        },
        number_of_bids: {
          required,
        },
      },
    };
  },

  mounted() {
    this.fetchSubscriptionPlanServices()
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    list() {
      this.$router.push({
        name: "SuperAdminPlans",
      });
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api
        .createPlan(this.datasend)
        .then((_) => {
          this.resetValidation();
          this.list();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        advantages: null,
        name: null,
        commercial_description: null,
        type: null,
        recurrency_type: null,
        value: null,
        streamlined_bill_percentage: null,
        per_project_service_fee: null,
        tax_percentage: null,
        invoice_fee_percentage: null,
        invoice_fee_fix: null,
        withdraw_value_fix: null,
        interval_count: null,
        number_of_bids: null,
        limited_access_to_project_list: null,
        can_access_calendar: null,
        invoice_management: null,
        profile_searchable: null,
        can_access_messaging: null,
        can_access_historical_data: null,
        job_tracking: null,
        can_see_stars_and_reviews: null,
        can_view_list_of_available_providers: null,
        can_access_phone: null,
        default: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
    addResponse() {
      this.datasend.advantages.push({
        plan: null,
        order: null,
        advantage: null,
      });
    },

    removeResponse(item) {
      this.datasend.items = this.datasend.advantages.filter((el) => el != item);
    },

    fetchSubscriptionPlanServices() {
      this.fetchingServices = true;

      this.clearErrorsMessages();
      api.fetchSubscriptionPlanServices()
        .then(s => this.services = s)
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.fetchingServices = false;
        });
    },

  },
};
</script>
