<template>
  <div class="text-center">
    <h1 class="title-error">
      Page not found
    </h1>

    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <p class="description-error">
          The page you are looking for might have been removed had its name changed
          or is temporarily unavailable.
        </p>

        <div class="py-5">
          <button
            class="btn-primary"
            @click="navigateToSafety"
          >
            Back to safety
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",

  methods: {
    navigateToSafety() {
      this.$router.replace({ name: 'Login' })
    }
  }
};
</script>

<style scoped>
.title-error {
  font-size: 2.625rem;
  color: #222a36;
  font-weight: 600;
  margin-bottom: 1rem;
}
.description-error {
  font-size: 1rem;
  color: #5b5b5b;
  font-weight: 400;
  margin: 1rem 0;
}
</style>
