<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Create Invoice</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <div class="mb-4 row">
                <label
                  class="col-6 border-bottom text-center h5 border-3 pb-3"
                  :class="
                    choice == false
                      ? 'border-primary text-primary'
                      : 'border-secondary text-secondary'
                  "
                  @click="handleChoiceSelection(false)"
                >
                  Job
                </label>

                <label
                  class="col-6 border-bottom text-center h5 border-3 pb-3"
                  :class="
                    choice == true
                      ? 'border-primary text-primary'
                      : 'border-secondary text-secondary'
                  "
                  @click="handleChoiceSelection(true)"
                >Client</label>
              </div>

              <div
                v-if="!choice"
                class="mb-3 col-md-12"
              >
                <label class="form-label">
                  Job <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.job"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      v$.datasend.job.$invalid && v$.datasend.job.$dirty,
                  }"
                  @blur="v$.datasend.job.$touch"
                  @change="handleJobSelection"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a Job
                  </option>
                  <option
                    v-for="(item, index) in listData"
                    :key="index"
                    :value="item.job.id"
                  >
                    {{ item.job.title }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.job.$invalid && v$.datasend.job.$dirty) ||
                      errors.job
                  "
                >
                  <small
                    v-if="v$.datasend.job.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.job"
                    class="invalid-feedback"
                  >
                    {{ errors.job }}
                  </small>
                </template>
              </div>

              <div
                v-else
                class="mb-3 col-md-12"
              >
                <button
                  class="float-end btn-circle mb-2"
                  type="button"
                  @click="addClient"
                >
                  <img
                    :src="plusVectorIcon"
                    alt="Add client"
                  >
                </button>

                <label class="form-label">
                  Client <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.client"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      v$.datasend.client.$invalid && v$.datasend.client.$dirty,
                  }"
                  @blur="v$.datasend.client.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a Client
                  </option>
                  <option
                    v-for="(item, index) in listDataClient"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.company_name }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.client.$invalid &&
                      v$.datasend.client.$dirty) ||
                      errors.client
                  "
                >
                  <small
                    v-if="v$.datasend.client.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.client"
                    class="invalid-feedback"
                  >
                    {{ errors.client }}
                  </small>
                </template>
              </div>

              <div class="mb-3 row">
                <div class="col-md-6">
                  <label class="form-label">
                    Send Date <span class="text-danger">*</span>
                  </label>

                  <vue-date-picker
                    v-model="datasend.send"
                    placeholder="Send Date"
                    class="datepicker form-control"
                    :class="{
                      'is-invalid':
                        (v$.datasend.send.$invalid &&
                          v$.datasend.send.$dirty) ||
                        errors.send,
                    }"
                    auto-apply
                    :enable-time-picker="true"
                    :min-date="new Date()"
                    :format="'MM-dd-yyyy HH:mm'"
                    @blur="v$.datasend.send.$touch"
                    @update:model-value="handleSendDateChange"
                  />
                  <template
                    v-if="
                      (v$.datasend.send.$invalid && v$.datasend.send.$dirty) ||
                        errors.send
                    "
                  >
                    <small
                      v-if="v$.datasend.send.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.send"
                      class="invalid-feedback"
                    >
                      {{ errors.send }}
                    </small>
                  </template>
                </div>

                <div class="col-md-6">
                  <label class="form-label">
                    Due Date <span class="text-danger">*</span>
                  </label>

                  <vue-date-picker
                    v-model="datasend.due_date"
                    placeholder="Due Date"
                    class="datepicker form-control"
                    :class="{
                      'is-invalid':
                        (v$.datasend.due_date.$invalid &&
                          v$.datasend.due_date.$dirty) ||
                        errors.due_date,
                    }"
                    auto-apply
                    :enable-time-picker="false"
                    :min-date="datasend.send || new Date()"
                    :start-date="datasend.send || new Date()"
                    :format="'MM-dd-yyyy'"
                  />

                  <template
                    v-if="
                      (v$.datasend.due_date.$invalid &&
                        v$.datasend.due_date.$dirty) ||
                        errors.due_date
                    "
                  >
                    <small
                      v-if="v$.datasend.due_date.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.due_date"
                      class="invalid-feedback"
                    >
                      {{ errors.due_date }}
                    </small>
                  </template>
                </div>
              </div>

              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Terms <span class="text-danger">*</span>
                </label>
                <textarea
                  v-model="datasend.message"
                  cols="30"
                  rows="4"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write message..."
                  :class="{
                    'is-invalid':
                      v$.datasend.message.$invalid &&
                      v$.datasend.message.$dirty,
                  }"
                  @blur="v$.datasend.message.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.message.$invalid &&
                      v$.datasend.message.$dirty) ||
                      errors.message
                  "
                >
                  <small
                    v-if="v$.datasend.message.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.message.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>
                  <small
                    v-if="v$.datasend.message.maxLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain no more than 255 characters
                  </small>

                  <small
                    v-else-if="errors.message"
                    class="invalid-feedback"
                  >
                    {{ errors.message }}
                  </small>
                </template>
              </div>
            </div>

            <div
              v-if="datasend.items.length > 0"
              class="col-md-12 mb-3"
            >
              <div class="col-12 py-3 mb-3">
                <div class="row text-start">
                  <p class="col-6 title">
                    Item & Description
                  </p>
                  <div class="col-6 mt-3 text-end">
                    <button
                      class="btn-primary px-5"
                      type="button"
                      @click="addResponse"
                    >
                      Add Item
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, i) in datasend.items"
                :key="i"
                class="mb-3"
              >
                <div class="col-12 my-3">
                  <div class="row text-start">
                    <div class="col-md-1 offset-11">
                      <img
                        :src="croixVectorIcon"
                        alt="Remove Icon"
                        @click="removeResponse(item)"
                      >
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-12">
                    <label class="form-label">Item</label>
                    <input
                      v-model="item.item"
                      class="form-control p-3 shadow-none"
                      type="text"
                      placeholder="Item"
                    >
                    <template v-if="errors.items[i]?.item">
                      <small
                        v-for="(error, j) in errors.items[i]?.item"
                        :key="j"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </small>
                    </template>
                  </div>
                  <div class="col-md-6 text-end">
                    <div class="my-2 text-start">
                      <label class="form-label">Quantity</label>
                      <input
                        v-model="item.quantity"
                        class="form-control p-3 shadow-none"
                        type="number"
                        @input="
                          item.quantity = formatPositiveNumber(
                            $event.target.value
                          )
                        "
                      >
                      <template v-if="errors.items[i]?.quantity">
                        <small
                          v-for="(error, j) in errors.items[i]?.quantity"
                          :key="j"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </small>
                      </template>
                    </div>
                  </div>
                  <div class="col-md-6 text-end">
                    <div class="my-2 text-start">
                      <label class="form-label">Price</label>
                      <money3
                        v-model="item.value"
                        prefix="$"
                        class="form-control p-3"
                      />
                      <template v-if="errors.items[i]?.value">
                        <small
                          v-for="(error, j) in errors.items[i]?.value"
                          :key="j"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </small>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <label class="form-label">Description</label>
                  <textarea
                    v-model="item.description"
                    cols="30"
                    rows="4"
                    class="form-control pl-3 shadow-none"
                    placeholder="Item Description"
                  />
                  <template v-if="errors.items[i]?.description">
                    <small
                      v-for="(error, j) in errors.items[i]?.description"
                      :key="j"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </small>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-5 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="text-end">
                  <div class="d-inline-block text-bold">
                    <p>Sub Total : &nbsp;</p>
                    <p>Tax(8%) : &nbsp;</p>
                    <p>Service Fee : &nbsp;</p>
                    <p>You will receive : &nbsp;</p>
                    <p>Total : &nbsp;</p>
                  </div>
                  <div class="d-inline-block">
                    <p>{{ formatPrice(subTotal) }}</p>
                    <p>{{ tax }}</p>
                    <p>{{ formatPrice(serviceFee) }}</p>
                    <p>{{ formatPrice(willReceive) }}</p>
                    <p>{{ formatPrice(total) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disaled="creatingInvoice"
                >
                  <submission-spinner v-if="creatingInvoice" />
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import plusVectorIcon from "@/assets/img/icons/plus.svg";
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import { Money3Component } from "v-money3";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import api from "@/services/api";
import { GeneralHelpers } from "@/utils/general";
import { GlobalConstants } from '@/configs/constants';
import AlerteDanger from "@/components/AlerteDanger.vue";

export default {
  name: "ProviderInvoiceCreate",

  components: {
    money3: Money3Component,
    SubmissionSpinner,
    AlerteDanger
},

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    plusVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    choice: false,
    listData: [],
    listDataClient: [],
    creatingInvoice: false,
    datasend: {
      items: [],
      job: null,
      message: "",
      due_date: null,
      client: null,
      send: null,
    },
    errors: {
      items: [],
      client: null,
      job: null,
      mutableName: null,
      message: null,
      due_date: null,
      error: null,
      send: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        job: this.choice
          ? {}
          : {
              required,
            },

        message: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255)
        },

        due_date: {
          required,
        },

        send: {
          required,
        },

        client: this.choice
          ? {
              required,
            }
          : {},
      },
    };
  },

  computed: {
    subTotal() {
      return this.datasend.items.reduce((prev, curr) => {
        return prev + curr.quantity * curr.value;
      }, 0);
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    tax() {
      return (this.currentPlan?.tax_percentage || 0 / 100) * this.subTotal;
    },

    total() {
      return this.subTotal + this.tax;
    },

    serviceFee() {
      const total =
        ((this.currentPlan?.invoice_fee_percentage || 0) / 100) *
          this.subTotal +
        this.currentPlan?.invoice_fee_fix;
      return total.toFixed(2);
    },

    willReceive() {
      let total = this.subTotal - this.serviceFee;
      return Math.max(total, 0);
    },
  },

  mounted() {
    this.fetchBids();
    this.fetchClientSummary();
  },

  methods: {
    ...GeneralHelpers,
    ...DateHelpers,
    ...validationHelpers,
    ...PriceHelpers,

    handleJobSelection() {
      const selectedJobItem = this.listData.find(
        (item) => item.job.id === this.datasend.job
      );
      if (selectedJobItem) {
        const description = selectedJobItem.job.description_plain_text;
        this.datasend.message = description
          ? description
          : this.stripHtmlTags(selectedJobItem.job.description);
      }
    },

    handleChoiceSelection(choice) {
      this.choice = choice
      this.datasend.message = null
    },

    back() {
      this.$router.back();
    },

    addClient() {
      this.$router.push({
        name: "ClientPortfolioInfoCreate",
      });
    },

    detail(id) {
      this.$router.push({
        name: "ProviderInvoiceDetails",
        params: { id: id },
      });
    },

    handleSendDateChange(value) {
      if (
        this.datasend.due_date &&
        new Date(value).getTime() > new Date(this.datasend.due_date).getTime()
      ) {
        this.datasend.due_date = value;
      }
    },

    save() {
      if (this.creatingInvoice) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.creatingInvoice = true;
      this.clearErrorsMessages();

      if (this.datasend.due_date) {
        this.datasend.due_date = this.formatDate(
          this.datasend.due_date,
          "YYYY-MM-DD"
        );
      }
      if (this.datasend.send) {
        this.datasend.send = this.formatDate(
          this.datasend.send,
          "YYYY-MM-DD HH:mm"
        );
        const allRoutes = this.$router.getRoutes();
        this.datasend.redirectLink = `${
          GlobalConstants.BASE_URL +
          allRoutes.find((route) => route.name == "SharedInvoiceDetails").path
        }`;
      }

      api
        .createInvoice(this.datasend)
        .then((response) => {
          this.resetValidation();
          this.detail(response.data.id);
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.creatingInvoice = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        items: [],
        client: null,
        job: null,
        mutableName: null,
        title: null,
        message: null,
        due_date: null,
        error: null,
        send: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            if (error == 'items') {
              this.errors[error] = err.response.data[error]
            } else {
              this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
            }
            
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    addResponse() {
      this.datasend.items.push({
        item: '',
        value: 0,
        quantity: 0,
        description: '',
      });
      this.errors.items.push({
        item: null,
        value: null,
        quantity: null,
        description: null,
      });
    },

    removeResponse(item) {
      this.datasend.items = this.datasend.items.filter((el) => el != item);
    },

    fetchBids() {
      this.loading = true;

      api
        .fetchBids()
        .then((response) => {
          this.listData = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    fetchClientSummary() {
      this.loading = true;

      api
        .fetchClientSummary()
        .then((response) => {
          this.listDataClient = response.data.data;
          this.addResponse();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
