<template>
  <div
    class="notification-list-item"
    :class="{ 'notification-list-item-unread': !hasBeenRead }"
  >
    <div class="notification-list-item-content">
      <div
        class="notification-list-item-detail"
        :class="{ 'w-100': type === notificationTypes.SIMPLE }"
      >
        <p>{{ title }}</p>

        <p class="text-muted">
          {{ content }}
        </p>

        <p class="text-muted">
          <small>{{ formatPublishDate(date) }}</small>
        </p>
      </div>

      <div>
        <button
          v-if="
            type === notificationTypes.START_REQUEST &&
              data.job_status === jobStatus.ACTIVE &&
              requestToStartJobStatus === startJobRequestStatus.PENDING
          "
          class="btn-secondary"
          :class="{ 'mr-3': canRedirect }"
          @click="confirmClaim(actions.APPROVE_START_JOB_REQUEST)"
        >
          <submission-spinner
            v-if="approvingStartJobRequest"
            :color="'#209516'"
          />
          Approve
        </button>

        <a
          v-if="canRedirect"
          class="btn-primary"
          @click="viewNotification"
        >
          View
        </a>
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import api from "@/services/api";

import { DateHelpers } from "@/utils/date-helpers";
import NotiificationHelpers from "@/utils/notification";

import { JobStatus, StartJobRequestStatus } from "@/configs/constants/jobs";
import { GlobalEvents, NotificationTypes } from "@/configs/constants";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

export default {
  name: "NotificationItem",

  components: {
    ConfirmModal,
    SubmissionSpinner,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    date: {
      type: String,
      required: true,
    },

    read: {
      type: Boolean,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    actions: {
      APPROVE_START_JOB_REQUEST: "approve-start-job-request",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    approvingStartJobRequest: false,
    requestToStartJobStatus: null,
    hasBeenRead: false,
  }),

  computed: {
    notificationTypes() {
      return NotificationTypes;
    },

    startJobRequestStatus() {
      return StartJobRequestStatus;
    },

    isCustomer() {
      return this.$store.getters["token/isCustomer"];
    },

    canRedirect() {
      return ![
        NotificationTypes.SIMPLE,
        NotificationTypes.CLIENT_INVITATION,
      ].includes(this.type);
    },

    jobStatus() {
      return JobStatus;
    },
  },

  watch: {
    "data.request_status": function (val) {
      this.requestToStartJobStatus = val;
    },

    read (val) {
      this.hasBeenRead = val;
    }
  },

  created() {
    this.hasBeenRead = this.read;
    if (this.data) {
      this.requestToStartJobStatus = this.data.request_status;
    }
  },

  methods: {
    ...DateHelpers,
    ...NotiificationHelpers,

    viewNotification() {
      const route = this.getNotificationRedirectionRoute(
        this.isCustomer,
        this.type,
        this.data
      );
      console.log(this.isCustomer,
        this.type,
        this.data, route)
      this.markNotificationsAsRead();
      this.$router.push(route);
    },

    async markNotificationsAsRead() {
      try {
        await api.markNotificationAsRead(this.id);
        this.hasBeenRead = true;
        this.$emitter.emit(GlobalEvents.FETCH_LATEST_NOTIFICATIONS);
      } catch (_) {}
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.APPROVE_START_JOB_REQUEST:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to approve this request ? The job will start automatically.";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.APPROVE_START_JOB_REQUEST:
          this.approveStartJobRequest();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    approveStartJobRequest() {
      this.approvingStartJobRequest = true;

      api
        .approveStartJobRequest(this.data.request_id)
        .then((_) => {
          this.markNotificationsAsRead();
          this.requestToStartJobStatus = StartJobRequestStatus.ACCEPTED;
          toast.success(
            `${this.data.job_title || "Job"} successfully started.`
          );
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.approvingStartJobRequest = false;
        });
    },
  },
};
</script>
