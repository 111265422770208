<template>
  <div class="divider d-flex align-items-center mb-4">
    <p class="text-center mx-3 mb-0">
      {{ formateDate }}
    </p>
  </div>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";

export default {
  name: "MessagesDate",

  props: {
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    formateDate() {
      const now = new Date();
      const date = new Date(this.date);
      const difTime = this.diffDays(now, date, "seconds");
      let formatedDate = "";

      if (difTime <= this.secondsInOneDay) {
        if (this.isSame(now, date, "day")) {
          formatedDate = "Today";
        } else {
          formatedDate = "Yesterday";
        }
      } else if (difTime / this.secondsInOneDay <= 2) {
        formatedDate = "Yesterday";
      } else {
        if (difTime / this.secondsInOneDay <= 6) {
          formatedDate = this.formatDate(date, "dddd");
        } else {
          formatedDate = this.formatDate(date, "MM/DD/YYYY");
        }
      }

      return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
    },

    secondsInOneDay() {
      return 60 * 60 * 24;
    },
  },

  methods: {
    ...DateHelpers,
  },
};
</script>

<style></style>
