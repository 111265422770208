<template>
  <Draggable
    v-if="tache"
    class="col-12 bg-light text-dark mb-3 overflow-visible"
  >
    <span class="float-end m-0 p-0 pt-2">
      <group-button-table
        :status="null"
        :options="options"
        @update:options="choose($event)"
      />
    </span>
    <div
      v-if="tache.priority"
      class="col-12 mb-0"
      style="height: 0.2rem"
      :style="{'background-color': config.priority.find((el) => el.id == tache.priority)?.color}"
      @click="$emit('edit')"
    />
    <p
      class="col-12 text-wrap fw-normal p-2 mb-0"
      @click="$emit('edit')"
    >
      {{ tache.name }}
    </p>
    <p
      v-if="tache.checklist"
      class="w-100 text-wrap fw-normal p-2 mb-0"
      @click="$emit('edit')"
    >
      <span class="fw-bold"> Check list: </span>
      <span 
        class="badge fw-normal ms-2"
        :class="tache.checklist.filter(el=> el.completed == true).length == tache.checklist.length ? 'bg-primary text-white': 'bg-secondary text-dark'"
      >
        {{ tache.checklist.filter(el=> el.completed == true).length }} / {{ tache.checklist.length }}
      </span>
    </p>
    <p
      v-if="tache.assigned !== 0"
      class="d-flex flex-row-reverse p-2 mb-0"
      @click="$emit('edit')"
    >
      <span
        v-for="(assign, l) in tache.assigned"
        :key="l"
        class="avatar-wrapper ms-2"
      >
        <img
          v-if="config.assigned.find(el => el.id == assign)?.url"
          :src="config.assigned.find(el => el.id == assign)?.url"
          class="user-avatar"
          style="width: 40px; height: 40px;"
          alt="Customer Profile Photo"
        >
        <avatar
          v-else-if="config.assigned.find(el => el.id == assign)"
          :name="config.assigned.find(el => el.id == assign)?.name"
          :size="40"
          :color="config.assigned.find(el => el.id == assign)?.avatarColorConfigs?.color"
          :background="config.assigned.find(el => el.id == assign)?.avatarColorConfigs?.background"
          class="proposal-avatar"
          alt="Provider avatar"
        />
        <div class="circle position-absolute" />
      </span>
    </p>
  </Draggable>
</template>

<script>
import GroupButtonTable from "@/components/GroupButtonTable.vue";
import Avatar from "vue3-avatar";
import { Draggable } from "vue3-smooth-dnd";
export default {
  name: "TaskToDo",
  components: { GroupButtonTable, Avatar, Draggable },
  
  props: {
    tache: {
      type: Object,
      required: false,
      default: null,
    },

    config: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    options: [
      {
        title: "Edit",
        value: "edit",
      },
      {
        title: "Delete",
        value: "delete",
      }
    ],
  }),
  mounted() {
  },
  methods: {

    choose(action) {
      switch (action) {
        case 'edit':
          this.$emit('edit')
          break;
        case 'delete':
          this.$emit('delete')
          break;
      
        default:
          break;
      }
    },    
  }
};
</script>