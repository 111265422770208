export const JobSettingsItems = [
  {
    title: "Categories",
    description: "Tracker, identifier ...",
    path: "/superadmin/jobs/settings/categories",
    icon: "settingsJobLanguageIcon",
  },
  {
    title: "Skills",
    description: "Créer, consulter...",
    path: "/superadmin/jobs/settings/skills",
    icon: "settingsSkillCategoryIcon",
  },
  {
    title: "Contract Types",
    description: "Créer, consulter...",
    path: "/superadmin/jobs/settings/contract-types",
    icon: "settingsContractTypeIcon",
  },
  {
    title: "Levels",
    description: "Modifier, consulter",
    path: "/superadmin/jobs/settings/levels",
    icon: "settingsLevelIcon",
  },
  {
    title: "Job Languages",
    description: "Modifier, consulter",
    path: "/superadmin/jobs/settings/languages",
    icon: "settingsJobLanguageIcon",
  },
  {
    title: "Screening Questions",
    description: "Tracker, identifier ...",
    path: "/superadmin/jobs/settings/screening-questions",
    icon: "settingsScreeningQuestionsIcon",
  },
  {
    title: "Items for Pricing",
    description: "Créer, consulter...",
    path: "/superadmin/jobs/settings/items",
    icon: "settingsSkillCategoryIcon",
  },
  {
    title: "Versions",
    description: "Créer, consulter...",
    path: "/superadmin/jobs/settings/versions",
    icon: "settingsContractTypeIcon",
  },
];