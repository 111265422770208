<template>
  <div
    ref="proposalRef"
    class="proposal ms-0"
  >
    <template v-if="proposal?.owner?.user">
      <div class="row">
        <div class="col-xl-6 d-flex align-items-center mb-3">
          <div>
            <router-link
              :to="{
                name: 'ProviderProposalDetails',
                query: {
                  status: $route.query.status,
                },
                params: {
                  id: jobId,
                  proposalId: proposal.id,
                },
              }"
              class="provider-name"
            >
              {{ proposal.job.title }}
            </router-link>

            <div class="d-flex">
              <p class="proposal-date">
                Applied on
                {{ formatDate(proposal.created_at, "MMMM Do, YYYY") }}
              </p>

              <div class="rating">
                <span>Rate :</span>
                <rating-stars :rating="proposal.owner.rating ?? 0" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 position-proposal-icon text-xl-end">
          <template v-if="showStatus">
            <span
              v-if="getProposalStatusStyle(proposalStatus).class"
              class="proposal-status me-3"
              :class="getProposalStatusStyle(proposalStatus).class"
            >
              <div
                v-if="proposalStatus === jobProposalStatus.ACCEPTED"
                class="circle me-2"
              />
              <span class="mb-0">{{
                formatProposalStatus(proposalStatus)
              }}</span>
            </span>
          </template>

          <router-link
            :to="{
              name: 'ProviderProposalDetails',
              query: {
                status: $route.query.status,
              },
              params: {
                id: jobId,
                proposalId: proposal.id,
              },
            }"
            class="btn-primary"
          >
            View
          </router-link>
        </div>
      </div>

      <p class="description ms-0">
        {{ shortDescription }}

        <router-link
          v-if="proposal.message"
          :to="{
            name: 'ProviderProposalDetails',
            params: {
              id: jobId,
              proposalId: proposal.id,
            },
          }"
          class="text-primary"
        >
          more
        </router-link>
      </p>
    </template>
  </div>
</template>

<script>
import { nextTick } from "vue";

import JobHelper from "@/utils/job";
import { DateHelpers } from "@/utils/date-helpers";

import RatingStars from "@/components/RatingStars.vue";

import { JobProposalStatus } from "@/configs/constants/jobs";
import { GlobalEvents } from '@/configs/constants';

export default {
  name: "ProviderProposalItem",

  components: {
    RatingStars,
  },

  beforeRouteLeave(to, from) {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  props: {
    proposal: {
      type: Object,
      required: true,
    },

    showStatus: {
      type: Boolean,
      required: false,
      default: true,
    },

    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    proposalStatus: null,
  }),

  computed: {
    jobProposalStatus() {
      return JobProposalStatus;
    },

    shortDescription() {
      if (this.proposal.message?.length <= 230) {
        return this.proposal.message;
      } else if (this.proposal.message?.length > 230) {
        return this.proposal.message.slice(0, 230);
      }
      return "";
    },

    jobId() {
      if (typeof this.proposal.job === "string") return this.proposal.job;
      else if (typeof this.proposal.job === "object")
        return this.proposal.job.id;
      return "";
    },
  },

  created() {
    this.proposalStatus = this.proposal.status

    nextTick(() => {
      if (this.$refs.proposalRef) {
        this.observer = new IntersectionObserver(this.callback);
        this.observer.observe(this.$refs.proposalRef);
      }
    });

    this.$emitter.on(
      GlobalEvents.job.PROPOSAL_RESPONSE,
      ({ status, proposalId }) => {
        if (this.proposal?.id === proposalId) {
          this.proposalStatus = status
        }
      }
    );
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,

    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.isLastItem) {
          this.$emit("fetchNextProposalsPage");
        }
      });
    },
  },
};
</script>
