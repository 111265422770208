<template>
  <li class="item">
    <div class="accordion-element">
      <div class="accordion-item">
        <span
          class="title accordion-header collapsed"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + job.id"
          aria-expanded="false"
          :aria-controls="'collapse' + job.id"
        >
          {{ job.title }}
        </span>
        <p
          v-if="job.created_at"
          class="date"
        >
          Posted at {{ formatDate(job.created_at, "MMMM Do, YYYY") }} -
          <router-link
            :to="{
              name: jobDetailsPage,
              params: { id: job.id },
              query: { ...$route.query },
            }"
            class="link"
          >
            More details
          </router-link>
        </p>
        <div
          :id="'collapse' + job.id"
          class="accordion-collapse collapse"
          :data-bs-parent="parent"
        >
          <div class="accordion-body">
            <p v-html="sanitizedDescription" />
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";
import DOMPurify from "dompurify";

export default {
  props: {
    job: {
      type: Object,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
  },

  computed: {
    sanitizedDescription() {
      return DOMPurify.sanitize(this.job.description);
    },

    isAuthenticated() {
      return this.$store.getters["token/isAuthenticated"];
    },

    isCustomer() {
      return this.$store.getters["token/isCustomer"];
    },

    isProvider() {
      return this.$store.getters["token/isProvider"];
    },

    jobDetailsPage() {
      let page = "ProviderJobDetails"
      if (!this.isAuthenticated) page = "ProviderJobDetails";
      if (this.isCustomer) page = "CustomerJobDetails";
      return page;
    },
  },

  methods: {
    ...DateHelpers,
  },
};
</script>

<style scoped>
.item {
  margin-left: 0.5rem;
}

.title.collapsed::after {
  transform: translate(0, -65%) rotate(135deg);
}

.title::after {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  top: 0.7rem;
  right: 1rem;
  transform: translate(0, -65%) rotate(-45deg);
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}

.title {
  font-weight: 400;
  font-size: 1.2rem;
  position: relative;
  display: block;
  cursor: pointer;
}

.link {
  font-size: .9rem;
  color: #209516;
  cursor: pointer;
  margin-left: .4rem;
}

.date {
  font-size: 0.8rem;
  font-style: italic;
  color: #c1c1c1;
}

.accordion-body {
  padding: 0;
}
</style>