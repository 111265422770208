<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />

          <div class="mb-3 col-md-12">
            <label class="pb-1 text-dark">
              Name <span class="text-danger">*</span>
            </label>
            <input
              v-model="datasend.name"
              type="text"
              autofocus
              class="form-control pl-3 shadow-none"
              placeholder="Name"
              :class="{
                'is-invalid':
                  v$.datasend.name.$invalid && v$.datasend.name.$dirty,
              }"
              @blur="v$.datasend.name.$touch"
            >
            <template
              v-if="
                (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
                  errors.nameErrorMessage
              "
            >
              <small
                v-if="v$.datasend.name.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="v$.datasend.name.minLength.$invalid"
                class="invalid-feedback"
              >
                The minimum size for this field is 3 characters
              </small>

              <small
                v-else-if="errors.nameErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.nameErrorMessage }}
              </small>
            </template>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn-primary"
            :disabled="loading"
            @click="save"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CreateToDoTask",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    datasend: {
      name: null,
      job: null,
    },
    errors: {
      nameErrorMessage: null,
      serverSideErrorMessage: null,
    },
    req: null,
  }),

  validations() {
    return {
      datasend: {
        name: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },

  methods: {
    ...validationHelpers,

    openModal(el) {
      this.showModal = true;
      this.datasend.job = el.job;
      this.datasend.status = el.id;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api
        .createToDoTask(this.datasend)
        .then((_) => {
          this.datasend = { label: null };
          this.resetValidation();
          this.closeModal();
          this.$emit("updateList");
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors.nameErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.nameErrorMessage = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
