<template>
  <router-link
    v-if="proposal?.owner?.user"
    :to="{
      name: 'CustomerJobProposal',
      params: {
        id: jobId,
        proposalId: proposal.id,
      },
    }"
    class="proposal"
  >
    <div class="col-md-12 d-flex">
      <profile-avatar
        :url="proposal.owner.user.photo?.url"
        :fullname="proposal.owner.user.full_name"
        :color="proposal.owner.user.profile_color"
        :background="proposal.owner.user.profile_background"
      />

      <div class="ms-2 w-100">
        <router-link
          :to="{ 
            name: 'ProviderPublicProfile',
            params: {
              username: `@${proposal?.owner.user.username}`,
            },
          }"
          class="provider-name"
        >
          {{ proposal.owner.user.full_name }}
        </router-link>

        <div class="d-xl-block">
          <div class="rating">
            <rating-stars :rating="proposal.owner.rating ?? 0" />
            <span class="bid-amount">{{ formatPrice(proposal.amount) }}</span>
          </div>
          <p class="proposal-date">
            Applied on {{ formatDate(proposal.created_at, "MMMM Do, YYYY") }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import JobHelper from "@/utils/job";
import { DateHelpers } from "@/utils/date-helpers";
import PriceHelpers from "@/utils/price-format";

import RatingStars from "@/components/RatingStars.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";

import { JobProposalStatus } from "@/configs/constants/jobs";

export default {
  name: "ProposalItem",

  components: {
    RatingStars,
    ProfileAvatar,
  },

  props: {
    proposal: {
      type: Object,
      required: true,
      default: null,
    },
  },

  computed: {
    jobProposalStatus() {
      return JobProposalStatus;
    },

    shortDescription() {
      if (this.proposal.message?.length <= 230) {
        return this.proposal.message;
      } else if (this.proposal.message?.length > 230) {
        return this.proposal.message.slice(0, 230);
      }
      return "";
    },

    jobId() {
      if (typeof this.proposal.job === "string") return this.proposal.job;
      else if (typeof this.proposal.job === "object")
        return this.proposal.job.id;
      return "";
    },
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,
  },
};
</script>

<style scoped>
.proposals .proposal {
  color: #222a36;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  display: block;
}
.proposals .proposal:hover {
  color: #222a36;
  text-decoration: none;
  border: 1px solid #e5e8eb;
}
.proposals .proposal .proposal-avatar {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.proposals .proposal .provider-name {
  color: #209516;
  margin-bottom: 0.3rem;
}
.proposals .proposal .bid-amount {
  float: right;
  font-weight: 500;
}
.proposals .proposal .proposal-date {
  margin-bottom: 0;
}
</style>
