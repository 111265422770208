import { Request } from "@/services/request";

const BASE_URL = "api/v1/plan/";

let SUBSCRIPTION_PLAN_SERVICES;

export default {
  fetchSubscriptionPlans() {
    return new Request()
      .append(BASE_URL)
      .method("get");
  },

  fetchSubscriptionPlanServices() {
    if (SUBSCRIPTION_PLAN_SERVICES) {
      return Promise.resolve(SUBSCRIPTION_PLAN_SERVICES);
    } else {
      return new Request()
      .append(`api/v1/plan_services/`)
      .method("get")
      .then(response => {
        SUBSCRIPTION_PLAN_SERVICES = response?.data?.data ?? [];
        return SUBSCRIPTION_PLAN_SERVICES;
      });
    }
  },

  createPlan(data) {
    return new Request().append(BASE_URL).method("post").setData(data);
  },

  updatePlan(id, data) {
    return new Request()
      .append(`${BASE_URL}${id}/`)
      .method("put")
      .setData(data);
  },

  deletePlan(id) {
    return new Request()
      .append(`${BASE_URL}${id}/`)
      .method("delete");
  },

  updatePlanOneAttr(id, data) {
    return new Request()
      .append(`${BASE_URL}${id}/`)
      .method("patch")
      .setData(data);
  },

  fetchSubscriptionPlanById(id) {
    return new Request()
      .append(`${BASE_URL}${id}/`)
      .method("get");
  },

  fetchSubscriptionPlanDiscountByID(id, coupon) {
    return new Request()
      .append(`${BASE_URL}${id}/with_discount/`)
      .method("get")
      .params({ coupon });
  },

  subscribeToPlan(id, data) {
    return new Request()
      .append(`${BASE_URL}${id}/make_subscription/`)
      .method("post")
      .setData(data);
  },

  executePaypalSubscription(code) {
    return new Request()
      .append(`${BASE_URL}execute_paypal_agreement/`)
      .method("post")
      .setData({ code });
  },

  buyBids(id, data) {
    return new Request()
      .append(`${BASE_URL}${id}/buy_bids/`)
      .method("post")
      .setData(data)
  },

  fetchPlanServicesBidList() {
    return new Request()
      .append(`api/v1/plan_services/bid_list/`)
      .method("get");
  },
};
