<template>
  <div
    id="pagination"
    class="col-12"
  >
    <div
      class="pagination-list"
      :class="{ 'justify-content-end': alignEnd }"
    >
      <button
        :disabled="currentPage === 1"
        class="btn pagination-list-item"
        @click.prevent="prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>

      <button
        v-for="page in startSlice"
        :key="page"
        class="btn pagination-list-item"
        :class="{ active: page === currentPage }"
        @click.prevent="gotoPage(page)"
      >
        {{ page }}
      </button>

      <template v-if="totalPages > paginatoinBreakSize && !isEdge">
        <template v-if="!startSlice.includes(currentPage)">
          <div class="pagination-dot">
            ...
          </div>
        </template>

        <button
          class="btn pagination-list-item"
          :class="{ active: page === currentPage - 1 }"
          @click.prevent="gotoPage(currentPage - 1)"
        >
          {{ currentPage - 1 }}
        </button>

        <button
          :key="currentPage"
          class="btn pagination-list-item active"
          @click.prevent="gotoPage(currentPage)"
        >
          {{ currentPage }}
        </button>

        <button
          class="btn pagination-list-item"
          :class="{ active: page === currentPage + 1 }"
          @click.prevent="gotoPage(currentPage + 1)"
        >
          {{ currentPage + 1 }}
        </button>

        <template v-if="!endSlice.includes(currentPage)">
          <div class="pagination-dot">
            ...
          </div>
        </template>
      </template>

      <template v-else>
        <template v-if="totalPages > paginatoinBreakSize + 1">
          <div class="pagination-dot">
            ...
          </div>
        </template>
      </template>

      <button
        v-for="page in endSlice"
        :key="page"
        class="btn pagination-list-item"
        :class="{ active: page === currentPage }"
        @click.prevent="gotoPage(page)"
      >
        {{ page }}
      </button>

      <button
        :disabled="currentPage === totalPages"
        class="btn pagination-list-item"
        @click.prevent="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { PaginationContants } from "@/configs/constants";

export default {
  name: "AppPagination",

  props: {
    currentPage: {
      type: Number,
      required: true,
    },

    hasPreviousPage: {
      type: Boolean,
      required: true,
    },

    previousPage: {
      type: Number,
      required: true,
    },

    hasNextPage: {
      type: Boolean,
      required: true,
    },

    nextPage: {
      type: Number,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },

    totalElements: {
      type: Number,
      required: true,
    },

    totalPages: {
      type: Number,
      required: true,
    },

    alignEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    paginatoinBreakSize() {
      return PaginationContants.paginatoinBreakSize;
    },

    pages() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },

    startSlice() {
      if (
        this.totalPages <= this.paginatoinBreakSize ||
        this.totalPages == this.paginatoinBreakSize + 1
      )
        return this.pages;

      return this.currentPage < this.paginatoinBreakSize
        ? this.pages.slice(0, this.paginatoinBreakSize)
        : this.pages.slice(0, 1);
    },

    endSlice() {
      if (
        this.totalPages <= this.paginatoinBreakSize ||
        this.totalPages == this.paginatoinBreakSize + 1
      )
        return [];

      return this.currentPage > this.totalPages - this.paginatoinBreakSize + 1
        ? this.pages.slice(-this.paginatoinBreakSize)
        : this.pages.slice(-1);
    },

    isEdge() {
      return (
        this.startSlice.includes(this.currentPage) ||
        this.endSlice.includes(this.currentPage)
      );
    },
  },

  methods: {
    gotoPage(page) {
      this.$emit("onGotoPage", page);
    },

    prev() {
      this.$emit("onPrev");
    },

    next() {
      this.$emit("onNext");
    },
  },
};
</script>

<style scoped>
#pagination .pagination-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#pagination .pagination-list-item {
  background: #bec6d0;
  margin-right: 1rem;
}
#pagination .pagination-list-item:focus {
  box-shadow: none;
}
#pagination .pagination-list-item:disabled {
  border-color: #bec6d0 !important;
}
#pagination .pagination-list-item.active {
  background: #209516;
  border: 1px solid #209516;
  color: #fff;
}
#pagination .pagination-dot {
  margin-right: 1rem;
}
#pagination .pagination-list-item svg {
  height: 15px;
}
</style>
