<template>
  <section class="content proposal">
    <div class="page-header  pb-0 border-0">
      <h3 class="mt-3 mind">
        {{ pageTitle }}
      </h3>
    </div>

    <div
      class="page-content"
    >
      <div class="job-detail p-0">
        <div
          v-if="proposals.length > 0"
          class="proposals p-0 border-top border-0"
        >
          <provider-proposal-item
            v-for="proposal in proposals"
            :key="proposal.id"
            :proposal="proposal"
            :show-status="status !== proposalStatus.ACCEPETED"
            @fetchNextProposalsPage="onFetchNextProposalsPage"
          />
        </div>

        <loader-spinner v-if="fetching" />
      </div>
    </div>
    <confirm-modal
      ref="confirmModal"
      title="Access restricted"
      message="You have a limited access to some features on this page. Please upgrade your account."
      ok-button-title="Upgrade"
      @onConfirm="$router.push({ name: 'ProviderSubscription' })"
    />
  </section>
</template>

<script>
import access from "@/services/access";
import api from "@/services/api";
import ListMixin from "@/mixins/list";
import ProviderProposalItem from "@/components/provider/jobs/ProviderProposalItem.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { ProposalStatus } from "@/configs/constants/jobs";
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
  name: "ProviderProposalsPage",

  components: {
    ProviderProposalItem,
    LoaderSpinner,
    ConfirmModal,
  },

  mixins: [ListMixin(api.getProposals, true, false)],

  data: () => ({
    access: access,
    status: ProposalStatus.ACCEPETED,
    pageTitle: "Won Proposals",
    fetching: false,
    proposals: [],
    maxPagesReached: false,
  }),

  computed: {
    proposalStatus() {
      return ProposalStatus
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    }
  },

  watch: {
    listData(val) {
      if (access.canAccessMarketplace()) {
        this.proposals = this.proposals.concat(val);
      }
      this.fetching = false;
    }
  },

  mounted() {
    if (!access.canAccessMarketplace()) {
      this.confirmClaim()
    } else {
      this.getProposals();
    }
  },

  methods: {
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },
    getProposals() {
      switch (this.$route.params?.status) {
        case ProposalStatus.ACCEPETED:
          this.status = ProposalStatus.ACCEPETED;
          this.pageTitle = "Bids Won";
          break;
        
        case ProposalStatus.ACTIVE:
          this.status = ProposalStatus.ACTIVE;
          this.pageTitle = "Active Jobs";
          break;
      
        default:
          this.status = undefined;
          this.pageTitle = "Proposals";
          break;
      }

      this.fetching = true;
      this.updateListFilters({
        page: 1,
        status: this.status,
      });
    },

    onFetchNextProposalsPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        status: this.status,
        page: this.currentPage + 1,
      });
    },
  },
};
</script>
