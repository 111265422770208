<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <alerte-danger
            :head-error-message="errors.mutableNameErrorMessage"
            @updateContent="errors.mutableNameErrorMessage = $event"
          />
          <p>Are you sure you want to Delete {{ mutableName }}</p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn-danger"
            :disabled="loading"
            @click="deleted"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMessages from "@/utils/error-messages";
import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
    name: "DeleteSetting",

    components: {
        SubmissionSpinner,
        AlerteDanger,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },

        label: {
            type: String,
            required: false,
            default: null,
        },
    },

    data: () => ({
        showModal: false,
        fadeModal: false,
        id: null,
        mutableName: null,
        loading: false,
        errors: {
            mutableNameErrorMessage: null,
            serverSideErrorMessage: null,
        },
    }),

    methods: {
        openModal(item) {
            this.id = item.id;
            this.mutableName = item.name;
            this.showModal = true;
            setTimeout(() => {
                this.fadeModal = true;
            }, 200);
        },

        closeModal() {
            this.fadeModal = false;
            setTimeout(() => {
                this.showModal = false;
            }, 200);
        },

        deleted() {
            if (this.loading) return;

            this.loading = true;
            this.clearErrorsMessages();

            api.deleteJobConfigType(this.id, this.label)
                .then((_) => {
                    this.closeModal();
                    this.$emit("updateList");
                })
                .catch((error) => {
                    this.errorsHandler(error);
                })
                .then(() => {
                    this.loading = false;
                });
        },

        clearErrorsMessages() {
            this.errors.mutableNameErrorMessage = null;
            this.errors.serverSideErrorMessage = null;
        },

        errorsHandler(err) {
            if (err.response) {
                if (err.response.status === 401) {
                    this.errors.serverSideErrorMessage =
                        errorMessages.INVALID_CREDENTIALS;
                } else if (err.response.status === 400) {
                    for (const error in err.response.data) {
                        this.errors.mutableNameErrorMessage =
                            err.response.data[error];
                    }
                } else {
                    this.errors.serverSideErrorMessage =
                        errorMessages.AN_ERROR_HAS_OCCURED;
                }
            } else {
                this.errors.serverSideErrorMessage =
                    errorMessages.AN_ERROR_HAS_OCCURED;
            }
        },
    },
};
</script>
