// layouts

import AuthLayout from "@/layouts/AuthLayout.vue";

// view

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";

export default [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        meta: {
          redirectIfAuthenticated: true,
        },
        component: Login,
      },

      {
        path: "register",
        name: "Register",
        meta: {
          redirectIfAuthenticated: true,
        },
        component: Register,
      },

      {
        path: "forgot-password",
        name: "ForgotPassword",
        meta: {
          redirectIfAuthenticated: true,
        },
        component: ForgotPassword,
      },

      {
        path: "reset-password",
        name: "ResetPassword",
        meta: {
          redirectIfAuthenticated: true,
        },
        component: ResetPassword,
        beforeEnter(to, from, next) {
          if (to.query.token?.length > 0) {
            to.query.hasToken = true;
            next();
          } else {
            to.query.hasToken = false;
            next();
          }
        },
      },

      {
        path: "verify-email",
        name: "VerifyEmail",
        component: VerifyEmail,
        beforeEnter(to, from, next) {
          if (to.query.token?.length > 0) {
            to.query.hasToken = true;
            next();
          } else {
            to.query.hasToken = false;
            next();
          }
        },
      },
    ],
  },
];
