<template>
  <section
    v-if="!laoading && showChat"
    class="chat"
  >
    <div id="chat2">
      <chat
        :messages="messages"
        :participants="participants"
        :send-images="sendImages"
        :send-emojis="sendEmojis"
        :can-init-chat="canInitChat"
        :load-more-messages="fecthMessages"
        :stop-fetching-last-messages="stopFetchingLastMessages"
        @onType="onType"
        @onDownloadFile="onDownloadFile"
        @onImageSelected="onImageSelected"
        @onSubmitMessage="onSubmitMessage"
      />
    </div>
  </section>
</template>

<script>
import api from "@/services/api";

import Chat from "@/components/chat/Chat.vue";

import { DateHelpers } from "@/utils/date-helpers";

import { ChatMessageTypes, GlobalEvents, WebSocketEvents } from "@/configs/constants";

export default {
  name: "InterviewChat",

  components: {
    Chat,
  },

  props: {
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },

    canInitChat: {
      type: Boolean,
      required: true,
    },

    proposalRoomId: {
      type: String,
      required: true,
    },

    customer: {
      type: Object,
      required: true,
    },

    provider: {
      type: Object,
      required: true,
    },

    proposalId: {
      type: String,
      required: true,
    },

    sendImages: {
      type: Boolean,
      required: false,
      default: true,
    },

    sendEmojis: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    messages: [],
    laoading: false,
    roomId: null,
    room: null,
    mustOpenChatRoom: false,
    stopFetchingLastMessages: false,
  }),

  computed: {
    showChat() {
      return this.canInitChat || this.messages.length > 0;
    },

    myself() {
      return this.$store.getters["user/user"];
    },
  },

  watch: {
    proposalRoomId(val) {
      this.roomId = val;
    },
  },

  mounted() {
    this.roomId = this.proposalRoomId;
    if (this.roomId) {
      this.fetchRoom();
    } else {
      this.mustOpenChatRoom = true;
    }
  },

  created() {
    this.$emitter.on(GlobalEvents.chat.CHAT_MESSAGE_RECEIVED, (data) => {
      this.messages.push({
        ...data,
        myself: this.myself.id === data.owner.id,
        content: data.message,
        type: data.message_type,
        uploaded:
          data.message_type === ChatMessageTypes.FILE ? true : undefined,
      });
    });
  },

  methods: {
    ...DateHelpers,

    async fecthMessages(page, callback) {
      page = page && page > 1 ? page : 2;

      try {
        const response = await api.fetchLastMessages({
          page,
          roomId: this.roomId,
        });
        this.messages = (response.data.data || [])
          .map((x) => ({
            ...x,
            myself: this.myself.id === x.owner.id,
            content: x.message,
            type: x.message_type,
            uploaded:
              x.message_type === ChatMessageTypes.FILE ? true : undefined,
          }))
          .reverse()
          .concat(this.messages);
      } catch (error) {
        this.stopFetchingLastMessages = true;
      } finally {
        if (callback) {
          callback();
        }
      }
    },

    async fetchRoom() {
      try {
        this.loading = true;
        const response = await api.fetchRoom(this.roomId);
        this.room = response.data;
        this.messages = (this.room.messages || [])
          .map((x) => ({
            ...x,
            myself: this.myself.id === x.owner.id,
            content: x.message,
            type: x.message_type,
            uploaded:
              x.message_type === ChatMessageTypes.FILE ? true : undefined,
          }))
          .reverse();
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async openChatRoom() {
      const data = {
        bid: this.proposalId,
        provider: this.provider.id,
        customer: this.customer.id,
        participants: this.participants.concat([
          this.customer.user?.id,
          this.provider.user?.id,
        ]),
      };

      try {
        const response = await api.openNewRoom(data);
        this.mustOpenChatRoom = false;
        this.roomId = response.data.id;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(err);
      } finally {
        this.sendingMessage = false;
      }
    },

    async onSubmitMessage(message) {
      try {
        if (this.mustOpenChatRoom) {
          await this.openChatRoom();
        }
        this.sendingMessage = true;

        const response = await api.sendMessage({
          owner: message.participantId,
          message: message.content,
          message_type: message.type,
          chat_room: this.roomId,
        });
        
        // this.messages.push({
        //   ...response.data,
        //   myself: true,
        //   content: response.data.message,
        //   type: response.data.message_type,
        // });
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(err);
      } finally {
        this.sendingMessage = false;
      }
    },

    async onImageSelected(data) {
      try {
        if (this.mustOpenChatRoom) {
          await this.openChatRoom();
        }
        this.sendingMessage = true;

        this.messages.push({
          ...data.message,
          myself: true,
          owner: { id: data.message.participantId },
          created_at: this.formatDate(this.today(), "YYYY-MM-DD HH:mm"),
        });

        const messageIndex = this.messages.length - 1;

        const response = await api.sendMessage({
          owner: data.message.participantId,
          message_type: data.message.type,
          chat_room: this.roomId,
          file: data.file,
        });

        this.messages[messageIndex] = {
          ...response.data,
          myself: true,
          preview: null,
          uploaded: true,
          type: response.data.message_type,
        };
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorsHandler(err);
      } finally {
        this.sendingMessage = false;
      }
    },

    onType(text) {
      if (!text) return
      this.$emitter.emit(GlobalEvents.EMIT_WEBSOCKET_EVENT, {
        event_type: WebSocketEvents.chat.IS_TYPING,
        data: {
          fullname: this.myself.full_name,
          room_id: this.proposalRoomId,
          participants: this.participants,
          current_user: this.myself.id
        }
      });
    },

    onDownloadFile(message) {
      const link = document.createElement("a");
      link.href = message.file.url;
      link.target = "_blank";
      link.download = message.file.original_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>