<template>
  <div
    v-if="headErrorMessage !== null"
    class="alert alert-danger alert-dismissible fade show"
    role="alert"
  >
    {{ headErrorMessage }}

    <!-- data-bs-dismiss="alert"
    aria-label="Close" -->
    
    <button
      type="button"
      class="btn-close"
      @click="closeModal"
    />
  </div>
</template>

<script>
export default {
  name: "AlerteDanger",

  props: {
    headErrorMessage: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
  }),

  methods: {

    closeModal() {
      setTimeout(() => {
        this.$emit('updateContent', null)
      }, 200);
    },
  },
};
</script>

<style scoped>
.alert-danger button.btn-close:hover {
  background-color: transparent !important;
}
</style>
