<template>
  <div
    v-if="isAuthenticated"
    class="dropdown me-3"
    :class="{ 'notifications-unread': hasUnreadNotifications }"
  >
    <img
      :src="icons.notificationIcon"
      alt="notification"
      class="notification-icon cursor-pointer"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >

    <div
      ref="DropdownMenu"
      class="dropdown-menu"
      @click="onDropdownHide"
    >
      <div class="px-2 py-3">
        <h5 class="mb-0">
          Notifications
        </h5>
      </div>

      <ul>
        <template v-if="isNotif">
          <li
            v-for="(notification, index) in notifications"
            :key="index"
          >
            <a
              class="dropdown-item py-2"
              :class="{ 'unread': !notification.read }"
              @click="viewNotification(notification)"
            >
              <div class="message">
                <small class="title">
                  {{ dotText(notification.title) }}
                </small>

                <small
                  v-if="notification.created_at"
                  class="time"
                >
                  {{ formatPublishDate(notification.created_at) }}
                </small>
              </div>
            </a>
          </li>

          <li class="d-flex drop py-3">
            <div class="col text-center">
              <button
                class="read-all text-danger"
                :disabled="!hasUnreadNotifications"
                @click="markAllNotificationsAsRead"
              >
                Mark all as read
              </button>
            </div>

            <div class="col text-center">
              <button
                class="btn btn-secondary px-5"
                @click="navigateToNotificationsPage"
              >
                View All
              </button>
            </div>
          </li>
        </template>

        <div
          v-else
          class="empty"
        >
          <div>No notifications</div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";

import amountEarned from "@/assets/img/icons/amount-earned.svg";
import notificationIcon from "@/assets/img/icons/notification.svg";

import api from "@/services/api";

import { DateHelpers } from "@/utils/date-helpers";
import NotiificationHelpers from "@/utils/notification";
import { GlobalEvents } from "@/configs/constants";

export default {
  data() {
    return {
      icons: {
        notificationIcon,
        amountEarned,
      },
      notifications: [],
      hasUnreadNotifications: false,
    };
  },

  computed: {
    isNotif() {
      return this.notifications && this.notifications.length !== 0;
    },

    isAuthenticated() {
      return this.$store.getters["token/isAuthenticated"];
    },

    isCustomer() {
      return this.$store.getters["token/isCustomer"];
    },

    notificationLink() {
      if (this.isCustomer) return "CustomerNotifications";
      else return "ProviderNotifications";
    },
  },

  mounted() {
    this.fetchLatestNotifications();
  },

  created() {
    this.$emitter.on(GlobalEvents.NEW_NOTIFICATION, ({ notification }) => {
      if (this.notifications.length >= 3) {
        this.notifications.pop();
      }

      this.hasUnreadNotifications = true;
      this.notifications.unshift(notification);
    });

    this.$emitter.on(GlobalEvents.FETCH_LATEST_NOTIFICATIONS, () => {
      this.fetchLatestNotifications();
    });
  },

  methods: {
    ...DateHelpers,
    ...NotiificationHelpers,

    async fetchLatestNotifications() {
      try {
        const response = await api.fetchLatestNotifications();
        const data = response.data;
        this.notifications = data.latest;
        this.hasUnreadNotifications = data.unread_number > 0;
      } catch (_) {}
    },

    async markNotificationsAsRead(id) {
      try {
        await api.markNotificationAsRead(id);
        this.fetchLatestNotifications();
      } catch (_) {}
    },

    async markAllNotificationsAsRead() {
      try {
        await api.markAllNotificationsAsRead();
        this.hasUnreadNotifications = false;
      } catch (_) {}
    },

    onDropdownHide(event) {
      event.stopPropagation();
    },
    
    viewNotification(notification) {
      const route = this.getNotificationRedirectionRoute(
        this.isCustomer,
        notification.type,
        notification.data
      );
      this.closeDropdownMenu();
      this.markNotificationsAsRead(notification.id);
      this.$router.push(route);
    },

    navigateToNotificationsPage() {
      this.closeDropdownMenu();

      if (this.$route.name === this.notificationLink) {
        window.location.reload();
        return;
      }

      this.$router.push({
        name: this.notificationLink,
      });
    },

    dotText(value) {
      if (value?.length > 55) {
        const truncated = value.substring(0, 45) + "...";
        return truncated;
      }
      return value;
    },

    closeDropdownMenu() {
      nextTick(() => {
        if (this.$refs.DropdownMenu) {
          this.$refs.DropdownMenu.classList.remove("show");
        }
      });
    },
  },
};
</script>

<style scoped>
.dropdown li {
  border: none !important;
}
.dropdown-menu {
  padding: 0 !important;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(167, 165, 165, 0.53);
  border: 0;
}
.dropdown-menu .empty {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  height: 60px;
  width: 100%;
}
.dropdown-menu ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.dropdown-item {
  display: flex;
  white-space: initial;
  position: relative;
  border: none !important;
}
.dropdown-item:hover img {
  filter: brightness(0) saturate(100%) invert(40%) sepia(57%) saturate(3792%)
    hue-rotate(83deg) brightness(94%) contrast(86%);
}
.dropdown-item .icon {
  width: 2.5rem !important;
  padding-right: 0.5rem;
}
.dropdown-item .message {
  display: block;
  position: relative;
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.unread.dropdown-item::after {
  content: "";
  position: absolute;
  top: 0.5rem !important;
  right: 10px;
  width: 8px;
  height: 8px;
  background: #209516;
  border-radius: 1000px;
}

.dropdown-item .message .title,
.dropdown .drop a {
  font-size: 0.9rem;
}
.dropdown-item .message .time {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #999;
}
.dropdown-divider {
  margin: 0;
}
.show-all {
  font-weight: 500;
  border-top: 1px solid #e5e8eb;
}

.dropdown .drop {
  cursor: auto;
}
.dropdown .drop button.read-all {
  background: transparent;
}
.dropdown .drop button.read-all[disabled] {
  background: transparent;
  opacity: 0.7;
}

@media screen and (min-width: 560px) {
  .dropdown-menu {
    min-width: 350px;
  }
}
</style>
