<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div 
      class="modal-dialog modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Coupon
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <ul
            v-if="result"
            class="mb-3 col-md-12 ps-0"
          >
            <li
              v-if="result?.code"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Code :
              </div>
              <div class="col-md-6 text-black">
                {{ result?.code }}
              </div>
            </li>
            <li
              v-if="result?.duration"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Duration :
              </div>
              <div class="col-md-6 text-black">
                {{ result?.duration }}
              </div>
            </li>
            <li
              v-if="result?.duration_in_months"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Duration in months :
              </div>
              <div class="col-md-6 text-black">
                {{ result?.duration_in_months }}
              </div>
            </li>
            <li
              v-if="result?.max_redemptions"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Max redemptions :
              </div>
              <div class="col-md-6 text-black">
                {{ result?.max_redemptions }}
              </div>
            </li>
            <li
              v-if="result?.percent_off"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Percent off :
              </div>
              <div class="col-md-6 text-black">
                {{ result?.percent_off }} %
              </div>
            </li>
            <li
              v-if="result?.amount_off"
              class="row mb-2"
            >
              <div class="col-md-6 fw-bold">
                Amount off :
              </div>
              <div class="col-md-6 text-black">
                {{ formatPrice(result?.amount_off) }}
              </div>
            </li>
          </ul>
          <div
            v-else
            class="mb-3 col-md-12"
          >
            <input
              v-model="coupon"
              type="text"
              class="form-control pl-3 shadow-none"
              placeholder="code"
            >
            <template
              v-if="errors.coupon"
            >
              <small
                v-if="errors.coupon"
                class="invalid-feedback"
              >
                {{ errors.coupon }}
              </small>
            </template>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            v-if="result"
            type="button"
            class="btn-primary"
            :disabled="loading"
            @click="goback"
          >
            Apply Coupon
          </button>
          <button
            v-else
            type="button"
            class="btn-primary"
            :disabled="loading"
            @click="save"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Verify coupon
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";
import PriceHelpers from "@/utils/price-format";

import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from './AlerteDanger.vue';

export default {
  name: "SubscriptionCode",

  components: {
    SubmissionSpinner,
    AlerteDanger
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  
  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    id: null,
    coupon: null,
    errors: {
      coupon: null,
      serverSideErrorMessage: null,
    },
    result: null
  }),

  methods: {
    ...validationHelpers,
    ...PriceHelpers,

    openModal() {
      this.showModal = true;
      this.coupon = null;
      this.result = null;
      this.errors = {
      coupon: null,
      serverSideErrorMessage: null,
    };
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
    goback(){
      this.$emit("update:coupon", this.result);
      this.result = null;
      this.closeModal()
    },
    save() {
      if (this.loading) return;
      this.clearErrorsMessages();
      this.loading = true;
      api.couponByCode(this.coupon)
        .then((data) => {
          this.result = data.data;
          this.coupon = null;
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
      coupon: null,
      serverSideErrorMessage: null,
    };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.coupon = err.response.data[error];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
