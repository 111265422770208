import { Request } from "@/services/request";

export default {
  fetchJobConfigTypes(page = 1, title) {
    return new Request()
      .append(`api/v1/${title}/`)
      .method("get")
      .params({ page });
  },

  createJobConfigType(data, title) {
    return new Request()
      .append(`api/v1/${title}/`)
      .method("post")
      .setData(data);
  },

  updateJobConfigType(id, data, title) {
    return new Request()
      .append(`api/v1/${title}/${id}/`)
      .method("patch")
      .setData(data);
  },
  updateJobConfigTypePut(id, data, title) {
    return new Request()
      .append(`api/v1/${title}/${id}/`)
      .method("patch")
      .setData(data);
  },

  deleteJobConfigType(id, title) {
    return new Request()
    .append(`api/v1/${title}/${id}/`)
    .method("delete");
  },

  createJobConfigSkillsType(data, title) {
    return new Request()
      .append(`api/v1/${title}/`)
      .method("post")
      .setData(data);
  },

  updateJobConfigSkillsType(id, data, title) {
    return new Request()
      .append(`api/v1/${title}/${id}/`)
      .method("patch")
      .setData(data);
  },
};
