<template>
  <div
    ref="jobRef"
    class="job-content"
    :class="{ 'border-bottom-0': isLastItem }"
    @mouseover="showIcon"
    @mouseleave="hiddenIcon"
  >
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{
              name: 'CustomerJobDetails',
              params: { id: job.id },
              query: { ...$route.query },
            }"
            class="subtitle"
          >
            {{ job.title }}
          </router-link>
        </h3>
        <p class="date">
          Posted at {{ formatDate(job.created_at, "MMMM Do, YYYY") }}
        </p>
      </div>

      <div class="col-md-4 d-flex justify-content-end">
        <div>
          <ul
            v-if="show && (canEditJob || canDeleteJob || canDuplicateJob)"
            class="d-flex p-0 mt-0 mb-2 list-unstyled justify-content-end"
          >
            <li v-if="canDuplicateJob">
              <button
                class="btn-outline me-3"
                @click.prevent="confirmClaim('Duplicate')"
              >
                <img :src="icons.duplicate">
              </button>
            </li>
            <li v-if="canEditJob">
              <router-link
                :to="{
                  name: 'CustomerJobEdit',
                  params: { id: job.id },
                }"
                class="btn-outline edit"
              >
                <img :src="icons.edit">
              </router-link>
            </li>

            <li
              v-if="canDeleteJob"
              class="ml-3"
            >
              <button
                class="btn-outline trash"
                
                @click.prevent="confirmClaim('Delete')"
              >
                <img :src="icons.trash">
              </button>
            </li>
          </ul>

          <template v-if="job.status === jobStatus.ACTIVE">
            <span
              v-if="job.awarded"
              class="job-awarded"
            >Awarded</span>
            <span
              v-else
              class="job-unawarded"
            >Not awarded yet</span>
          </template>
        </div>
      </div>
    </div>

    <div class="mt-3 auto-flex">
      <div
        v-if="job.level"
        class="d-flex"
      >
        <p class="case">
          Level :
        </p>

        <p
          v-if="job.level"
          class="number-case me-4"
        >
          {{ job.level.name }}
        </p>
      </div>

      <template v-if="job.budget_type !== jobBudgetTypes.NOT_SURE">
        <div
          v-if="job.budget_type === jobBudgetTypes.FIXED"
          class="d-flex"
        >
          <template v-if="job.budget > 0">
            <p class="case">
              Budget :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(job.budget) }}
            </p>
          </template>
        </div>

        <template v-else>
          <div
            v-if="job.budget_from !== null && job.budget_from !== undefined"
            class="d-flex"
          >
            <p class="case">
              Budget from :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(job.budget_from) }}
              <template
                v-if="
                  job.payment_frequency &&
                    formatJobPaymentFrequencies(job.payment_frequency).short
                "
              >
                / {{ formatJobPaymentFrequencies(job.payment_frequency).short }}
              </template>
            </p>
          </div>

          <div
            v-if="job.budget_to !== null && job.budget_to !== undefined"
            class="d-flex"
          >
            <p class="case">
              Budget to :
            </p>

            <p class="number-case me-4">
              {{ formatPrice(job.budget_to) }}
              <template
                v-if="
                  job.payment_frequency &&
                    formatJobPaymentFrequencies(job.payment_frequency).short
                "
              >
                / {{ formatJobPaymentFrequencies(job.payment_frequency).short }}
              </template>
            </p>
          </div>
        </template>
      </template>

      <div
        v-else
        class="d-flex"
      >
        <p class="case">
          Budget Type :
        </p>

        <p
          v-if="job.budget_type"
          class="number-case me-4"
        >
          &nbsp;{{ formatJobBudgetTypes(job.budget_type) }}
        </p>
      </div>

      <div
        v-if="job.bids_end"
        class="d-flex me-4"
      >
        <p class="case">
          Bids ends :
        </p>

        <p class="number-case">
          {{ formatDate(job.bids_end, "MMMM Do, YYYY") }}
        </p>
      </div>

      <div
        v-if="job.job_type"
        class="d-flex me-4"
      >
        <p class="case">
          Job Type :
        </p>

        <p class="number-case">
          {{ formatJobType(job.job_type) }}
        </p>
      </div>
      <div
        v-if="job.status"
        class="d-flex me-4"
      >
        <p class="case">
          Job Status :
        </p>

        <p class="number-case">
          {{ statusToUI(job.status) }}
        </p>
      </div>
    </div>

    <div v-if="job.description">
      <p v-html="sanitizedDescription" />

      <router-link
        v-if="job.description?.length > 154"
        :to="{ name: 'CustomerJobDetails', params: { id: job.id } }"
        class="text-primary ms-2"
      >
        more
      </router-link>
    </div>

    <div class="my-4">
      <div>
        <span
          v-for="skill in job.skills"
          :key="skill.id"
          class="type-job d-inline-block"
        >{{ skill.name }}</span>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      :ok-button-title="confirm.title"
      :message="confirm.message"
      @onConfirm="confirmAction"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import edit from "@/assets/img/icons/edit.svg";
import trash from "@/assets/img/icons/delete.svg";
import duplicate from "@/assets/img/icons/heroicons_document-duplicate-20-solid.svg";
import api from "@/services/api";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import { JobBudgetTypes, JobStatus } from "@/configs/constants/jobs";
import errorMessages from "@/utils/error-messages";

export default {
  name: "CustomerJobItem",

  components: {
    ConfirmModal,
  },

  beforeRouteLeave(to, from) {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  props: {
    job: {
      type: Object,
      required: true,
      default: null,
    },

    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      show: false,
      icons: {
        edit,
        trash,
        duplicate
      },
      errors: {
        notFoundErrorMessage: null,
        serverSideErrorMessage: null,
      },
      confirm: {
        title: '',
        message: "",
      }
    };
  },

  computed: {
    shortDescription() {
      if (this.job.description?.length <= 154) {
        return this.job.description;
      } else if (this.job.description?.length > 154) {
        return `${this.job.description.slice(0, 154)}...`;
      }
      return "";
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobStatus() {
      return JobStatus;
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.shortDescription ?? "");
    },

    currentUser() {
      return this.$store.getters["user/user"];
    },

    canDeleteJob() {
      return (
        !this.job.awarded && this.currentUser?.id === this.job?.owner?.user?.id
      );
    },

    canEditJob() {
      return (
        this.currentUser?.id === this.job?.owner?.user?.id &&
        [JobStatus.DRAFT, JobStatus.ACTIVE].includes(this.job.status)
      );
    },
    canDuplicateJob() {
      return (
        this.currentUser?.id === this.job?.owner?.user?.id
      );
    },
  },

  created() {
    nextTick(() => {
      if (this.$refs.jobRef) {
        this.observer = new IntersectionObserver(this.callback);
        this.observer.observe(this.$refs.jobRef);
      }
    });
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,
    ...statusToUI,

    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.isLastItem) {
          this.$emit("fetchNextPage");
        }
      });
    },

    showIcon() {
      this.show = true;
    },

    hiddenIcon() {
      this.show = false;
    },

    confirmClaim(title) {
      switch (title) {
        case 'Delete':
          this.confirm = {
            title: title,
            message: "Are you sure you want to delete this job ?",
          };
          
          break;
        
        case 'Duplicate':
          this.confirm = {
            title: title,
            message: "Are you sure you want to duplicate this job ?",
          };

          break;
      
        default:
          break;
      }
      this.$refs.confirmModal.openModal();
      
    },
    async confirmAction() {
      switch (this.confirm.title) {
        case 'Delete':
          await this.deleteJob()
          break;
        
        case 'Duplicate':
          await this.duplicateJob()
          break;
      
        default:
          break;
      };
      this.confirm = {
        title: '',
        message: "",
      }
    },

    async deleteJob() {
      try {
        this.loadingJob = true;
        await api.deleteJob(this.job.id);
        this.$emit("isDelete", this.job.id);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },
    async duplicateJob() {
      try {
        this.loadingJob = true;
        await api.duplicateJob(this.job.id);
        this.$emit("isDuplicate", this.job.id);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.job-awarded {
  font-style: italic;
  color: #209516;
  font-size: 0.85rem;
}
.job-unawarded {
  font-style: italic;
  color: #f1c40f;
  font-size: 0.85rem;
}

.btn-outline.trash {
  background: none;
  filter: brightness(0) saturate(100%) invert(30%) sepia(98%) saturate(4568%)
    hue-rotate(351deg) brightness(99%) contrast(84%);
  padding: 0.5rem 0.6rem;
}
.btn-outline.edit {
  background: none;
  filter: brightness(0) saturate(100%) invert(39%) sepia(57%) saturate(1201%)
    hue-rotate(79deg) brightness(97%) contrast(86%);
  padding: 0.5rem 0.6rem;
}

.btn-outline.edit ~ .btn-outline.trash {
  margin-left: 0.5rem;
}
</style>
