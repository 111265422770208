<template>
  <div class="job-detail">
    <section class="content">
      <div class="page-header">
        <div class="d-md-flex title-part mb-0">
          <router-link
            :to="{ name: 'SuperAdminInvoices' }"
            class=" title me-1"
          >
            Invoices
          </router-link>

          <p class="title-content">
            / {{ invoice.invoice_no }}
          </p>
        </div>

        <div class="d-flex align-items-center">
          <div class="position-proposal-icon me-3">
            <app-invoice-status
              v-if="invoice"
              :status="invoice.status || ''"
            />
          </div>
          <div class="position-proposal-icon">
            <span
              v-if="invoice.invoice_no"
              class="proposal-status border-primary "
              @click="donwloadInvoice(invoice.invoice_no)"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              />
              <img
                v-else
                :src="download" 
                alt="" 
                class="me-2" 
              >
              <span class="mb-0 text-primary fw-normal">Download</span>
            </span>
          </div>
        </div>
      </div>

      <div 
        id="element-to-convert"
        class="page-content"
      >
        <div
          v-if="provider"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ provider.full_name }}
                </h5>
                <p class="my-0">
                  {{ provider.location_description }}
                </p>
                <p class="my-0">
                  {{ provider.country }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <h6 class="mb-2 text-secondary">
                  Balance Due
                </h6>
                <h5 class="text-bold mb-0">
                  {{ formatPrice(invoice.total) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        
        <div
          v-if="job?.owner"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ job.owner.user.full_name }}
                </h5>
                <p class="my-0">
                  {{ job.owner.user.location_description }}
                </p>
                <p class="my-0">
                  {{ job.owner.user.country }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Invoice Date :
                  </p>
                  <p class="col-md-4 ">
                    {{ formatDate(invoice.created_at) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Due Date :
                  </p>
                  <p class="col-md-4 ">
                    {{ formatDate(invoice.due_date ) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Job Name :
                  </p>
                  <p class="col-md-4 ">
                    {{ job.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="invoice?.client"
          class="col-md-12 my-5"
        >
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">
                  {{ invoice.client.full_name }}
                </h5>
                <p class="my-0">
                  {{ invoice.client.company_name }}
                </p>
                <p class="my-0">
                  {{ invoice.client.main_propriety.location_description }}
                </p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Invoice Date :
                  </p>
                  <p class="col-md-4 ">
                    {{ formatDate(invoice.created_at) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Due Date :
                  </p>
                  <p class="col-md-4 ">
                    {{ formatDate(invoice.due_date ) }}
                  </p>
                </div>
                <div class="row text-end">
                  <p class="col-md-8 text-primary">
                    Primary contact :
                  </p>
                  <p class="col-md-4 ">
                    {{ invoice.client.primary_contact.full_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 md-3">
          <div class="table-responsive">
            <table class="table">
              <head-table :list="thead" />
              <tbody>
                <tr
                  v-for="(item, index) in invoice.items"
                  :key="index"
                >
                  <td>
                    {{ index+1 }}
                  </td>
                  <td>
                    <h6>
                      {{ item.item }}
                    </h6>
                    <p class="mb-2 text-secondary">
                      {{ item.description }}
                    </p>
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ formatValue(item.value) }}
                  </td>
                  <td>
                    {{ formatValue(item.quantity * item.value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

     
        <div class="col-md-12 my-5 text-end d-flex">
          <div class="col-md-12 align-self-end mb-3">
            <div class="text-end">
              <div class="d-inline-block text-bold">
                <p>Total : </p>
              </div>
              <div class="d-inline-block ">
                <p> {{ formatPrice(invoice.total) }}</p>
              </div>
            </div>

            <!-- <button class="btn-primary rounded-0 mt-3">
              Balance Due {{ formatPrice(invoice.total) }}
            </button> -->
          </div>
        </div>

        <div class="col-md-12 text-start">
          <div class="col-md-3 mb-3">
            <h5 class="mb-3 text-primary ">
              Note :
            </h5>
            <p class="my-0">
              {{ invoice.message }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import download from "@/assets/img/icons/download.svg";
import HeadTable from '@/components/HeadTable.vue';
import errorMessages from "@/utils/error-messages";

import PriceHelpers from "@/utils/price-format";
import html2pdf from "html2pdf.js";
import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";
export default {
  name: "SuperAdminInvoiceDetails",
  components: { HeadTable, AppInvoiceStatus },

  data: () => ({
    download,
    showModal: false,
    fadeModal: false,
    loading: false,
    downloading: false,
    listData: [],
    invoice: {},
    job: {},
    provider: {},
    thead: [ "#", "Item & Description", "Quantity", "Value", "Amount" ],
    datasend: {
      items: [{item: null, value: null, quantity: null, description: null}],
      job: null,
      message: "",
      due_date: null,
      send: null
    },
    errors: {
      items: null,
      job: null,
      mutableName: null,
      message: null,
      due_date: null,
      error: null,
      send: null,
      serverSideErrorMessage: null,
    },
  }),
  mounted() {
    this.fetchInvoiceById()
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    
    back() {
      this.$router.back()
    },
    donwloadInvoice() {
      if (this.downloading) return;

      this.downloading = true;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: `${this.invoice.invoice_no}.pdf`,
      });
      setTimeout(() => {
        this.downloading = false;
      }, 2000);
    },
    async fetchInvoiceById() {
      this.loading = true;

      try {
        let response = await api.fetchInvoiceById(this.$route.params.id);
        this.invoice = response.data;
        this.job = response.data.job;
        this.provider = response.data.bid.owner.user;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      this.errors = {
        items: null,
        job: null,
        mutableName: null,
        title: null,
        message: null,
        due_date: null,
        error: null,
        send: null,
        serverSideErrorMessage: null,
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] = error == 'error' ? err.response.data[error] : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>