import { Request } from "@/services/request";
import Papa from 'papaparse';

export default {
  addProperty(data) {
    return new Request()
      .append(`api/v1/customerproperties/`)
      .method("post")
      .setData(data);
  },

  bulkAddProperties(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: "greedy",
        transformHeader: header => {
          return header.toLowerCase().replaceAll(' ', '_').replaceAll('-','');
        },
        complete: (results) => {
          console.log(results);
          resolve(new Request()
            .append(`api/v1/customerproperties/bulk/`)
            .method("post")
            .setData(results.data)
          );
        },
        error: err => reject(err)
      });
    });
  },

  fetchProperties({ page = 1, search = null,}) {
    return new Request()
      .append(`api/v1/customerproperties/`)
      .method("get")
      .params({ page, search });
  },

  fetchPropertiesJobs({
    page = 1,
    id = null,
    provider_propertie_associate = null
  }) {
    return new Request().append(`api/v1/customerproperties/${id}/job_list/`)
    .method("get")
    .params({ page, provider_propertie_associate });
  },

  fetchPropertiesEstimates({
    page = 1,
    id = null,
    provider_propertie_associate = null
  }) {
    return new Request().append(`api/v1/estimation/${id}/by_customer_property/`)
    .method("get")
    .params({ page, provider_propertie_associate });
  },

  fetchAllProperties() {
    return new Request()
      .append(`api/v1/customerproperties/customer_properties/`)
      .method("get")
  },

  fetchProperty(id) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("get")
  },

  deleteProperty(id) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("delete")
  },

  updateProperty(id, data) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("patch")
      .setData(data);
  },
}
