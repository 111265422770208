<template>
  <div class="subscription-plans-boxes">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-6 border-bottom border-5"
          :class="{ 'border-primary bg-white': choice == 'month' }"
          @click="choice = 'month'"
        >
          <h4
            class="text-center py-3"
            :class="{ 'text-primary': choice == 'month' }"
          >
            Per Month
          </h4>
        </div>
        <div
          class="col-md-6 border-bottom border-5"
          :class="{ 'border-primary bg-white': choice == 'year' }"
          @click="choice = 'year'"
        >
          <h4
            class="text-center py-3"
            :class="{ 'text-primary': choice == 'year' }"
          >
            Per Year
          </h4>
        </div>
      </div>
      <div class="row customize-bundle">
        <h3 class="pricing-title my-5">
          Customize Your ServiceLoop Tool
        </h3>

        <loader-spinner v-if="fetchingPlans || fetchingServices" />

        <div class="col-md-8 left">
          <plan-list
            :plans="plans.filter((el) => el.recurrency_type == choice)"
            @onPlanSelected="handlePlanSelection"
          />
        </div>

        <div
          v-if="selectedPlan"
          class="col-md-4 right"
        >
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="total">
            <div class="total-content">
              <div class="list-pricing">
                <h3 class="title mb-3">
                  Your Plan
                </h3>

                <span class="sub-title">{{ selectedPlan.name }}</span>

                <p class="mt-3">
                  <span class="sub-title">
                    Number of Bid{{ selectedPlan.number_of_bids > 1 ? "s" : "" }}:
                  </span>
                  <span class="text-primary">
                    {{ selectedPlan.number_of_bids }}
                  </span>
                </p>
                <div class="pricing-content">
                  <ul class="pricing-card">
                    <feature-item
                      v-for="feature in features.filter(feature => selectedPlan[feature.condition])"
                      :key="feature.condition"
                      :title="feature.title"
                      :checked="true"
                    />
                    <feature-item
                      v-for="plan_service in selectedPlan.services"
                      :key="plan_service.code"
                      :title="plan_service.name"
                      :checked="true"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <div class="pt-3 border-top total-footer">
              <p class="price">
                <span v-if="coupon">
                  {{
                    selectedPlan.value -
                      (coupon?.percent_off
                        ? (selectedPlan.value * coupon?.percent_off) / 100
                        : coupon?.amount_off) <
                      0
                      ? formatPrice(0)
                      : formatPrice(
                        selectedPlan.value -
                          (coupon?.percent_off
                            ? (selectedPlan.value * coupon?.percent_off) /
                              100
                            : coupon?.amount_off) || 0
                      )
                  }}
                </span>

                <span v-else>
                  {{ formatPrice(selectedPlan.value || 0) }}
                </span>
                / per {{ selectedPlan?.recurrency_type }}
              </p>
              <ul
                v-if="coupon"
                class="list-group"
              >
                <li class="list-group-item">
                  <span class="fw-bold ms-3">Coupon:</span>
                  {{ coupon.code }}
                  {{
                    coupon?.percent_off
                      ? coupon?.percent_off + "%"
                      : formatPrice(coupon?.amount_off)
                  }}
                  <img
                    alt="smallclose"
                    class="float-end p-0"
                    :src="icons.smallclose"
                    @click="coupon = null"
                  >
                </li>
              </ul>
              <button
                v-else
                class="btn btn-outline-primary d-block mt-3 btn-subscribe-plan"
                :disabled="!canSubscribe"
                :class="{ 'btn-secondary': !canSubscribe }"
                @click="create"
              >
                <submission-spinner v-if="submitting" />
                Apply Coupon
              </button>
              <button
                class="btn btn-primary d-block mt-3 btn-subscribe-plan"
                :disabled="!canSubscribe"
                @click="subscribeToPlan"
              >
                <submission-spinner v-if="submitting" />
                Subscribe Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <subscription-code
      ref="SubscriptionCode"
      title="Subscription"
      @update:coupon="coupon = $event"
    />
    <no-credit-card-modal
      ref="confirmModal"
      type="ProviderCreateCreditCard"
    />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

import vectorCircleGreen from "@/assets/img/icons/circle-green.svg";
import vectorCircleGris from "@/assets/img/icons/circle-gris.svg";
import smallclose from "@/assets/img/icons/poubell-red.svg";

import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import PriceHelpers from "@/utils/price-format";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import PlanList from "@/components/subscription-plans/PlanList.vue";
import FeatureItem from "@/components/subscription-plans/FeatureItem.vue";

import { SubscriptionPlanFeatures } from "@/configs/constants/subscription-plans";
import NoCreditCardModal from "@/components/modals/NoCreditCardModal.vue";
import SubscriptionCode from "@/components/SubscriptionCode.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderSubscriptionPage",

  components: {
    PlanList,
    FeatureItem,
    LoaderSpinner,
    SubmissionSpinner,
    NoCreditCardModal,
    SubscriptionCode,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      vectorCircleGreen,
      vectorCircleGris,
      smallclose,
    },
    plans: [],
    services: [],
    fetchingPlans: false,
    fetchingServices: false,
    submitting: false,
    selectedPlan: null,
    coupon: null,
    choice: "month",
    selectedServices: [],
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    features() {
      return SubscriptionPlanFeatures;
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },

    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },

    canSubscribe() {
      let activateSubscription = true;

      const isSamePlan = this.selectedPlan.id === this.currentPlan?.id;
      if (isSamePlan) {
        let newServiceAdded = false;

        for (const service of this.selectedServices) {
          const found = this.currentPlanServices.find(
            (x) => x.id === service.id
          );
          if (!found) {
            newServiceAdded = true;
            break;
          }
        }

        activateSubscription = newServiceAdded;
      }

      return activateSubscription;
    },
  },

  mounted() {
    this.fetchCreditCards();
    this.fetchSubscriptionPlans();
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,

    create() {
      this.$refs.SubscriptionCode.openModal();
    },

    fetchCreditCards() {
      api.fetchCreditCards({ page: 1 })
        .then((response) => {
          if (response?.data?.data.length <= 0) {
            this.$refs.confirmModal.openModal();
          }
        }).catch((_) => {
          /**
           * TODO
           * Handle errors
           */
        });
    },

    handlePlanSelection(plan) {
      this.selectedPlan = plan;
    },

    addService(service) {
      const index = this.selectedServices.findIndex((x) => x.id === service.id);
      if (index === -1) {
        this.selectedServices.push({
          ...service,
          newlyAdded: true,
        });
        this.selectedPlan.value += service.value;
      }
    },

    removeService(id) {
      const index = this.selectedServices.findIndex((x) => x.id === id);
      if (index !== -1) {
        this.selectedPlan.value -= this.selectedServices[index].value;
        this.selectedServices.splice(index, 1);
      }

      const serviceIndex = this.services.findIndex(
        (service) => service.id === id
      );

      if (serviceIndex !== -1) {
        this.services[serviceIndex].added = false;
      }
    },

    fetchSubscriptionPlans() {
      this.fetchingPlans = true;

      api.fetchSubscriptionPlans()
        .then((response) => {
          this.plans = response.data.data.map((plan) => ({
            ...plan,
            basePrice: plan.value,
          }));
          const selectedPlan = this.plans.find(
            (plan) => plan.id === this.currentPlan.id
          );
          this.handlePlanSelection(selectedPlan);

          this.fetchSubscriptionPlanServices();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingPlans = false;
        });
    },

    fetchSubscriptionPlanServices() {
      this.fetchingServices = true;

      this.clearErrorsMessages();
      api.fetchSubscriptionPlanServices()
        .then(s => {
          this.services = s;

          for (let index = 0; index < this.services.length; index++) {
            const service = this.services[index];

            const serviceFound = this.currentPlanServices.find(
              (x) => x.id === service.id
            );
            let added = false;
            if (serviceFound) {
              added = true;

              this.selectedServices.push({
                ...service,
                newlyAdded: false,
              });
              this.selectedPlan.value += service.value;
            }

            this.services[index].added = added;
          }
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.fetchingServices = false;
        });
    },

    async subscribeToPlan() {
      if (this.submitting) return;
      this.submitting = true;

      try {
        const response = await api.subscribeToPlan(
          this.selectedPlan.id,
          this.coupon
            ? {
                planservices: this.selectedServices
                  .filter((service) => service.newlyAdded)
                  .map((service) => service.id),
                coupon: this.coupon.code,
              }
            : {
                planservices: this.selectedServices
                  .filter((service) => service.newlyAdded)
                  .map((service) => service.id),
              }
        );

        this.$store.dispatch("user/saveUser", {
          ...this.currentProvider,
          my_current_plan: response.data.my_current_plan,
          my_current_planservices: response.data.my_current_planservices,
        });
        toast.success("Subscription successfully done.");
        this.coupon = null;
        this.fetchSubscriptionPlans();
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.submitting = false;
      }
    },

    clearErrorsMessages() {
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            toast.error(err.response.data[error]);
            this.errors.serverSideErrorMessage = err.response.data[error];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
