export const setApproval = ({ commit }, payload) => {
  commit("setApproval", payload);
};

export const forget = ({ commit }) => {
  commit("setApproval", 0);
};

export const setCustomerNeededToCompleteProfile = ({ commit }, payload) => {
  commit("setCustomerNeededToCompleteProfile", payload);
};
export const setJobProfile = ({ commit }, payload) => {
  commit("setJobProfile", payload);
};
export const setInvoiceProfile = ({ commit }, payload) => {
  commit("setInvoiceProfile", payload);
};
export const setEstimateProfile = ({ commit }, payload) => {
  commit("setEstimateProfile", payload);
};

export const resetCustomerNeededToCompleteProfile = ({ commit }) => {
  commit("setCustomerNeededToCompleteProfile", null);
};
