<template>
  <div class="row">
    <plan-item
      v-for="(plan, index) in plans"
      :key="index"
      :plan="plan"
      :is-selected="plan.id === selectedPlanId"
      @onSelectPlan="handlePlanSelection(plan)"
    />
  </div>
</template>

<script>
import PlanItem from "@/components/subscription-plans/PlanItem.vue";

export default {
  name: "PlanList",

  components: {
    PlanItem,
  },

  props: {
    plans: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selectedPlanId: null,
  }),

  computed: {
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
  },

  created() {
    this.selectedPlanId = this.currentPlan.id
  },

  methods: {
    handlePlanSelection(plan) {
      this.selectedPlanId = plan.id;
      this.$emit("onPlanSelected", plan);
    },
  },
};
</script>
