<template>
  <section class="content">
    <div class="page-header ps-0">
      <div class="title-part mb-0">
        <p class="mb-0 mind">
          Invitations to bid
        </p>
      </div>

      <Datepicker v-if="false" />
    </div>

    <subcontract-list
      :subcontracts="jobs"
      @fetchNextPage="onFetchNextJobsPage"
    />

    <loader-spinner v-if="fetching" />
  </section>
</template>

<script>
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import Datepicker from "@/components/AppDatePicker.vue";
import SubcontractList from "@/components/provider/jobs/SubcontractList.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

export default {
  name: "ProviderSubcontract",

  components: { SubcontractList, Datepicker, LoaderSpinner },

  mixins: [ListMixin(api.fetchAllSubcontract, true, false)],

  data: () => ({
    jobs: [],
    fetching: false,
    maxPagesReached: false,
  }),

  watch: {
    listData(val) {
      this.jobs = this.jobs.concat(val);
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchAllSubcontract();
  },

  methods: {
    fetchAllSubcontract() {
      this.fetching = true;
      this.updateListFilters({
        page: 1,
      });
    },

    onFetchNextJobsPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        page: this.currentPage + 1,
      });
    },
  },
};
</script>
