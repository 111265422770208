<template>
  <div
    class="register-section col-lg-10 col-xl-8 mx-auto"
  >
    <p class="title-auth text-center text-primary mb-3">
      Sign up
    </p>

    <p class="text-secondary mx-auto text-center">
      You will be assigned the "free" plan until you select a different plan
    </p>

    <form
      class="mx-auto mt-4"
      @submit.prevent="register"
    >
      <alerte-danger
        :head-error-message="errors.serverSideErrorMessage"
        @updateContent="errors.serverSideErrorMessage = $event"
      />
      <alerte-danger
        :head-error-message="errors.otherErrorMessage"
        @updateContent="errors.otherErrorMessage = $event"
      />

      <div class="wrapper mb-3">
        <input
          id="option-1"
          v-model="profile"
          class="shadow-none"
          type="radio"
          :value="userScopes.CUSTOMER"
        >
        <label
          for="option-1"
          class="option option-1"
        >
          <span>Customer</span>
          <div class="dot" />
        </label>

        <input
          id="option-2"
          v-model="profile"
          class="shadow-none"
          type="radio"
          :value="userScopes.PROVIDER"
        >
        <label
          for="option-2"
          class="option option-2"
        >
          <span>Provider</span>
          <div class="dot" />
        </label>
      </div>

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="images.vectorPerson"
            alt="icon user"
            class="position-absolute ms-3 email"
          >
          <input
            v-model="fullname"
            type="text"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                (v$.fullname.$invalid && v$.fullname.$dirty) ||
                errors.fullnameErrorMessage,
            }"
            placeholder="Full name"
            @blur="v$.fullname.$touch"
          >
        </div>
        <template
          v-if="
            (v$.fullname.$invalid && v$.fullname.$dirty) ||
              errors.fullnameErrorMessage
          "
        >
          <small
            v-if="v$.fullname.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.fullname.minLength.$invalid"
            class="invalid-feedback"
          >
            Must contain at least 3 characters
          </small>

          <small
            v-else-if="errors.fullnameErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.fullnameErrorMessage }}
          </small>
        </template>
      </div>

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="images.vectorEmail"
            alt="icon email"
            class="position-absolute ms-3 email"
          >
          <input
            v-model="email"
            type="email"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                (v$.email.$invalid && v$.email.$dirty) ||
                errors.emailErrorMessage,
            }"
            placeholder="Email"
            @blur="v$.email.$touch"
          >
        </div>
        <template
          v-if="
            (v$.email.$invalid && v$.email.$dirty) || errors.emailErrorMessage
          "
        >
          <small
            v-if="v$.email.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.email.email.$invalid"
            class="invalid-feedback"
          >
            Must be a valid email address
          </small>

          <small
            v-else-if="errors.emailErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.emailErrorMessage }}
          </small>
        </template>
      </div>

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="images.vectorPassword"
            alt="icon password"
            class="position-absolute ms-3 email"
          >
          <img
            v-if="showPassword"
            :src="images.vectorEye"
            alt="icon password"
            class="position-absolute eye"
            @click="togglePasswordVisibility"
          >
          <img
            v-else
            :src="images.vectorEyeOff"
            alt="icon password"
            class="position-absolute eye-off"
            @click="togglePasswordVisibility"
          >
          <input
            v-model.trim="password"
            :type="showPassword ? 'text' : 'password'"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                (v$.password.$invalid && v$.password.$dirty) ||
                errors.passwordErrorMessage,
            }"
            placeholder="Password"
            @blur="checkPasswordsValidity"
          >
        </div>
        <template
          v-if="
            (v$.password.$invalid && v$.password.$dirty) ||
              errors.passwordErrorMessage
          "
        >
          <small
            v-if="v$.password.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </small>

          <small
            v-else-if="v$.password.minLength.$invalid"
            class="invalid-feedback"
          >
            The minimum size for this field is 6 characters
          </small>

          <small
            v-else-if="errors.passwordErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.passwordErrorMessage }}
          </small>
        </template>
      </div>

      <div class="mb-3">
        <div class="position-relative">
          <img
            :src="images.vectorPassword"
            alt="icon password"
            class="position-absolute ms-3 email"
          >
          <img
            v-if="showConfirmPassword"
            :src="images.vectorEye"
            alt="icon password"
            class="position-absolute end-0 pe-2 eye"
            @click="toggleConfirmPasswordVisibility"
          >
          <img
            v-else
            :src="images.vectorEyeOff"
            alt="icon password"
            class="position-absolute end-0 pe-2 eye-off"
            @click="toggleConfirmPasswordVisibility"
          >

          <input
            v-model="confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            class="form-control ps-5 shadow-none"
            :class="{
              'is-invalid':
                v$.confirmPassword.$invalid && v$.confirmPassword.$dirty,
            }"
            placeholder="Confirm Password"
            @blur="checkPasswordsValidity"
          >
        </div>
        <template
          v-if="v$.confirmPassword.$invalid && v$.confirmPassword.$dirty"
        >
          <small
            v-if="v$.confirmPassword.sameAs.$invalid"
            class="invalid-feedback"
          >
            The passwords don't match
          </small>
        </template>
      </div>
      <div class="mb-3">
        <p class="mt-3">
          <input
            v-model="politique"
            class="d-inline shadow-none"
            type="checkbox"
          >
          I agree to 
          <a 
            href="https://serviceloop.co/terms-of-service/" 
            target="_blank" 
            rel="noopener noreferrer"
            class=" text-decoration-underline"
          >
            the terms of service
          </a>       
        </p>
        <template v-if="errors.politiqueErrorMessage">
          <small class="invalid-feedback">
            This field is required
          </small>
        </template>
      </div>
      
      <div class="text-center">
        <button
          type="submit"
          class="btn-primary submit-button"
          :disabled="loading"
        >
          <submission-spinner v-if="loading" />
          Sign Up
        </button>
      </div>

      <p class="back-to-login">
        Already have an account?
        <router-link
          :to="{ name: 'Login' }"
          class="text-decoration-underline"
        >
          Log In
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import vectorPerson from "@/assets/img/icons/symbols_person.svg";
import vectorEmail from "@/assets/img/icons/symbols_mail.svg";
import vectorPassword from "@/assets/img/icons/symbols_password.svg";
import vectorEye from "@/assets/img/icons/symbols_eye.svg";
import vectorEyeOff from "@/assets/img/icons/symbols_eye_off.svg";

import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";

import api from "@/services/api";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";
import { ProviderStatus, UserScopes } from "@/configs/constants/users";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import { GlobalConstants } from '@/configs/constants';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "RegisterPage",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    images: {
      vectorPerson,
      vectorEmail,
      vectorPassword,
      vectorEye,
      vectorEyeOff,
    },
    fullname: null,
    email: null,
    password: null,
    confirmPassword: null,
    redirectLink: null,
    profile: null,
    politique: false,
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    errors: {
      emailErrorMessage: null,
      fullnameErrorMessage: null,
      politiqueErrorMessage: false,
      passwordErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      fullname: {
        required,
        minLength: minLength(3),
      },

      email: {
        required,
        email,
      },

      password: {
        required,
        minLength: minLength(6),
      },

      confirmPassword: {
        sameAs: sameAs(this.password),
      },
    };
  },

  computed: {
    userScopes() {
      return UserScopes;
    },
  },

  created() {
    const allRoutes = this.$router.getRoutes();
    this.redirectLink = `${
      GlobalConstants.BASE_URL +
      allRoutes.find((route) => route.name == "VerifyEmail").path
    }`;
  },

  mounted() {
    if (this.$route.query?.origin) {
      switch (this.$route.query.origin.toUpperCase()) {
        case UserScopes.CUSTOMER:
          this.profile = UserScopes.CUSTOMER;
          break;

        case UserScopes.PROVIDER:
          this.profile = UserScopes.PROVIDER;
          break;

        default:
          this.profile = UserScopes.CUSTOMER;
          break;
      }
    } else {
      this.profile = UserScopes.CUSTOMER;
    }
  },

  methods: {
    ...validationHelpers,

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    checkPasswordsValidity() {
      this.v$.password.$touch();
      this.v$.confirmPassword.$touch();
    },

    async register() {
      if (this.loading) return;
      if (this.politique == false) {
        this.errors.politiqueErrorMessage = true;
        return
      } 
      this.password = this.password?.trim() || null;
      this.confirmPassword = this.confirmPassword?.trim() || null;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }
      this.loading = true;
      this.clearErrorsMessages();

      const body = {
        email: this.email,
        password: this.password,
        full_name: this.fullname,
        redirectLink: this.redirectLink,
        scope: this.profile.toUpperCase(),
      };
      try {
        await api.register(body, (data) => {
          if (data.scope === UserScopes.PROVIDER) {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.CUSTOMER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            if (data.status === ProviderStatus.NEED_COMPLETE_PROFILE) {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderProfile",
                }
              );
            } else {
              this.$router.push(
                this.$route.query.redirectFrom || {
                  name: "ProviderDashboard",
                }
              );
            }
          } else {
            if (
              this.$route.query.redirectFrom?.includes(
                UserScopes.PROVIDER.toLowerCase()
              )
            ) {
              this.$route.query.redirectFrom = null;
            }

            this.$router.push(
              this.$route.query.redirectFrom || { name: "CustomerDashboard" }
            );
          }
        });
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      this.errors.emailErrorMessage = null;
      this.errors.fullnameErrorMessage = null;
      this.errors.passwordErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[`${error}ErrorMessage`] = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
