import { Request } from "@/services/request";

const BASE_URL = 'api/v1/bid/';

export default {
  createBid(data) {
    return new Request().append(BASE_URL).method("post").setData(data);
  },

  checkIfHaveBid(projectId) {
    return new Request()
      .append(`${BASE_URL}have_bid/`)
      .method("get")
      .params({ project: projectId });
  },

  fetchBid(id) {
    return new Request().append(`${BASE_URL}${id}/`).method("get");
  },

  fetchBids() {
    return new Request().append(`${BASE_URL}bid_awarded/`).method("get");
  },

  fetchRemainingBidCount() {
    return new Request().append(`${BASE_URL}remaining/`).method("get");
  },

  makeBidActive(id) {
    return new Request().append(`${BASE_URL}${id}/make_active/`).method("post");
  },

  makeBidInactive(id) {
    return new Request()
      .append(`${BASE_URL}${id}/make_inactive/`)
      .method("post");
  },


};
