<template>
  <div :class="classes">
    <div
      v-if="url"
      :style="{ 'background-image': `url(${url})` }"
      class="user-profile-photo"
    />
    <avatar
      v-else
      :name="fullname"
      :size="size || 48"
      :color="color"
      :background="background"
      class="proposal-avatar"
      alt="Profile avatar"
    />
  </div>
</template>

<script>
import Avatar from "vue3-avatar";

export default {
  name: "ProfileAvatar",

  components: {
    Avatar,
  },

  props: {
    url: {
      type: String,
      required: false,
      default: ""
    },

    fullname: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    },

    background: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      required: false,
      default: 48
    },

    classes: {
      type: String,
      required: false,
      default: ""
    },
  },
};
</script>