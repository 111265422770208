import { Request } from "@/services/request";

const baseUrl = "api/v1/chat/room"

export default {
  fetchRooms(search = null) {
    return new Request()
      .append(`${baseUrl}/`)
      .method("get")
      .params({ search });
  },

  fetchRoom(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  fetchRoomByProposalId(id) {
    return new Request().append(`api/v1/bid/${id}/bid_room/`).method("get");
  },

  openNewRoom(data) {
    return new Request()
      .append(`${baseUrl}/open_room/`)
      .method("post")
      .setData(data);
  },
};