<template>
  <div>
    <list-header
      title="Providers"
      :options="headerOptions"
      :search="true"
      @update:options="
        fetchAllProviders(
          $event.value ? { page: 1, status: $event.value } : { page: 1 }
        ),
        (headerOptions.title = $event.title),
        (status = $event.value),
        (page = 1)
      "
      @update:search="
        fetchAllProviders($event ? { page: 1, search: $event } : { page: 1 }),
        (search = $event),
        (page = 1)
      "
    />

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table
              :list="thead"
              :error="error"
            />
            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td>
                  <router-link
                    :to="{
                      name: 'SuperAdminProviderDetails',
                      params: { id: item.id, tag: 'info' }
                    }"
                    class="text-primary"
                  >
                    {{ item.user.full_name }}
                    <span 
                      v-if="item?.new"
                      class="p-1 bg-danger border border-light rounded-circle d-inline-block"
                    />
                  </router-link>
                </td>

                <td>
                  {{ item.user.email }}
                </td>

                <td>
                  <rating-stars :rating="item.rating ?? 0" />
                </td>

                <td>
                  {{ formatDate(item.user.created_at) }}
                </td>

                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <option-action
                    :status="item.status"
                    :options="[...ProviderStatusList, {
                      title: 'Login as',
                      value: 'login',
                    },]"
                    @update:options="confirmClaim(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <loader-spinner v-if="fetching" />
        </div>
      </div>
      
      <app-pagination
        v-if="totalPages > 1"
        :align-end="true"
        :current-page="currentPage"
        :has-previous-page="hasPreviousPage"
        :previous-page="currentPage - 1 || 1"
        :has-next-page="hasNextPage"
        :next-page="currentPage + 1"
        :per-page="itemsPerPage"
        :total-elements="itemsCount"
        :total-pages="totalPages"
        @onGotoPage="fetchAllProviders({ page: $event, search })"
        @onPrev="fetchAllProviders({ page: currentPage - 1 || 1, search })"
        @onNext="fetchAllProviders({ page: currentPage + 1, search })"
      />
    </div>

    <account-refusal-reason-modal
      ref="accountRefusalReasonModal"
      @onConfirm="onConfirmRefusal"
    />
    <confirm-modal
      ref="confirmModal"
      :ok-button-title="confirmationOkBtnText"
      :message="confirmationMessage"
      @onConfirm="updateStatus"
    />
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import OptionAction from"@/components/OptionAction.vue";
import RatingStars from "@/components/RatingStars.vue";
import AccountRefusalReasonModal from "@/components/provider/AccountRefusalReasonModal.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import statusToUI from "@/utils/statusFormate";
import { DateHelpers } from "@/utils/date-helpers";
import { ProviderStatusList, ProviderStatus, UserScopes } from "@/configs/constants/users";
import errorMessages from '@/utils/error-messages';
import LoaderSpinner from '@/components/LoaderSpinner.vue';
import AppPagination from '@/components/AppPagination.vue';

export default {
  name: "SuperadminProvidersPage",

  components: {
    RatingStars,
    ListHeader,
    HeadTable,
    ConfirmModal,
    OptionAction,
    AccountRefusalReasonModal,
    LoaderSpinner,
    AppPagination,
  },

  mixins: [ListMixin(api.fetchAllProviders, true)],
  data: () => ({
    ProviderStatusList,
    headerOptions: {
      title: ProviderStatusList[0].title,
      list: [
        ...ProviderStatusList,
        {
          title: "Incomplete profile",
          value: ProviderStatus.NEED_COMPLETE_PROFILE,
        },
        {
          title: "Awaiting for approval",
          value: ProviderStatus.NEED_APPROVAL,
        },
      ],
    },
    thead: ["Fullname", "Email", "Rating", "Date Joined", "Status", ""],
    confirmationOkBtnText: null,
    confirmationMessage: null,
    selected: null,
    status: null,
    listData: [],
    page: 1,
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    fetching: false,
    error: null
  }),

  computed: {
    approvalNumber() {
      return this.$store.getters["sDashState/approvalNumber"];
    },

    providerStatus() {
      return ProviderStatus;
    },
  },
  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchAllProviders({ page: this.page });
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,

    confirmClaim(element, status) {
      this.selected = element;
      this.status = status;
      let message = "";
      const fullname = this.selected.user.full_name;

      switch (this.status) {
        case this.providerStatus.ACTIVE:
          message = `Are you sure you want to activate ${fullname} account ?`;
          break;

        case this.providerStatus.DEACTIVATE:
          message = `Are you sure you want to deactivate ${fullname} account ?`;
          break;

        case 'login':
          message = `Are you sure you want to login as ${fullname} ?`;
          break;

        case this.providerStatus.REFUSED:
          this.$refs.accountRefusalReasonModal.openModal();
          break;

        default:
          message = `Are you sure you want to make ${this.statusToUI(
            status
          )} ${fullname} account ?`;
          break;
      }

      if (this.status !== this.providerStatus.REFUSED) {
        this.confirmationMessage = message;
        this.confirmationOkBtnText = "Yes";
        this.$refs.confirmModal.openModal();
      }
    },

    onConfirmRefusal(reason) {
      this.updateStatus(reason);
    },

    updateStatus(refusalReason) {
      if (this.status == 'login') {
        this.loginAsProvider()
      } else { 
        if (this.loading) return;
        this.loading = true;
        const oldStatus = this.selected.status;
        this.error = null;
        let _request = api.updateProviderStatus(this.selected.id, this.status);
        if (refusalReason) {
          _request = api.updateProviderStatus(
            this.selected.id,
            this.status,
            refusalReason
          );
        }

        _request
          .then((_) => {
            if (this.status === ProviderStatus.NEED_APPROVAL) {
              this.$store.commit(
                "sDashState/setApproval",
                this.approvalNumber + 1
              );
            }
            if (oldStatus === ProviderStatus.NEED_APPROVAL) {
              this.$store.commit(
                "sDashState/setApproval",
                this.approvalNumber - 1
              );
            }

            this.fetchAllProviders({ page: this.page });
            this.confirmationOkBtnText = null;
            this.confirmationMessage = null;
            this.selected = null;
            this.status = null;
            this.$refs.confirmModal.closeModal();
          })
          .catch((error) => {
            this.errorsHandler(error)
          })
          .then(() => {
            this.loading = false;
          });
      }
    },

    fetchAllProviders(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          this.error = err.response.data.error
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    async loginAsProvider() {
      this.loading = true;

      try {
        await api.loginAsProvider(this.selected.id, (data) => {
          if (
            this.$route.query.redirectFrom?.includes(
              UserScopes.CUSTOMER.toLowerCase()
            )
          ) {
            this.$route.query.redirectFrom = null;
          }

          if (data.status === ProviderStatus.NEED_COMPLETE_PROFILE) {
            this.$router.push(
              this.$route.query.redirectFrom || {
                name: "ProviderProfile",
              }
            );
          } else {
            this.$router.push(
              this.$route.query.redirectFrom || {
                name: "ProviderDashboard",
              }
            );
          }
        });
      } catch (err) {
        console.error(err);
        // this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>