import { UserScopes } from "@/configs/constants/users";

export const token = (state) => state.token;

export const deviceId = (state) => state.deviceId;

export const isAuthenticated = (state) => state.token != null;

export const isCustomer = (state) => state.token?.scope === UserScopes.CUSTOMER;

export const isProvider = (state) => state.token?.scope === UserScopes.PROVIDER;

export const isSuperAdmin = (state) => {
  return state.token?.scope === UserScopes.SUPER_ADMIN;
};
