<template>
  <div class="navbar w-100 mb-3">
    <div class="container-fluid p-0">
      <div class="d-flex align-items-center justify-content-between gap-1">
        <h4 class="title-content">
          {{ title }}
        </h4>
        <slot />
      </div>

      <div
        v-if="start && end"
        class="d-flex align-items-center justify-content-between gap-3"
      >
        <div
          v-if="start"
          class="position-relative"
        >
          <input
            type="date"
            class="position-absolute w-100 h-100 opacity-0"
            title="Start Date"
            @input="$emit('update:start', $event.target.value)"
          >
          <div
            class="dropdown-toggle"
          >
            <img
              alt="filtericon"
              :src="filtericon"
            >
            <span>Start: {{ start }}</span>
          </div>
        </div>

        <div
          v-if="end"
          class="position-relative"
        >
          <input
            type="date"
            class="position-absolute w-100 h-100 opacity-0"
            title="End Date"
            @input="$emit('update:end', $event.target.value)"
          >
          <div
            class="dropdown-toggle"
          >
            <img
              alt="filtericon"
              :src="filtericon"
            >
            <span>End: {{ end }}</span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-items-center justify-content-between gap-3"
        :class="{'filter-blur': hide}"
      >
        <div
          v-if="options?.list && hide == false"
          class="nav-item dropdown"
        >
          <div
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              alt="filtericon"
              :src="filtericon"
            >
            {{ options.title }}
          </div>
          <ul
            class="dropdown-menu"
          >
            <li
              v-for="(item, index) in options.list.filter(
                (el) => el.title !== options.title
              )"
              :key="index"
            >
              <div
                class="dropdown-item cursor-pointer"
                @click="filter(item)"
              >
                {{ item.title }}
              </div>
            </li>
          </ul>
        </div>

        <div class="input-group">
          <input
            v-if="search"
            v-model="text"
            class="ms-3 form-control shadow-none"
            type="search"
            :placeholder="hide ? 'Locked' : 'Search'"
            aria-label="Search"
            :disabled="hide"
          >
          <button
            v-if="search"
            class="btn-primary"
            type="submit"
            :disabled="hide"
            @click="searching"
          >
            {{ hide ? "Locked" : "Search" }}
          </button>
        </div>
        <button
          v-if="add"
          class="btn-primary"
          type="buton"
          :disabled="hide"
          @click="create"
        >
          {{ hide ? "Locked" : "Add" }}
        </button>
        <button
          v-if="hasBulkCreate"
          class="btn-secondary"
          type="button"
          :disabled="hide"
          @click="bulkCreate"
        >
          {{ hide ? "Locked" : "Bulk Add" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import filtericon from "@/assets/img/icons/filtericon.svg";
export default {
  name: "ListHeader",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    add: {
      type: Boolean,
      required: false,
      default: false,
    },
    start: {
      type: String,
      required: false,
      default: null,
    },
    end: {
      type: String,
      required: false,
      default: null,
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    text: "",
    filtericon,
  }),
  computed: {
    hasBulkCreate() {
      return this.$attrs && this.$attrs.onBulkCreate
    }
  },
  methods: {
    filter(select) {
      if (this.hide === false) {
        this.$emit("update:options", select);
      }
    },
    searching() {
      if (this.hide === false) {
        this.$emit("update:search", this.text);
      }
    },
    create() {
      if (this.hide === false) {
        this.$emit("create");
      }
    },
    bulkCreate() {
      this.$emit("bulkCreate");
    }
  },
};
</script>

<style scoped>
.form-control {
  padding: .375rem .75rem;
}

h4 {
  margin-bottom: 0;
}

.btn-secondary {
  width: 9rem;
}
</style>